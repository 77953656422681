const {getWisdPresalePrice, saveCryptoPresalePaymentAPI} = require("../../../apihelper/presale");


const getPresalePriceData = async (round) => {
    try {
        const result = await getWisdPresalePrice(round);
        if (result.code) {
            throw new Error(`Error: ${result.code} ${result.message}`);
        }
        return result;
    } catch (error) {
        console.error("Error fetching presale price data:", error);
    }
};

const saveCryptoPresalePayment = async (recipient_address, sender_address, signature_hash, amount, type) => {
  try {
      const data = {
        recipient_address,
        sender_address,
        signature_hash,
        amount,
        type
      }
      console.log("data")
      const result = await saveCryptoPresalePaymentAPI(data);
      console.log("Result", result)
      if (result.code) {
          throw new Error(`Error: ${result.code} ${result.message}`);
      }
      return result;
  } catch (error) {
      console.error("Error fetching presale price data:", error);
  }
};

const handleStripeCheckout = async (price_id) => {
    try {
        const response = await fetch("https://investing.crowdwisdom.live/create-onetime-checkout-session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({price_id}), // Send the price_id in the request body
        });

        const session = await response.json();

        if (session.error) {
            console.error("Server Error: ", session.error); 
            return;
        }

        if (session.url) {
            // window.location.href = session.url; // Redirect to Stripe Checkout
            window.open(session.url, '_blank');
        }
    } catch (error) {
        console.error("Error during checkout: ", error);
    }
};

// const verifyTransactionBackend = async (signature, recipient, amount) => {
//     try {
//       const response = await fetch("https://investing.crowdwisdom.live/verify-payment", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           signature,
//           recipient,
//           amount,
//         }),
//       });
  
//       const data = await response.json();
  
//       if (data.success) {
//         console.log("✅ Payment verified successfully:", data);
//       } else {
//         console.log("❌ Payment verification failed:", data);
//       }
//     } catch (error) {
//       console.error("❌ Error verifying transaction on backend:", error);
//     }
//   };


  
// const verifyTransactionEthBackend = async (txHash, recipient, amount) => {
//   try {
//     const response = await fetch("https://investing.crowdwisdom.live/verify-peyment-eth", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         txHash,
//         recipient,
//         amount,
//       }),
//     });

//     const data = await response.json();

//     if (data.success) {
//       console.log("✅ Payment verified successfully:", data);
//     } else {
//       console.log("❌ Payment verification failed:", data);
//     }
//   } catch (error) {
//     console.error("❌ Error verifying transaction on backend:", error);
//   }
// };
   

module.exports = {getPresalePriceData, handleStripeCheckout, saveCryptoPresalePayment};
