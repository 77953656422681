import { BorderBottom } from '@mui/icons-material';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import { Grid, useStepContext } from "@mui/material";
import Typography from '@mui/material/Typography';
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import IconButton from "@mui/material/IconButton";
import Drawer from '@mui/material/Drawer';
import Box from "@mui/material/Box";
import { Tabs, Tab, Button } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useLocation } from "react-router-dom";
import LoginPopUp from "./loginpopup";



const drawerWidth = 292;

function SubscriptionTab({ title, price, categories,onSelectAmount, price_id }) {
  const amount = parseFloat(price.replace('$', '')) * 100;
  return (
    <Box 
      sx={{ 
        padding: 2, 
        textAlign: 'center',
        borderRadius: 3,  // Rounded corners for the card
        border: '1px solid #ccc',  // Border around the card
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Light shadow for a lifted effect
        overflow: 'hidden', 
        backgroundColor: '#fff',
        margin:'auto',
        marginLeft:"8px"
      }}
    >
      {/* Title and Price section with gradient background */}
      <Typography
        variant="h6"
        component="div"
        style={{
          fontFamily: "Satoshi, sans-serif",
          fontWeight: 500,
          fontSize: "26px",
          margin:"5px"
          
        }}
      > 
        <span style={{
          backgroundColor:"#1877f2",
          padding:"6px 8px",
          borderRadius:"5px",
          color:"white",
          marginLeft:'3px',
          
        }}> {title}</span>
      </Typography>
        
      <Typography variant="subtitle1"
    style={{
        fontSize: "26px",
        fontWeight: "bold"
    }}>
    {price}
    <span style={{
        fontSize: "18px",
        color: "grey"
    }}>
        {price === "$1" ? " /per month, Paid Annually" : " /year"}
    </span>
</Typography>



<Button 
        onClick={() => onSelectAmount(price_id)}
       
        sx={{ 
          marginTop: 3, 
          width: '65%', 
          borderRadius: '25px',
          backgroundColor: '#1877F2',
          color: '#fff',
          fontFamily:'Satoshi, sans-serif',
          fontWeight:'bold',
          textTransform: 'none',
          fontSize:'19px',
          ':hover': {
            backgroundColor: '#0A1D37' // Darker shade on hover
          }
        }}
      >
        Select Plan
      </Button>



<Box sx={{ marginTop: 2, textAlign: 'left' }}>
        {categories.map((category, catIndex) => (
          <Box key={catIndex} sx={{ marginBottom: 2 }}>
            <Typography variant="h6" fontWeight="bold" fontSize="18px" color="black">{category.categoryTitle}</Typography>
            {category.features.map((feature, featIndex) => (
              <Typography key={featIndex} sx={{ marginBottom: 1 }}>
                 <span style={{fontSize: "18px",marginRight:"5px"}}>
  <img src="/images/green-tick.svg" alt="Green Tick" style={{ width: "18px", height: "18px" }} />
</span>{feature}</Typography>
            ))}
          </Box>
        ))}
      </Box>
      
      
      
      {/* Subscribe Button */}
      <Button 
        onClick={() => onSelectAmount(price_id)}
        variant="contained" 
        sx={{ 
          marginTop: 3, 
          width: '70%', 
          borderRadius: '25px',
          backgroundColor: '#1877F2',
          color: '#fff',
          fontFamily:'Satoshi, sans-serif',
          fontWeight:'bold',
          textTransform: 'none',
          fontSize:'19px',
          ':hover': {
            backgroundColor: '#0A1D37' // Darker shade on hover
          }
        }}
      >
        Select Plan     
        </Button>
    </Box>
  );
}

const Screener360_Pricing = (props) => {
  
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { window1 } = props;
  const navigate = useNavigate();
  const location = useLocation(); 
  const loc = location.pathname;
  const container = window1 !== undefined ? () => window1().document.body : undefined;

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  const mediaQueryVar2 = useMediaQuery(
    json2mq({
      maxWidth: 410,
    })
  );

  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };


  const mediaQueryVar3 = useMediaQuery(
    json2mq({
      maxWidth: 415,
    })
  );

  const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    width: '100%',
    margin: mediaQueryVar? 'auto': "0px",
    backgroundColor: '#f7f9fc',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    maxWidth: mediaQueryVar? '850px':"100%",
    flex: "1",
    marginLeft:mediaQueryVar? "30%" : "",
    marginTop:"2%",
    paddingBottom:"1.5%",
    marginRight:mediaQueryVar? "" : "",
  },
  header: {
    background: 'linear-gradient(90deg, rgba(24, 119, 242, 0.17) 0.02%, rgba(24, 119, 242, 0.00) 123.27%)',
    padding: '15px',
    borderRadius: '0px 14px 0px 0px',
    marginBottom: '20px',
    textAlign: 'left',
    borderBottom:"1px solid #1877F2"
    
  },
  headerText: {
    margin: 0,
    color: '#000',
    fontWeight: '600',
    fontSize: mediaQueryVar? '20px' : "18px",
    marginLeft:mediaQueryVar? "2%" : "0px",
    fontFamily: "'Satoshi', sans-serif",
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr 1fr',
    gap: '20px',
    alignItems: 'start',
  },
  featureList: {
    paddingRight: mediaQueryVar? '20px':"-20px",
    borderRight: mediaQueryVar? '1px solid #D1D1D1' : "",
    height:mediaQueryVar? "70.8vh" : "",
    width:mediaQueryVar? "24vw" : (mediaQueryVar3?"45vw":"35vw"),
    marginRight:mediaQueryVar? "":(mediaQueryVar3?"-40px":"")
  },
  featureTitle: {
    fontSize: mediaQueryVar? '24px':"14px",
    marginTop: mediaQueryVar? '9%':"17%",
    fontWeight:"600",
    marginLeft:"9%"
  },
  features: {
    listStyleType: 'none',
    paddingLeft: '0',
    fontSize: '16px',
    lineHeight: '1.8em',
    marginLeft:"9%",
    marginRight:"5%"
  },
  plan1: {
    background: 'linear-gradient(180deg, #EBF3FF 0%, #FFF 100%)',
    border: '1px solid #B7D6FF',
    borderRadius: '16px',
    padding: mediaQueryVar? '20px':"20px 20px 10px 20px",
    textAlign: 'center',
    width:"100%",
    minWidth: mediaQueryVar? '' : "80px",
    maxWidth: mediaQueryVar? '160px' : "",
    marginLeft:mediaQueryVar? "4vw" : "",
    borderBottom:"1px solid #B7D6FF",
  },
  plan2: {
    background: 'linear-gradient(180deg, #EBF3FF 0%, #FFF 100%)',
    border: '1px solid #B7D6FF',
    borderRadius: '16px',
    padding: mediaQueryVar? '20px':"20px 20px 10px 20px",
    textAlign: 'center',
    width:"100%",
    minWidth: mediaQueryVar? '' : "80px",
    maxWidth: mediaQueryVar? '160px' : "",
    marginLeft: mediaQueryVar? '1vw':"0.1vw",
  },
  priceText: {
    fontSize: mediaQueryVar? '24px':"15.29px",
    fontWeight: '600',
    margin: '10px 0',
    marginBottom:"20%"
  },
  planFeatures: {
    listStyleType: 'none',
    paddingLeft: '0',
    fontSize: '20px',
    lineHeight: '2.2em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subscribeButton: {
    backgroundColor: '#1877F2',
    color: '#fff',
    padding: mediaQueryVar? '10px 24px 10px 18px': "4.572px 10.973px",
    border: 'none',
    borderRadius: '40px',
    cursor: 'pointer',
    marginTop: '20px',
    width:mediaQueryVar? "8.5vw":"20vw",
    marginLeft:mediaQueryVar?"-6px":(mediaQueryVar2?"-17px":"-15px"),
    fontSize: mediaQueryVar?"20px":"9.144px",
    fontWeight:"600",
    fontFamily:"'Satoshi', sans-serif"
  },
};

const handleCheckout = async (price_id, email) => {
  console.log("Price Id: ",price_id,email );
  try {
    const response = await fetch('https://investing.crowdwisdom.live/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ price_id, email }), // Send the price_id in the request body
    });

    const session = await response.json();

    if (session.error) {
      console.error('Server Error: ', session.error); // Log server error
      alert(`Error: ${session.error}`);
      return;
    }

    if (session.url) {
      window.location.href = session.url; // Redirect to Stripe Checkout
    }
  } catch (error) {
    console.error('Error during checkout: ', error);
  }
};



const [isLoginModalOpen, setLoginModalOpen] = useState(false);



const onSelectAmount = (price_id) => {
  const userData = localStorage.getItem("cw_portfolio_user");
      if (!userData) {
          const originalUrl = '/subscriptions/screener360';
          sessionStorage.setItem("originalUrl", originalUrl);
          window.history.pushState({}, "", "/login");
          localStorage.setItem("isPopupManuallyOpened", "true");

          // Open the login modal
          setLoginModalOpen(true);
          
          return;
      }
      else{
        const userData_full = JSON.parse(userData);

          // Access the email_id
        const emailId = userData_full.email_id;

        console.log("USER DATA:", emailId);
        handleCheckout(price_id, emailId); // Call handleCheckout with the selected amount
      }

 
};
  const drawer = (
    <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
<SidebarMenuList/>
    </div>
);

const handleDrawerToggle = () => {
  setMobileOpen(!mobileOpen);
};



const [selectedPlan, setSelectedPlan] = useState('Index Full Pack');

const planContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '15px',
  color: '#000',
  maxWidth: "1000px",
  fontFamily:"Satoshi, sans-serif",
  marginLeft:"25%",
  marginRight:"15px"
};

const cardStyle = (plan) => ({
  width: '100%',
  padding: '15px',
  borderRadius: '15px',
  textAlign: 'center',
  cursor: 'pointer',
  border: selectedPlan === plan ? '2px solid #ffb400' : '2px solid #1877F2',
  backgroundColor: selectedPlan === plan ? '#fff' : '#fff',
  boxShadow: selectedPlan === plan ? '0px 0px 20px rgba(255, 180, 0, 0.5)' : 'none',
  transition: 'all 0.3s ease',
});

const buttonStyle = {
  marginTop: '15px',
  marginBottom: '10px',
  padding: '9px 25px',
  borderRadius: '25px',
  backgroundColor: '#1877F2',
  color: '#fff',
  border: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  fontSize:"18px",
  transition: 'background-color 0.3s ease',
};

const selectPlan = (plan) => {
  setSelectedPlan(plan);
};


const [selectedTab, setSelectedTab] = useState(0);
  
  const plans = [
    
    {
      title: "Screener360",
      price: "$1",
      price_id: "price_1QV4J8KAXtGoEI6mJntRzF1L",
      categories: [
        {
          categoryTitle: "The Screener360 Indicators power Crowdwisdom360 Indices, consistently outperforming benchmarks and competitors.",
          features: [
       ]
        },

        {
            categoryTitle: "Screener360 Indicators",
            features: [
          "Gain access to 7 unique signals, including:  ",
          "Insights from all influencers",
          "Top 10% influencers",
          "Indices",
          "Whales",
          "News",
          "Social media trends",
          "Performance grades",
          "These indicators are available for both individual cryptocurrencies and crypto screeners."
         ]
          },
        
        
        {
          categoryTitle: "Notifications",
          features: [
            "Get free, unlimited notifications for crypto updates.",
            "Stay informed on price movements, market trends, and breaking news. "
          ]
        },

        {
            categoryTitle: "Influencer Predictions",
            features: [
              "Unlimited access to influencer predictions with accuracy and rankings.",
              "Use insights from the best-performing influencers to enhance your strategy."
            ]
          },

          {
            categoryTitle: "Top Portfolios",
            features: [
              "Access the top 10 performing portfolios designed for maximum returns and reduced risks.",
            ]
          },

          {
            categoryTitle: "Index Choice",
            features: [
              "Enjoy full access to one CW360 index every month, built using data-driven influencer signals.",
            ]
          },

          {
            categoryTitle: "Wisecoins Bonus*",
            features: [
              "Receive 2,500 Wisecoins worth $15 as a bonus.",
              "Redeemable for platform benefits or tradeable by Q2."
            ]
          },
      
      ],
     
    },
   
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  return (
    
    <div style={{backgroundColor:mediaQueryVar? "#EDF1F6":"#fff", paddingBottom:"15%",marginTop:mediaQueryVar? "2%": "", marginRight:mediaQueryVar? "":"-10px"}}>
    <Helmet>
        <title>Subscriptions</title>
      </Helmet>
      {isLoginModalOpen && (
        <LoginPopUp
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
      
      <Grid item xs={12} align="left">
    <div style={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between", // Changed from space-between to flex-end
        marginTop: "0px", 
        marginBottom: "10px",
        marginLeft: "5px"
    }}>
       
       {mediaQueryVar===false ? (
    <div style={{
      marginLeft:"10px",
      border:"2px solid #1877F2",
      borderRadius : "28px",
      padding:"2px 4px",
      backgroundColor :"#1877F2",
      color:"white",
      fontSize :"20px",
      fontWeight:"bold",
      width:"202px",
      textAlign:"center",
      height: "40px",
      marginTop:"20px"
    }}>
      <div style={{
        color:"black",
        backgroundColor:"white",
        borderRadius:"28px",
        width:"190px",
        padding:"2px 5px",
        fontFamily:"Satoshi, sans-serif",
        height: "33px",
      }}>
      Subscriptions
      </div>
      </div>
   ) : (
<div style={{
      marginLeft:"25%",
      border:"2px solid #1877F2",
      borderRadius : "28px",
      padding:"5px 4px",
      backgroundColor :"#1877F2",
      color:"white",
      fontSize :"20px",
      fontWeight:"bold",
      width:"210px",
      textAlign:"center",
      marginBottom:"10px",
      fontFamily:"Satoshi, sans-serif",
      height:"48px"
    }}>
      <div style={{
        color:"black",
        backgroundColor:"white",
        borderRadius:"28px",
        width:"200px",
        padding:"2px 6px",
        height:"35px"
      }}>
      Subscriptions
      </div>
      </div>

   )}
   
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
        >
            <img 
                src='/images/menubar.svg'
                alt="Menu Icon" 
                style={{ 
                    width: "24px", 
                    height: "24px",
                    marginRight: "10px",
                    marginTop: "10px" 
                }} 
            />
        </IconButton>


    </div>
</Grid>



<div className>
          <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}

                    sx={{

                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{

                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376'},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            </div>
  

    {mediaQueryVar ? (

<div style={planContainerStyle}>

{/* Plan 3: Crypto Screener Pack */}
<div
  style={cardStyle('Crypto Screener Pack')}
  onClick={() => selectPlan('Crypto Screener Pack')}
>
  <h4 style={{
  backgroundColor: '#1877F2',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
  fontSize:'26px',
  fontWeight: 'bold', // Optional for styling
}}>Screener360</h4>
   <h2 style={{fontWeight:"500",fontSize:"32px"}} >$1
  <span style={{
      fontSize: "16px",
      color:"grey"
    }}> /per month, Paid Annually</span>
  </h2>
  <button style={buttonStyle} onClick={() => onSelectAmount('price_1QV4J8KAXtGoEI6mJntRzF1L')}>Select Plan</button>

  <p style={{textAlign:"left",fontWeight:"bold",fontSize:"20px"}}>The Screener360 Indicators power Crowdwisdom360 Indices, consistently outperforming benchmarks and competitors.</p>
  
  <p style={{textAlign:"left",fontWeight:"bold",fontSize:"18px"}}>Screener360 Indicators</p>
  
  <p style={{textAlign:"left",fontWeight:"500",fontSize:"16px"}}>- Gain access to 7 unique signals, including:</p>
    <ul style={{ textAlign: "left", listStyleType: "none", fontSize: "16px", paddingLeft: "0" }}>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Insights from all influencers
      </li>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Top 10% influencers  
      </li>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Indices        </li>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Whales        </li>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        News        </li>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Social media trends        </li>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Performance grades        </li>
    </ul>
    <p style={{textAlign:"left",fontWeight:"500",fontSize:"16px"}}>- These indicators are available for both individual cryptocurrencies and crypto screeners.  </p>
   
 

<p style={{textAlign:"left",fontWeight:"bold",fontSize:"18px"}}>Notifications</p>

<ul style={{ textAlign: "left", listStyleType: "none", fontSize: "16px", paddingLeft: "0" }}>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Get free, unlimited notifications for crypto updates.      </li>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Stay informed on price movements, market trends, and breaking news.  
        </li>
</ul>

<p style={{textAlign:"left",fontWeight:"bold",fontSize:"18px"}}>Influencer Predictions</p>

<ul style={{ textAlign: "left", listStyleType: "none", fontSize: "16px", paddingLeft: "0" }}>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Unlimited access to influencer predictions with accuracy and rankings.        </li>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Use insights from the best-performing influencers to enhance your strategy.   
        </li>
</ul>

<p style={{textAlign:"left",fontWeight:"bold",fontSize:"18px"}}>Top Portfolios</p>

<ul style={{ textAlign: "left", listStyleType: "none", fontSize: "16px", paddingLeft: "0" }}>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Access the top 10 performing portfolios designed for maximum returns and reduced risks. </li>
    
</ul>

<p style={{textAlign:"left",fontWeight:"bold",fontSize:"18px"}}>Index Choice</p>

<ul style={{ textAlign: "left", listStyleType: "none", fontSize: "16px", paddingLeft: "0" }}>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Enjoy full access to one CW360 index every month, built using data-driven influencer signals.  </li>
    
</ul>
  
<p style={{textAlign:"left",fontWeight:"bold",fontSize:"18px"}}>Wisecoins Bonus</p>

<ul style={{ textAlign: "left", listStyleType: "none", fontSize: "16px", paddingLeft: "0" }}>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Receive 2,500 Wisecoins worth $15 as a bonus.   </li>
      <li style={{ position: "relative", paddingLeft: "25px" }}>
        <span
          style={{
            position: "absolute",
            left: "0",
            fontSize: "18px",
          }}
        >
          <img
            src="/images/green-tick.svg"
            alt="Green Tick"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
        Redeemable for platform benefits or tradeable by Q2.  
        </li>
</ul>



</div>


</div>
    ) : (
      

      <Box sx={{ maxWidth: 400, margin: 'auto'}}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"  // Makes the tabs scrollable
        scrollButtons="auto"  // Shows scroll buttons automatically when needed
        textColor="primary"
        indicatorColor="primary"
        sx={{ backgroundColor: '#f7f7f7', marginBottom: 2 ,marginLeft: "37%"}}
      >
        {plans.map((plan, index) => (
          <Tab label={plan.title} key={index}
          sx={{  padding: '6px 12px', marginRight: '4px',textTransform: 'none' }}
          />
        ))}
      </Tabs>
      <Box>
        {plans.map((plan, index) => (
          selectedTab === index && (
            <SubscriptionTab 
              key={index} 
              title={plan.title} 
              price={plan.price} 
              categories={plan.categories || []}  
              onSelectAmount={onSelectAmount} 
              price_id ={plan.price_id}
            />
          )
        ))}
      </Box>
    </Box>

    ) }
    {mediaQueryVar === false && (


<div
  style={{
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "105%",
    zIndex: "100",
    marginBottom:"0px",
    marginLeft:"-10px"
  }}
>
<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="Screener"
        onClick={() => 
          navigate("/crypto-screener")
        }
        icon={<img src={loc === '/crypto-screener' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
       label="FAQs"
       onClick={() => 
      navigate("/portfoliofaqs")
  }
  icon={<img src={loc==='/portfoliofaqs'? "/images/dollarS.svg":"/images/dollarU.svg"} width={22} height={22} alt="Icon" style={{marginBottom : "-3px"}}/>}
/>
</BottomNavigation>
</div>
)}
    </div>
    
    
  );
};



export default Screener360_Pricing;
