import { useEffect } from "react";
import { completeGoogleLogin } from "../../apihelper/login";

const handleGoogleLogin = async (redirectUrl) => {
    const searchParams = new URLSearchParams(window.location.search);
    const googleLoginCode = searchParams.get("code");

    if (!googleLoginCode) return;

    try {
        const data = await completeGoogleLogin(googleLoginCode);

        if (data.code === "200") {
            localStorage.setItem("cw_portfolio_user", JSON.stringify(data.data));
            const portfolioUserId = localStorage.getItem("portfolio_userId");

            // Dynamically get the current URL
            let redirectUrl = window.location.origin + window.location.pathname;

            if (portfolioUserId) {
                redirectUrl = redirectUrl;
                localStorage.removeItem("portfolio_userId");
            } else if (data.data.portfolio_form_redirect === "yes") {
                redirectUrl = `${window.location.origin}/profile`;
            } else {
                redirectUrl = `${window.location.origin}/home`;
            }

            window.location.replace(redirectUrl);
        } else {
            console.error("GOOGLE LOGIN FAILED");
        }
    } catch (error) {
        console.error("Error during Google login:", error);
    }
};

const GoogleLoginHandler = ({ completeGoogleLogin }) => {
    useEffect(() => {
        handleGoogleLogin(completeGoogleLogin);
    }, [completeGoogleLogin]);

    return null; // This component doesn't render anything
};

export default GoogleLoginHandler;
