import React from 'react';
import { Helmet } from 'react-helmet';

const MetaProperty = ({ 
  title = "Best Crypto Portfolios", 
  description = "Crowdwisdom360 helps you create a Market-Beating Crypto Portfolio using vetted Influencers and publicly available", 
  logo = "https://investing.crowdwisdom.live/images/CWLogo.webp",
  ogDescription = "Track and compare shadow portfolios from financial influencers.",
  twitterImageAlt = "coin image" 
}) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      
      {/* Static meta tags */}
      <meta property="og:description" content= {ogDescription}/>
      <meta property="og:url" content="https://investing.crowdwisdom.live/" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={logo} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={logo} />
      <meta name="twitter:image:alt" content={twitterImageAlt} />
     
    </Helmet>
  );
};

export default MetaProperty;
