import React, {useEffect, useState} from "react";
import {useAppKitAccount, useAppKitNetwork, useAppKitProvider, useDisconnect, useWalletInfo} from "@reown/appkit/react";
import {
    SystemProgram,
    PublicKey,
    Transaction,
    LAMPORTS_PER_SOL,
    Connection,
    clusterApiUrl,
    sendAndConfirmTransaction,
} from "@solana/web3.js";
import {saveCryptoPresalePayment} from "../presale_form/api";
import {recipientAddress} from "../../../common/constant/api_key";
import ProcessingAlert from "../../../common/alert/ProcessingAlert";
import SuccessPaymentAlert from "../../../common/alert/SuccessPaymentAlert";
import {solana} from "@reown/appkit/networks";
import { store } from "../../../common/appkitConfig.tsx";

const SOLConnectButton = ({paymentMethod, select_price}) => {
    const {isConnected, address} = useAppKitAccount();
    const {disconnect} = useDisconnect();
    const {walletProvider} = useAppKitProvider("solana", {
        autoSwitchNetwork: false,
    });
    const {switchNetwork, chainId} = useAppKitNetwork();
    const isWrongNetwork = chainId !== solana.id;

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    // for testnet
    // const connection = new Connection(clusterApiUrl("devnet"), "confirmed");

    // Testing quicknode api endpoint
    // const connection = new Connection("https://alpha-crimson-diagram.solana-mainnet.quiknode.pro/12683d2983a09482058b04c03b66a20eff0732b6/", "confirmed");
    store.solanaConnection = new Connection(
        "https://solana-mainnet.g.alchemy.com/v2/IesGw7t2mCeN_nIOQFp4avF8XCgZDq5P",
        "confirmed"
    );

    // for mainnet (not working)
    // const connection = new Connection(clusterApiUrl("mainnet-beta"), "confirmed");

    useEffect(() => {
        const switchToSolana = async () => {
            if (isWrongNetwork && switchNetwork) {
                alert("Switching to Solana network...");
                await switchNetwork(solana);
            }
        };
        switchToSolana();
    }, [isWrongNetwork, address, switchNetwork]);

    const initiateTransaction = async (provider, connection, address) => {
        console.log("Connection", connection, address)
        if (!address || !connection) throw Error('user is disconnected');
  
        const wallet = new PublicKey(address);
        if (!wallet) throw Error('wallet provider is not available');
  
        const latestBlockhash = await connection.getLatestBlockhash();
  
        const transaction = new Transaction({
            feePayer: wallet,
            blockhash: latestBlockhash.blockhash,
            lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
          }).add(
            SystemProgram.transfer({
              fromPubkey: wallet,
              toPubkey: new PublicKey(address), // destination address
              lamports: 1,
            })
          );
        console.log(":transaction", transaction)
  
         const response = await walletProvider.sendTransaction(transaction, connection)
          console.log("response", response)
        return response
    }
  

    const handleSendSol = async () => {
        if (!select_price) {
            alert("Please select an amount");
            return;
        }
        try {
            const response = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd");
            const data = await response.json();
            const solPriceInUsd = data.solana.usd;

            const priceInUSD = select_price;
            const amountInSol = priceInUSD / solPriceInUsd;
            console.log("Solana price in USD:", solPriceInUsd, amountInSol);
            console.log("walletProvider.publicKey.startsWith('0x')", walletProvider);
            // if (address.startsWith("0x")) {
            //     throw new Error("Invalid recipient address. Please connect a Solana wallet.");
            // }

            const recipientPublicKey = new PublicKey(recipientAddress);
            // const amountInLamports = Math.floor(amountInSol * LAMPORTS_PER_SOL);
            const amountInLamports = 1;
            // Get user's SOL balance
            const balance = await store.solanaConnection.getBalance(walletProvider.publicKey);
            const balanceInSol = balance / LAMPORTS_PER_SOL; // Convert balance from lamports to SOL
            store.accountState.address = address;
            console.log(`User Balance: ${balanceInSol} SOL`);
            console.log(`SOL Required: ${amountInSol} SOL`);
            console.log(`store Required: `, store);

            const result = await initiateTransaction(store.solanaProvider, store.solanaConnection, store.accountState.address)

            // Check if balance is sufficient
            // if (balance < amountInLamports) {
            //     alert("Insufficient balance. Please add more SOL to your wallet.");
            //     return;
            // }
            
            console.log(`Balance: ${amountInLamports} SOL`);
            // const {blockhash} = await connection.getLatestBlockhash("confirmed");

            // const transaction = new Transaction();
            // transaction.add(
            //     SystemProgram.transfer({
            //         fromPubkey: walletProvider?.publicKey,
            //         toPubkey: recipientPublicKey,
            //         lamports: amountInLamports, // Send the amount in lamports
            //     })
            // );

            // transaction.feePayer = walletProvider.publicKey;
            // transaction.recentBlockhash = blockhash;

            // setLoading(true);
            // console.log("error_test");
            // const signature = await walletProvider.sendTransaction(transaction, connection);
            // console.log("signedTransaction", signature);
            // await connection.confirmTransaction(signature, {commitment: "processed"});
            const type = "SOL";
            // const verify_response = await verifyTransactionBackend(signature, recipientPublicKey.toBase58(), amountInSol);
            // await saveCryptoPresalePayment(
            //     recipientPublicKey.toBase58(),
            //     walletProvider.publicKey.toBase58(),
            //     signature,
            //     select_price,
            //     type
            // );
            // console.log("Verification response:", verify_response);
            setIsSuccess(true);
        } catch (error) {
            console.error("Error sending SOL:", error);
            alert(`An error occurred: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };
    // const handleDisconnect = async () => {
    //     setLoading(true);
    //     try {
    //         await disconnect();
    //         setIsSuccess(false);
    //     } catch (error) {
    //         console.error("Disconnection failed:", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     if (isConnected) {
    //         handleDisconnect();
    //     }
    // }, [paymentMethod]);

    return loading ? (
        <ProcessingAlert />
    ) : (
        <>
            <div style={{textAlign: "center"}}>
                <appkit-button />
                {isConnected && <button onClick={() => handleSendSol()}>Pay with {paymentMethod.toUpperCase()}</button>}
            </div>
            <SuccessPaymentAlert open={isSuccess} onClose={() => setIsSuccess(false)} />
        </>
    );
};

export default SOLConnectButton;
