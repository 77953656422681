import Footer from "../../common/footer/Footer";
import Header from "../../common/header";
import "./presale.css";
import MeetTeam from "./meet_team/MeetTeam";
import PresaleForm from "./presale_form/PresaleForm";
import Investment from "./other_components/Investment";
import Products from "./other_components/Products";
import ParticipatingSection from "./other_components/ParticipatingSection";
import Chart from "./Chart/Chart";
import RoadMap from "./roadmap/RoadMap";

export default function Presale() {
    return (
        <div className="main-div presale page-wrapper">
            <Header />
            <PresaleForm  className="d-flex justify-content-center align-items-center"/>
            <div className="d-flex justify-content-center align-items-center">
                <div className="main-content col-md-8">
                    <MeetTeam />
                    <Investment />
                    <Chart/>
                    <RoadMap />
                    <Products />

                </div>
            </div>
            <ParticipatingSection/>
            <Footer className="bottom-0" />
        </div>
    );
}
