import React, { useEffect, useState, useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import LoginPopUp from "./loginpopup";
import {useNavigate} from "react-router-dom";
import {useLocation, Link} from "react-router-dom";
import {Tabs, Tab} from "@mui/material";
import {Helmet} from "react-helmet";
import Typography from "@mui/material/Typography";
import { useParams } from 'react-router-dom';
import {BottomNavigation, Button} from "@mui/material";
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import IconButton from "@mui/material/IconButton";
import {Grid} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
const drawerWidth = 292;
const QuizPage = (props) => {
  const {window1} = props;
  const { crypto } = useParams();
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const mediaQueryVar = useMediaQuery(json2mq({ minWidth: 900 }));
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const loc = location.pathname;
  const navigate = useNavigate();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const container = window1 !== undefined ? () => window1().document.body : undefined;
  const [selectedButton, setSelectedButton] = useState("Quiz");
  const [tag, setTag] = useState(" ");
  const drawer = (
          <div className="d-flex flex-column h-100" style={{overflowY: "hidden"}}>
              <SidebarMenuList />
          </div>
      );
  const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleLoginClick = () => {
      // Mask the URL by adding /login at the end without navigating
      window.history.pushState({}, "", "/login");
      localStorage.setItem("isPopupManuallyOpened", "true");
      // Open the login modal
      setLoginModalOpen(true);
  };

  const [quizIndex, setQuizIndex] = useState(0);
  const [quizLoader, setQuizLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available

  const [visibleCount, setVisibleCount] = useState(3); // Start with 3 questions
  const observerRef = useRef(null);

  const fetchData = async () => {
    if (!hasMore) return; // Prevent unnecessary API calls
  
    try {
      setLoading(true);
      const questionsData = {
        userid: userData ? userData.user_id : '0',
        crypto_tag: crypto,
        start: quizIndex + 1,
        limit: 3
      };
  
      const response = await fetch(
        "https://crowdwisdom.live/wp-json/api/v1/fetch_quiz_questions",
        { 
          method: "POST", 
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(questionsData),
        }
      );
  
      const result = await response.json();
  
      if (result.code === "200" && result.response.status === 200) {
        const newQuestions = result.response.data;
        
        if (newQuestions.length === 0) {
          setHasMore(false); // No more questions to load
        } else {
          setQuestions((prevQuestions) => [...prevQuestions, ...newQuestions]);
          setTag(result.response.tags);
        }
      } else {
        console.error("Error:", result.message);
      }
    } catch (err) {
      console.error("An error occurred while fetching data:", err);
    } finally {
      setLoading(false);
      setQuizLoader(false);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, [quizIndex]); // Fetch when quizIndex updates

  const loadMoreQuiz = () => {
    if (!hasMore) return; // Stop loading when no more data
    setQuizLoader(true);
    setQuizIndex((prevIndex) => prevIndex + 3);
  };
  

  useEffect(() => {
    if (!hasMore) return; // Stop observing if no more data
  
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreQuiz();
        }
      },
      { threshold: 1.0 }
    );
  
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
  
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [questions, quizLoader, hasMore]);
  



  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, {replace: true});
};

 const mediaQueryVar2 = useMediaQuery(
        json2mq({
            maxWidth: 420,
        })
    );
    const mediaQueryVar3 = useMediaQuery(
        json2mq({
            minWidth: 375,
        })
    )

const handleButtonClick = (buttonName) => {
  // If the same button is clicked again, don't toggle the view
  if (selectedButton === buttonName) {
      return;
  }

  // Toggle the view based on the clicked button
  setSelectedButton(buttonName);
};

  const handleOptionChange = async (questionIndex, option) => {

    if (!userData) {
      const originalUrl = window.location.pathname + window.location.search;
      sessionStorage.setItem("originalUrl", originalUrl);
      window.history.pushState({}, "", "/login");
      localStorage.setItem("isPopupManuallyOpened", "true");

      // Open the login modal
      setLoginModalOpen(true);
      //window.location.href = `${process.env.REACT_APP_HOME_URL}subscriptions/screener360`;

      return;
  } 
  else{

    if (selectedAnswers[questionIndex]) return; // Prevent changing answer after selection


    const isCorrect = option === questions[questionIndex].correct_answer;

    setSelectedAnswers((prev) => ({
      ...prev,
      [questionIndex]: { selected: option, isCorrect: option === questions[questionIndex].correct_answer },
    }));


    if (isCorrect) {
      setScore((prevScore) => prevScore + 25); // Update score immediately
    }

    setQuestions((prev) =>
      prev.map((q, i) =>
        i === questionIndex ? { ...q, isCorrect } : q
      )
    );

    //console.log("Handle option change data: ", questions[questionIndex]);

    const rewardData = {
      userid: userData.user_id, // Assuming the user data is stored in localStorage and contains an `id`
      rewards: isCorrect ? 25 : 0, // Assign rewards based on the correctness
      action: 'quiz_answer', // Action type (can be customized)
      question_id : questions[questionIndex].id
  };

  try {
      const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/quiz_rewards_for_each_user", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(rewardData),
      });

      const result = await response.json();

      if (result.code === "200" && result.response.status === 200) {
          //console.log("Reward stored successfully:", result.response.data);
      } else {
          console.error("Error storing reward:", result.message);
      }
  } catch (err) {
      console.error("Error occurred while storing reward:", err);
  }




  }
    

  };

  return (
    
    <div style={{marginLeft: mediaQueryVar? drawerWidth : "0", padding: mediaQueryVar? "2px" : "10px",backgroundColor: "#F2F2F2", paddingBottom:mediaQueryVar? "": "50px"}}>
                    
                    

             <Helmet>
                     <title>{tag} Quiz</title>
             </Helmet>
                    {mediaQueryVar && (
                    <div>
                        <Box
                            component="nav"
                            sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                            aria-label="mailbox folders"
                        >
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                            <Drawer
                                container={container}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: {xs: "block", sm: "none"},
                                    "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: drawerWidth,
                                        backgroundColor: "white",
                                        color: "#727376",
                                    },
                                }}
                            >
                                {drawer}
                            </Drawer>
                            <Drawer
                                variant="permanent"
                                sx={{
                                    display: {xs: "none", sm: "block"},
                                    "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: drawerWidth,
                                        backgroundColor: "white",
                                        color: "#727376",
                                    },
                                }}
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Box>
                    </div>
                    )}
                    
{mediaQueryVar==false? null :(
    <div
    style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: "10px",
        paddingLeft: "10px",
        
    }}
>
<Box
sx={{
backgroundColor: "#1877F2", // Background color for the container
paddingTop: {xs: "10px", sm: "4px"}, // Padding inside the container
paddingLeft: {xs: "0px", sm: "0px"},
paddingRight: {xs: "0px", sm: "0px"},
paddingBottom: {xs: "0px", sm: "4px"},
borderRadius: "50px", // Border radius for the container
display: "flex", // Make sure the box is flexible
justifyContent: "flex-start", // Center the content
alignItems: "center", // Center the content
display: "inline-block",
minWidth: crypto ? "400px" : "360px",
height: {xs: "44px", sm: "48px"},
border: "none",
marginLeft: "0.3%",
marginTop: "10px",
}}
>
<Tabs
value={selectedButton}
onChange={(event, newValue) => handleButtonClick(newValue)}
aria-label="basic tabs example"
sx={{
alignSelf: mediaQueryVar === true ? "flex-start" : "",
marginLeft: mediaQueryVar === true ? "2px" : "2px",
borderRadius: "50px", // Adjust border radius for more rounded corners
border: "none",
"& .MuiTabs-indicator": {
display: "none",
},
display: "flex", // Make sure the tabs are displayed in a row
overflow: "hidden", // Prevent the tabs from spilling out of the border radius
}}
>
<Tab
label={tag ? `${tag} Price Analysis` : "Price Analysis"}
onClick={() => {
    navigate(`/crypto/${crypto}`);
  }}
value="CoinPrices"
sx={{
whiteSpace: "nowrap",
bottom: "0px",
marginTop: "0px",
marginBottom: "0px",
marginLeft: "3px",
marginRight: "3px",
paddingBottom: "0px",
paddingTop: "0px",
paddingLeft: "20px",
paddingRight: "20px",
minHeight: "20px",
lineHeight: {xs: "36px", sm: "36px"},
borderRadius: selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
textTransform: "none",
fontSize: "17px",
fontStyle: "normal",
fontWeight: selectedButton === "CoinPrices" ? "700" : "500",

backgroundColor:
  selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
border: "none", // Remove border for tabs
background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
}}
/>


<Tab
label={userData ? "Predict and Win" : "Audit My Portfolio"}
value="PredictandWin"
onClick={() => {
if (userData) {
  navigate("/predict-now", { state: { cryptoValue: tag } });
} else {
  navigate("/audit-my-portfolio");
}
}}
sx={{
whiteSpace: "nowrap",
bottom: "0px",
marginTop: "1.1px",
marginBottom: "0px",
marginLeft: "3px",
marginRight: "3px",
paddingBottom: "2px",
paddingTop: "0px",
paddingLeft: "20px",
paddingRight: "20px",
minHeight: "20px",
lineHeight: {xs: "28px", sm: "36px"},
borderRadius: selectedButton === "PredictandWin" ? "20px" : "20px", // Rounded corners for selected tab
textTransform: "none",
fontSize: {xs: "13px", sm: "17px"},
fontStyle: "normal",
fontWeight: selectedButton === "PredictandWin" ? "700" : "500",

backgroundColor:
  selectedButton === "PredictandWin" ? "#FFFFFF" : "transparent", // White background for selected tab
color:
  selectedButton === "PredictandWin" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
border: "none", // Remove border for tabs
background: selectedButton === "PredictandWin" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
}}
/>
<Tab
label="Quiz & Earn"
value="Quiz"
onClick={() => {
  navigate(`/crypto/${crypto}/quiz`);
}}
sx={{
whiteSpace: "nowrap",
bottom: "0px",
marginTop: "1.1px",
marginBottom: "0px",
marginLeft: "3px",
marginRight: "3px",
paddingBottom: "2px",
paddingTop: "0px",
paddingLeft: "20px",
paddingRight: "20px",
minHeight: "20px",
lineHeight: {xs: "28px", sm: "36px"},
borderRadius: selectedButton === "Quiz" ? "20px" : "20px", // Rounded corners for selected tab
textTransform: "none",
fontSize: {xs: "13px", sm: "17px"},
fontStyle: "normal",
fontWeight: selectedButton === "Quiz" ? "700" : "500",

backgroundColor:
  selectedButton === "Quiz" ? "#FFFFFF" : "transparent", // White background for selected tab
color:
  selectedButton === "Quiz" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
border: "none", // Remove border for tabs
background: selectedButton === "Quiz" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
}}
/>
</Tabs>
</Box>

</div>
)}


<Grid item xs={12} align="left">
                {mediaQueryVar ? null : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between", // Changed from space-between to flex-end
                            marginTop: "0px",
                            marginLeft: "5px",
                            fontSize: "20px",
                        }}
                    >
                        <Typography style={{fontSize: "20px", fontWeight: "700"}}> {tag} Quiz </Typography>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                            sx={{display: {sm: "none"}}}
                        >
                            <img
                                src="/images/menubar.svg"
                                alt="Menu Icon"
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "10px",
                                    marginTop: "10px",
                                }}
                            />
                        </IconButton>
                    </div>
                )}
            </Grid>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: "block", sm: "none"},
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "400",
                        backgroundColor: "white",
                        color: "#727376",
                    },
                }}
            >
                {drawer}
            </Drawer>
            {mediaQueryVar ? null : (
                <Box
                    sx={{
                        backgroundColor: "#1877F2", // Background color for the container
                        padding: "3.105px", // Padding inside the container
                        borderRadius: "38.042px", // Border radius for the container
                        display: "flex", // Ensure that the box is flexible
                        alignItems: "center", // Center the content vertically
                        display: "inline-block",
                        width: "auto",
                        height: "37px",
                        border: "none",
                        marginLeft: mediaQueryVar3 ? "-3px" : "-7px",
                        marginTop: "2%",
                        marginBottom: "5%",
                    }}
                >
                    <Tabs
                        value={selectedButton}
                        onChange={(event, newValue) => handleButtonClick(newValue)}
                        aria-label="basic tabs example"
                        sx={{
                            flexGrow: 1, // Make the tabs take up remaining space
                            "& .MuiTabs-indicator": {
                                display: "none",
                            },
                            display: "flex", // Ensure that the tabs are displayed in a row
                            overflow: "hidden", // Prevent the tabs from spilling out of the border radius
                        }}
                    >
                        <Tab
                            label={tag ? `${tag} Price Analysis` : "Price Analysis"}

                            value="CoinPrices"
                            onClick={() => {
                              navigate(`/crypto/${crypto}`);
                            }}
                            sx={{
                                whiteSpace: "nowrap",
                                borderRadius: "20.962px",
                                minHeight: "31px",
                                height: "31px",
                                marginLeft: "1.5px",
                                marginTop: "0.2px",
                                border: "1px solid black",
                                fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
                                marginRight: "-10px",
                                fontSize: mediaQueryVar2 ? "11px" : "13px",
                                paddingRight: "-8px",
                                textTransform: "none",
                                backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
                                color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                                border: "none", // Remove border for tabs
                            }}
                        />
                       

                        <Tab
                            label={userData ? "Predict & Win" : "Audit My Portfolio"}
                            value="PortfolioGallery"
                            onClick={() => {
                                if (userData) {
                                    navigate("/predict-now", { state: { cryptoValue: tag } });
                                } else {
                                    navigate("/audit-my-portfolio");
                                }
                            }}
                            sx={{
                                whiteSpace: "nowrap",
                                borderRadius: "20.962px",
                                minHeight: "31.5px",
                                height: "31.5px",
                                fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
                                marginLeft: "0px",
                                paddingLeft: "-15px",
                                marginTop: "0.5px",
                                border: "1px solid black",
                                marginBottom: "-5px",
                                fontSize: mediaQueryVar2 ? "11px" : "13px",
                                marginRight: mediaQueryVar3 ? "-3px" : "-10px",
                                backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
                                color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                                textTransform: "none",
                                border: "none",
                            }}
                        />

<Tab
                            label= "Quiz & Earn"
                            value="Quiz"
                            onClick={() => {
                                    navigate(`/crypto/${crypto}/quiz`);
                            }}
                            sx={{
                                whiteSpace: "nowrap",
                                borderRadius: "20.962px",
                                minHeight: "31.5px",
                                height: "31.5px",
                                fontWeight: selectedButton === "Quiz" ? "700" : "500",
                                marginLeft: "0.5px",
                                paddingLeft: "-15px",
                                marginTop: "0.5px",
                                border: "1px solid black",
                                marginBottom: "-5px",
                                fontSize: mediaQueryVar2 ? "11px" : "13px",
                                marginRight: mediaQueryVar3 ? "-3px" : "-10px",
                                backgroundColor: selectedButton === "Quiz" ? "#FFFFFF" : "transparent", // White background for selected tab
                                color: selectedButton === "Quiz" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                                textTransform: "none",
                                border: "none",
                            }}
                        />
                    </Tabs>
                </Box>
            )}
                    
<div style={{ display: "flex", flexDirection: "column", backgroundColor: "#F2F2F2", minHeight: "100vh", justifyContent: "flex-start", padding:mediaQueryVar? "20px":"" }}>

      {/* Quiz Header */}
      <div style={{ background: "linear-gradient(white, #b3d4fc)", color: "#1877F2", padding: "10px 20px", borderRadius: "30px", border: "1px solid #1877F2", textAlign: "center", fontWeight: "600", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",  maxWidth:mediaQueryVar?"600px":"", marginLeft:"auto", marginBottom: "20px" ,marginRight:"auto"}}>
        Answer simple questions and Farm $WISD token due to be listed on RAYDIUM in April!
      </div>

      <div>
      {questions.length > 0 ? (
        questions.map((question, index) => (
          <div
            key={index}
            style={{
              minWidth: mediaQueryVar ? "600px" : "340px",
              maxWidth: "600px",
              border: "1px solid #1877F2",
              borderRadius: "10px",
              marginBottom: "20px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {/* Question Title */}
            <div
              style={{
                backgroundColor: "#1877F2",
                color: "white",
                padding: "20px 15px",
                fontWeight: "600",
              }}
            >
              {question.question}
            </div>
            <div style={{ padding: "15px", backgroundColor: "white" }}>
              {[question.option1, question.option2, question.option3, question.option4].map((option, i) => (
                <label
                  key={i}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="radio"
                    name={`question-${index}`}
                    value={option}
                    checked={selectedAnswers[index]?.selected === option}
                    onChange={() => handleOptionChange(index, option)}
                    disabled={selectedAnswers[index]} // Disable after selection
                    style={{
                    marginRight: "10px",
                    flexShrink: 0, // Prevent shrinking
                    minWidth: "20px", // Ensure it doesn't reduce in size
                    minHeight: "20px",
                      border: "2px solid #1877F2",
                      borderRadius: "50%",
                      appearance: "none",
                      backgroundColor: selectedAnswers[index]?.selected === option ? "#1877F2" : "transparent",
                      boxShadow: selectedAnswers[index]?.selected === option ? "0 0 0 3px white inset" : "none",
                      cursor: selectedAnswers[index] ? "not-allowed" : "pointer",
                    }}
                  />
                  <span
                    style={{
                      flexGrow: 1,
                      background: "linear-gradient(white, #e0efff)",
                      padding: "10px",
                      borderRadius: "20px",
                      textAlign: "left",
                      color: "#333",
                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                      fontWeight: "500",
                    }}
                  >
                    {option}
                  </span>
                </label>
              ))}

              {/* Show Answer Feedback */}
              {selectedAnswers[index] && (
                <div
                  style={{
                    marginTop: "10px",
                    fontWeight: "bold",
                    color: selectedAnswers[index].isCorrect ? "green" : "red",
                  }}
                >
                  {selectedAnswers[index].isCorrect ? (
                    <>
                      ✅ Correct! You Earned{" "}
                      <a
                        href="https://investing.crowdwisdom.live/reward-transaction-history"
                        style={{ color: "#1877F2", textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        25 WISD
                      </a>
                    </>
                  ) : (
                    `❌ Wrong! Correct Answer: ${question.correct_answer}`
                  )}
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div>
          {/* Skeleton Loader */}
          {quizLoader &&
            Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                style={{
                  minWidth: mediaQueryVar ? "600px" : "340px",
                  maxWidth: "600px",
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  animation: "pulse 1.5s infinite ease-in-out",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {/* Skeleton Question */}
                <div
                  style={{
                    backgroundColor: "#1877F2",
                    height: "50px",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                ></div>
                <div style={{ padding: "15px" }}>
                  {/* Skeleton Options */}
                  {Array.from({ length: 4 }).map((_, i) => (
                    <div key={i} style={{ marginBottom: "20px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={{
                            borderRadius: "50%",
                            border: "2px solid #1877F2",
                            height: "20px",
                            width: "20px",
                            marginRight: "10px",
                            backgroundColor: "#fff",
                          }}
                        ></span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            background: "linear-gradient(white, #e0efff)",
                            height: "40px",
                            borderRadius: "20px",
                            width: "100%",
                          }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      )}

      {/* Observer div for infinite scroll */}
      <div ref={observerRef} style={{ height: "20px", marginBottom: "20px" }} />

      {/* Rotating Loader (Spinner) */}
      {quizLoader && (
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <div className="spinner"></div>
        </div>
      )}

      {/* CSS for Rotating Spinner */}
      <style jsx>{`
        .spinner {
          width: 40px;
          height: 40px;
          border: 4px solid rgba(0, 0, 0, 0.1);
          border-left-color: #1877F2;
          border-radius: 50%;
          animation: spin 1s linear infinite;
          display: inline-block;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
     
    
      {isLoginModalOpen && (
                <LoginPopUp
                    isLoginModalOpen={isLoginModalOpen}
                    setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                    onClose={handleModalClose}
                />
            )}
    </div>


    {mediaQueryVar === false && (
                <div
                    style={{
                        position: "fixed",
                        bottom: "0",
                        backgroundColor: "white",
                        width: "105%",
                        zIndex: "100",
                        marginBottom: "0px",
                        marginLeft: "-25px",
                    }}
                >
                    <BottomNavigation showLabels>
                        
                        <BottomNavigationAction
                            label="Crypto Screener"
                            onClick={() => navigate("/crypto-screener")}
                            icon={
                                <img
                                    src={
                                        loc === "/crypto-screener" ? "/images/portfolioS.svg" : "/images/portfolioU.svg"
                                    }
                                    width={"20px"}
                                    height={"20px"}
                                    alt="screener"
                                />
                            }
                        />
                        <BottomNavigationAction
                            label="Whitepaper"
                            onClick={() => navigate("/whitepaper")}
                            icon={
                                <img
                                    src={
                                        loc === "/portfolio-gallery"
                                            ? "/images/dashboardS.svg"
                                            : "/images/dashboardU.svg"
                                    }
                                    width={"20px"}
                                    height={"20px"}
                                    alt="whitepaper"
                                />
                            }
                        />
                        <BottomNavigationAction
                            label="Portfolio Audit"
                            onClick={() => {
                                if (userData) {
                                    navigate("/subscriptions/screener360");
                                } else {
                                    const originalUrl = "/subscriptions/screener360";
                                    sessionStorage.setItem("originalUrl", originalUrl);
                                    handleLoginClick();
                                }
                            }}
                            icon={
                                <img
                                    src={
                                        loc === "/subscriptions/screener360"
                                            ? "/images/dollarS.svg"
                                            : "/images/dollarU.svg"
                                    }
                                    width={24}
                                    height={24}
                                    alt="Icon"
                                    style={{marginBottom: "-3px"}}
                                />
                            }
                        />
                    </BottomNavigation>
                </div>
            )}
    </div>
  );
};

export default QuizPage;
