import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Drawer from '@mui/material/Drawer';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoginPopUp from './loginpopup';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip, Button } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import { Helmet } from 'react-helmet';
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import { useLocation } from "react-router-dom";
const drawerWidth = 292;


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const EmailTemplateDesign = (props) => {
  const { window1 } = props;
  const location = useLocation(); 
  const loc = location.pathname;
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

  const [chartData, setChartData] = useState({});
  const [selectedPortfolio, setSelectedPortfolio] = useState('');
  const [roiData, setRoiData] = useState({});
  const container = window1 !== undefined ? () => window1().document.body : undefined;
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
};

const drawer = (
    <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
<SidebarMenuList/>
    </div>
);

const [portfolioData, setPortfolioData] = useState([]);
const [portfolioData_emerge, setPortfolioData_emerge] = useState([]);
const [portfolioData_indices, setPortfolioData_indices] = useState([]);


useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(
        'https://staging-b8ee-crowdwisdomlive.wpcomstaging.com/wp-json/api/v1/getIndexTemplateData',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}),
        }
      );

      if (response.ok) {
        const data = await response.json(); // Parse the JSON response
        console.log("Email Template Data - outperforming: ", data);
        setPortfolioData(data?.data || []); // Update the state with the response data
      } else {
        console.error('Error: Unexpected response code', response.status);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  fetchData();
}, []);


useEffect(() => {
    const fetchData_emerge = async () => {
      try {
        const response = await fetch(
          'https://staging-b8ee-crowdwisdomlive.wpcomstaging.com/wp-json/api/v1/getIndexTemplateData_emerging',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
          }
        );
  
        if (response.ok) {
          const data = await response.json(); // Parse the JSON response
          console.log("Email Template Data - Emerging: ", data);
          setPortfolioData_emerge(data?.data || []); // Update the state with the response data
        } else {
          console.error('Error: Unexpected response code', response.status);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };
  
    fetchData_emerge();
  }, []);


  useEffect(() => {
    const fetchData_indices = async () => {
      try {
        const response = await fetch(
          'https://staging-b8ee-crowdwisdomlive.wpcomstaging.com/wp-json/api/v1/getIndexTemplateData_indices',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
          }
        );
  
        if (response.ok) {
          const data = await response.json(); // Parse the JSON response
          console.log("Email Template Data - indices: ", data);
          setPortfolioData_indices(data?.data || []); // Update the state with the response data
        } else {
          console.error('Error: Unexpected response code', response.status);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };
  
    fetchData_indices();
  }, []);


const groupByCategory = (data) => {
  const grouped = {};
  data.forEach((item) => {
    if (!grouped[item.modified_name]) {
      grouped[item.modified_name] = [];
    }
    grouped[item.modified_name].push(item);
  });
  return grouped;
};

const groupedData = groupByCategory(portfolioData);
const groupedData_emerge = groupByCategory(portfolioData_emerge);


return (
    <div>
    <div class="outperforming-crypto-container"
      style={{
        maxWidth: '900px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        overflow:'hidden'
      }}
    >
      {/* Heading */}
      <h2
        style={{
          fontWeight: 'bold',
          fontSize: '24px',
          marginBottom: '20px',
          color: '#000',
        }}
      >
        ▶ Outperforming Cryptos, In Last 7 Days
      </h2>
  
      {/* Grid Container */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '20px',
        }}
      >
        {Object.entries(groupedData).map(([category, items], index) => (
          <div key={index}>
            {/* Category Title */}
            <h3
              style={{
                color: '#1877F2',
                fontSize: '15px',
                marginBottom: '10px',
                fontWeight: 'bold'
              }}
            >
              {category}
            </h3>
  
            {/* Crypto Row */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                gap: '10px',
              }}
            >
              {items[0]?.outperformers.map((crypto, cryptoIndex) => (
                <div
                  key={cryptoIndex}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '2px solid #007BFF',
                    borderRadius: '30px',
                    padding: '5px 7px',
                    minWidth: '120px',
                  }}
                >
                  {/* Crypto Logo */}
                  <div
                    style={{
                      width: '25px',
                      height: '25px',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      marginRight: '7px',
                    }}
                  >
                    <img
                      src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${crypto.crypto_id}.png`}
                      alt={crypto.symbol}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                  {/* Crypto Symbol */}
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: '12px',
                      color: '#1877F2',
                    }}
                  >
                    {crypto.symbol}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>

<div class="emerging-crypto-container"
style={{
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  fontFamily: 'Arial, sans-serif',
}}
>
{/* Heading */}
<h2
  style={{
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#000',
  }}
>
  ▶ Emerging Cryptos, In Last 7 Days
</h2>

{/* Grid Container */}
<div
  style={{
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '20px',
  }}
>
  {Object.entries(groupedData_emerge).map(([category, items], index) => (
    <div key={index}>
      {/* Category Title */}
      <h3
        style={{
          color: '#1877F2',
          fontSize: '15px',
          marginBottom: '10px',
          fontWeight: 'bold'
        }}
      >
        {category}
      </h3>

      {/* Crypto Row */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          gap: '10px',
        }}
      >
        {items[0]?.outperformers.map((crypto, cryptoIndex) => (
          <div
            key={cryptoIndex}
            style={{
              display: 'flex',
              alignItems: 'center',
              border: '2px solid #007BFF',
              borderRadius: '30px',
              padding: '5px 7px',
              minWidth: '120px',
            }}
          >
            {/* Crypto Logo */}
            <div
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                overflow: 'hidden',
                marginRight: '7px',
              }}
            >
              <img
                src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${crypto.crypto_id}.png`}
                alt={crypto.symbol}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
            {/* Crypto Symbol */}
            <span
              style={{
                fontWeight: 500,
                fontSize: '12px',
                color: '#1877F2',
              }}
            >
              {crypto.symbol}
            </span>
          </div>
        ))}
      </div>
    </div>
  ))}
</div>
</div>

<div style={{ fontFamily: 'Arial, sans-serif', color: '#000', maxWidth: '900px', overflow:'hidden',margin: '0 auto',
  padding: '20px', }}>
<h2
  style={{
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#000',
  }}
>
  ▶ Changes In The Index, In Last 7 Days
</h2>

      {portfolioData_indices.map((portfolio, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'flex-start', margin: '30px 0' }}>
            
            
          {/* Left Section */}
          <div style={{display:'flex', flexDirection: 'column', borderRight: '1px solid #000'}}>
          

          <div
            style={{
              width: '300px',
              padding: '20px',
              border: '1px solid #000',
              borderRadius: '15px',
              marginRight: '30px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <h2 style={{ color: '#1877F2', fontSize: '14px', marginBottom: '10px', textAlign:'center',fontWeight: 'bold' }}>
              {portfolio.portfolio_name} Index Performance
            </h2>
            <div style={{ borderTop: '1px solid #000', paddingTop: '5px' }}>
              <p style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 0',fontSize: '13px' }}>
                Last 1 Days <span style={{ color: '#1877F2',fontWeight: 'bold' }}>{portfolio.performance.one_day}</span>
              </p>
              <p style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 0' ,fontSize: '13px',borderTop: '1px solid #000',paddingTop: '5px'  }}>
                Last 7 Days <span style={{ color: '#1877F2',fontWeight: 'bold' }}>{portfolio.performance.seven_day}</span>
              </p>
              <p style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 0',fontSize: '13px' ,borderTop: '1px solid #000',paddingTop: '5px'  }}>
                Last 30 Days <span style={{ color: '#1877F2',fontWeight: 'bold' }}>{portfolio.performance.thirty_day}</span>
              </p>
              <p style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 0',fontSize: '13px',borderTop: '1px solid #000' ,paddingTop: '5px'  }}>
                Last 90 Days <span style={{ color: '#1877F2',fontWeight: 'bold' }}>{portfolio.performance.ninety_day}</span>
              </p>
            </div>
           
          </div>
          
            </div>

          {/* Right Section */}
          <div style={{ flex: 1 }}>
            <h3 style={{ fontSize: '14px', color: '#1877F2', textDecoration: 'underline', cursor: 'pointer' }}>
              Changes made in the index
            </h3>

            {/* Coins Added */}
            <div style={{ marginTop: '20px' }}>
              <h4 style={{ color: '#1877F2', fontSize: '12px',fontWeight: 'bold' }}>• Added to the Portfolio</h4>
              <div style={{ display: 'flex', gap: '10px', marginTop: '10px',flexWrap:'wrap' }}>
                {portfolio.coins.added.map((coin, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: 'linear-gradient(to right, #c1fafa, #fff59d)',
                      padding: '5px 5px',
                      borderRadius: '20px',
                    }}
                  >
                    <img
                      src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${coin.logo}.png`}
                      alt={coin.slug}
                      style={{ width: '25px', height: '25px', marginRight: '8px', borderRadius: '50%' }}
                    />
                    <span style={{  fontWeight: '500' , fontSize: '12px' }}>{coin.slug}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Coins Removed */}
            <div style={{ marginTop: '20px' }}>
              <h4 style={{ color: '#1877F2', fontSize: '12px', fontWeight: 'bold' }}>• Removed from the Portfolio</h4>
              <div style={{ display: 'flex', gap: '10px', marginTop: '10px' ,flexWrap:'wrap'}}>
                {portfolio.coins.removed.map((coin, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: 'linear-gradient(to right, #c1fafa, #fff59d)',
                      padding: '5px 5px',
                      borderRadius: '20px',
                    }}
                  >
                    <img
                      src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${coin.logo}.png`}
                      alt={coin.slug}
                      style={{ width: '25px', height: '25px', marginRight: '8px', borderRadius: '50%' }}
                    />
                    <span style={{ fontWeight: '500' , fontSize: '12px'}}>{coin.slug}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>




</div>


  );
  
  
};


export default EmailTemplateDesign;
