import React from "react";

const Chart = () => {
    return (
        <div className="container mt-5">
            <div>
                <h1 className="theme-bg shadow sub-heading">Tokenomics Allocation</h1>
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    margin: "auto",
                }}
                className="shadow bg-white card"
            >
                <img
                    src="images/presale/pie-chart-img.png"
                    alt="pie chart"
                    style={{maxHeight: "500px", maxWidth: "700px", width: "100%"}}
                />
            </div>
        </div>
    );
};

export default Chart;
