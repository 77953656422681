import React from "react";
import Box from "@mui/material/Box";
import VerifiedIcon from '@mui/icons-material/Verified';
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import LoginPopUp from "../loginpopup.js";
import IconButton from "@mui/material/IconButton";
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AddIcon from '@mui/icons-material/Add';
import BarChartIcon from '@mui/icons-material/BarChart';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CreatableSelect from "react-select/creatable";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import { Tabs, Tab } from '@mui/material';
import { Helmet } from 'react-helmet';
import TableRow from "@mui/material/TableRow";
import { Button, Grid, TextField, Card, Checkbox } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "../home/home.css";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import { completeGoogleLogin } from "../../apihelper/login";
import CircularProgress from "@mui/material/CircularProgress";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
import  PortFolioGallery  from "../home/PortFolioGallery.js";
import  BestCryptos  from "../home/bestcryptos.js";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  
  getTotalunit,
  getFetchdropdown,
} from "../../apihelper/cryptocurrencylist";

import Dialog from "@mui/material/Dialog";
import HiddenGems from "./hidden_gems.js";
import BreakOutStars from "./breakout_stars.js";
import SmartMoneyPicks from "./smart_money_picks.js";
const drawerWidth = 292;
// 
export default function Home(props) {

  
  const showPopup = props.showPopup;

  // Check if referral ID exists in URL
  const urlParams = new URLSearchParams(window.location.search);
  let referrerId = urlParams.get('ref');

  // If referral ID is not in the URL, check localStorage
  if (!referrerId) {
    referrerId = localStorage.getItem('referrerId');
  }

  // Process the referrerId during registration/login
  if (referrerId) {
     //console.log(`Referred by user: ${referrerId}`);
  }

  const [selectedmenu, setselectedmenu] = useState("");
  const [options, setOptions] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
  const [Usablewallet, setUsablewallet] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [user_role, setuser_role] = useState("");
  const location = useLocation(); 
  const queryString = location.search; 
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { window1 } = props;
  const [alignment, setAlignment] = React.useState("left");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showloginpage, setShowloginpage] = useState(false);
  const loc = location.pathname;
  const [filter, setFilter] = useState("");
  const [loadingForLogin, setLoadingForLogin] = useState(false);
  const [loaderForSymbolLink, setLoaderForSymbolLink] = useState(false);
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );

  const mediaQueryVar5 = useMediaQuery(
    json2mq({
      minWidth: 1900,
    })
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
      <SidebarMenuList />
    </div>
  );
 
  useEffect(() => {
    setShowloginpage(true);
    const search = window.location.search;
    const params = new URLSearchParams(search);
   
    if (params) {
      const googleLoginCode = params.get("code");
      if (googleLoginCode) {
        setLoadingForLogin(true);
        completeGoogleLogin(googleLoginCode).then((data) => {
        
          if (data.code == "200") {
          
            localStorage.setItem(
              "cw_portfolio_user",
              JSON.stringify(data.data)
            );
            const portfolio_userId = localStorage.getItem("portfolio_userId");
         
            if (portfolio_userId) {
              window.location.replace(
                `${process.env.REACT_APP_HOME_URL}portfolio/${portfolio_userId}`
              );
              localStorage.removeItem("portfolio_userId");
            } else {
              if (data.data.portfolio_form_redirect === "yes") {
                window.location.replace(
                  `${process.env.REACT_APP_HOME_URL}profile`
                );
              } else {
                window.location.replace(
                  `${process.env.REACT_APP_HOME_URL}home`
                );
              }
            }
          } else {
            console.log("GOOGLE LOGIN FAILED");
          }
        });
      } else {
        setShowloginpage(false);
      }
    }
  });


  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);

  const container =
    window1 !== undefined ? () => window1().document.body : undefined;

    const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  useEffect(() => {
    if (showPopup) {
      setLoginModalOpen(true); // Open the login modal
      localStorage.setItem("isPopupManuallyOpened", "true");
    }
  }, [showPopup]);
  

  const handleLoginClick = () => {
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    setLoginModalOpen(false);
    navigate("/hidden-gems", { replace: true });
  };

  const currentTab = location.pathname.split("/").pop() || "hidden-gems";
  const [selectedButton, setSelectedButton] = useState(currentTab);

  useEffect(() => {
    setSelectedButton(currentTab); // Sync state when URL changes
  }, [currentTab]);

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    navigate(`/crypto-screener/${buttonName}`);
  };





 




  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    if (queryString.startsWith("?login") && !userData) {
      setTimeout(() => {
        handleLoginClick();
      }, 6000);
    }
  }, [queryString]);
 const [cryptoData, setCryptoData] = useState([]);
  
 
 return (

    <>
    {mediaQueryVar === true ? (
        <Box
            sx={{
                flexDirection: mediaQueryVar === false ? "column" : "",
                display: "flex",
                height: "100vh",
           }}
                className="maindiv"
          >
           <Helmet>
                <title>Top Performing Cryptos</title>
            </Helmet>
            <CssBaseline />
            <div className="windowheader">
         <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
         >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src="images/logo_with_bg.png"
              width={100}
              height={20}
              alt="CWLOGO"
              style={{ margin: "10px", borderRadius: "5px" }}
            />
          </Toolbar>
        </AppBar>
      </div>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "#EDF1F6",
          padding: "2vw",
          flexDirection: 'column',
          minHeight: '100vh', 
          }}
        className="maindiv"
      >

    
      {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}


        {loadingForLogin && (
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loadingForLogin && (
          <>

   
        <div style={{display:"flex",justifyContent:"space-between"}}>

        <Box
      sx={{
        backgroundColor: "#1877F2", 
        paddingTop: {xs:'10px', sm:'4px'},
        paddingLeft: {xs:'0px', sm:'0px'},
        paddingRight:{xs:'0px', sm:'0px'},
        paddingBottom: {xs:'0px', sm:'4px'},
        borderRadius: "50px", 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center',
        width: "515px",
        height:{xs:'44px', sm:'48px'},
        border:'none',
        marginLeft:"17px",
        marginTop:"-10px"
      }}
    >
                  
 <Tabs
  value={selectedButton}
  onChange={(event, newValue) => handleButtonClick(newValue)}
  aria-label="basic tabs example"
  sx={{
    alignSelf: mediaQueryVar === true ? "flex-start" : "",
    marginLeft: mediaQueryVar === true ? "2px" : "2px",
    borderRadius: "50px", 
    border: "none",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    display: 'flex', 
    overflow: 'hidden', 
  }}
>
  <Tab
    label="Hidden Gems"
    value="hidden-gems"
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"1.1px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"2px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'28px', sm:'36px'},
      borderRadius: selectedButton === "hidden-gems" ? "20px" : "20px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: {xs:'13px', sm:'16px'},
      fontStyle: "normal",
      fontWeight: selectedButton === "hidden-gems" ? "700" : "500",
      
      backgroundColor: selectedButton === "hidden-gems" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "hidden-gems" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "hidden-gems" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
  
  <Tab
    label="BreakOut Stars"
    value="breakout-stars"
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"0px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"0px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'36px', sm:'36px'},
      borderRadius: selectedButton === "breakout-stars" ? "50px" : "50px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: selectedButton === "breakout-stars" ? "700" : "500", 
      
      backgroundColor: selectedButton === "breakout-stars" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "breakout-stars" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', 
      background: selectedButton === "breakout-stars" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
  <Tab
    label="Smart Money Picks"
    value="smart-money-picks"
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"0px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"0px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'36px', sm:'36px'},
      borderRadius: selectedButton === "smart-money-picks" ? "50px" : "50px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: selectedButton === "smart-money-picks" ? "700" : "500", 
      
      backgroundColor: selectedButton === "smart-money-picks" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "smart-money-picks" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "smart-money-picks" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
</Tabs>




        </Box>
  
   <TextField
   style={{
     width: "25%",
     borderRadius: "46px",
     marginTop: mediaQueryVar === true ? "" : "60px",
     fontFamily: "Satoshi, sans-serif",
     marginRight:"12px",
     border:" 1px #1877F2 solid",
     backgroundColor:"#fff",
     paddingTop:"0px",
     paddingBottom:"2px",
     paddingLeft:"12px",
     paddingRight:"30px",
     marginBottom:"10px",
     marginRight:"2%",
     fontSize:"16px",
     fontWeight:"500",
     color:"#9D9DA5",
     marginLeft:"5px",
   }}
   className="cryptocurrencyListHeaderSearch"
   size="small"
   placeholder="Search Crypto"
   onChange={(e) => setFilter(e.target.value)}
   value={filter}
   InputProps={{
     startAdornment: (
       <InputAdornment position="start">
         <img src={window.constants.asset_path  + '/images/search.svg'}
              width={20} height={20} 
              alt="search Icon" />
       </InputAdornment>
     ),
     style: {
       border: "none", // Remove the border from the input
     },
   }}
   sx={{
     '& .MuiOutlinedInput-root': {
       '& fieldset': {
         borderColor: 'transparent', // Remove the border of the fieldset
       },
       '&:hover fieldset': {
         borderColor: 'transparent', // Remove the border on hover
       },
       '&.Mui-focused fieldset': {
         borderColor: 'transparent', // Remove the border when focused
       },
     },
   }}
 />
</div>

            
             
              
              <div style={{ display:"flex",marginBottom:"20px", flexDirection:"column"}}>


              {selectedButton === "hidden-gems" ? (
                <HiddenGems filter={filter} onButtonClick={handleButtonClick} />
                    ) : selectedButton === "breakout-stars" ? (
                <BreakOutStars filter={filter} onButtonClick={handleButtonClick} />
                    ) : selectedButton === "smart-money-picks" ? (
                <SmartMoneyPicks filter={filter} onButtonClick={handleButtonClick} />
                ) : null}

                
                </div>
          
          </>
        )}
        
      </Box>
 
    </Box>

      ):(

<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh", // Ensures it takes at least the full viewport height
    flexGrow: 1, // Allows the Box to grow and fill the available space
    overflowY: "auto", 
    
  }}
>

<Helmet>
        <title>{currentTab}</title>
</Helmet>
  <CssBaseline />
  
  <Box
    component="nav"
    sx={{
      width: "100%", // Take full width on mobile
    }}
    aria-label="mailbox folders"
  >
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth, // Adjust width for mobile
          backgroundColor: "white",
          color: "#727376",
        },
      }}
    >
      {drawer}
    </Drawer>
  </Box>

  <div style={{ backgroundColor: 'white', position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}>

  <Box
    component="main"
    sx={{
      height:"100vh",
      flexGrow: 1,
      paddingTop: 3,
      paddingBottom: 1,
      paddingLeft: 1,
      paddingRight: 0,
      backgroundColor: "white",
      paddingBottom: "2vw",
      marginRight: "6px",
      overflow: "auto",
       
    }}
    className="maindiv"
  >

    


{isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}


    {loadingForLogin && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <CircularProgress />
      </div>
    )}



    {!loadingForLogin && (
      <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Typography
            variant="h6"
            component="div"
            style={{
              fontFamily: "Satoshi, sans-serif",
              fontWeight: 500,
              fontSize: "20px",
            }}
          >
            <span style={{
              backgroundColor:"#1877f2",
              padding:"4px 4px",
              borderRadius:"5px",
              color:"white"
            }}> 

     {selectedButton === "hidden-gems"
      ? "Hidden Gems"
      : selectedButton === "breakout-stars"
      ? "BreakOut Stars"
      : selectedButton === "smart-money-picks"
      ? "Smart Money Picks"
      : ""}
            
            </span>
          </Typography>
  <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{ display: { sm: "none" }, ml: 'auto' }} // ml: 'auto' can be removed since flex does the job
  >
    <img 
      src={window.constants.asset_path + '/images/menubar.svg'}
      alt="Menu Icon" 
      style={{ width: "24px", height: "24px", marginRight: "17px", marginBottom: "10px", marginTop: "-10px" }} 
    />
  </IconButton>
</div>


       
               <TextField
                 style={{
                   width: "99%",
                   borderRadius: "46px",
                   marginTop: mediaQueryVar ? "" : "10px",
                   fontFamily: "Satoshi, sans-serif",
                   marginRight: "20px",
                   border: "1px #b6b6b8 solid",
                   backgroundColor: "white",
                   paddingTop: "0px",
                   paddingBottom: "2px",
                   paddingLeft: "12px",
                   paddingRight: "30px",
                   marginBottom: "5px",
                   fontSize:"14px",
                   fontWeight:"400",
                   marginLeft:"0px"
                 }}
                 size="small"
                 placeholder="Search Cryptos"
                 onChange={(e) => setFilter(e.target.value)}
                 value={filter}
                 InputProps={{
                   startAdornment: (
                     <InputAdornment position="start">
                       <img  width={13} height={13} src={window.constants.asset_path + '/images/search.svg'} alt="search Icon" />
                     </InputAdornment>
                   ),
                   style: {
                     border: "none", // Remove the border from the input
                   },
                 }}
                 sx={{
                   '& .MuiOutlinedInput-root': {
                     '& fieldset': {
                       borderColor: 'transparent', // Remove the border of the fieldset
                     },
                     '&:hover fieldset': {
                       borderColor: 'transparent', // Remove the border on hover
                     },
                     '&.Mui-focused fieldset': {
                       borderColor: 'transparent', // Remove the border when focused
                     },
                   },
                 }}
               />


        <Grid
          container
          justifyContent="flex-end"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#1877F2", // Background color for the container
              paddingTop:"3.105px" ,// Padding inside the container
              paddingLeft:"0px" ,
              paddingRight:"0px" ,
              paddingBottom: "3.105px",
              borderRadius: "38.042px", // Border radius for the container
              display: 'inline-flex', // Make sure the box is flexible
              justifyContent: 'center', // Center the content
              alignItems: 'center', // Center the content
              width: "345px", // Full width on extra small screens
              height: "39px",
              border: 'none',
              marginLeft: "0px",
            }}
          >
            <Tabs
              value={selectedButton}
              onChange={(event, newValue) => handleButtonClick(newValue)}
              aria-label="basic tabs example"
              sx={{
                marginLeft: "0px",
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                display: 'flex', // Make sure the tabs are displayed in a row
                overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
              }}
            >
              <Tab
                label="Hidden Gems"
                value="hidden-gems"
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius:"20.962px",
                  minHeight:"31.5px",
                  height:"31.5px",
                  fontWeight: selectedButton === "hidden-gems" ? "700" : "500",
                  marginLeft:"1px",
                  paddingLeft:"-15px",
                  marginTop:"8px",
                  border:"1px solid black",
                  marginBottom:"-5px",
                  fontSize:"11px",
                  marginRight:"-15px",
                  backgroundColor: selectedButton === "hidden-gems" ? "#FFFFFF" : "transparent", // White background for selected tab
                  color: selectedButton === "hidden-gems" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                  textTransform:"none",
                  border:"none"
                }}
              />
              <Tab
                    label="BreakOut Stars"
                    value="breakout-stars"
                    sx={{
                      whiteSpace: "nowrap",
                      borderRadius: "20.962px",
                      minHeight: "31px",
                      height: "31px",
                      marginLeft: "10px",
                      marginTop: "8px",
                      border: "1px solid black",
                      fontWeight: selectedButton === "breakout-stars" ? "700" : "500",
                      marginRight: "-13px",
                      fontSize: "11px",
                      paddingRight: "-8px",
                      textTransform: "none",
                      backgroundColor: selectedButton === "breakout-stars" ? "#FFFFFF" : "transparent", // White background for selected tab
                      color: selectedButton === "breakout-stars" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                      border: 'none', // Remove border for tabs
                    }}
                  />
              <Tab
                label="Smart Money Picks"
                value="smart-money-picks"
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius:"20.962px",
                  minHeight:"31px",
                  height:"31px",
                  marginLeft:"5px",
                  marginTop:"8px",
                  border:"1px solid black",
                  fontWeight: selectedButton === "smart-money-picks" ? "700" : "500",
                  marginRight:"0px",
                  fontSize:"11px",
                  paddingRight:"-8px",
                  textTransform:"none",
                  backgroundColor: selectedButton === "smart-money-picks" ? "#FFFFFF" : "transparent", // White background for selected tab
                  color: selectedButton === "smart-money-picks" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                  border: 'none', // Remove border for tabs
                }}
              />
            </Tabs>
          </Box>
        </Grid>
                {selectedButton === "hidden-gems" ? (
                     <HiddenGems filter={filter} onButtonClick={handleButtonClick} />
                        ) : selectedButton === "breakout-stars" ? (
                     <BreakOutStars filter={filter} onButtonClick={handleButtonClick} />
                        ) : selectedButton === "smart-money-picks" ? (
                     <SmartMoneyPicks filter={filter} onButtonClick={handleButtonClick} />
                        ) : null}

      </>
      
    )}
   
  </Box>
  
  
  </div>

  {mediaQueryVar === false && (

    <div
      style={{
        position: "fixed",
        bottom: "0",
        backgroundColor: "white",
        width: "105%",
        zIndex: "1000",
        boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)", // Optional shadow for depth
        marginLeft:"-10px"
      }}
    >
      <BottomNavigation showLabels>
                                <BottomNavigationAction
                                    label={<span style={{ fontSize: "11px" }}>Hidden Gems</span>}
                                    onClick={() => {
                                        navigate("/crypto-screener/hidden-gems");
                                    }}
                                    icon={
                                        <img
                                            src={
                                                loc === "/crypto-screener/hidden-gems"
                                                    ? `${window.constants.asset_path}/images/dashboardS.svg`
                                                    : `${window.constants.asset_path}/images/dashboardU.svg`
                                            }
                                            width={20}
                                            height={20}
                                            alt="Icon"
                                        />
                                    }
                                />
                                <BottomNavigationAction
                                    label={<span style={{ fontSize: "11px" }}>BreakOut Stars</span>}
                                    onClick={() => navigate("/crypto-screener/breakout-stars")}
                                    icon={
                                        <img
                                            src={
                                                loc === "/crypto-screener/breakout-stars"
                                                    ? `${window.constants.asset_path}/images/portfolioS.svg`
                                                    : `${window.constants.asset_path}/images/portfolioU.svg`
                                            }
                                            width={20}
                                            height={20}
                                            alt="Icon"
                                        />
                                    }
                                />
                                <BottomNavigationAction
                                    label={<span style={{ fontSize: "11px" }}>Smart Picks</span>}
                                    onClick={() => navigate("/crypto-screener/smart-money-picks")}
                                    icon={
                                        <img
                                            src={
                                                userData
                                                    ? loc === "/crypto-screener/smart-money-picks"
                                                        ? "/images/dollarS.svg"
                                                        : "/images/dollarU.svg"
                                                    : "/images/right-arrow.png"
                                            }
                                            width={24}
                                            height={24}
                                            alt="Icon"
                                            style={{marginBottom: "-3px"}}
                                        />
                                    }
                                />
                            </BottomNavigation>
      
    </div>

)}

</Box>

)}
</>
);
}
