import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import React from "react";

const ProcessingAlert = () => {
    return (
        <Modal
            open={true}
            disableBackdropClick
            aria-labelledby="processing-modal-title"
            aria-describedby="processing-modal-description"
            closeAfterTransition
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    boxShadow: 24,
                    p: {xs: 3, sm: 4}, // Adjust padding for small and medium screens
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    width: {xs: "90%", sm: "400px"}, // Responsive width
                }}
            >
                <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="primary"
                    sx={{fontSize: {xs: "18px", sm: "22px"}}} // Responsive font size
                >
                    Processing Payment
                </Typography>
                <CircularProgress
                    sx={{my: 3}}
                    size={50} // Smaller size for better fit on small screens
                    thickness={4}
                    color="primary"
                />
                <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{fontSize: {xs: "14px", sm: "16px"}}} // Responsive text size
                >
                    Please wait while we securely process your payment...
                </Typography>
            </Box>
        </Modal>
    );
};

export default ProcessingAlert;
