import React, { useState, useEffect } from 'react';

const EmailPopUp = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const currentPageUrl = window.location.href; 

  console.log("Current page Url: ",currentPageUrl);

  useEffect(() => {
    // Trigger popup after 7 seconds if it hasn't been shown before in this session
    if (!sessionStorage.getItem('popupShown')) {
      setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem('popupShown', 'true');
      }, 15000); // 7 seconds
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleSave = () => {
    if (!email) {
      alert('Please enter a valid email.');
      return;
    }
  
    const currentPageUrl = window.location.href; // Ensure this value is captured
  
    setIsLoading(true);
  
    // Send email and pageUrl to the WordPress API
    fetch('https://crowdwisdom.live/wp-json/api/v1/saveUserEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, pageUrl: currentPageUrl }),
    })
      .then((response) => {
        // Ensure the response is valid JSON
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Response:', data); // Debugging: Log the API response
  
        setIsLoading(false);
  
        // Adjust to match the backend's response structure
        if (data.code === 200) {
          alert(data.message);
          setShowPopup(false); // Close the popup on success
        } else {
          alert(data.message || 'There was an error. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error:', error); // Debugging: Log errors
        setIsLoading(false);
        alert('An error occurred. Please try again later.');
      });
  };
  

  return (
    <>
      {showPopup && (
        <div
          id="signup-popup"
          style={{
            display: 'block',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'linear-gradient(90deg, #9AEB37 0%, #FFF73F 100%)',
            padding: '20px',
            borderRadius: '15px',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
            width: '400px',
            maxWidth: '100%',
            zIndex: '10000'
          }}
        >
          <p
            id="close-popup-mark"
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '30px',
              cursor: 'pointer'
            }}
            onClick={handleClose}
          >
            &times;
          </p>
          <img
           src={`${window.constants.asset_path}/images/login.png`}
            alt="Wise Coins"
            style={{
              
              width: '170px',
              height: '150px',
              marginBottom: '15px',
              borderRadius: '50%'
            }}
          />
          <p style={{ marginBottom: '20px', color: '#1877F2', fontSize: '22px' }}>
            <strong>Sign up and <br />Get $wisecoins worth $10</strong>
          </p>

          {/* Email Input Field */}
          <input
            type="email"
            id="user-email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              marginBottom: '15px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontSize: '16px',
              background:'#fff',
              color:'#000'
            }}
          />

          {/* Sign Up Button */}
          <button
            id="save-button"
            onClick={handleSave}
            style={{
              backgroundColor: '#1877F2',
              color: 'white',
              padding: '10px 25px',
              border: 'none',
              borderRadius: '19px',
              fontSize: '16px',
              cursor: 'pointer',
              marginBottom: '5px'
            }}
          >
         {isLoading ? 'Saving...' : 'Save'}
          </button>

          <p id="close-para">
            <a
              href="https://investing.crowdwisdom.live/portfolio-gallery"
              id="close-popup"
              style={{
                color: '#1877F2',
                textDecoration: 'none',
                fontSize: '16px'
              }}
            >
              Explore 2000 crypto portfolios
            </a>
          </p>
        </div>
      )}

      <style>
        {`
          @media (max-width: 600px) {
            #signup-popup {
              width: 290px !important;
              padding: 10px;
              
            }

            #signup-popup img {
              display:none;
              width: 120px;
              height: 100px;
            }

            #signup-popup p {
              font-size: 20px !important;
             
            }

            #user-email {
              padding: 8px;
              font-size: 14px;
            }

            #save-button {
              padding: 8px 20px;
              font-size: 14px;
              margin-bottom:5px;
            }

            #close-popup {
              font-size: 18px ;
            }

            #close-para {
              margin-bottom: -5px;
            }
          }
        `}
      </style>
    </>
  );
};

export default EmailPopUp;
