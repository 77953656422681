import React from "react";
import {Link} from "react-router-dom";

const ParticipatingSection = () => {
    return (
        <div className="farming-page text-wrap">
            <h1 style={{fontSize: "62px"}}>
                Participate in <span className="bg-light p-1 rounded">Farming</span>
            </h1>
            <p style={{fontSize: "24px"}}>Maximize your profits through yield farming programs</p>
            <div className="d-flex justify-content-center">
                <div className="cards-container">
                    <img src="images/side1.png" alt="wise" className="wise-image wise1 d-none d-lg-block" />

                    <div className="my-3 mx-1">
                        <div className="card">
                            <img src="/images/burningAI.svg" alt="Icon 1" className="card-icon" />
                            <p>
                                Become a Portfolio Creator and <strong>Earn 3000 Wise Coins</strong>
                            </p>
                            <div style={{width: "100%", border: "1px solid #D6D6D6", marginBottom: "20px"}}></div>
                            <Link to="/crypto-screener">
                                <button className="card-button btn btn-primary rounded-pill text-white form-control">Add your Portfolio</button>
                            </Link>
                        </div>
                    </div>
                    <div className="my-3 mx-1">
                        <div className="card">
                            <img src="/images/share.svg" alt="Icon 2" className="card-icon " />
                            <p>
                                Share your Favorite Portfolios on Social Media and <strong>win 1000 Wise Coins</strong>
                            </p>
                            <div style={{width: "100%", border: "1px solid #D6D6D6", marginBottom: "20px"}}></div>
                            <Link to="/my-portfolio">
                                <button className="card-button btn btn-primary rounded-pill text-white form-control">Share Portfolio</button>
                            </Link>
                        </div>
                    </div>
                    <img src="images/side2.png" alt="wise" className="wise-image wise1 d-none d-lg-block" />
                </div>
            </div>
        </div>
    );
};

export default ParticipatingSection;
