import React from "react";
import Card from "./Card";

export default function MeetTeam() {
    const dummyUsers = [
        {
            id: 1,
            name: "Thomas Lean",
            image: "images/profile_image/meet_team_profile/Image_2.png",
            domain: "Senior Front-end Developer",
            about: "Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
            twitter: "https://twitter.com/thomasLean",
            facebook: "https://facebook.com/thomasLean",
            dribble: "https://dribble.com/thomasLean",
            github: "https://github.com/thomasLean",
        },
        {
            id: 2,
            name: "Thomas Lean",
            image: "images/profile_image/meet_team_profile/Image_1.png",
            domain: "Senior Front-end Developer",
            about: "Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
            twitter: "https://twitter.com/thomasLean",
            facebook: "https://facebook.com/thomasLean",
            dribble: "https://dribble.com/thomasLean",
            github: "https://github.com/thomasLean",
        },
        {
            id: 3,
            name: "Thomas Lean",
            image: "images/profile_image/meet_team_profile/Image_3.png",
            domain: "Senior Front-end Developer",
            about: "Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
            twitter: "https://twitter.com/thomasLean",
            facebook: "https://facebook.com/thomasLean",
            dribble: "https://dribble.com/thomasLean",
            github: "https://github.com/thomasLean",
        },
        {
            id: 4,
            name: "Thomas Lean",
            image: "images/profile_image/meet_team_profile/Image_2.png",
            domain: "Senior Front-end Developer",
            about: "Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
            twitter: "https://twitter.com/thomasLean",
            facebook: "https://facebook.com/thomasLean",
            dribble: "https://dribble.com/thomasLean",
            github: "https://github.com/thomasLean",
        },
        {
            id: 5,
            name: "Thomas Lean",
            image: "images/profile_image/meet_team_profile/Image_1.png",
            domain: "Senior Front-end Developer",
            about: "Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
            twitter: "https://twitter.com/thomasLean",
            facebook: "https://facebook.com/thomasLean",
            dribble: "https://dribble.com/thomasLean",
            github: "https://github.com/thomasLean",
        },
    ];
    return (
        <div className="container mt-5">
            <div>
                <h1 className="theme-bg shadow sub-heading">Meet The Team</h1>
            </div>

            <div className="row">
                {dummyUsers.map((user) => (
                    <Card key={user.id} user={user} />
                ))}
            </div>
        </div>
    );
}
