import React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import {useLocation} from "react-router-dom";
import LoginPopUp from "../loginpopup.js";

import CreatableSelect from "react-select/creatable";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {
    ButtonGroup,
    BottomNavigation,
    Paper,
    Chip,
    CardHeader,
    CardMedia,
    CardContent,
    Popover,
    Autocomplete,
    FormGroup,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import {Helmet} from "react-helmet";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import TableRow from "@mui/material/TableRow";
import {Button, Grid, TextField, Card, Checkbox} from "@mui/material";
import {useState} from "react";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {useEffect, useRef} from "react";
import Divider from "@mui/material/Divider";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ListSubheader from "@mui/material/ListSubheader";
import ClearIcon from "@mui/icons-material/Clear"; // Import ClearIcon
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import {FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {getCryporCurrencyListHome, getPortfolioForGallery, getSymbolbyPortfolioid} from "../../apihelper/homepage";
import "./home.css";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingButton from "@mui/lab/LoadingButton";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import BarChartIcon from "@mui/icons-material/BarChart";
// import LOGO from './images/logo.png'
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import {DialogTitle, DialogContent, DialogActions} from "@mui/material"; // Import Material-UI components

import {completeGoogleLogin} from "../../apihelper/login";
import CircularProgress from "@mui/material/CircularProgress";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
import {getsymbollink} from "../../apihelper/getsymbollink.js";
import {addToPortfolio, copyToPortfolio} from "../../apihelper/portfolio";
import {getTotalunit, getFetchdropdown} from "../../apihelper/cryptocurrencylist";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Modal from "@mui/material/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Dialog from "@mui/material/Dialog";

const drawerWidth = 292;
// import { useParams } from "react-router-dom";
export default function Home(props) {
    const {loading = true} = props;
    const {filter} = props; // Destructure filter from props
    const location = useLocation();
    const loc = location.pathname;
    const {onButtonClick} = props;
    const [selectedmenu, setselectedmenu] = useState("");
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [portfolioTypeId, setPortfolioTypeId] = useState("");
    const [options, setOptions] = useState([]);
    const [totalUnits, setTotalUnits] = useState(0);
    const [newOptionValue, setNewOptionValue] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [Usablewallet, setUsablewallet] = useState("");
    const [selectedOption, setSelectedOption] = useState([]);
    const [user_role, setuser_role] = useState("");
    const [showModal, setShowModal] = useState(false); // Change the state name to showModal

    const [value, setValue] = React.useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [isPaidMember, setIsPaidMember] = useState("no");

    const handleChange = async (selectedOption, slug) => {
        if (selectedOption && selectedOption._isNew_) {
            setIsDialogOpen(true);
            console.log("Creating new option:", selectedOption.value);
            setSelectedOption(null);
            setdataslug(slug);
        } else if (!selectedOption) {
            // Handle the case when the selection is cleared
            setSelectedOption(null);
            setShowAlert(false);
            // Perform any other necessary actions
            return;
        } else {
            const selectedValue = selectedOption.value;
            if (selectedValue.includes("-")) {
                setErrorMessage("Hyphens are not allowed in the option."); // Set error message
                setShowAlert(true); // Show the alert
                return; // Abort further processing
            }
            setShowAlert(false);
            setSelectedOption(selectedOption);
            const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
            getTotalunit({
                user_id: userData.user_id,
                slug: slug, // Assuming value contains the slug
                portfolio_type_id: selectedOption.value, // Adjust as needed
                Token: userData.token,
            }).then((res) => {
                if (res.code === "200") {
                    setTotalUnits(res.total_uint);
                    setUsablewallet(res.wallet_amount);
                } else {
                    console.log("Data fething error");
                }
                // console.log(slugdata)
            });
        }
    };
    const CustomDialog = styled(Dialog)(({theme}) => ({
        "& .MuiBackdrop-root": {
            // Customize backdrop styles here
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the background color and opacity
        },
    }));

    const fetchDropdownOptions = async () => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

        getFetchdropdown({
            user_id: userData.user_id,
            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                const newOptions = res.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
                setOptions(newOptions);
                setuser_role(res.user_role);

                // Set the last option as the default selected option
                if (newOptions.length > 0) {
                    setSelectedOption(newOptions[newOptions.length - 1]);
                    handleChange(newOptions[newOptions.length - 1], slugpopupdata.slugname);
                }
            } else {
                console.log("Data fetching error");
            }
        });
    };

    const handleCreate = async (inputValue) => {
        setTotalUnits(0);
        setUsablewallet("");

        if (options.find((option) => option.value === inputValue)) {
            return; // Exit early if the option already exists
        }
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        //console.log(inputValue);
        const newOption = {value: inputValue, label: inputValue};
        const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
        setOptions(newOptions); // Update the options state
        const createdOption = newOptions.find((option) => option.value == inputValue);
        // console.log(createdOption);
        if (createdOption) {
            handleChange(createdOption);
        }
    };

    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const {window1} = props;
    const [alignment, setAlignment] = React.useState("left");
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [showloginpage, setShowloginpage] = useState(false);

    const [slugdata, setSlugdata] = useState([]);

    const [index, setIndex] = useState(0);
    const [addMoreLoader, setAddMoreLoader] = useState(false);

    const [loaderbutton, setLoaderButton] = useState(true);
    const [dataslug, setdataslug] = useState("");
    const [loadingForLogin, setLoadingForLogin] = useState(false);
    const [lastUpdatedTime, setLastUpdatedTiem] = useState("--");
    const [loaderForSymbolLink, setLoaderForSymbolLink] = useState(false);
    const [unit, setUnit] = useState("");
    const [buySell, setbuySell] = React.useState("");
    const [price, setPrice] = useState("");
    const [open, setOpen] = React.useState(false);
    const [selectedDate, handleDateChange] = useState("");
    const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] = useState("loading...");
    const [severity, setSeverity] = useState("info");
    const handleClose = () => setOpen(false);
    const [slugpopupdata, setSlugpopupdata] = useState({
        slugname: "",
        slug: "",
        units: 0,
    });
    const [categories, setCategories] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [hashtagData, setHashtagData] = useState("");

    const [snackbaropen, setSnackbaropen] = React.useState(false);
    const [hoveredSignal, setHoveredSignal] = useState(false);
    const [hoveredCategory, setHoveredCategory] = useState(false);
    const [isPremiumSignalsPopoverOpen, setIsPremiumSignalsPopoverOpen] = useState(false); // Popover open state
    const [anchorElForPremiumSignalsPopover, setAnchorElForPremiumSignalsPopover] = useState(null); // Popover anchor
    const [selectedPremiumSignals, setSelectedPremiumSignals] = useState(false); // For premium signals selection
    const [hoveredPremiumSignals, setHoveredPremiumSignals] = useState(false); // For hover state

    const [selectedVolume, setSelectedVolume] = useState([]);
    const [hoveredVolume, setHoveredVolume] = useState(false);
    const [volumeAnchor, setVolumeAnchor] = useState(null);
    const [activeVolumeOption, setActiveVolumeOption] = useState(null);

    const [selectedMarketCap, setSelectedMarketCap] = useState([]);
    const [hoveredMarketCap, setHoveredMarketCap] = useState(false);
    const [marketCapAnchor, setMarketCapAnchor] = useState(null);
    const [activeMarketCapOption, setActiveMarketCapOption] = useState(null);
    const [isVolumePopoverOpen, setIsVolumePopoverOpen] = useState(false);
    const [isMarketCapPopoverOpen, setIsMarketCapPopoverOpen] = useState(false);

    const sentimentFilterData = [
        {categories: "All Influencers", subOptions: ["Bullish", "Bearish"]},
        {categories: "Top 10% Influencers", subOptions: ["Bullish", "Bearish"]},
        {categories: "Institutions", subOptions: ["Bullish", "Bearish"]},
        {categories: "Whales", subOptions: ["Bullish", "Bearish"]},

        {categories: "Individuals", subOptions: ["Bullish", "Bearish"]},
        {categories: "Grade", subOptions: ["Bullish", "Bearish"]},
    ];

    const handlePremiumSignalsPopoverOpen = (event) => {
        setAnchorElForPremiumSignalsPopover(event.currentTarget); // Open the popover when button is clicked
        setIsPremiumSignalsPopoverOpen(true); // Set popover open state
    };

    const handlePremiumSignalsPopoverClose = () => {
        setIsPremiumSignalsPopoverOpen(false); // Close the popover when clicked outside
    };

    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

    const [selectedSentimentFilters, setSelectedSentimentFilters] = useState([]);
    const [isSentimentFilterOpen, setIsSentimentFilterOpen] = useState(false);
    const [hoveredSentiment, setHoveredSentiment] = useState(false);
    const [sentimentFilterAnchor, setSentimentFilterAnchor] = useState(null);

    const handleSentimentFilterOpen = (event) => {
        setSentimentFilterAnchor(event.currentTarget);
        setIsSentimentFilterOpen(true);
    };

    const handleSentimentFilterClose = () => {
        setIsSentimentFilterOpen(false);
        setSentimentFilterAnchor(null);
    };

    const handleSentimentCheckboxChange = (category, subOption) => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        // Check if the user is logged in and handle accordingly
        // if (!userData) {
        //   // User is not logged in
        //   setIsSentimentFilterOpen(false);
        //   setSentimentFilterAnchor(null);
        //  //window.location.href = `${process.env.REACT_APP_HOME_URL}subscriptions/screener360`;
        //   const originalUrl = window.location.pathname + window.location.search;
        //   sessionStorage.setItem("originalUrl", originalUrl);
        //   handleLoginClick(); // Redirect to login

        // } else
        if (isPaidMember == "yes") {
            // User is logged in and is a paid member
            // Proceed with the selection logic
            // Check if the selected subOption is either Bullish or Bearish
            const isBullish = subOption.toLowerCase().includes("bullish");
            const isBearish = subOption.toLowerCase().includes("bearish");

            // Filter out the existing filters for the category
            const updatedFilters = selectedSentimentFilters.filter((filter) => {
                if (filter.includes(category)) {
                    // If we are changing an existing filter, ensure not both Bullish and Bearish are selected for the category
                    if (isBullish && filter.includes("Bullish")) return true;
                    if (isBearish && filter.includes("Bearish")) return true;
                    return false; // Remove the conflicting filter
                }
                return true; // Keep other filters
            });

            // Add the new filter (if it's not already selected)
            if (updatedFilters.includes(`${category} ${subOption}`)) {
                updatedFilters.splice(updatedFilters.indexOf(`${category} ${subOption}`), 1); // Remove if already selected
            } else {
                updatedFilters.push(`${category} ${subOption}`);
            }

            // Update the state with the new selected filters
            setSelectedSentimentFilters(updatedFilters);
        } else {
            window.location.href = `${process.env.REACT_APP_HOME_URL}subscriptions/screener360`;
        }
    };
    const handleSentimentFilterRemove = (filter) => {
        // Remove the filter from the selectedSentimentFilters array
        setSelectedSentimentFilters(selectedSentimentFilters.filter((f) => f !== filter));
    };

    const clearAllSentimentFilters = () => {
        setSelectedSentimentFilters([]);
    };

    const volumeOptions = [">$10B", "$1B - $10B", "$100M - $1B", "$10M - $100M", "$1M - $10M", "<$1M"];

    const marketCapOptions = [">$10B", "$1B - $10B", "$100M - $1B", "$10M - $100M", "$1M - $10M", "<$1M"];

    const handleVolumeClose = () => {
        setVolumeAnchor(null);
    };

    const handleMarketCapClose = () => {
        setMarketCapAnchor(null);
    };

    const handleVolumeClick = (event) => {
        setVolumeAnchor(event.currentTarget);
    };

    const handleMarketCapClick = (event) => {
        setMarketCapAnchor(event.currentTarget);
    };
    const handleVolumeRemove = () => {
        setSelectedVolume([]);
        setActiveVolumeOption(null);
        setIsVolumePopoverOpen(false);
        setHoveredVolume(false);
    };

    const handleMarketCapRemove = () => {
        setActiveMarketCapOption(null); // Clear the active option
        setSelectedMarketCap([]); // Reset the selected option
        setIsMarketCapPopoverOpen(false); // Close the popover
        setHoveredMarketCap(false);
    };
    const handleClearPremiumSignalsOptions = () => {
        setSelectedPremiumSignals(false); // Clear the selected premium signals
        //setSelectedPremiumSignalsOption(null); // Clear the selected option
        setIsPremiumSignalsPopoverOpen(false); // Close the popover
        setHoveredPremiumSignals(false);
    };

    const closesnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbaropen(false);
    };
    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );
    const mediaQueryVar2 = useMediaQuery(
        json2mq({
            maxWidth: 389,
        })
    );
    const mediaQueryVar5 = useMediaQuery(
        json2mq({
            minWidth: 1900,
        })
    );
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className="d-flex flex-column h-100" style={{overflowY: "hidden"}}>
            <SidebarMenuList />
        </div>
    );

    const handleButtonClick = (buttonName) => {
        // Pass the buttonName to the parent component's function
        onButtonClick(buttonName);
    };

    const handleClick = (buySell) => {
        setSnackbaropen(true);
        setOpen(true);
        setSeverity("info");
        setIsFormSubmitted(false);
        // setSnackbarcolor("success");
        // setSnackbarmessage("Data saved successfully");
        setMsgforUpdatePortfolio("loading...");
        console.log(price, selectedDate, buySell, unit);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const portfolioId =
        selectedOption && !isNaN(selectedOption.value) && /^\d+$/.test(selectedOption.value)
            ? parseInt(selectedOption.value)
            : 0;
        const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId

        addToPortfolio({
            portfolio_type_id: portfolioId,
            portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined

            existing_portfolio: existingPortfolio,
            user_id: userData.user_id,
            slug: slugpopupdata.slugname,
            unit: unit,
            acquisition_price: price,
            date_time: selectedDate,
            type: buySell,
            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                // setOpen(false);
                setSeverity("info");
                //console.log(res.message);
                setMsgforUpdatePortfolio(res.message);
                setPortfolioTypeId(res.portfolio_type_id);
                setIsFormSubmitted(true);
                // navigate(
                //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
                //     selectedOption.label
                //   )}`
                // );
            } else {
                setSeverity("error");
                // setOpen(true)
                setMsgforUpdatePortfolio(res.message);
            }
        });
    };

    useEffect(() => {
        setShowloginpage(true);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        // console.log(params);
        if (params) {
            const googleLoginCode = params.get("code");
            if (googleLoginCode) {
                setLoadingForLogin(true);
                completeGoogleLogin(googleLoginCode).then((data) => {
                    //console.log(data);
                    if (data.code == "200") {
                        //console.log(data.message);
                        localStorage.setItem("cw_portfolio_user", JSON.stringify(data.data));
                        const portfolio_userId = localStorage.getItem("portfolio_userId");
                        if (portfolio_userId) {
                            //  navigate(portfolio/${portfolio_userId}, { replace: true });
                            window.location.replace(`${process.env.REACT_APP_HOME_URL}portfolio/${portfolio_userId}`);
                            localStorage.removeItem("portfolio_userId");
                        } else {
                            if (data.data.portfolio_form_redirect === "yes") {
                                //navigate("/profile", { replace: true });
                                window.location.replace(`${process.env.REACT_APP_HOME_URL}profile`);
                            } else {
                                window.location.replace(`${process.env.REACT_APP_HOME_URL}cryptocurrencylist`);
                                //navigate("/cryptocurrencylist", { replace: true });
                            }
                        }
                    } else {
                        console.log("GOOGLE LOGIN FAILED");
                        // TODO
                        // pop up the error
                    }
                });
            } else {
                setShowloginpage(false);
            }
        }
    });

    useEffect(() => {
        setIndex(0);
        setLoader(false);

        if (filter.length < 1 && filter.length >= 1) {
            setLoader(true);
            setAddMoreLoader(false);
        } else {
            getCryporCurrencyListHome({
                slug: filter,
                start_index: 0,
                limit: `${20}`,
                pathname: "crypto-screener",
            }).then((res) => {
                if (res.code === "200") {
                    console.log("data got successfully");
                    setSlugdata(res.data);
                    setuser_role(res.user_role);
                    setLastUpdatedTiem(res.current_price_updated_on);

                    if (res.data.length < 20) {
                        setLoaderButton(false);
                    } else {
                        setLoaderButton(true);
                    }

                    setLoader(true);
                    setAddMoreLoader(false);
                } else {
                    console.log("Data fetching error");
                }
            });
        }
    }, [filter]);
    const getBackgroundColor = (item) => {
        const textColor = item.outlook_for_today[0].textcolor;
        if (textColor === "#ff6900") {
            return "rgba(236, 82, 29, 0.16)";
        } else if (textColor === "#00d084") {
            return "rgba(0, 185, 174, 0.16)";
        } else if (textColor === "#cf2e2e") {
            return "rgba(206, 45, 79, 0.16)";
        }
        // Add more conditions for other text colors if needed
        // If the color doesn't match any specific condition, return a default color
        return "defaultBackgroundColor";
    };

    useEffect(() => {
        fetchDropdownOptions();
    }, []);
    console.log(hashtagData);
    console.log("index:" + index);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const open1 = Boolean(anchorEl);
    const [loading1, setLoading1] = useState(true);
    const [error, setError] = useState(null);

    const fetchDistinctCategories = async () => {
        try {
            const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/getDistinctCategories", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const result = await response.json();
            console.log(result);
            if (result.code === "200") {
                setCategories(result.data); // Set categories in state
            } else {
                setError(result.message);
            }
        } catch (err) {
            setError("An error occurred while fetching categories.");
        } finally {
            setLoading1(false);
        }
    };

    // Separate useEffect for fetching categories
    useEffect(() => {
        fetchDistinctCategories();
    }, []); // This runs only once after the component mounts

    const [showAlert, setShowAlert] = useState(false);
    const handleChange2 = (e) => {
        const value = e.target.value;
        // Check if the input contains a hyphen
        if (value.includes("-")) {
            setShowAlert(true); // Show the alert if there's a hyphen
        } else {
            setShowAlert(false); // Hide the alert if there's no hyphen
            setNewOptionValue(value); // Update the state with the new value
        }
    };

    const observerRef = useRef(null);
    const loadMore = () => {
        if (addMoreLoader) return; // Prevent duplicate calls

        setAddMoreLoader(true);
        setIndex((prevIndex) => prevIndex + 20);

        getCryporCurrencyListHome({
            slug: filter,
            start_index: index + 20,
            limit: `${20}`,
            category: selectedCategory,
            hashtagdata: hashtagData,
            selected_filters: selectedFilters,
            volume: selectedVolume,
            marketCap: selectedMarketCap,
            sentiment_filters: selectedSentimentFilters,
            user_id: userData?.user_id,
            pathname: "crypto-screener",
        }).then((res) => {
            if (res.code === "200") {
                console.log("New data loaded successfully");
                setSlugdata((prevData) => [...prevData, ...res.data]);
                setIsPaidMember(res.paid_member);

                if (res.data.length < 20) {
                    setLoaderButton(false);
                } else {
                    setLoaderButton(true);
                }
            } else {
                console.log("Error fetching data");
            }
            setAddMoreLoader(false);
        });
    };
    useEffect(() => {
        console.log("calling");

        if (!loaderbutton || addMoreLoader) return; // Avoid unnecessary calls

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    console.log("Bottom reached! Loading more...");
                    loadMore();
                }
            },
            {rootMargin: "100px", threshold: 0.1}
        );

        const currentRef = observerRef.current;
        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
            observer.disconnect(); // Proper cleanup
        };
    }, [loaderbutton, addMoreLoader, loadMore]);

    const [isLoginModalOpen, setLoginModalOpen] = useState(false);

    const handleLoginClick = () => {
        // Mask the URL by adding /login at the end without navigating
        window.history.pushState({}, "", "/login");
        localStorage.setItem("isPopupManuallyOpened", "true");
        // Open the login modal
        setLoginModalOpen(true);
    };

    const handleAddToPortfolio = (idx) => {
        console.log("IDX in crypto screenr:", idx);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        if (userData) {
            setSlugpopupdata({
                slugname: slugdata[idx].slug,
                slug: slugdata[idx].name,
                units: slugdata[idx].total_unit,
                pricetoday: slugdata[idx].price_today,
                logo: slugdata[idx].logo,
            });
            setOpen(true);
        } else {
            // Save the source in sessionStorage
            const originalUrl = window.location.pathname + window.location.search;
            sessionStorage.setItem("originalUrl", originalUrl);
            sessionStorage.setItem("loginSource", "addToPortfolio");
            window.history.pushState({}, "", "/login");
            localStorage.setItem("isPopupManuallyOpened", "true");
            setLoginModalOpen(true);
        }
    };

    const handleModalClose = () => {
        // Close the modal and reset the URL to the current page
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    };

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            border: 0,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: 0,
        },
    }));

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));
    var rows = [],
        i = 0,
        len = 15;
    while (++i <= len) rows.push(i);

    const container = window1 !== undefined ? () => window1().document.body : undefined;

    const handleDialogSubmit = () => {
        // Handle submitting the dialog (e.g., creating the new option)
        console.log("Creating new option:", newOptionValue);

        // Update options state with the new option
        const newOption = {value: newOptionValue, label: newOptionValue};
        setOptions([...options, newOption]);
        setSelectedOption(newOption);
        // Close the dialog
        setIsDialogOpen(false);

        // setSelectedOption(selectedOption);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        getTotalunit({
            user_id: userData.user_id,
            slug: dataslug, // Assuming value contains the slug
            portfolio_type_id: newOption.value, // Adjust as needed
            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                setTotalUnits(res.total_uint);
                setNewOptionValue("");
                setUsablewallet(res.wallet_amount);
            } else {
                console.log("Data fething error");
            }
            // console.log(slugdata)
        });
    };
    // Step 1: Create a shallow copy of slugdata
    const slugdata1 = slugdata.slice();

    // Step 2: Sort slugdata1 based on total_roi_value
    const sortedSlugdata = slugdata1;

    const formatToNonZero = (number) => {
        number = parseFloat(number);

        if (number >= 1) {
            return number.toFixed(2);
        } else if (number >= 0.1) {
            return number.toFixed(1);
        } else if (number >= 0.01) {
            return number.toFixed(4);
        } else if (number >= 0.001) {
            return number.toFixed(5);
        } else if (number >= 0.0001) {
            return number.toFixed(6);
        } else if (number >= 0.00001) {
            return number.toFixed(7);
        } else if (number >= 0.000001) {
            return number.toFixed(8);
        } else if (number >= 0.0000001) {
            return number.toFixed(9);
        } else if (number >= 0.00000001) {
            return number.toFixed(10);
        } else if (number >= 0.000000001) {
            return number.toFixed(11);
        }
        return number.toFixed(1);
    };

    const filterData = [
        {categories: "RSI", subOptions: ["Bullish", "Bearish"]},
        {categories: "MACD", subOptions: ["Bullish", "Bearish"]},
        {categories: "Bollinger Bands", subOptions: ["Bullish", "Bearish"]},
        {categories: "Fibonacci Retracement", subOptions: ["Bullish", "Bearish"]},
        {categories: "On Balance Volume", subOptions: ["Bullish", "Bearish"]},
        {categories: "Heads and Shoulders", subOptions: ["Bullish", "Bearish"]},
        {categories: "Triangles", subOptions: ["Bullish", "Bearish"]},
    ];

    const [filterAnchor, setFilterAnchor] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]); // Store selected filters as objects

    const handleFilterOpen = (event) => setFilterAnchor(event.currentTarget);
    const handleFilterClose = () => setFilterAnchor(null);

    const isFilterOpen = Boolean(filterAnchor);

    const handleCheckboxChange = (categories, subOption) => {
        const filterKey = `${categories} ${subOption}`; // Create unique key like 'Category SubOption'

        setSelectedFilters((prev) => {
            // Check if the current filter key is already selected
            const isCurrentlySelected = prev.includes(filterKey);

            // If the current option is already selected, deselect it
            if (isCurrentlySelected) {
                return prev.filter((item) => item !== filterKey);
            }

            // Create a new array that deselects any other option in the same category
            const updatedFilters = prev.filter((item) => !item.startsWith(categories));

            // Add the newly selected option
            return [...updatedFilters, filterKey];
        });
    };

    const clearAllFilters = () => {
        setSelectedFilters([]); // Clear all selected filters
        handleFilterClose(); // Close the popover
    };
    // Debug: Log selected filters to check the values
    console.log("Selected Filters:", selectedFilters);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        // First, clear the filters
        urlParams.delete("filters");

        // Add "technical-signals" filter if selectedFilters are not empty
        if (selectedFilters.length > 0) {
            urlParams.append("filters", "technical-signals");
        }

        // Add selected category filter if a category is selected and not empty
        if (selectedCategory) {
            urlParams.append("filters", "categories");
        }

        // Add "volume" filter if any volume filter is selected
        if (selectedVolume.length > 0) {
            urlParams.append("filters", "volume");
        }

        // Add "market-cap" filter if any market cap filter is selected
        if (selectedMarketCap.length > 0) {
            urlParams.append("filters", "market-cap");
        }

        // Add "sentiment" filter if any sentiment filter is selected
        if (selectedSentimentFilters.length > 0) {
            urlParams.append("filters", "sentiment");
        }

        // Generate the updated URL with the filters
        const newUrl = urlParams.toString()
            ? `${window.location.pathname}?${urlParams.toString()}`
            : window.location.pathname;

        // Update the browser's URL
        window.history.pushState(null, "", newUrl);
    }, [selectedFilters, selectedCategory, selectedVolume, selectedMarketCap, selectedSentimentFilters]); // Watch all the filter states for changes
    useEffect(() => {
        console.log(selectedCategory);
        console.log("hashtag:" + hashtagData);

        getCryporCurrencyListHome({
            slug: filter,
            start_index: `${index}`,
            limit: `${20}`,
            category: selectedCategory,
            hashtagdata: hashtagData,
            selected_filters: selectedFilters,
            volume: selectedVolume,
            marketCap: selectedMarketCap,
            sentiment_filters: selectedSentimentFilters,
            user_id: userData?.user_id,
            pathname: "crypto-screener",
        }).then((res) => {
            if (res.code === "200") {
                console.log("data got successfully");
                setSlugdata(res.data);
                setIsPaidMember(res.paid_member);
                console.log("is paid:" + isPaidMember);
                console.log("Selected Sentiment Filters :" + selectedSentimentFilters);
                console.log(res.data);
                setuser_role(res.user_role);
                const updatedSlugData = res.data.map((item) => ({
                    ...item,
                    backgroundColor: getBackgroundColor(item), // Calculate background color dynamically
                }));

                if (res.data.length < 20) {
                    setLoaderButton(false);
                } else {
                    setLoaderButton(true);
                }
                setLoader(true);
                setAddMoreLoader(false);
            } else {
                console.log("Data fething error");
            }
            // console.log(slugdata)
        });
    }, [selectedCategory, hashtagData, selectedFilters, selectedVolume, selectedMarketCap, selectedSentimentFilters]);

    const handleFilterRemove = (filterToRemove) => {
        setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter !== filterToRemove));
    };

    const handleCategoryRemove = () => {
        setSelectedCategory(null);
    };

    return (
        <>
            {mediaQueryVar === true ? (
                <Box
                    sx={{
                        flexDirection: mediaQueryVar === false ? "column" : "",

                        display: "flex",
                        height: "100vh",
                    }}
                    className="maindiv"
                >
                    <Helmet>
                        <title>Crypto Screener</title>
                    </Helmet>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            minHeight: "100vh",
                            width: {sm: `calc(100% - ${drawerWidth}px)`},
                            backgroundColor: "white",
                        }}
                        className="maindiv"
                    >
                        {isLoginModalOpen && (
                            <LoginPopUp
                                isLoginModalOpen={isLoginModalOpen}
                                setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                                onClose={handleModalClose}
                            />
                        )}

                        <div
                            style={{
                                fontFamily: "Satoshi, sans-serif",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "2%", // Add spacing between buttons
                                marginTop: "-25px",
                                marginBottom: "25px",
                            }}
                        >
                            <Button
                                endIcon={
                                    <img
                                        src={
                                            selectedFilters.length > 0 || hoveredSignal
                                                ? "images/arrowdown-white.svg"
                                                : "images/arrowdown.svg"
                                        }
                                        width="14"
                                        height="7"
                                        alt="endIcon"
                                    />
                                }
                                variant="outlined"
                                onClick={handleFilterOpen}
                                onMouseEnter={() => setHoveredSignal(true)}
                                onMouseLeave={() => setHoveredSignal(false)}
                                sx={{
                                    background: selectedFilters.length > 0 || hoveredSignal ? "#1877F2" : "white",
                                    height: "36.86px",
                                    color: selectedFilters.length > 0 || hoveredSignal ? "white" : "#1877F2",
                                    padding: "6px 14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    border: "1px #1877F2 solid",
                                    marginRight: "2%",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    "&:hover": {
                                        background: "#1877F2",
                                        color: "white",
                                    },
                                }}
                            >
                                Technical Signals
                            </Button>

                            <Popover
                                open={isFilterOpen}
                                anchorEl={filterAnchor}
                                onClose={handleFilterClose}
                                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                                transformOrigin={{vertical: "top", horizontal: "center"}}
                                sx={{
                                    "& .MuiPopover-paper": {
                                        maxHeight: "400px",
                                        overflowY: "auto",
                                        width: "300px",
                                    },
                                }}
                            >
                                {/* Filters Content */}
                                <Box sx={{padding: "16px"}}>
                                    <Box sx={{position: "sticky", top: 0, background: "white", zIndex: 1}}>
                                        <Button
                                            size="small"
                                            onClick={clearAllFilters}
                                            sx={{textTransform: "capitalize", marginBottom: "8px"}}
                                            startIcon={<ClearIcon />}
                                        >
                                            Clear All
                                        </Button>
                                    </Box>
                                    <Divider />
                                    {filterData.map((filter) => (
                                        <Box key={filter.categories} sx={{marginTop: "12px"}}>
                                            <Typography variant="subtitle1" sx={{fontWeight: "600", fontSize: "14px"}}>
                                                {filter.categories}
                                            </Typography>
                                            <FormGroup sx={{fontSize: "14px"}}>
                                                {filter.subOptions.map((subOption) => (
                                                    <FormControlLabel
                                                        key={subOption}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedFilters.includes(
                                                                    `${filter.categories} ${subOption}`
                                                                )}
                                                                onChange={() =>
                                                                    handleCheckboxChange(filter.categories, subOption)
                                                                }
                                                            />
                                                        }
                                                        label={subOption}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </Box>
                                    ))}
                                </Box>
                            </Popover>

                            <Button
                                endIcon={
                                    <img
                                        src={
                                            selectedCategory || hoveredCategory
                                                ? "images/arrowdown-white.svg"
                                                : "images/arrowdown.svg"
                                        }
                                        width="14"
                                        height="7"
                                        alt="endIcon"
                                    />
                                }
                                variant="outlined"
                                sx={{
                                    background: selectedCategory || hoveredCategory ? "#1877F2" : "white",
                                    color: selectedCategory || hoveredCategory ? "white" : "#1877F2",
                                    height: "36.86px",
                                    padding: "6px 14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    border: `1px ${selectedCategory || hoveredCategory ? "#1877F2" : "#1877F2"} solid`,
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    "&:hover": {
                                        background: "#1877F2",
                                        color: "white",
                                    },
                                }}
                                onClick={handlePopoverOpen}
                                onMouseEnter={() => setHoveredCategory(true)}
                                onMouseLeave={() => setHoveredCategory(false)}
                            >
                                Categories
                            </Button>

                            <Popover
                                open={open1}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                sx={{
                                    "& .MuiPopover-paper": {
                                        maxHeight: "300px",
                                        overflowY: "auto",
                                    },
                                }}
                            >
                                {/* Categories Content */}
                                <Box sx={{padding: "8px"}}>
                                    <Autocomplete
                                        id="searchable-category-select"
                                        options={categories}
                                        getOptionLabel={(option) => option}
                                        value={selectedCategory}
                                        onChange={(event, newValue) => {
                                            setSelectedCategory(newValue);
                                            handlePopoverClose();
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Search categories" variant="outlined" />
                                        )}
                                        sx={{margin: "10px", width: "300px"}}
                                    />
                                </Box>
                            </Popover>

                            {/* Volume Button */}
                            {/* Volume Button */}
                            <Button
                                endIcon={
                                    <img
                                        src={
                                            isVolumePopoverOpen || selectedVolume.length > 0 || hoveredVolume
                                                ? "images/arrowdown-white.svg"
                                                : "images/arrowdown.svg"
                                        }
                                        width="14"
                                        height={
                                            isVolumePopoverOpen || selectedVolume.length > 0 || hoveredVolume
                                                ? "7"
                                                : "7"
                                        }
                                        alt="arrow"
                                    />
                                }
                                variant="outlined"
                                onClick={(e) => {
                                    // Toggle the popover
                                    setIsVolumePopoverOpen(!isVolumePopoverOpen);
                                    handleVolumeClick(e);
                                }}
                                onMouseEnter={() => setHoveredVolume(true)}
                                onMouseLeave={() => setHoveredVolume(false)}
                                sx={{
                                    background:
                                        isVolumePopoverOpen || selectedVolume.length > 0 || hoveredVolume
                                            ? "#1877F2"
                                            : "white",
                                    color:
                                        isVolumePopoverOpen || selectedVolume.length > 0 || hoveredVolume
                                            ? "white"
                                            : "#1877F2",
                                    height: "36.86px",
                                    padding: "6px 14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    border: "1px solid #1877F2",
                                    fontSize: "16px",
                                    marginLeft: "2%",
                                    fontWeight: "500",
                                    "&:hover": {
                                        background: "#1877F2",
                                        color: "white",
                                    },
                                }}
                            >
                                Volume
                            </Button>

                            <Popover
                                open={isVolumePopoverOpen}
                                anchorEl={volumeAnchor}
                                onClose={() => setIsVolumePopoverOpen(false)} // Close the popover on close
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <List>
                                    {/* Clear All Option */}
                                    <ListItem
                                        button
                                        onClick={() => {
                                            setActiveVolumeOption(null); // Clear the active option
                                            setSelectedVolume([]); // Clear all selected options
                                            setIsVolumePopoverOpen(false); // Close the popover
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            onClick={clearAllFilters}
                                            sx={{textTransform: "capitalize", marginBottom: "0px"}}
                                            startIcon={<ClearIcon />}
                                        >
                                            Clear All
                                        </Button>
                                    </ListItem>
                                    <Divider />

                                    {/* Volume Options */}
                                    {volumeOptions.map((option, index) => (
                                        <ListItem
                                            button
                                            key={index}
                                            onMouseEnter={() => setHoveredVolume(true)}
                                            onMouseLeave={() => setHoveredVolume(false)}
                                        >
                                            <Checkbox
                                                checked={selectedVolume.includes(option)} // If the option is selected, check the box
                                                onChange={() => {
                                                    setSelectedVolume((prev) => {
                                                        // Toggle the option in the selectedVolume array
                                                        if (prev.includes(option)) {
                                                            // Remove the option if it's already selected
                                                            return prev.filter((item) => item !== option);
                                                        } else {
                                                            // Add the option if it's not already selected
                                                            return [...prev, option];
                                                        }
                                                    });
                                                }}
                                                sx={{
                                                    color: selectedVolume.includes(option) ? "#1877F2" : "inherit", // Highlight checked box
                                                }}
                                            />
                                            <ListItemText primary={option} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Popover>

                            {/* Market Cap Button */}
                            <Button
                                endIcon={
                                    <img
                                        src={
                                            selectedMarketCap.length > 0 || hoveredMarketCap || isMarketCapPopoverOpen
                                                ? "images/arrowdown-white.svg"
                                                : "images/arrowdown.svg"
                                        }
                                        width="14"
                                        height={
                                            isMarketCapPopoverOpen || selectedMarketCap.length > 0 || hoveredMarketCap
                                                ? "7"
                                                : "7"
                                        }
                                        alt="arrow"
                                    />
                                }
                                variant="outlined"
                                onClick={(e) => {
                                    // Toggle the popover
                                    setIsMarketCapPopoverOpen(!isMarketCapPopoverOpen);
                                    handleMarketCapClick(e);
                                }}
                                onMouseEnter={() => setHoveredMarketCap(true)}
                                onMouseLeave={() => setHoveredMarketCap(false)}
                                sx={{
                                    background:
                                        isMarketCapPopoverOpen || selectedMarketCap.length > 0 || hoveredMarketCap
                                            ? "#1877F2"
                                            : "white",
                                    color:
                                        isMarketCapPopoverOpen || selectedMarketCap.length > 0 || hoveredMarketCap
                                            ? "white"
                                            : "#1877F2",
                                    height: "36.86px",
                                    padding: "6px 14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    border: "1px solid #1877F2",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    marginLeft: "2%",
                                    "&:hover": {
                                        background: "#1877F2",
                                        color: "white",
                                    },
                                }}
                            >
                                Market Cap
                            </Button>

                            <Popover
                                open={isMarketCapPopoverOpen}
                                anchorEl={marketCapAnchor}
                                onClose={() => setIsMarketCapPopoverOpen(false)} // Close the popover on close
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <List>
                                    {/* Clear All Option */}
                                    <ListItem
                                        button
                                        onClick={() => {
                                            setActiveMarketCapOption(null); // Clear the active option
                                            setSelectedMarketCap([]); // Clear all selected options
                                            setIsMarketCapPopoverOpen(false); // Close the popover
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            onClick={clearAllFilters}
                                            sx={{textTransform: "capitalize", marginBottom: "0px"}}
                                            startIcon={<ClearIcon />}
                                        >
                                            Clear All
                                        </Button>
                                    </ListItem>
                                    <Divider />
                                    {/* Market Cap Options */}
                                    {marketCapOptions.map((option, index) => (
                                        <ListItem
                                            button
                                            key={index}
                                            onMouseEnter={() => setHoveredMarketCap(true)}
                                            onMouseLeave={() => setHoveredMarketCap(false)}
                                        >
                                            <Checkbox
                                                checked={selectedMarketCap.includes(option)} // If the option is selected, check the box
                                                onChange={() => {
                                                    setSelectedMarketCap((prev) => {
                                                        // Toggle the option in the selectedMarketCap array
                                                        if (prev.includes(option)) {
                                                            // Remove the option if it's already selected
                                                            return prev.filter((item) => item !== option);
                                                        } else {
                                                            // Add the option if it's not already selected
                                                            return [...prev, option];
                                                        }
                                                    });
                                                }}
                                                sx={{
                                                    color: selectedMarketCap.includes(option) ? "#1877F2" : "inherit", // Highlight checked box
                                                }}
                                            />
                                            <ListItemText primary={option} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Popover>

                            {/* Premium Signals Button */}
                            <Button
                                endIcon={
                                    <>
                                        {isSentimentFilterOpen && isPaidMember === "no" && (
                                            <img
                                                src="images/lock-new.svg" // Lock icon path
                                                width="24"
                                                height="24"
                                                alt="endIcon"
                                                style={{
                                                    position: "absolute",
                                                    top: "-20px",
                                                    left: "50%",
                                                    transform: "translateX(-50%)",
                                                }} // Positioning lock icon on top of the button
                                            />
                                        )}

                                        <img
                                            src={
                                                isSentimentFilterOpen ||
                                                selectedSentimentFilters.length > 0 ||
                                                hoveredSentiment
                                                    ? "images/arrowdown-white.svg"
                                                    : "images/arrowdown.svg"
                                            }
                                            width="14"
                                            height="7"
                                            alt="isSentimentFilterOpen"
                                        />
                                    </>
                                }
                                variant="outlined"
                                onClick={handleSentimentFilterOpen}
                                onMouseEnter={() => setHoveredSentiment(true)}
                                onMouseLeave={() => setHoveredSentiment(false)}
                                sx={{
                                    background:
                                        selectedSentimentFilters.length > 0 || isSentimentFilterOpen || hoveredSentiment
                                            ? "#1877F2"
                                            : "white",
                                    height: "36.86px",
                                    color:
                                        selectedSentimentFilters.length > 0 || isSentimentFilterOpen || hoveredSentiment
                                            ? "white"
                                            : "#1877F2",
                                    padding: "6px 14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    border: "1px #1877F2 solid",
                                    marginRight: "2%",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    "&:hover": {
                                        background: "#1877F2",
                                        color: "white",
                                    },
                                }}
                            >
                                Influencer Signals
                            </Button>

                            <Popover
                                open={isSentimentFilterOpen}
                                anchorEl={sentimentFilterAnchor}
                                onClose={handleSentimentFilterClose}
                                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                                transformOrigin={{vertical: "top", horizontal: "center"}}
                                sx={{
                                    "& .MuiPopover-paper": {
                                        maxHeight: "400px",
                                        overflowY: "auto",
                                        width: "300px",
                                    },
                                }}
                            >
                                {/* Filters Content */}
                                <Box sx={{padding: "16px"}}>
                                    <Box sx={{position: "sticky", top: 0, background: "white", zIndex: 1}}>
                                        <Button
                                            size="small"
                                            onClick={clearAllSentimentFilters}
                                            sx={{textTransform: "capitalize", marginBottom: "8px"}}
                                            startIcon={<ClearIcon />}
                                        >
                                            Clear All
                                        </Button>
                                    </Box>
                                    <Divider />
                                    {sentimentFilterData.map((filter) => (
                                        <Box key={filter.categories} sx={{marginTop: "12px"}}>
                                            <Typography variant="subtitle1" sx={{fontWeight: "600", fontSize: "14px"}}>
                                                {filter.categories}
                                            </Typography>
                                            <FormGroup sx={{fontSize: "14px"}}>
                                                {filter.subOptions.map((subOption) => (
                                                    <FormControlLabel
                                                        key={subOption}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedSentimentFilters.includes(
                                                                    `${filter.categories} ${subOption}`
                                                                )}
                                                                onChange={() =>
                                                                    handleSentimentCheckboxChange(
                                                                        filter.categories,
                                                                        subOption
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={subOption}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </Box>
                                    ))}
                                </Box>
                            </Popover>
                        </div>

                        <div
                            style={{
                                marginTop: "10px",
                                backgroundColor: "#EDF1F6",
                                marginLeft: "-40px",
                                marginRight: "-10px",
                                padding: "20px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexWrap: "wrap",
                                    gap: "10px",
                                }}
                            >
                                {selectedFilters.map((filter, index) => {
                                    // Split the filter into the signal name and the sub-option
                                    const parts = filter.split(" "); // Split by space
                                    const signal = parts.slice(0, -1).join(" "); // Everything except the last word is the signal
                                    const subOption = parts[parts.length - 1]; // The last word is the sub-option

                                    return (
                                        <Button
                                            key={index}
                                            sx={{
                                                background: "#fff",
                                                color: "#1877F2",
                                                borderRadius: "6px",
                                                border: "1px solid #1877F2",
                                                textTransform: "none",
                                                padding: "6px 14px",
                                                display: "flex", // Ensure that the button content is aligned
                                                alignItems: "center", // Align text and icon horizontally
                                                justifyContent: "space-between", // Ensure space between text and cross icon
                                            }}
                                        >
                                            {signal} - {subOption} {/* Adding '-' before the sub-option */}
                                            <ClearIcon
                                                sx={{
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                    marginLeft: "8px", // Add spacing between the filter text and the cross icon
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent button click action when clicking the cross icon
                                                    handleFilterRemove(filter); // Implement the logic to remove the filter
                                                }}
                                            />
                                        </Button>
                                    );
                                })}

                                {selectedCategory && (
                                    <Button
                                        sx={{
                                            background: "#fff",
                                            color: "#1877F2",
                                            borderRadius: "6px",
                                            border: "1px solid #1877F2",
                                            textTransform: "none",
                                            padding: "6px 14px",
                                            display: "flex", // Ensure that the button content is aligned
                                            alignItems: "center", // Align text and icon horizontally
                                            justifyContent: "space-between", // Ensure space between text and cross icon
                                        }}
                                    >
                                        {selectedCategory}
                                        <ClearIcon
                                            sx={{
                                                fontSize: "16px",
                                                cursor: "pointer",
                                                marginLeft: "8px", // Add spacing between the filter text and the cross icon
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent button click action when clicking the cross icon
                                                handleCategoryRemove(); // Implement the logic to remove the selected category
                                            }}
                                        />
                                    </Button>
                                )}

                                {selectedVolume.length > 0 && (
                                    <Button
                                        sx={{
                                            background: "#fff",
                                            color: "#1877F2",
                                            borderRadius: "6px",
                                            border: "1px solid #1877F2",
                                            textTransform: "none",
                                            padding: "6px 14px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        Volume - {selectedVolume.join(", ")} {/* Adding Volume */}
                                        <ClearIcon
                                            sx={{
                                                fontSize: "16px",
                                                cursor: "pointer",
                                                marginLeft: "8px",
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleVolumeRemove(); // Implement the logic to remove the volume filter
                                            }}
                                        />
                                    </Button>
                                )}

                                {selectedMarketCap.length > 0 && (
                                    <Button
                                        sx={{
                                            background: "#fff",
                                            color: "#1877F2",
                                            borderRadius: "6px",
                                            border: "1px solid #1877F2",
                                            textTransform: "none",
                                            padding: "6px 14px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        Market Cap - {selectedMarketCap.join(", ")} {/* Adding Market Cap */}
                                        <ClearIcon
                                            sx={{
                                                fontSize: "16px",
                                                cursor: "pointer",
                                                marginLeft: "8px",
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleMarketCapRemove(); // Implement the logic to remove the market cap filter
                                            }}
                                        />
                                    </Button>
                                )}
                                {selectedSentimentFilters.map((filter, index) => {
                                    // Split the filter into the signal name and the sub-option
                                    const parts = filter.split(" "); // Split by space
                                    const signal = parts.slice(0, -1).join(" "); // Everything except the last word is the signal
                                    const subOption = parts[parts.length - 1]; // The last word is the sub-option

                                    return (
                                        <Button
                                            key={index}
                                            sx={{
                                                background: "#fff",
                                                color: "#1877F2",
                                                borderRadius: "6px",
                                                border: "1px solid #1877F2",
                                                textTransform: "none",
                                                padding: "6px 14px",
                                                display: "flex", // Ensure that the button content is aligned
                                                alignItems: "center", // Align text and icon horizontally
                                                justifyContent: "space-between", // Ensure space between text and cross icon
                                            }}
                                        >
                                            {signal} - {subOption} {/* Adding '-' before the sub-option */}
                                            <ClearIcon
                                                sx={{
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                    marginLeft: "8px", // Add spacing between the filter text and the cross icon
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent button click action when clicking the cross icon
                                                    handleSentimentFilterRemove(filter); // Implement the logic to remove the sentiment filter
                                                }}
                                            />
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                        <div style={{
                                display:"flex",
                                justifyContent : "center",
                                alignItems:"center",
                                gap:"80px"
                            }}>
                            <button 
                            onClick={() => navigate("/crypto-screener/hidden-gems")}
                            style={{
                                background: "#1877F2",
                                color  : "white",
                                borderRadius:"20px",
                                padding:"5px 12px",
                                border:"none"
                            }}>
                                Hidden Gems
                            </button>
                            <button
                            onClick={() => navigate("/crypto-screener/breakout-stars")}
                            style={{
                                background: "#1877F2",
                                color  : "white",
                                borderRadius:"20px",
                                padding:"5px 12px",
                                 border:"none"
                            }}>
                                BreakOut Stars
                            </button>
                            <button 
                            onClick={() => navigate("/crypto-screener/smart-money-picks")}
                            style={{
                                background: "#1877F2",
                                color  : "white",
                                borderRadius:"20px",
                                padding:"5px 12px",
                                 border:"none"
                            }}>
                                Smart Money Picks
                            </button>
                            </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: "20px",
                                marginLeft: "0px",
                                marginTop: "3%",
                            }}
                        >
                            
                            {loader === false && (
                                <>
                                    {rows.map((idx) => (
                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <div
                                                style={{
                                                    flex: "0 0 auto",
                                                    width: mediaQueryVar === true ? "50%" : "100%",
                                                }}
                                            >
                                                <Card sx={{maxWidth: 545, m: 2}}>
                                                    <CardHeader
                                                        avatar={
                                                            <Skeleton
                                                                animation="wave"
                                                                variant="circular"
                                                                width={40}
                                                                height={40}
                                                            />
                                                        }
                                                        title={
                                                            <Skeleton
                                                                animation="wave"
                                                                height={10}
                                                                width="80%"
                                                                style={{marginBottom: 6}}
                                                            />
                                                        }
                                                        subheader={
                                                            <Skeleton animation="wave" height={10} width="40%" />
                                                        }
                                                    />

                                                    <CardContent>
                                                        <React.Fragment>
                                                            <Skeleton
                                                                animation="wave"
                                                                height={10}
                                                                style={{marginBottom: 6}}
                                                            />
                                                            <Skeleton
                                                                animation="wave"
                                                                height={10}
                                                                style={{marginBottom: 6}}
                                                            />
                                                        </React.Fragment>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                            {mediaQueryVar === true && (
                                                <div style={{flex: "0 0 auto", width: "50%"}}>
                                                    <Card sx={{maxWidth: 545, m: 2}}>
                                                        <CardHeader
                                                            avatar={
                                                                <Skeleton
                                                                    animation="wave"
                                                                    variant="circular"
                                                                    width={40}
                                                                    height={40}
                                                                />
                                                            }
                                                            title={
                                                                <Skeleton
                                                                    animation="wave"
                                                                    height={10}
                                                                    width="80%"
                                                                    style={{marginBottom: 6}}
                                                                />
                                                            }
                                                            subheader={
                                                                <Skeleton animation="wave" height={10} width="40%" />
                                                            }
                                                        />

                                                        {/* <Skeleton sx={{ height: 100 }} animation="wave" variant="rectangular" /> */}
                                                        <CardContent>
                                                            <React.Fragment>
                                                                <Skeleton
                                                                    animation="wave"
                                                                    height={10}
                                                                    style={{marginBottom: 6}}
                                                                />
                                                                <Skeleton
                                                                    animation="wave"
                                                                    height={10}
                                                                    style={{marginBottom: 6}}
                                                                />
                                                            </React.Fragment>
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </>
                            )}

                            {loader === true && (
                                <Grid container spacing={0}>
                                    {sortedSlugdata.map((obj, idx) => (
                                        <Grid item xs={12} md={6} key={idx}>
                                            <div
                                                key={idx}
                                                style={{
                                                    marginLeft: "5px",
                                                    borderRadius: "10px",
                                                    backgroundColor: "white",
                                                    border: " 1px #ECECEC solid",
                                                    padding: "24px",
                                                    marginBottom: "20px",
                                                    marginRight: "10px",
                                                }}
                                            >
                                                {/* First column data */}
                                                <div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            borderBottom: "1px #E2E2E4 solid",
                                                            marginBottom: "8px",
                                                            fontSize: mediaQueryVar === false ? "13px" : "",
                                                        }}
                                                    >
                                                        {/* Logo & Name */}
                                                        <div style={{display: "flex", alignItems: "center", flex: 1}}>
                                                            <img
                                                                className="b57ac673f06a4b0338a596817eb0a5Icon"
                                                                alt="logo"
                                                                src={obj.logo}
                                                                style={{
                                                                    width: "38px",
                                                                    height: "38px",
                                                                    border: "2px solid black",
                                                                    borderRadius: "20px",
                                                                    marginRight: "8px",
                                                                    marginBottom: "10px",
                                                                }}
                                                            />

                                                            {/* Name or Link */}
                                                            <div>
                                                                {obj["article_link"] ? (
                                                                    <a
                                                                        href={obj.article_link}
                                                                        style={{
                                                                            color: "black",
                                                                            textDecoration: "underline",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                fontSize: "18px",
                                                                                fontWeight: "600",
                                                                                fontFamily: "Satoshi, sans-serif",
                                                                                color: "black",
                                                                                marginBottom: "10px",
                                                                            }}
                                                                        >
                                                                            {obj.slug}
                                                                        </div>
                                                                    </a>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: "18px",
                                                                            fontWeight: "600",
                                                                            fontFamily: "Satoshi, sans-serif",
                                                                            color: "black",
                                                                            marginBottom: "10px",
                                                                            textDecoration: "underline",
                                                                        }}
                                                                    >
                                                                        {obj.slug}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* Recommendation Status (Centered) */}
                                                        <div
                                                            style={{
                                                                flex: 1,
                                                                textAlign: "center", // Centers text horizontally
                                                                fontWeight: "500",
                                                                marginBottom: "8px",
                                                                marginRight: "22px",
                                                                color:
                                                                    obj.recommend === "Hold"
                                                                        ? "orange"
                                                                        : obj.recommend === "Buy"
                                                                        ? "green"
                                                                        : obj.recommend === "Sell"
                                                                        ? "red"
                                                                        : "black",
                                                            }}
                                                        >
                                                            {obj.recommend}
                                                        </div>

                                                        {/* Button (Right Side) */}
                                                        <Button
                                                            className="btn"
                                                            variant="outlined"
                                                            onClick={() => handleAddToPortfolio(idx)}
                                                            style={{
                                                                backgroundColor: "white",
                                                                padding: "4px 10px",
                                                                fontFamily: "Satoshi, sans-serif",
                                                                borderRadius: "48px",
                                                                border: "1px solid #1877F2",
                                                                textTransform: "none",
                                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                                                fontWeight: "500",
                                                                color: "#1877F2",
                                                                whiteSpace: "nowrap",
                                                                width: "120px",
                                                                height: "30px",
                                                                marginLeft: "20px",
                                                                marginBottom: "10px",
                                                            }}
                                                        >
                                                            Add to Portfolio
                                                        </Button>
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        <div style={{flex: "0 0 auto", width: "17%"}}>
                                                            <span
                                                                style={{
                                                                    color: "#7F7F80",
                                                                    fontSize: "14px",
                                                                    letterSpacing: "0.46px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Price
                                                            </span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                flex: "0 0 auto",
                                                                width: "35%", // Add border to separate sections
                                                                paddingRight: "0px",
                                                                marginRight: "5px",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "black",
                                                                    fontSize: "14px",
                                                                    overflow: "hidden",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    textOverflow: "ellipsis",
                                                                    fontWeight: "500",
                                                                }}
                                                            >
                                                                {obj.price_today}
                                                            </span>
                                                        </div>

                                                        <div
                                                            style={{
                                                                width: "2px",
                                                                backgroundColor: "#E2E2E4",
                                                                height: "42px",
                                                                marginTop: "10px",
                                                                marginLeft: "-30px",
                                                                marginRight: "25px",
                                                                marginBottom: "-30px",
                                                            }}
                                                        ></div>

                                                        <div style={{flex: "0 0 auto", width: "23%"}}>
                                                            <span
                                                                style={{
                                                                    color: "#7F7F80",
                                                                    fontSize: "14px",
                                                                    letterSpacing: "0.46px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Market Cap
                                                            </span>
                                                        </div>
                                                        <div style={{flex: "0 0 auto", marginLeft: "auto"}}>
                                                            <span
                                                                style={{
                                                                    color: "black",
                                                                    fontSize: "14px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                }}
                                                            >
                                                                {obj.buy_percent}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        <div style={{flex: "0 0 auto", width: "17%"}}>
                                                            <span
                                                                style={{
                                                                    color: "#7F7F80",
                                                                    fontSize: "14px",
                                                                    letterSpacing: "0.46px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                30 Days
                                                            </span>
                                                        </div>
                                                        <div style={{flex: "0 0 auto", width: "35%"}}>
                                                            <span
                                                                style={{
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    fontSize: "14px",
                                                                    color: "black",
                                                                }}
                                                            >
                                                                {obj.thirtyd_change}
                                                            </span>
                                                        </div>
                                                        <div style={{flex: "0 0 auto", width: "23%"}}>
                                                            <span
                                                                style={{
                                                                    color: "#7F7F80",
                                                                    fontSize: "14px",
                                                                    letterSpacing: "0.46px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Forecast 2025
                                                            </span>
                                                        </div>
                                                        <div style={{flex: "0 0 auto", marginLeft: "auto"}}>
                                                            <span
                                                                style={{
                                                                    color: "black",
                                                                    fontSize: "14px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                }}
                                                            >
                                                                {obj.price_prediction_2025}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        <div style={{flex: "0 0 auto", width: "17%"}}>
                                                            <span
                                                                style={{
                                                                    color: "#7F7F80",
                                                                    fontSize: "14px",
                                                                    letterSpacing: "0.46px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    fontWeight: 500,
                                                                    whiteSpace: "nowrap",
                                                                }}
                                                            >
                                                                Top 100 Holders
                                                            </span>
                                                        </div>
                                                        <div style={{flex: "0 0 auto", width: "35%"}}>
                                                            <span
                                                                style={{
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    fontSize: "14px",
                                                                    color: "black",
                                                                    marginLeft: "50px",
                                                                }}
                                                            >
                                                                {obj.holdings || "NA"}
                                                            </span>
                                                        </div>
                                                        <div style={{flex: "0 0 auto", width: "23%"}}>
                                                            <span
                                                                style={{
                                                                    color: "#7F7F80",
                                                                    fontSize: "14px",
                                                                    letterSpacing: "0.46px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Grade
                                                            </span>
                                                        </div>
                                                        <div style={{flex: "0 0 auto", marginLeft: "auto"}}>
                                                            <span
                                                                style={{
                                                                    color: "black",
                                                                    fontSize: "14px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                }}
                                                            >
                                                                {obj.grade === "Outperformer" ? (
                                                                    <button
                                                                        disabled
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            padding: "3px",
                                                                            borderRadius: "5px",
                                                                            backgroundColor: "#DFFFF0",
                                                                            color: "#199F5F",
                                                                            border: "2px solid #199F5F",
                                                                            width: "100%",
                                                                            fontSize: "12px",
                                                                        }}
                                                                    >
                                                                        Outperformer
                                                                    </button>
                                                                ) : obj.grade === "Emerging Outperformer" ? (
                                                                    <button
                                                                        disabled
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            padding: "3px",
                                                                            borderRadius: "5px",
                                                                            backgroundColor: "#DFFFF0",
                                                                            color: "#199F5F",
                                                                            border: "2px solid #199F5F",
                                                                            width: "100%",
                                                                            fontSize: "12px",
                                                                        }}
                                                                    >
                                                                        Outperformer
                                                                    </button>
                                                                ) : obj.grade === "Moderate Performer" ? (
                                                                    <button
                                                                        disabled
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            padding: "3px",
                                                                            borderRadius: "5px",
                                                                            backgroundColor: "#F9F4E7",
                                                                            color: "#D4AF37",
                                                                            border: "2px solid #D4AF37",
                                                                            width: "100%",
                                                                            fontSize: "12px",
                                                                        }}
                                                                    >
                                                                        Moderate Performer
                                                                    </button>
                                                                ) : obj.grade === "Underperformer" ? (
                                                                    <button
                                                                        disabled
                                                                        style={{
                                                                            alignItems: "center",
                                                                            fontWeight: "bold",
                                                                            padding: "3px ",
                                                                            borderRadius: "5px",
                                                                            backgroundColor: "#FFE3E3",
                                                                            color: "#FF4141",
                                                                            border: "2px solid #FF4141",
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        Underperformer
                                                                    </button>
                                                                ) : null}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        <div style={{flex: "0 0 auto", width: "17%"}}>
                                                            <span
                                                                style={{
                                                                    color: "#7F7F80",
                                                                    fontSize: "14px",
                                                                    letterSpacing: "0.46px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Category
                                                            </span>
                                                        </div>
                                                        <div style={{flex: "0 0 auto", width: "35%"}}>
                                                            <span
                                                                style={{
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    fontSize: "14px",
                                                                    color: "black",
                                                                }}
                                                            >
                                                                {obj.category}
                                                            </span>
                                                        </div>
                                                        <div style={{flex: "0 0 auto", width: "23%"}}>
                                                            <span
                                                                style={{
                                                                    color: "#7F7F80",
                                                                    fontSize: "14px",
                                                                    letterSpacing: "0.46px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Risk
                                                            </span>
                                                        </div>
                                                        <div style={{flex: "0 0 auto", marginLeft: "auto"}}>
                                                            <span
                                                                style={{
                                                                    color:
                                                                        obj.risk === "High"
                                                                            ? "red"
                                                                            : obj.risk === "Medium"
                                                                            ? "orange"
                                                                            : "green",
                                                                    fontSize: "14px",
                                                                    fontFamily: "Satoshi, sans-serif",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                }}
                                                            >
                                                                {obj.risk}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}

                            <div style={{position: "relative"}}>
                                {addMoreLoader && (
                                    <div
                                        style={{
                                            position: "absolute", // Keeps it inside the content area
                                            bottom: "0px", // Always at the bottom of the content
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "rgba(255, 255, 255, 0.8)", // Light background for visibility
                                            padding: "10px",
                                            borderRadius: "10px",
                                            zIndex: 999, // Ensures visibility
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                border: "4px solid rgba(67, 97, 238, 0.3)", // Light blue border
                                                borderTop: "4px solid #4361ee", // Dark blue spinning part
                                                borderRadius: "50%",
                                                animation: "spin 1s linear infinite",
                                            }}
                                        ></div>
                                    </div>
                                )}

                                <div ref={observerRef} style={{height: "20px", marginBottom: "50px"}}></div>

                                <style>
                                    {`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}
                                </style>
                            </div>
                        </div>
                        {slugdata.length === 0 && loader === true && (
                            <div style={{textAlign: "center", marginTop: "20px"}}>
                                <h4>No Data Found</h4>
                            </div>
                        )}
                    </Box>
                    {/* } */}

                    <Modal
                        sx={{overflow: "hidden"}}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className="CardOpener" style={{overflow: "hidden"}}>
                            {isFormSubmitted ? (
                                <Card className="card__container">
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CheckCircleOutlineIcon sx={{fontSize: 50, mr: 1}} />
                                        </div>
                                        <Typography
                                            severity="success"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            Portfolio added successfully!
                                        </Typography>
                                    </>
                                    <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <Button
                                            onClick={() => {
                                                setIsFormSubmitted(false);
                                                setOpen(false);
                                                setUnit("");
                                                setSelectedOption("");
                                                setPrice("");
                                                setTotalUnits(0);
                                                handleDateChange("");
                                                setUsablewallet("");
                                            }}
                                            color="primary"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "150px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                        >
                                            Add More Cryptos
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                                                        selectedOption.label.replace(/\s+/g, "-")
                                                    )}`
                                                )
                                            }
                                            variant="outlined"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "100px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                            color="primary"
                                        >
                                            View Portfolio
                                        </Button>
                                    </div>
                                </Card>
                            ) : (
                                <Dialog
                                    PaperProps={{
                                        sx: {
                                            position: mediaQueryVar ? "static" : "fixed",
                                            bottom: mediaQueryVar ? "" : 0,
                                            left: mediaQueryVar ? "" : 0,
                                            right: mediaQueryVar ? "" : 0,
                                            marginTop: "1%",
                                            maxWidth: "100%",
                                            minHeight: "60%", // Adjust max height if needed
                                            borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
                                            padding: "10px",
                                            alignItems: "center",
                                            overflow: "scroll",
                                            "&::-webkit-scrollbar": {
                                                display: "none",
                                            },
                                            "-ms-overflow-style": "none",
                                            "scrollbar-width": "none", // Ensure no scrollbar
                                        },
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <div style={{padding: "20px", width: "400px", margin: "0", marginTop: "-5px"}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginBottom: "20px",
                                                borderBottom: "1px #E3E3E3 solid",
                                            }}
                                        >
                                            <Typography
                                                style={{fontWeight: "500", fontSize: "20.767px", paddingLeft: "100px"}}
                                                gutterBottom
                                            >
                                                Add To Portfolio
                                            </Typography>

                                            <div className="primaryButton">
                                                <img
                                                    className="frameIcon"
                                                    alt="frameIcon"
                                                    onClick={handleClose}
                                                    src={window.constants.asset_path + "/images/frame17.svg"}
                                                    style={{background: "#F3F3F3", width: "25px", height: "25px"}}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginBottom: "5px",
                                                marginLeft: mediaQueryVar === false ? "10px" : "",
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: "13.845px",
                                                    fontWeight: "500",
                                                    marginBottom: "1px",
                                                    color: "black",
                                                    marginTop: "-8px",
                                                }}
                                            >
                                                Select Portfolio
                                            </Typography>
                                            <div style={{marginTop: "5px"}}>
                                                <CreatableSelect
                                                    isClearable
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: "12px",
                                                            border: "1px solid #1877F2",
                                                            boxShadow: "none",
                                                            minHeight: "40px",
                                                            "&:hover": {
                                                                borderRadius: "12px",
                                                                border: "2px solid #1877F2",
                                                            },
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            borderRadius: "8px",
                                                        }),
                                                        placeholder: (provided) => ({
                                                            ...provided,
                                                            color: "",
                                                        }),
                                                        dropdownIndicator: (provided) => ({
                                                            ...provided,
                                                            color: "#1877F2",
                                                            border: "1px solid #1877F2",
                                                            padding: "5px",
                                                            margin: "8px",
                                                            borderRadius: "3.786px",
                                                            "&:hover": {
                                                                color: "#1877F2",
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({
                                                            display: "none",
                                                        }),
                                                    }}
                                                    placeholder=""
                                                    onChange={(options) =>
                                                        handleChange(options, slugpopupdata.slugname)
                                                    }
                                                    onCreateOption={handleCreate}
                                                    options={[
                                                        {value: "", label: "Create new portfolio", _isNew_: true}, // Dynamic "create new option" item
                                                        ...options, // Existing options
                                                    ]}
                                                    value={selectedOption}
                                                />
                                            </div>
                                            {showAlert && (
                                                <Alert severity="error" sx={{marginBottom: "10px"}}>
                                                    {errorMessage}
                                                </Alert>
                                            )}
                                        </div>
                                        <Typography
                                            style={{
                                                fontSize: "13.845px",
                                                fontWeight: "500",
                                                color: "#000",
                                                marginTop: "10px",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            Usable Wallet Value: {Usablewallet}
                                        </Typography>
                                        <div
                                            style={{
                                                padding: "8px",
                                                background: "#F3F3F3",
                                                borderRadius: "8px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            <div style={{display: "flex", alignItems: "center", flex: 0, minWidth: 0}}>
                                                <img
                                                    style={{
                                                        marginRight: "10px",
                                                        border: "2px solid #000",
                                                        borderRadius: "19px",
                                                        height: "27.69px",
                                                        width: "27.69px",
                                                    }}
                                                    alt="logo"
                                                    src={slugpopupdata.logo}
                                                />
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "500",
                                                            marginBottom: "4px",
                                                            fontSize: "14px",
                                                            color: "#7F7F80",
                                                        }}
                                                    >
                                                        {slugpopupdata.slugname}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    marginLeft: "23%",
                                                    flex: 1,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontWeight: "500",
                                                        marginBottom: "4px",
                                                        fontSize: "13px",
                                                        color: "#7F7F80",
                                                    }}
                                                >
                                                    {" "}
                                                    Price
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12.114px",
                                                        fontWeight: "500",
                                                        color: "black",
                                                        margin: "0",
                                                        marginLeft: "15px",
                                                    }}
                                                >
                                                    {slugpopupdata.pricetoday}
                                                </Typography>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontWeight: "500",
                                                        marginBottom: "4px",
                                                        fontSize: "13px",
                                                        color: "#7F7F80",
                                                    }}
                                                >
                                                    {" "}
                                                    Units
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12.114px",
                                                        fontWeight: "500",
                                                        color: "black",
                                                        margin: "0",
                                                    }}
                                                >
                                                    {totalUnits}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="card__container__row3">
                                            <div style={{marginLeft: "-5px"}}>
                                                <p style={{fontSize: "14px", fontWeight: "500"}}>
                                                    Enter number of Units
                                                </p>
                                                <input
                                                    style={{
                                                        backgroundColor: "white",
                                                        color: "black",
                                                        padding: "20px",
                                                        border: "1px solid #1877F2",
                                                        borderRadius: "10.384px",
                                                        width: "350px",
                                                        height: "45px",
                                                        margin: 0,
                                                    }}
                                                    min="1"
                                                    max="10"
                                                    type="number"
                                                    placeholder=""
                                                    value={unit}
                                                    onChange={(e) => setUnit(e.target.value)}
                                                ></input>
                                            </div>
                                            <div>
                                                $
                                                {formatToNonZero(
                                                    unit *
                                                        parseFloat((slugpopupdata.pricetoday || "").replace(/^\$/, ""))
                                                )}
                                            </div>
                                            {user_role === "superuser" && (
                                                <>
                                                    <div className="card__container__row3__r2">
                                                        <div style={{marginLeft: "-5px"}}>
                                                            <p style={{fontSize: "14px", fontWeight: "500"}}>
                                                                Buy/Sell Price($)
                                                            </p>
                                                            <input
                                                                style={{
                                                                    backgroundColor: "white",
                                                                    color: "black",
                                                                    padding: "20px",
                                                                    border: "1px solid #1877F2",
                                                                    borderRadius: "10.384px",
                                                                    width: "345px",
                                                                    height: "45px",
                                                                }}
                                                                className="card__container__row3__r2__input"
                                                                min="1"
                                                                max="10"
                                                                type="number"
                                                                placeholder="Enter Price"
                                                                value={price}
                                                                onChange={(e) => setPrice(e.target.value)}
                                                            ></input>
                                                        </div>
                                                    </div>
                                                    <div style={{marginBottom: "10px", marginLeft: "-5px"}}>
                                                        <p style={{fontSize: "14px", fontWeight: "500"}}>Date</p>
                                                        <TextField
                                                            type="date"
                                                            value={selectedDate}
                                                            onChange={(e) => handleDateChange(e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            sx={{
                                                                "& .MuiOutlinedInput-root": {
                                                                    borderRadius: "10.384px",
                                                                    border: "1px solid #1877F2",
                                                                    boxShadow: "none",
                                                                    width: "190%",
                                                                    height: "45px",
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            <div className="card__container__row3__r4" style={{marginTop: "10px"}}>
                                                <Button
                                                    className={
                                                        buySell === "buy" ? "buttonToggleButActive" : "buttonToggleBuy"
                                                    }
                                                    value="Buy"
                                                    style={{
                                                        lineHeight: "20px",
                                                        width: "159px",
                                                        borderRadius: "27px",
                                                        border: "1px solid #1877F2",
                                                        padding: "15px 85px",
                                                        marginLeft: "-10px",
                                                        backgroundColor: buySell === "buy" ? "" : "#1877F2",
                                                        color: buySell === "buy" ? "" : "white",
                                                        textTransform: "none",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                    }}
                                                    onClick={() => handleClick("buy")}
                                                >
                                                    Add
                                                </Button>
                                                <Button
                                                    className={
                                                        buySell === "sell"
                                                            ? "buttonToggleSellActive"
                                                            : "buttonToggleSell"
                                                    }
                                                    value="sell"
                                                    style={{
                                                        lineHeight: "20px",
                                                        width: "159px",
                                                        borderRadius: "27px",
                                                        border: "1px solid #1877F2",
                                                        marginLeft: "10px",
                                                        padding: "15px 85px",
                                                        backgroundColor: buySell === "sell" ? "" : "#1877F2",
                                                        color: buySell === "sell" ? "" : "white",
                                                        textTransform: "none",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                    }}
                                                    onClick={() => handleClick("sell")}
                                                >
                                                    Remove
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                            )}
                        </div>
                    </Modal>

                    <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
                        <DialogTitle>
                            Create New Portfolio
                            <IconButton
                                aria-label="close"
                                onClick={() => {
                                    setIsDialogOpen(false);
                                    setNewOptionValue("");
                                }}
                                sx={{position: "absolute", right: 13, top: 8}}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    autoFocus
                                    label="Enter Name"
                                    value={newOptionValue}
                                    onChange={handleChange2}
                                    fullWidth
                                    margin="dense"
                                />
                                {showAlert && (
                                    <Alert severity="error" sx={{marginTop: "8px"}}>
                                        Hyphens are not allowed in the name.
                                    </Alert>
                                )}
                            </div>
                        </DialogContent>
                        <DialogActions sx={{marginRight: "14px"}}>
                            <Button
                                onClick={() => {
                                    setIsDialogOpen(false);
                                    setNewOptionValue("");
                                }}
                                sx={{fontWeight: "bold"}}
                                variant="outlined"
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleDialogSubmit}
                                variant="contained"
                                color="primary"
                                sx={{fontWeight: "bold"}}
                                disabled={!newOptionValue.trim()}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        open={snackbaropen}
                        autoHideDuration={5000}
                        onClose={closesnackbar}
                        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    >
                        <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
                    </Snackbar>
                </Box>
            ) : (
                <Box
                    sx={{
                        flexDirection: mediaQueryVar === false ? "column" : "",

                        display: "flex",
                        height: "100vh",
                    }}
                    className="maindiv"
                >
                    <Helmet>
                        <title>Crypto Screener</title>
                    </Helmet>
                    {isLoginModalOpen && (
                        <LoginPopUp
                            isLoginModalOpen={isLoginModalOpen}
                            setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                            onClose={handleModalClose}
                        />
                    )}
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            minHeight: "100vh",
                            width: {sm: `calc(100% - ${drawerWidth}px)`},
                            backgroundColor: "white",
                        }}
                        className="maindiv"
                    >
                        <div
                            style={{
                                display: "flex",

                                flexDirection: "column",
                                marginRight: "0px",
                                marginLeft: "0px",
                            }}
                        >
                            {loader === false && (
                                <>
                                    {rows.map((idx) => (
                                        <div style={{display: "flex", justifyContent: "space-between", flexGrow: 1}}>
                                            <div
                                                style={{
                                                    flex: "0 0 auto",
                                                    width: mediaQueryVar === true ? "50%" : "100%",
                                                }}
                                            >
                                                <Card sx={{maxWidth: 540, m: 2}}>
                                                    <CardHeader
                                                        avatar={
                                                            <Skeleton
                                                                animation="wave"
                                                                variant="circular"
                                                                width={40}
                                                                height={40}
                                                            />
                                                        }
                                                        title={
                                                            <Skeleton
                                                                animation="wave"
                                                                height={10}
                                                                width="80%"
                                                                style={{marginBottom: 6}}
                                                            />
                                                        }
                                                        subheader={
                                                            <Skeleton animation="wave" height={10} width="40%" />
                                                        }
                                                    />

                                                    <CardContent>
                                                        <React.Fragment>
                                                            <Skeleton
                                                                animation="wave"
                                                                height={10}
                                                                style={{marginBottom: 6}}
                                                            />
                                                            <Skeleton
                                                                animation="wave"
                                                                height={10}
                                                                style={{marginBottom: 6}}
                                                            />
                                                        </React.Fragment>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                            {mediaQueryVar === true && (
                                                <div style={{flex: "0 0 auto", width: "50%"}}>
                                                    <Card sx={{maxWidth: 545, m: 2}}>
                                                        <CardHeader
                                                            avatar={
                                                                <Skeleton
                                                                    animation="wave"
                                                                    variant="circular"
                                                                    width={40}
                                                                    height={40}
                                                                />
                                                            }
                                                            title={
                                                                <Skeleton
                                                                    animation="wave"
                                                                    height={10}
                                                                    width="80%"
                                                                    style={{marginBottom: 6}}
                                                                />
                                                            }
                                                            subheader={
                                                                <Skeleton animation="wave" height={10} width="40%" />
                                                            }
                                                        />

                                                        {/* <Skeleton sx={{ height: 100 }} animation="wave" variant="rectangular" /> */}
                                                        <CardContent>
                                                            <React.Fragment>
                                                                <Skeleton
                                                                    animation="wave"
                                                                    height={10}
                                                                    style={{marginBottom: 6}}
                                                                />
                                                                <Skeleton
                                                                    animation="wave"
                                                                    height={10}
                                                                    style={{marginBottom: 6}}
                                                                />
                                                            </React.Fragment>
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </>
                            )}
                            <div
                                style={{
                                    fontFamily: "Satoshi, sans-serif",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: mediaQueryVar2 ? "0%" : "0%", // Spacing between buttons
                                    marginTop: "15px",
                                    marginBottom: "5px",
                                    flexWrap: "wrap", // Allow buttons to wrap
                                }}
                            >
                                <Button
                                    endIcon={
                                        <img
                                            src={
                                                selectedFilters.length > 0 || hoveredSignal
                                                    ? "images/arrowdown-white.svg"
                                                    : "images/arrowdown.svg"
                                            }
                                            width="14"
                                            height="7"
                                            alt="endIcon"
                                        />
                                    }
                                    variant="outlined"
                                    onClick={handleFilterOpen}
                                    onMouseEnter={() => setHoveredSignal(true)}
                                    onMouseLeave={() => setHoveredSignal(false)}
                                    sx={{
                                        background: selectedFilters.length > 0 || hoveredSignal ? "#1877F2" : "white",
                                        height: "36.86px",
                                        color: selectedFilters.length > 0 || hoveredSignal ? "white" : "#1877F2",
                                        padding: "6px 8px",
                                        textTransform: "none",
                                        borderRadius: "6px",
                                        border: "1px #1877F2 solid",
                                        marginRight: "2%",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        "&:hover": {
                                            background: "#1877F2",
                                            color: "white",
                                        },
                                        marginBottom: "7px",
                                    }}
                                >
                                    Technical Signals
                                </Button>

                                <Popover
                                    open={isFilterOpen}
                                    anchorEl={filterAnchor}
                                    onClose={handleFilterClose}
                                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                                    transformOrigin={{vertical: "top", horizontal: "center"}}
                                    sx={{
                                        "& .MuiPopover-paper": {
                                            maxHeight: "400px",
                                            overflowY: "auto",
                                            width: "300px",
                                        },
                                    }}
                                >
                                    {/* Filters Content */}
                                    <Box sx={{padding: "16px"}}>
                                        <Box sx={{position: "sticky", top: 0, background: "white", zIndex: 1}}>
                                            <Button
                                                size="small"
                                                onClick={clearAllFilters}
                                                sx={{textTransform: "capitalize", marginBottom: "8px"}}
                                                startIcon={<ClearIcon />}
                                            >
                                                Clear All
                                            </Button>
                                        </Box>
                                        <Divider />
                                        {filterData.map((filter) => (
                                            <Box key={filter.categories} sx={{marginTop: "12px"}}>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{fontWeight: "600", fontSize: "14px"}}
                                                >
                                                    {filter.categories}
                                                </Typography>
                                                <FormGroup sx={{fontSize: "14px"}}>
                                                    {filter.subOptions.map((subOption) => (
                                                        <FormControlLabel
                                                            key={subOption}
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedFilters.includes(
                                                                        `${filter.categories} ${subOption}`
                                                                    )}
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            filter.categories,
                                                                            subOption
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                            label={subOption}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </Box>
                                        ))}
                                    </Box>
                                </Popover>

                                <Button
                                    endIcon={
                                        <img
                                            src={
                                                selectedCategory || hoveredCategory
                                                    ? "images/arrowdown-white.svg"
                                                    : "images/arrowdown.svg"
                                            }
                                            width="14"
                                            height="7"
                                            alt="selectedCategory"
                                        />
                                    }
                                    variant="outlined"
                                    sx={{
                                        background: selectedCategory || hoveredCategory ? "#1877F2" : "white",
                                        color: selectedCategory || hoveredCategory ? "white" : "#1877F2",
                                        height: "36.86px",
                                        padding: "6px 8px",
                                        textTransform: "none",
                                        borderRadius: "6px",
                                        border: `1px ${
                                            selectedCategory || hoveredCategory ? "#1877F2" : "#1877F2"
                                        } solid`,
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        "&:hover": {
                                            background: "#1877F2",
                                            color: "white",
                                        },
                                        marginBottom: "7px",
                                    }}
                                    onClick={handlePopoverOpen}
                                    onMouseEnter={() => setHoveredCategory(true)}
                                    onMouseLeave={() => setHoveredCategory(false)}
                                >
                                    Categories
                                </Button>

                                <Popover
                                    open={open1}
                                    anchorEl={anchorEl}
                                    onClose={handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                    sx={{
                                        "& .MuiPopover-paper": {
                                            maxHeight: "300px",
                                            overflowY: "auto",
                                        },
                                    }}
                                >
                                    {/* Categories Content */}
                                    <Box sx={{padding: "8px"}}>
                                        <Autocomplete
                                            id="searchable-category-select"
                                            options={categories}
                                            getOptionLabel={(option) => option}
                                            value={selectedCategory}
                                            onChange={(event, newValue) => {
                                                setSelectedCategory(newValue);
                                                handlePopoverClose();
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Search categories" variant="outlined" />
                                            )}
                                            sx={{margin: "10px", width: "300px"}}
                                        />
                                    </Box>
                                </Popover>

                                {/* Volume Button */}
                                {/* Volume Button */}
                                <Button
                                    endIcon={
                                        <img
                                            src={
                                                isVolumePopoverOpen || selectedVolume.length > 0 || hoveredVolume
                                                    ? "images/arrowdown-white.svg"
                                                    : "images/arrowdown.svg"
                                            }
                                            width="14"
                                            height={
                                                isVolumePopoverOpen || selectedVolume.length > 0 || hoveredVolume
                                                    ? "7"
                                                    : "7"
                                            }
                                            alt="arrow"
                                        />
                                    }
                                    variant="outlined"
                                    onClick={(e) => {
                                        // Toggle the popover
                                        setIsVolumePopoverOpen(!isVolumePopoverOpen);
                                        handleVolumeClick(e);
                                    }}
                                    onMouseEnter={() => setHoveredVolume(true)}
                                    onMouseLeave={() => setHoveredVolume(false)}
                                    sx={{
                                        background:
                                            isVolumePopoverOpen || selectedVolume.length > 0 || hoveredVolume
                                                ? "#1877F2"
                                                : "white",
                                        color:
                                            isVolumePopoverOpen || selectedVolume.length > 0 || hoveredVolume
                                                ? "white"
                                                : "#1877F2",
                                        height: "36.86px",
                                        padding: "6px 8px",
                                        textTransform: "none",
                                        borderRadius: "6px",
                                        border: "1px solid #1877F2",
                                        fontSize: "14px",
                                        marginLeft: "2%",
                                        fontWeight: "500",
                                        "&:hover": {
                                            background: "#1877F2",
                                            color: "white",
                                        },
                                        marginBottom: "7px",
                                    }}
                                >
                                    Volume
                                </Button>

                                <Popover
                                    open={isVolumePopoverOpen}
                                    anchorEl={volumeAnchor}
                                    onClose={() => setIsVolumePopoverOpen(false)} // Close the popover on close
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                    <List>
                                        {/* Clear All Option */}
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setActiveVolumeOption(null); // Clear the active option
                                                setSelectedVolume([]); // Clear all selected options
                                                setIsVolumePopoverOpen(false); // Close the popover
                                            }}
                                        >
                                            <Button
                                                size="small"
                                                onClick={clearAllFilters}
                                                sx={{textTransform: "capitalize", marginBottom: "0px"}}
                                                startIcon={<ClearIcon />}
                                            >
                                                Clear All
                                            </Button>
                                        </ListItem>
                                        <Divider />

                                        {/* Volume Options */}
                                        {volumeOptions.map((option, index) => (
                                            <ListItem
                                                button
                                                key={index}
                                                onMouseEnter={() => setHoveredVolume(true)}
                                                onMouseLeave={() => setHoveredVolume(false)}
                                            >
                                                <Checkbox
                                                    checked={selectedVolume.includes(option)} // If the option is selected, check the box
                                                    onChange={() => {
                                                        setSelectedVolume((prev) => {
                                                            // Toggle the option in the selectedVolume array
                                                            if (prev.includes(option)) {
                                                                // Remove the option if it's already selected
                                                                return prev.filter((item) => item !== option);
                                                            } else {
                                                                // Add the option if it's not already selected
                                                                return [...prev, option];
                                                            }
                                                        });
                                                    }}
                                                    sx={{
                                                        color: selectedVolume.includes(option) ? "#1877F2" : "inherit", // Highlight checked box
                                                    }}
                                                />
                                                <ListItemText primary={option} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Popover>

                                {/* Market Cap Button */}
                                <Button
                                    endIcon={
                                        <img
                                            src={
                                                isMarketCapPopoverOpen ||
                                                selectedMarketCap.length > 0 ||
                                                hoveredMarketCap
                                                    ? "images/arrowdown-white.svg"
                                                    : "images/arrowdown.svg"
                                            }
                                            width="14"
                                            height={
                                                isMarketCapPopoverOpen ||
                                                selectedMarketCap.length > 0 ||
                                                hoveredMarketCap
                                                    ? "7"
                                                    : "7"
                                            }
                                            alt="arrow"
                                        />
                                    }
                                    variant="outlined"
                                    onClick={(e) => {
                                        // Toggle the popover
                                        setIsMarketCapPopoverOpen(!isMarketCapPopoverOpen);
                                        handleMarketCapClick(e);
                                    }}
                                    onMouseEnter={() => setHoveredMarketCap(true)}
                                    onMouseLeave={() => setHoveredMarketCap(false)}
                                    sx={{
                                        background:
                                            isMarketCapPopoverOpen || selectedMarketCap.length > 0 || hoveredMarketCap
                                                ? "#1877F2"
                                                : "white",
                                        color:
                                            isMarketCapPopoverOpen || selectedMarketCap.length > 0 || hoveredMarketCap
                                                ? "white"
                                                : "#1877F2",
                                        height: "36.86px",
                                        padding: "6px 8px",
                                        textTransform: "none",
                                        borderRadius: "6px",
                                        border: "1px solid #1877F2",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        marginLeft: "2%",
                                        "&:hover": {
                                            background: "#1877F2",
                                            color: "white",
                                        },
                                        marginBottom: "7px",
                                        marginTop: "6px",
                                        marginRight: "2.5%",
                                    }}
                                >
                                    Market Cap
                                </Button>

                                <Popover
                                    open={isMarketCapPopoverOpen}
                                    anchorEl={marketCapAnchor}
                                    onClose={() => setIsMarketCapPopoverOpen(false)} // Close the popover on close
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                    <List>
                                        {/* Clear All Option */}
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setActiveMarketCapOption(null); // Clear the active option
                                                setSelectedMarketCap([]); // Clear all selected options
                                                setIsMarketCapPopoverOpen(false); // Close the popover
                                            }}
                                        >
                                            <Button
                                                size="small"
                                                onClick={clearAllFilters}
                                                sx={{textTransform: "capitalize", marginBottom: "0px"}}
                                                startIcon={<ClearIcon />}
                                            >
                                                Clear All
                                            </Button>
                                        </ListItem>
                                        <Divider />
                                        {/* Market Cap Options */}
                                        {marketCapOptions.map((option, index) => (
                                            <ListItem
                                                button
                                                key={index}
                                                onMouseEnter={() => setHoveredMarketCap(true)}
                                                onMouseLeave={() => setHoveredMarketCap(false)}
                                            >
                                                <Checkbox
                                                    checked={selectedMarketCap.includes(option)} // If the option is selected, check the box
                                                    onChange={() => {
                                                        setSelectedMarketCap((prev) => {
                                                            // Toggle the option in the selectedMarketCap array
                                                            if (prev.includes(option)) {
                                                                // Remove the option if it's already selected
                                                                return prev.filter((item) => item !== option);
                                                            } else {
                                                                // Add the option if it's not already selected
                                                                return [...prev, option];
                                                            }
                                                        });
                                                    }}
                                                    sx={{
                                                        color: selectedMarketCap.includes(option)
                                                            ? "#1877F2"
                                                            : "inherit", // Highlight checked box
                                                    }}
                                                />
                                                <ListItemText primary={option} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Popover>

                                {/* Premium Signals Button */}
                                <Button
                                    endIcon={
                                        <>
                                            {isSentimentFilterOpen && (!userData || isPaidMember === "no") && (
                                                <img
                                                    src="images/lock-new.svg" // Lock icon path
                                                    width="24"
                                                    height="24"
                                                    alt="lock"
                                                    style={{
                                                        position: "absolute",
                                                        top: "-20px",
                                                        left: "50%",
                                                        transform: "translateX(-50%)",
                                                    }} // Positioning lock icon on top of the button
                                                />
                                            )}

                                            <img
                                                src={
                                                    isSentimentFilterOpen ||
                                                    selectedSentimentFilters.length > 0 ||
                                                    hoveredSentiment
                                                        ? "images/arrowdown-white.svg"
                                                        : "images/arrowdown.svg"
                                                }
                                                width="14"
                                                height="7"
                                                alt="arrowdown"
                                            />
                                        </>
                                    }
                                    variant="outlined"
                                    onClick={handleSentimentFilterOpen}
                                    onMouseEnter={() => setHoveredSentiment(true)}
                                    onMouseLeave={() => setHoveredSentiment(false)}
                                    sx={{
                                        background:
                                            selectedSentimentFilters.length > 0 ||
                                            isSentimentFilterOpen ||
                                            hoveredSentiment
                                                ? "#1877F2"
                                                : "white",
                                        height: "36.86px",
                                        color:
                                            selectedSentimentFilters.length > 0 ||
                                            isSentimentFilterOpen ||
                                            hoveredSentiment
                                                ? "white"
                                                : "#1877F2",
                                        padding: "6px 8px",
                                        textTransform: "none",
                                        borderRadius: "6px",
                                        border: "1px #1877F2 solid",
                                        marginRight: "2%",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        "&:hover": {
                                            background: "#1877F2",
                                            color: "white",
                                        },
                                    }}
                                >
                                    Influencer Signals
                                </Button>

                                <Popover
                                    open={isSentimentFilterOpen}
                                    anchorEl={sentimentFilterAnchor}
                                    onClose={handleSentimentFilterClose}
                                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                                    transformOrigin={{vertical: "top", horizontal: "center"}}
                                    sx={{
                                        "& .MuiPopover-paper": {
                                            maxHeight: "400px",
                                            overflowY: "auto",
                                            width: "300px",
                                        },
                                    }}
                                >
                                    {/* Filters Content */}
                                    <Box sx={{padding: "16px"}}>
                                        <Box sx={{position: "sticky", top: 0, background: "white", zIndex: 1}}>
                                            <Button
                                                size="small"
                                                onClick={clearAllSentimentFilters}
                                                sx={{textTransform: "capitalize", marginBottom: "8px"}}
                                                startIcon={<ClearIcon />}
                                            >
                                                Clear All
                                            </Button>
                                        </Box>
                                        <Divider />
                                        {sentimentFilterData.map((filter) => (
                                            <Box key={filter.categories} sx={{marginTop: "12px"}}>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{fontWeight: "600", fontSize: "14px"}}
                                                >
                                                    {filter.categories}
                                                </Typography>
                                                <FormGroup sx={{fontSize: "14px"}}>
                                                    {filter.subOptions.map((subOption) => (
                                                        <FormControlLabel
                                                            key={subOption}
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedSentimentFilters.includes(
                                                                        `${filter.categories} ${subOption}`
                                                                    )}
                                                                    onChange={() =>
                                                                        handleSentimentCheckboxChange(
                                                                            filter.categories,
                                                                            subOption
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                            label={subOption}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </Box>
                                        ))}
                                    </Box>
                                </Popover>
                            </div>

                            <div
                                style={{
                                    marginTop: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexWrap: "wrap",
                                        gap: "10px",
                                    }}
                                >
                                    {selectedFilters.map((filter, index) => {
                                        // Split the filter into the signal name and the sub-option
                                        const parts = filter.split(" "); // Split by space
                                        const signal = parts.slice(0, -1).join(" "); // Everything except the last word is the signal
                                        const subOption = parts[parts.length - 1]; // The last word is the sub-option

                                        return (
                                            <Button
                                                key={index}
                                                sx={{
                                                    background: "#fff",
                                                    color: "#1877F2",
                                                    borderRadius: "6px",
                                                    border: "1px solid #1877F2",
                                                    textTransform: "none",
                                                    padding: "6px 14px",
                                                    display: "flex", // Ensure that the button content is aligned
                                                    alignItems: "center", // Align text and icon horizontally
                                                    justifyContent: "space-between", // Ensure space between text and cross icon
                                                }}
                                            >
                                                {signal} - {subOption} {/* Adding '-' before the sub-option */}
                                                <ClearIcon
                                                    sx={{
                                                        fontSize: "16px",
                                                        cursor: "pointer",
                                                        marginLeft: "8px", // Add spacing between the filter text and the cross icon
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent button click action when clicking the cross icon
                                                        handleFilterRemove(filter); // Implement the logic to remove the filter
                                                    }}
                                                />
                                            </Button>
                                        );
                                    })}

                                    {selectedCategory && (
                                        <Button
                                            sx={{
                                                background: "#fff",
                                                color: "#1877F2",
                                                borderRadius: "6px",
                                                border: "1px solid #1877F2",
                                                textTransform: "none",
                                                padding: "6px 14px",
                                                display: "flex", // Ensure that the button content is aligned
                                                alignItems: "center", // Align text and icon horizontally
                                                justifyContent: "space-between", // Ensure space between text and cross icon
                                            }}
                                        >
                                            {selectedCategory}
                                            <ClearIcon
                                                sx={{
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                    marginLeft: "8px", // Add spacing between the filter text and the cross icon
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent button click action when clicking the cross icon
                                                    handleCategoryRemove(); // Implement the logic to remove the selected category
                                                }}
                                            />
                                        </Button>
                                    )}

                                    {selectedVolume.length > 0 && (
                                        <Button
                                            sx={{
                                                background: "#fff",
                                                color: "#1877F2",
                                                borderRadius: "6px",
                                                border: "1px solid #1877F2",
                                                textTransform: "none",
                                                padding: "6px 14px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            Volume - {selectedVolume.join(", ")} {/* Adding Volume */}
                                            <ClearIcon
                                                sx={{
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                    marginLeft: "8px",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleVolumeRemove(); // Implement the logic to remove the volume filter
                                                }}
                                            />
                                        </Button>
                                    )}

                                    {selectedMarketCap.length > 0 && (
                                        <Button
                                            sx={{
                                                background: "#fff",
                                                color: "#1877F2",
                                                borderRadius: "6px",
                                                border: "1px solid #1877F2",
                                                textTransform: "none",
                                                padding: "6px 14px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            Market Cap - {selectedMarketCap.join(", ")} {/* Adding Market Cap */}
                                            <ClearIcon
                                                sx={{
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                    marginLeft: "8px",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleMarketCapRemove(); // Implement the logic to remove the market cap filter
                                                }}
                                            />
                                        </Button>
                                    )}
                                    {selectedSentimentFilters.map((filter, index) => {
                                        // Split the filter into the signal name and the sub-option
                                        const parts = filter.split(" "); // Split by space
                                        const signal = parts.slice(0, -1).join(" "); // Everything except the last word is the signal
                                        const subOption = parts[parts.length - 1]; // The last word is the sub-option

                                        return (
                                            <Button
                                                key={index}
                                                sx={{
                                                    background: "#fff",
                                                    color: "#1877F2",
                                                    borderRadius: "6px",
                                                    border: "1px solid #1877F2",
                                                    textTransform: "none",
                                                    padding: "6px 14px",
                                                    display: "flex", // Ensure that the button content is aligned
                                                    alignItems: "center", // Align text and icon horizontally
                                                    justifyContent: "space-between", // Ensure space between text and cross icon
                                                }}
                                            >
                                                {signal} - {subOption} {/* Adding '-' before the sub-option */}
                                                <ClearIcon
                                                    sx={{
                                                        fontSize: "16px",
                                                        cursor: "pointer",
                                                        marginLeft: "8px", // Add spacing between the filter text and the cross icon
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent button click action when clicking the cross icon
                                                        handleSentimentFilterRemove(filter); // Implement the logic to remove the sentiment filter
                                                    }}
                                                />
                                            </Button>
                                        );
                                    })}
                                </div>
                                {loader === true && (
                                    <div
                                        style={{
                                            paddingBottom: "300px",
                                            overflowX: "hidden",
                                            overflowY: "auto",
                                            "&::-webkit-scrollbar": {
                                                display: "none",
                                            },
                                            WebkitOverflowScrolling: "touch",
                                            // Hides scrollbar for Firefox
                                            scrollbarWidth: "none",
                                        }}
                                    >
                                        <Grid container spacing={0}>
                                            {sortedSlugdata.map((obj, idx) => (
                                                <Grid item xs={12} md={6} key={idx}>
                                                    <div
                                                        key={idx}
                                                        style={{
                                                            width: "96%",
                                                            margin: "2%",
                                                            borderRadius: "10px",
                                                            backgroundColor: "white",
                                                            border: " 1px #E2E2E4 solid",
                                                            padding: "10px",
                                                            marginBottom: "25px",
                                                        }}
                                                    >
                                                        {/* First column data */}
                                                        <div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    borderBottom: "1px #E2E2E4 solid",
                                                                    marginBottom: "10px",
                                                                    fontSize: mediaQueryVar === false ? "13px" : "",
                                                                }}
                                                            >
                                                                {/* Left Section - Logo & Name */}
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        flex: 1,
                                                                    }}
                                                                >
                                                                    <img
                                                                        className="b57ac673f06a4b0338a596817eb0a5Icon"
                                                                        alt="logo"
                                                                        src={obj.logo}
                                                                        style={{
                                                                            width: "38px",
                                                                            height: "38px",
                                                                            border: "2px solid black",
                                                                            borderRadius: "20px",
                                                                            marginRight: "8px",
                                                                            marginBottom: "10px",
                                                                        }}
                                                                    />

                                                                    {/* Name or Link */}
                                                                    <div>
                                                                        {obj["article_link"] ? (
                                                                            <a
                                                                                href={obj.article_link}
                                                                                style={{
                                                                                    color: "black",
                                                                                    textDecoration: "underline",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: "18px",
                                                                                        fontWeight: "600",
                                                                                        fontFamily:
                                                                                            "Satoshi, sans-serif",
                                                                                        color: "black",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                >
                                                                                    {obj.slug}
                                                                                </div>
                                                                            </a>
                                                                        ) : (
                                                                            <div
                                                                                style={{
                                                                                    fontSize: "18px",
                                                                                    fontWeight: "600",
                                                                                    fontFamily: "Satoshi, sans-serif",
                                                                                    color: "black",
                                                                                    marginBottom: "10px",
                                                                                    textDecoration: "underline",
                                                                                }}
                                                                            >
                                                                                {obj.slug}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {/* Center Section - Recommendation (Aligned in Middle) */}
                                                                <div
                                                                    style={{
                                                                        flex: 1,
                                                                        textAlign: "center",
                                                                        fontWeight: "500",
                                                                        fontSize: "14px",
                                                                        marginBottom: "10px",
                                                                        color:
                                                                            obj.recommend === "Hold"
                                                                                ? "orange"
                                                                                : obj.recommend === "Buy"
                                                                                ? "green"
                                                                                : obj.recommend === "Sell"
                                                                                ? "red"
                                                                                : "black",
                                                                    }}
                                                                >
                                                                    {obj.recommend}
                                                                </div>

                                                                {/* Right Section - Button */}
                                                                <Button
                                                                    className="btn"
                                                                    variant="outlined"
                                                                    onClick={() => handleAddToPortfolio(idx)}
                                                                    style={{
                                                                        backgroundColor: "white",
                                                                        padding: "4px 10px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        borderRadius: "48px",
                                                                        border: "1px solid #1877F2",
                                                                        textTransform: "none",
                                                                        fontSize:
                                                                            mediaQueryVar === false ? "10px" : "11px",
                                                                        fontWeight: "500",
                                                                        color: "#1877F2",
                                                                        whiteSpace: "nowrap",
                                                                        width: "120px",
                                                                        height: "30px",
                                                                        marginLeft: "20px",
                                                                    }}
                                                                >
                                                                    Add to Portfolio
                                                                </Button>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "#7F7F80",
                                                                        fontSize: "14px",
                                                                        letterSpacing: "0.46px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        fontWeight: 500,
                                                                        marginBottom: "5px",
                                                                    }}
                                                                >
                                                                    Price
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontSize: "14px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        marginBottom: "5px",
                                                                        whiteSpace: "nowrap", // Ensures the text does not wrap
                                                                        maxWidth: "60%", // Adjust this percentage as needed to fit within the container
                                                                    }}
                                                                >
                                                                    {obj.price_today}
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "#7F7F80",
                                                                        fontSize: "14px",
                                                                        letterSpacing: "0.46px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        fontWeight: 500,
                                                                        marginBottom: "5px",
                                                                    }}
                                                                >
                                                                    30 Days
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontSize: "14px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        marginBottom: "5px",

                                                                        whiteSpace: "nowrap", // Ensures the text does not wrap
                                                                        maxWidth: "60%", // Adjust this percentage as needed to fit within the container
                                                                    }}
                                                                >
                                                                    {obj.thirtyd_change}
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "#7F7F80",
                                                                        fontSize: "14px",
                                                                        letterSpacing: "0.46px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        fontWeight: 500,
                                                                        marginBottom: "5px",
                                                                    }}
                                                                >
                                                                    Market Cap
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontSize: "14px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        marginBottom: "5px",

                                                                        whiteSpace: "nowrap", // Ensures the text does not wrap
                                                                        maxWidth: "60%", // Adjust this percentage as needed to fit within the container
                                                                    }}
                                                                >
                                                                    {obj.buy_percent}
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center", // This ensures vertical alignment is centered
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "#7F7F80",
                                                                        fontSize: "14px",
                                                                        letterSpacing: "0.46px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    Forecast 2025
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontSize: "14px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap", // Ensures the text does not wrap
                                                                        maxWidth: "60%", // Adjust this percentage as needed to fit within the container
                                                                    }}
                                                                >
                                                                    {obj.price_prediction_2025}
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    marginTop: "5px",
                                                                    alignItems: "center", // This ensures vertical alignment is centered
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "#7F7F80",
                                                                        fontSize: "14px",
                                                                        letterSpacing: "0.46px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    Top 100 Holders
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontSize: "14px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap", // Ensures the text does not wrap
                                                                        maxWidth: "60%", // Adjust this percentage as needed to fit within the container
                                                                    }}
                                                                >
                                                                    {obj.holdings || "NA"}
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center", // This ensures vertical alignment is centered
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "#7F7F80",
                                                                        fontSize: "14px",
                                                                        letterSpacing: "0.46px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    Grade
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontSize: "14px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap", // Ensures the text does not wrap
                                                                        maxWidth: "60%", // Adjust this percentage as needed to fit within the container,
                                                                        marginTop: "5px",
                                                                        marginBottom: "5px",
                                                                    }}
                                                                >
                                                                    {obj.grade === "Outperformer" ? (
                                                                        <button
                                                                            disabled
                                                                            style={{
                                                                                fontWeight: "bold",
                                                                                padding: "3px",
                                                                                borderRadius: "5px",
                                                                                backgroundColor: "#DFFFF0",
                                                                                color: "#199F5F",
                                                                                border: "2px solid #199F5F",
                                                                                width: "100%",
                                                                                fontSize: "14px",
                                                                            }}
                                                                        >
                                                                            Outperformer
                                                                        </button>
                                                                    ) : obj.grade === "Emerging Outperformer" ? (
                                                                        <button
                                                                            disabled
                                                                            style={{
                                                                                fontWeight: "bold",
                                                                                padding: "3px",
                                                                                borderRadius: "5px",
                                                                                backgroundColor: "#DFFFF0",
                                                                                color: "#199F5F",
                                                                                border: "2px solid #199F5F",
                                                                                width: "100%",
                                                                                fontSize: "14px",
                                                                            }}
                                                                        >
                                                                            Outperformer
                                                                        </button>
                                                                    ) : obj.grade === "Moderate Performer" ? (
                                                                        <button
                                                                            disabled
                                                                            style={{
                                                                                marginRight: "2px",
                                                                                alignItems: "center",
                                                                                fontWeight: "bold",
                                                                                padding: "3px",
                                                                                borderRadius: "5px",
                                                                                backgroundColor: "#F9F4E7",
                                                                                color: "#D4AF37",
                                                                                border: "2px solid #D4AF37",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            Moderate Performer
                                                                        </button>
                                                                    ) : obj.grade === "Underperformer" ? (
                                                                        <button
                                                                            disabled
                                                                            style={{
                                                                                marginRight: "2px",
                                                                                alignItems: "center",
                                                                                fontWeight: "bold",
                                                                                padding: "3px",
                                                                                borderRadius: "5px",
                                                                                backgroundColor: "#FFE3E3",
                                                                                color: "#FF4141",
                                                                                border: "2px solid #FF4141",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            Underperformer
                                                                        </button>
                                                                    ) : null}
                                                                </span>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "#7F7F80",
                                                                        fontSize: "14px",
                                                                        letterSpacing: "0.46px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        fontWeight: 500,
                                                                        marginBottom: "3px",
                                                                    }}
                                                                >
                                                                    Risk
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            obj.risk === "High"
                                                                                ? "red"
                                                                                : obj.risk === "Medium"
                                                                                ? "orange"
                                                                                : obj.risk === "Low"
                                                                                ? "green"
                                                                                : obj.risk === "N/A"
                                                                                ? "black"
                                                                                : "black",
                                                                        fontSize: "14px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        marginBottom: "5px",

                                                                        whiteSpace: "nowrap", // Ensures the text does not wrap
                                                                        maxWidth: "60%", // Adjust this percentage as needed to fit within the container
                                                                    }}
                                                                >
                                                                    {obj.risk}
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "#7F7F80",
                                                                        fontSize: "14px",
                                                                        letterSpacing: "0.46px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        fontWeight: 500,
                                                                        marginBottom: "5px",
                                                                    }}
                                                                >
                                                                    Category
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontSize: "14px",
                                                                        fontFamily: "Satoshi, sans-serif",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        marginBottom: "5px",

                                                                        whiteSpace: "nowrap", // Ensures the text does not wrap
                                                                        maxWidth: "60%", // Adjust this percentage as needed to fit within the container
                                                                    }}
                                                                >
                                                                    {obj.category}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            ))}
                                            {addMoreLoader && (
                                                <div style={{'width': "100%"}}>
                                                <div className="loader-container" style={{"display": "flex", "justifyContent": "center"}}>
                                                    <div className="spinner"></div>
                                                </div>
                                                </div>
                                            )}

                                            <div
                                                ref={observerRef}
                                                className="observer-trigger"
                                                style={{visibility: "hidden"}}
                                            ></div>
                                            {loaderForSymbolLink === true && (
                                                <div>
                                                    <CircularProgress
                                                        style={{position: "fixed", top: "40vh", left: "45vw"}}
                                                    />
                                                </div>
                                            )}
                                            <style>
                                            {`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      .spinner {
        width: 40px;
        height: 40px;
        border: 4px solid rgba(67, 97, 238, 0.3);
        border-top: 4px solid #4361ee;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }

    `}
                                            </style>
                                        </Grid>
                                    </div>
                                )}
                            </div>
                        </div>
                        {slugdata.length === 0 && loader === true && (
                            <div style={{textAlign: "center", marginTop: "20px"}}>
                                <h4>No Data Found</h4>
                            </div>
                        )}

                        {loaderForSymbolLink === true && (
                            <div>
                                <CircularProgress style={{position: "fixed", top: "40vh", left: "45vw"}} />
                            </div>
                        )}
                    </Box>
                    {/* } */}

                    <Modal
                        sx={{overflow: "hidden"}}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className="CardOpener" style={{overflow: "hidden"}}>
                            {isFormSubmitted ? (
                                <Card className="card__container">
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CheckCircleOutlineIcon sx={{fontSize: 50, mr: 1}} />
                                        </div>
                                        <Typography
                                            severity="success"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            Portfolio added successfully!
                                        </Typography>
                                    </>
                                    <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <Button
                                            onClick={() => {
                                                setIsFormSubmitted(false);
                                                setOpen(false);
                                                setUnit("");
                                                setSelectedOption("");
                                                setPrice("");
                                                setTotalUnits(0);
                                                handleDateChange("");
                                                setUsablewallet("");
                                            }}
                                            color="primary"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "150px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                        >
                                            Add More Cryptos
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                                                        selectedOption.label
                                                    )}`
                                                )
                                            }
                                            variant="outlined"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "100px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                            color="primary"
                                        >
                                            View Portfolio
                                        </Button>
                                    </div>
                                </Card>
                            ) : (
                                <Dialog
                                    PaperProps={{
                                        sx: {
                                            position: mediaQueryVar ? "static" : "fixed",
                                            bottom: mediaQueryVar ? "" : 0,
                                            left: mediaQueryVar ? "" : 0,
                                            right: mediaQueryVar ? "" : 0,
                                            margin: "0",
                                            maxWidth: "100%",
                                            minHeight: "50%", // Adjust max height if needed
                                            borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
                                            padding: "10px",
                                            alignItems: "center",
                                            overflow: "scroll",
                                            "&::-webkit-scrollbar": {
                                                display: "none",
                                            },
                                            "-ms-overflow-style": "none",
                                            "scrollbar-width": "none", // Ensure no scrollbar
                                        },
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <div
                                        style={{
                                            padding: "20px",
                                            width: mediaQueryVar === false ? "100%" : "400px",
                                            margin: "0",
                                            marginTop: "-5px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginBottom: "20px",
                                                borderBottom: "1px #E3E3E3 solid",
                                            }}
                                        >
                                            <Typography
                                                style={{fontWeight: "500", fontSize: "20.767px", paddingLeft: "25%"}}
                                                gutterBottom
                                            >
                                                Add To Portfolio
                                            </Typography>

                                            <div className="primaryButton">
                                                <img
                                                    className="frameIcon"
                                                    alt="frameIcon"
                                                    onClick={handleClose}
                                                    src={window.constants.asset_path + "/images/frame17.svg"}
                                                    style={{background: "#F3F3F3", width: "25px", height: "25px"}}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginBottom: "5px",
                                                marginLeft: mediaQueryVar === false ? "5px" : "",
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: "13.845px",
                                                    fontWeight: "500",
                                                    marginBottom: "1px",
                                                    color: "black",
                                                    marginTop: "-8px",
                                                }}
                                            >
                                                Select Portfolio
                                            </Typography>
                                            <div style={{marginTop: "5px"}}>
                                                <CreatableSelect
                                                    isClearable
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: "12px",
                                                            border: "1px solid #1877F2",
                                                            boxShadow: "none",
                                                            minHeight: "40px",
                                                            "&:hover": {
                                                                borderRadius: "12px",
                                                                border: "2px solid #1877F2",
                                                            },
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            borderRadius: "8px",
                                                        }),
                                                        placeholder: (provided) => ({
                                                            ...provided,
                                                            color: "",
                                                        }),
                                                        dropdownIndicator: (provided) => ({
                                                            ...provided,
                                                            color: "#1877F2",
                                                            border: "1px solid #1877F2",
                                                            padding: "5px",
                                                            margin: "8px",
                                                            borderRadius: "3.786px",
                                                            "&:hover": {
                                                                color: "#1877F2",
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({
                                                            display: "none",
                                                        }),
                                                    }}
                                                    placeholder=""
                                                    onChange={(options) =>
                                                        handleChange(options, slugpopupdata.slugname)
                                                    }
                                                    onCreateOption={handleCreate}
                                                    options={[
                                                        {value: "", label: "Create new portfolio", _isNew_: true}, // Dynamic "create new option" item
                                                        ...options, // Existing options
                                                    ]}
                                                    value={selectedOption}
                                                />
                                            </div>
                                            {showAlert && (
                                                <Alert severity="error" sx={{marginBottom: "10px"}}>
                                                    {errorMessage}
                                                </Alert>
                                            )}
                                        </div>
                                        <Typography
                                            style={{
                                                fontSize: "13.845px",
                                                fontWeight: "500",
                                                color: "#000",
                                                marginTop: "10px",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            Usable Wallet Value: {Usablewallet}
                                        </Typography>
                                        <div
                                            style={{
                                                padding: "8px",
                                                background: "#F3F3F3",
                                                borderRadius: "8px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            <div style={{display: "flex", alignItems: "center", flex: 0, minWidth: 0}}>
                                                <img
                                                    style={{
                                                        marginRight: "10px",
                                                        border: "2px solid #000",
                                                        borderRadius: "19px",
                                                        height: "27.69px",
                                                        width: "27.69px",
                                                    }}
                                                    alt="logo"
                                                    src={slugpopupdata.logo}
                                                />
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "500",
                                                            marginBottom: "4px",
                                                            fontSize: "14px",
                                                            color: "#7F7F80",
                                                        }}
                                                    >
                                                        {slugpopupdata.slugname}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: "500",
                                                            color: "black",
                                                            margin: "0",
                                                        }}
                                                    >
                                                        {slugpopupdata.slug}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    marginLeft: "20px",
                                                    flex: 1,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontWeight: "500",
                                                        marginBottom: "4px",
                                                        fontSize: "13px",
                                                        color: "#7F7F80",
                                                    }}
                                                >
                                                    {" "}
                                                    Price
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12.114px",
                                                        fontWeight: "500",
                                                        color: "black",
                                                        margin: "0",
                                                    }}
                                                >
                                                    {slugpopupdata.pricetoday}
                                                </Typography>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    marginLeft: "20px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontWeight: "500",
                                                        marginBottom: "4px",
                                                        fontSize: "13px",
                                                        color: "#7F7F80",
                                                    }}
                                                >
                                                    {" "}
                                                    Units
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12.114px",
                                                        fontWeight: "500",
                                                        color: "black",
                                                        margin: "0",
                                                    }}
                                                >
                                                    {totalUnits}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="card__container__row3">
                                            <div style={{marginLeft: "-5px"}}>
                                                <p style={{fontSize: "14px", fontWeight: "500"}}>
                                                    Enter number of Units
                                                </p>
                                                <input
                                                    style={{
                                                        backgroundColor: "white",
                                                        color: "black",
                                                        padding: "20px",
                                                        border: "1px solid #1877F2",
                                                        borderRadius: "10.384px",
                                                        width: "100%",
                                                        height: "45px",
                                                        margin: 0,
                                                    }}
                                                    min="1"
                                                    max="10"
                                                    type="number"
                                                    placeholder=""
                                                    value={unit}
                                                    onChange={(e) => setUnit(e.target.value)}
                                                ></input>
                                            </div>
                                            <div>
                                                $
                                                {formatToNonZero(
                                                    unit *
                                                        parseFloat((slugpopupdata.pricetoday || "").replace(/^\$/, ""))
                                                )}
                                            </div>
                                            {user_role === "superuser" && (
                                                <>
                                                    <div className="card__container__row3__r2">
                                                        <div style={{marginLeft: "-5px"}}>
                                                            <p style={{fontSize: "14px", fontWeight: "500"}}>
                                                                Buy/Sell Price($)
                                                            </p>
                                                            <input
                                                                style={{
                                                                    backgroundColor: "white",
                                                                    color: "black",
                                                                    padding: "20px",
                                                                    border: "1px solid #1877F2",
                                                                    borderRadius: "10.384px",
                                                                    width: "100%",
                                                                    height: "45px",
                                                                }}
                                                                className="card__container__row3__r2__input"
                                                                min="1"
                                                                max="10"
                                                                type="number"
                                                                placeholder="Enter Price"
                                                                value={price}
                                                                onChange={(e) => setPrice(e.target.value)}
                                                            ></input>
                                                        </div>
                                                    </div>
                                                    <div style={{marginBottom: "10px", marginLeft: "-5px"}}>
                                                        <p style={{fontSize: "14px", fontWeight: "500"}}>Date</p>
                                                        <TextField
                                                            type="date"
                                                            value={selectedDate}
                                                            onChange={(e) => handleDateChange(e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            sx={{
                                                                "& .MuiOutlinedInput-root": {
                                                                    borderRadius: "10.384px",
                                                                    border: "1px solid #1877F2",
                                                                    boxShadow: "none",
                                                                    width: "190%",
                                                                    height: "45px",
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            <div className="card__container__row3__r4" style={{marginTop: "10px"}}>
                                                <Button
                                                    className={
                                                        buySell === "buy" ? "buttonToggleButActive" : "buttonToggleBuy"
                                                    }
                                                    value="Buy"
                                                    style={{
                                                        lineHeight: "20px",
                                                        width: "45%",
                                                        borderRadius: "27px",
                                                        border: "1px solid #1877F2",
                                                        padding: "15px 8px",
                                                        marginLeft: "5px",
                                                        backgroundColor: buySell === "buy" ? "" : "#1877F2",
                                                        color: buySell === "buy" ? "" : "white",
                                                        textTransform: "none",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                    }}
                                                    onClick={() => handleClick("buy")}
                                                >
                                                    Add
                                                </Button>
                                                <Button
                                                    className={
                                                        buySell === "sell"
                                                            ? "buttonToggleSellActive"
                                                            : "buttonToggleSell"
                                                    }
                                                    value="sell"
                                                    style={{
                                                        lineHeight: "20px",
                                                        width: "45%",
                                                        borderRadius: "27px",
                                                        border: "1px solid #1877F2",
                                                        marginLeft: "5px",
                                                        marginRight: "10px",
                                                        padding: "15px 8px",
                                                        backgroundColor: buySell === "sell" ? "" : "#1877F2",
                                                        color: buySell === "sell" ? "" : "white",
                                                        textTransform: "none",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                    }}
                                                    onClick={() => handleClick("sell")}
                                                >
                                                    Remove
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                            )}
                        </div>
                    </Modal>

                    <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
                        <DialogTitle>
                            Create New Portfolio
                            <IconButton
                                aria-label="close"
                                onClick={() => {
                                    setIsDialogOpen(false);
                                    setNewOptionValue("");
                                }}
                                sx={{position: "absolute", right: 13, top: 8}}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    autoFocus
                                    label="Enter Name"
                                    value={newOptionValue}
                                    onChange={handleChange2}
                                    fullWidth
                                    margin="dense"
                                />
                                {showAlert && (
                                    <Alert severity="error" sx={{marginTop: "8px"}}>
                                        Hyphens are not allowed in the name.
                                    </Alert>
                                )}
                            </div>
                        </DialogContent>
                        <DialogActions sx={{marginRight: "14px"}}>
                            <Button
                                onClick={() => {
                                    setIsDialogOpen(false);
                                    setNewOptionValue("");
                                }}
                                sx={{fontWeight: "bold"}}
                                variant="outlined"
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleDialogSubmit}
                                variant="contained"
                                color="primary"
                                sx={{fontWeight: "bold"}}
                                disabled={!newOptionValue.trim()}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        open={snackbaropen}
                        autoHideDuration={5000}
                        onClose={closesnackbar}
                        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    >
                        <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
                    </Snackbar>
                </Box>
            )}
        </>
    );
}
