import React, {useEffect, useState} from "react";
import {
    useAppKit,
    useAppKitAccount,
    useAppKitNetwork,
    useAppKitNetworkCore,
    useAppKitProvider,
    useDisconnect,
} from "@reown/appkit/react";
import {saveCryptoPresalePayment} from "../presale_form/api";
import {ethRecipientAddress} from "../../../common/constant/api_key";
import ProcessingAlert from "../../../common/alert/ProcessingAlert";
import SuccessPaymentAlert from "../../../common/alert/SuccessPaymentAlert";
import {ethers, JsonRpcSigner} from "ethers";
import {mainnet} from "@reown/appkit/networks";

const ETHConnect2 = ({paymentMethod, select_price}) => {
    const {address, isConnected, blockchain} = useAppKitAccount();
    const {disconnect} = useDisconnect();
    const {walletProvider} = useAppKitProvider("eip155", {
        autoSwitchNetwork: false,
    });
    const {switchNetwork, chainId} = useAppKitNetwork();
    const isWrongNetwork = chainId !== mainnet.id;

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        const switchToEthereum = async () => {
            if (isWrongNetwork && switchNetwork) {
                alert("Switching to Ethereum network...");
                await switchNetwork(mainnet);
            }
        };
        switchToEthereum();
    }, [isWrongNetwork, address, switchNetwork]);

    useEffect(() => {
        const handleChainChanged = (chainId) => {
            console.log("Network changed to:", chainId);
            alert(`Update you networ id. Your Network changed to: ${chainId}`);
            // Optionally, you can trigger a state update or alert the user
        };

        window.ethereum.on("chainChanged", handleChainChanged);

        return () => {
            window.ethereum.removeListener("chainChanged", handleChainChanged);
        };
    }, []);

    const initiateTransaction = async (signer) => {
        const tx = {
            to: ethRecipientAddress,
            // value: ethers.parseEther(ethToPay.toString()), // Amount in ETH
            value: ethers.parseEther("0.00001"), // Amount in ETH
        };

        try {
            alert(`Sending transaction on Chain ID: ${chainId}`);
            const response = await signer.sendTransaction(tx);
            // const response = "await signer.sendTransaction(tx)";
            console.log("Transaction successful:", response);
            return response;
        } catch (error) {
            console.error("Transaction failed:", error);
            alert("Failed to send transaction. Please check your network connection.");
        }
    };

    const handleSendEth = async () => {
        if (!select_price) {
            alert("Please select an amount");
            return;
        }
        try {
            console.log("!address || blockchain", address, blockchain);
            // setLoading(true);
            const response = await fetch(
                "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
            );
            const data = await response.json();
            console.log(data);
            const ethPriceInUsd = data.ethereum.usd;

            const priceInUSD = select_price;
            const ethToPay = priceInUSD / ethPriceInUsd;

            const provider = new ethers.BrowserProvider(walletProvider, chainId);
            const signer = new JsonRpcSigner(provider, address);

            alert(`Provider transaction 1. Chain ID: ${chainId}`);
            // Check user balance before sending
            const balance = await provider.getBalance(address);
            const balanceInEth = ethers.formatEther(balance);
            console.log("User Balance:", balanceInEth, "ETH");
            console.log("ETH Required:", ethToPay, "ETH");

            // if (parseFloat(balanceInEth) < parseFloat(ethToPay)) {
            //     alert("Insufficient balance. Please add more ETH to your wallet.");
            //     return;
            // }
            alert(`Provider transaction 2. Chain ID: ${chainId}`);
            setLoading(true);
            const transactionResponse = await initiateTransaction(signer);

            alert(`Sending trasaction waaiting for response ${chainId}`);
            await transactionResponse.wait();

            console.log("ETH transaction successful:", transactionResponse.hash);

            // const verified= await verifyTransactionEthBackend(transactionResponse.hash, ethRecipientAddress, ethToPay);
            // console.log("verified", verified)

            const type = "eth";
            await saveCryptoPresalePayment(
                ethRecipientAddress,
                await signer.getAddress(),
                transactionResponse.hash,
                select_price,
                type
            );
            setIsSuccess(true);
        } catch (error) {
            alert(`trasaction Failed chainid: ${chainId}, error:  ${error}`);
            console.error("Error sending ETH:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDisconnect = async () => {
        setLoading(true);
        try {
            await disconnect();
            setIsSuccess(false);
        } catch (error) {
            console.error("Disconnection failed:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isConnected) {
            handleDisconnect();
        }
    }, [paymentMethod]);

    return loading ? (
        <ProcessingAlert />
    ) : (
        <>
            <div style={{textAlign: "center"}}>
                <appkit-button />
                {isConnected && <button onClick={() => handleSendEth()}>Pay with {paymentMethod.toUpperCase()}</button>}
            </div>
            <SuccessPaymentAlert open={isSuccess} onClose={() => setIsSuccess(false)} />
        </>
    );
};

export default ETHConnect2;
