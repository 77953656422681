import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import '../CryptoPage.css'; // Assuming you have a CSS file for styling
import { fontSize } from '@mui/system';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip, Button } from "@mui/material";
import SidebarMenuList from "../../includes/sidebarMenuList";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, useStepContext } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { ContactSupportOutlined, Recommend } from '@mui/icons-material';
import {Link, useLocation } from "react-router-dom";
import { Tabs, Tab } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { keyframes } from '@mui/system';
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear"; // Import ClearIcon
import InputAdornment from "@mui/material/InputAdornment";
import { Link as RouterLink } from 'react-router-dom';
import LoginPopUp from '../loginpopup';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Popover,
  Checkbox,
  TextField
} from "@mui/material";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";
import { Helmet } from 'react-helmet';


// Define the keyframes for the left-right motion animation
const leftRightMotion = `
  @keyframes leftRightMotion {
    0% {
      transform: translateX(-30px); /* Start 30px left of center */
    }
    50% {
      transform: translateX(30px);  /* Move 30px right of center */
    }
    100% {
      transform: translateX(-30px); /* Return to 30px left of center */
    }
  }
`;


const drawerWidth = 292;

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 900,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
}));

const CategoryBasedInfluencers = (props) => {
  const { window1 } = props;
  const { crypto } = useParams();
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortType, setSortType] = useState('fd_asc');
  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [predictionLoader, setPredictionLoader] = useState(false);
  const [slug, setSlug] = useState(false);
  const [sevenDays, setSevenDays] = useState(false);
  const [thirtyDays, setThirtyDays] = useState(false);
  const [yearDays, setYearDays] = useState(false);
  const [rowsToShow, setRowsToShow] = useState(7);
  const [selectedSort, setSelectedSort] = useState('');
  const sortRef = useRef();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [portfolioData, setPortfolioData] = useState([]);
  const location = useLocation(); 
  const loc = location.pathname;
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const [liked, setLiked] = useState(false);
  const [bouncingRow, setBouncingRow] = useState(null);

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );

  const mediaQueryVar1 = useMediaQuery(
    json2mq({
      minWidth: 400,
    })
  );
  const mediaQueryVar2 = useMediaQuery(
    json2mq({
      maxWidth: 420,
    })
  );
  const mediaQueryVar3 = useMediaQuery(
    json2mq({
      minWidth: 390,
    })
  );
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const openfilter = Boolean(anchorEl);
  const handleCloseeoption = () => {
    setAnchorEl(null);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };

  const handleSortWinRate = () => {
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
  
    const sortedData = [...data].sort((a, b) => {
      const winRateA = parseFloat(a.win_rate.replace("%", "")); // Convert "77%" to 77
      const winRateB = parseFloat(b.win_rate.replace("%", "")); // Convert "85%" to 85
  
      if (newSortOrder === "desc") {
        return winRateB - winRateA; // Sort from highest to lowest
      } else {
        return winRateA - winRateB; // Sort from lowest to highest
      }
    });
  
    setData(sortedData); // Assuming you have a setData function to update the table
  };
  

  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };

  const isSmallScreen = useMediaQuery(
    json2mq({
      maxWidth: 380,
    })
  );

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
};

const drawer = (
    <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
<SidebarMenuList/>
    </div>
);


const container = window1 !== undefined ? () => window1().document.body : undefined;


const [isTableView, setIsTableView] = useState(true);
const [selectedButton, setSelectedButton] = useState("CoinPrices");
const [likeUpdated, setLikeUpdated] = useState(false);
const [sortOrder, setSortOrder] = useState(""); // Default to descending
const [sortByLikes, setSortByLikes] = useState(""); // Asc or Desc
const [sortByRanks, setSortByRanks] = useState(""); // Asc or Desc
const [originalData, setOriginalData] = useState([...data]); // To store original unsorted data


const handleSortByLikesChange = (event) => {
  const value = event.target.value;
  setSortByLikes(value);

  const sortedData = [...data].sort((a, b) => {
    if (value === "asc") {
      return a.likes - b.likes; // Low to High
    } else {
      return b.likes - a.likes; // High to Low
    }
  });

  setData(sortedData); // Update the sorted data
};
const handleSortByRanksChange = (event) => {
  const value = event.target.value;
  setSortByRanks(value);

  const sortedData = [...data].sort((a, b) => {
    if (value === "asc") {
      return a.rank - b.rank; // Low to High
    } else {
      return b.rank - a.rank; // High to Low
    }
  });

  setData(sortedData); // Update the sorted data
};
const handleClearAll = () => {
  setData(originalData); // Reset to original data
  setAnchorEl(null);
  setSortByLikes(""); // Clear the sort selection
  setSortByRanks("");
};

const handleSort = () => {
  const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
  setSortOrder(newSortOrder);
  const sortedData = [...data].sort((a, b) => {
    if (newSortOrder === "desc") {
      return b.likes - a.likes; // Sort from highest to lowest
    } else {
      return a.likes - b.likes; // Sort from lowest to highest
    }
  });
  setData(sortedData); // Assuming you have a setData function to update the table
};
const handleSortRank = () => {
  const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
  setSortOrder(newSortOrder);
  const sortedData = [...data].sort((a, b) => {
    if (newSortOrder === "desc") {
      return b.rank - a.rank; // Sort from highest to lowest
    } else {
      return a.rank - b.rank; // Sort from lowest to highest
    }
  });
  setData(sortedData); // Assuming you have a setData function to update the table
};
const pathname = window.location.pathname;
        const rawCategory = pathname.split('/')[2] || ''; // Adjust index based on URL structure
        const category = rawCategory.replace(/-/g, ' '); // Replace hyphens with spaces

useEffect(() => {
    const fetchInfluencerMetrics = async () => {
      try {
        // Extract the category from the URL
        const pathname = window.location.pathname;
        const rawCategory = pathname.split('/')[2] || ''; // Adjust index based on URL structure
        const category = rawCategory.replace(/-/g, ' '); // Replace hyphens with spaces
        console.log(category);
        const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/calculateCategoryBasedInfluencerMetrics', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ category }), // Send the category in the POST request
        });

        const result = await response.json();
        console.log(result);

        if (result.code === '200') {
          const sortedData = result.data.sort((a, b) => b.combined_metric - a.combined_metric);
          // Add rank to each item in sortedData
    const rankedData = sortedData.map((item, index) => ({
      ...item,  // Spread the original item properties
      rank: index + 1  // Add rank based on array index (1-based rank)
  }));

  setOriginalData(rankedData);
  // Set the final sorted data
  setData(rankedData);
 } else {
          setError(result.message);
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchInfluencerMetrics(); // Ensure the fetch function is called

  }, []);


  // Handle Like Function
  const handleLike = async (forecasterId) => {
    const userId = JSON.parse(localStorage.getItem("cw_portfolio_user"))?.user_id;
    try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/updateLike', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ forecasterId, userId }),
      });

      const result = await response.json();
      if (result.code === '200') {
      
          // Update the like count in the local state based on the backend response
          setData(prevData => {
              return prevData.map(item => 
                  item.forecaster_id === forecasterId
                      ? { ...item, likes: result.data.newLikeCount }  // Set to the new like count from the backend
                      : item
              );
          });
          setLikeUpdated(true);
        
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('An error occurred while updating the like.');
    }
  };
  
  
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setSortDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (index) => {
    setDropdownVisible(dropdownVisible === index ? null : index);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (sortType) => {
    setSortType(sortType);
    setSortDropdownVisible(false);
    setSelectedSort(sortType);
  };

  


  const filteredData = data.filter((obj) =>
  obj.forecaster_name.toLowerCase().includes(searchTerm.toLowerCase())
);



  const handleLoadMore = () => {
    setRowsToShow(rowsToShow + 7);
  };


const compareValues = (val1, val2, order = 'asc') => {
  if (val1 === 'Pending') return 1;  // Ensure 'Pending' is always last
  if (val2 === 'Pending') return -1; // Ensure 'Pending' is always last
  if (order === 'asc') return val1 > val2 ? 1 : val1 < val2 ? -1 : 0;
  return val1 < val2 ? 1 : val1 > val2 ? -1 : 0;
};

const sortedData = [...filteredData].sort((a, b) => {
  switch (sortType) {
    case 'ac_asc':
      return compareValues(a.accuracy, b.accuracy, 'asc');
    case 'ac_desc':
      return compareValues(a.accuracy, b.accuracy, 'desc');
    default:
      return 0;
  }
});

const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      setIsDataLoaded(true); // Set to true when data is available
    }
  }, [filteredData]);

  const handleButtonClick = (buttonName) => {
    // If the same button is clicked again, don't toggle the view
    if (selectedButton === buttonName) {
      return;
    }

    // Toggle the view based on the clicked button
    setSelectedButton(buttonName);
    setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
  };

  const [isPredictionDataLoaded, setIsPredictionDataLoaded] = useState(false);
  useEffect(() => {
    if (sortedData && sortedData.length > 0) {
      setIsPredictionDataLoaded(true); // Set to true when data is available
    }
  }, [sortedData]);

  const currentUrl = window.location.pathname; // Get the current URL path


  return (
    <div className="crypto-page" style={{backgroundColor: mediaQueryVar? "#EDF1F6" : "white", fontFamily: "'Satoshi', sans-serif",}}>
      <Helmet>
        <title>{category} Influencer Rankings</title>
      </Helmet>
      <Grid item xs={12} align="left">
      <div style={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between", // Changed from space-between to flex-end
        marginTop: "0px", 
        marginBottom: "10px",
        marginLeft: "5px"
    }}>
    {mediaQueryVar===false ? (
        <Typography
        variant="h6"
        component="div"
        style={{
          fontWeight: 500,
          fontSize: mediaQueryVar2 ? "20px" : "20px",
          marginRight:"3px"
        }}
      >
        <span style={{
          backgroundColor:"#1877f2",
          padding:"4px 4px",
          borderRadius:"5px",
          color:"white",
          marginLeft:'0px',
          
        }}>{category}</span> Influencer Rankings
      </Typography>
    ) : (null)}
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none"} }}
        >
            <img 
                src='/images/menubar.svg'
                alt="Menu Icon" 
                style={{ 
                    width: "24px", 
                    height: "24px",
                    marginRight: "10px",
                    marginTop: "10px" 
                }} 
            />
        </IconButton>
    </div>

</Grid>
<Drawer
    container={container}
    variant="temporary"
    open={mobileOpen}
    onClose={handleDrawerToggle}
    ModalProps={{
        keepMounted: true, // Better open performance on mobile.
    }}
    sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "400", backgroundColor: 'white', color: '#727376' },
    }}
>
    {drawer}
</Drawer>


{mediaQueryVar ? null : ( 
  <div style={{display: "flex", justifyContent:"space-between"}}>
  <TextField
  style={{
    width: "100%",
    borderRadius: "46px",
    border: "1px #1877F2 solid",
    backgroundColor: "white",
    paddingLeft: "12px",
    paddingRight: "30px",
    marginBottom: "10px",
  }}
  size="small"
  placeholder="Search Influencer"
  value={searchTerm}
  onChange={handleSearchChange}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <img src={window.constants.asset_path + '/images/search.svg'} alt="search Icon" />
      </InputAdornment>
    ),
    style: {
      border: "none", // Remove the border from the input
    },
  }}
  sx={{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent', // Remove the border of the fieldset
      },
      '&:hover fieldset': {
        borderColor: 'transparent', // Remove the border on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent', // Remove the border when focused
      },
    },
  }}
/>
<Box
  sx={{
    borderRadius: "10px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "5px",
    color: "#1877F2",
  }}
>
  <Button onClick={handlePopoverOpen}>
    <div
      style={{
        marginTop: "-90px",
        marginRight: "-15px",
        marginBottom: "-80px",
        border: "2px solid #1877F2",
        borderRadius: "50%",
        padding: "8px 9px",
      }}
    >
      <img
        width="20px"
        src={window.constants.asset_path + '/images/filter.svg'}
        alt="Filter Icon"
      />
    </div>
  </Button>

  <Popover
    open={openfilter}
    anchorEl={anchorEl}
    onClose={handleCloseeoption}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
  >
    <ListSubheader>Sort by Popularity</ListSubheader>
    <RadioGroup
      aria-label="sort-likes"
      name="sort-likes"
      value={sortByLikes} // Add state for this
      onChange={handleSortByLikesChange}
    >
      <MenuItem>
        <FormControlLabel
          value="asc"
          control={<Radio size="small" />}
          label={
            <Typography sx={{ fontWeight: "500" }}>
              Low to High
            </Typography>
          }
        />
      </MenuItem>
      <MenuItem>
        <FormControlLabel
          value="desc"
          control={<Radio size="small" />}
          label={
            <Typography sx={{ fontWeight: "500" }}>
              High to Low
            </Typography>
          }
        />
      </MenuItem>
    </RadioGroup>

    <ListSubheader>Sort by Ranks</ListSubheader>
    <RadioGroup
      aria-label="sort-ranks"
      name="sort-ranks"
      value={sortByRanks} // Add state for this
      onChange={handleSortByRanksChange}
    >
      <MenuItem>
        <FormControlLabel
          value="asc"
          control={<Radio size="small" />}
          label={
            <Typography sx={{ fontWeight: "500" }}>
              Low to High
            </Typography>
          }
        />
      </MenuItem>
      <MenuItem>
        <FormControlLabel
          value="desc"
          control={<Radio size="small" />}
          label={
            <Typography sx={{ fontWeight: "500" }}>
              High to Low
            </Typography>
          }
        />
      </MenuItem>
    </RadioGroup>

    <Divider />

    <Box sx={{ display: "flex", padding: "8px" }}>
      <Button
        size="small"
        sx={{ textTransform: "capitalize", marginLeft: "10px" }}
        onClick={handleClearAll} // Add function to reset data
        startIcon={<ClearIcon />}
      >
        Clear All
      </Button>
    </Box>
  </Popover>
</Box>

</div>
)}


{mediaQueryVar ? null : (
  <>
    {!isDataLoaded && (
      <Box sx={{ padding: "5px", backgroundColor: "#FFF", borderRadius: "8px", margin: "8px 0" }}>
        {Array.from(new Array(rowsToShow)).map((_, index) => (
          <Box
            key={index}
            sx={{
              padding: "16px",
              backgroundColor: "#F9F9F9",
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
              <Skeleton
                sx={{ marginRight: "8px", borderRadius: "8px" }}
                variant="rectangular"
                width={40}
                height={40}
              />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton variant="text" width="50%" height={30} />
              <Skeleton variant="text" width="50%" height={30} />
              <Skeleton variant="text" width="50%" height={30} />
            </Box>
          </Box>
        ))}
      </Box>
    )}
    {!loading && (
      <>
        {filteredData.slice(0, rowsToShow).map((obj, index) => (
  <Box
    key={index}
    sx={{
      overflowY: "auto",
      "&::-webkit-scrollbar": { display: "none" },
      scrollbarWidth: "none",
      marginBottom: index === data.length - 1 ? "50px" : "12px", // Ensure this margin for the last element
    }}
  >
    <Box
      sx={{
       
      }}
    >
      <a href={`../../influencer/${obj.forecaster_name.replace(/\s+/g, '-')}`} style={{textDecoration:"none"}}> 
      <Box sx={{
              borderRadius: "6px",
              marginTop: "9px",
              marginRight:"0px",
              paddingTop: "3px",
              paddingBottom:"3px",
              paddingLeft:"5px",
              paddingRight:"5px",
              border: "1px solid #DADEDF",
              
              background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))',
              minheight:"150px",
              
            }}>
              
          
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              
              <Box>
                {obj.photo ? (
                  <img
                    src={obj.photo}
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "33px",
                      border: "2px solid black"
                    }}
                    alt="Profile Pic"
                  />
                ) : (
                  <img
                    src={window.constants.asset_path + "/images/avtar.jpeg"}
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "33px",
                      border: "2px solid black",
                      marginLeft:"3px",
                     
                    }}
                    alt="Default Profile Pic"
                  />
                )}
              </Box>
              

              <Box sx={{ flexGrow: 1 }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="h6"
                    sx={{ color: "black", fontWeight: "500", fontSize: "16px", padding: "10px",fpntFamily:"Satoshi,sans-serif" }}
                  >
                    {obj.forecaster_name}
                  </Typography>
                  
                
                    <Typography variant="body2">
                      <img
                        src={`${window.constants.asset_path}/images/link.svg`} // Example path
                        alt="Link Symbol"
                        style={{
                          width: "10px",
                          height: "15px",
                          marginRight: "8px",
                          marginTop:"15px",
                        
                        }}
                      />
                      
                    </Typography>

                    
                  
    
                  
                </Stack>
               
              </Box>
              
            </Stack>
            
            <Box
            onClick={(e) => e.stopPropagation()} 
        sx={{
          display: "inline-flex",
          gap: "5px",
          flexWrap: "wrap",
          marginTop: "3px",
        }}
      >
        {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
        {obj.top_tags.map((tag, index) => (
          <Link 
          to={`/category/${tag.replace(/\s+/g, '-')}/influencers`} 
          key={tag.slug} 
          style={{ textDecoration: 'none' }}
        >
          <Box
            key={tag.slug}
            sx={{
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "2px 11px",
              fontSize: "11px",
              fontWeight: "500",
              color: "#000",
              
              marginBottom:"5px"
            }}
          >
            {tag}
          </Box></Link>
        ))}
      </Box>
         
        
        <Box
      onClick={(e) => e.stopPropagation()} 
  sx={{
    display: "inline-flex",
    gap: "5px",
    flexWrap: "wrap",
    marginTop: "-30px",
  }}
>

</Box>
      </Box>
      </a>
      <Box sx={{ background: "#fff", borderRadius: "6px", padding: "10px", border: "1px solid #1877F2", }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "0px"}}>
          <Box sx={{ flex: 1, marginRight: "8px" }}>
            <div style={{justifyContent: "space-between", display: "flex", margin:"4px", marginBottom:"-2px"}}>
              <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500", marginRight: mediaQueryVar3 ? "-10vw" : ""  }}>Rank:</span>
              <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>
              {obj.rank}
               
              </span>
              <a href={`../../influencer/${obj.forecaster_name.replace(/\s+/g, '-')}/portfolios`} style={{color:"black"}}>
                <Button
               
    className="btn"
    variant="outlined"
    style={{
      backgroundColor: "white",
      marginLeft: "15px",
      marginRight: "10px",
      borderRadius: "48px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "4px",
      paddingBottom: "4px",
      border: "2px solid #1877F2",
      fontSize: "12px",
      textTransform: "none",
      borderStyle: "solid",
      borderColor: "#1877F2",
      borderWidth: "1px",
      color: "#1877F2",
      height: "27px",
      marginTop:"-6px",
      width:"auto",
    }}
  >
    View Portfolios
  </Button>
  </a>
  <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500" }}>Win Rate:</span>
                  <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px", }}>
                  {obj.win_rate}
                   
                  </span>

            </div>
            
            
            <style>
        {`
          @keyframes bounce {
            0% { transform: scale(1); }
            50% { transform: scale(1.2); }
            100% { transform: scale(1); }
          }

          .bounce {
            animation: bounce 0.3s;
          }
        `}
      </style>
       
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
))}
{rowsToShow < filteredData.length && (
  <StyledTableRow>
    <StyledTableCell colSpan={6} align="center" style={{ position: 'relative', height: '100px' }}>
    <div className="loadingButton">
                  <LoadingButton
                    onClick={handleLoadMore}
                    loadingPosition="center"
                    variant="outlined"
                    style={{
                      maxWidth: "360px",
                      width: "80vw",
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: mediaQueryVar === false ? "80vw" : "180px",
                      marginBottom:"50px"
                    }}
                    
                  >
                    <span>Explore More</span>
                  </LoadingButton>
                </div>
    </StyledTableCell>
  </StyledTableRow>
)}
</>
    )}
  </>
)}


      
      {mediaQueryVar ? (
        
        <div className="layout">
          <div className>
          <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}

                    sx={{

                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{

                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376'},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            </div>
            
        <div className="content">
        
        {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}   
       


<TableContainer 
  style={{ 
    width: "96%", 
    overflowX: "hidden", 
    border: "1px solid #DADEDF", 
    borderRadius: "10px", 
    padding: 0, 
    marginTop: "0.8%", 
    marginBottom: "5%",
    marginLeft:"2%",
  }}
>
  <div style={{ backgroundColor: "white", padding: "3%", paddingTop:"2%", borderRadius: "8px" }}>
  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom:"2%", }}>
  <Typography
        variant="h6"
        component="div"
        style={{
          fontWeight: 700,
          fontSize: mediaQueryVar2 ? "20px" : "20px",
          marginRight:"3px"
        }}
      >
        <span style={{
          backgroundColor:"#1877f2",
          padding:"6px 6px",
          borderRadius:"5px",
          color:"white",
          marginLeft:'0px',
          marginRight:"5px"
          
        }}>{category}</span>Influencer Rankings
      </Typography>
  <TextField
    style={{
      width: "25%",
      borderRadius: "46px",
      border: "1px #1877F2 solid",
      backgroundColor: "white",
      paddingLeft: "12px",
      paddingRight: "30px",
      marginBottom: "10px",
    }}
    size="small"
    placeholder="Search Influencer"
    value={searchTerm}
    onChange={handleSearchChange}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <img src={window.constants.asset_path + '/images/search.svg'} alt="search Icon" />
        </InputAdornment>
      ),
      style: {
        border: "none", // Remove the border from the input
      },
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent', // Remove the border of the fieldset
        },
        '&:hover fieldset': {
          borderColor: 'transparent', // Remove the border on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent', // Remove the border when focused
        },
      },
    }}
  />

</div>

    <Table className="non_responsiveTable" aria-label="customized table" 
    style={{ marginLeft:"5px", marginRight:"2%",border: "1px solid #EEE",borderRadius:"5px" }}>
      <TableHead>
      <TableRow>
        <StyledTableCell 
            className="cryptotableviewHeader" 
            style={{ padding: 0, width: "15vw" }}
          >
            <button 
              
              onClick={handleSortRank}
              style={{ 
                textAlign: "center", 
                color: "#727376", 
                fontSize: "16px", 
                width: "100%", 
                height: "100%", 
                border: "none", 
                background: "white", 
                padding: "12px 12px", 
                margin: "0px 2px", 
                boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)",
                fontWeight: "500",
                cursor: "pointer"
              }}
            >
              Rank
              {sortOrder === "desc" ? (
      <span style={{ marginLeft: "8px" , fontSize:"18px", fontWeight:"bold"}}>↓</span>  // Down arrow for descending
    ) : (
      <span style={{ marginLeft: "8px", fontSize:"18px", fontWeight:"bold", color: "grey" }}>↑</span>

  // Up arrow for ascending
    )}
            </button>
          </StyledTableCell>
          <StyledTableCell 
            className="cryptotableviewHeader" 
            style={{ padding: 0, width: "50vw" }}
          >
            <button 
              disabled 
              style={{ 
                textAlign: "left", 
                color: "#727376", 
                fontSize: "16px", 
                width: "100%", 
                height: "100%", 
                border: "none", 
                background: "white", 
                padding: "12px 12px", 
                margin: "0px 2px", 
                boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)",
                fontWeight: "500"
              }}
            >
              Influencer Name
            </button>
          </StyledTableCell>
          <StyledTableCell 
            className="cryptotableviewHeader" 
            style={{ padding: 0, width: "15vw" }}
          >
            <button 
              
              onClick={handleSortWinRate}
              style={{ 
                textAlign: "center", 
                color: "#727376", 
                fontSize: "16px", 
                width: "100%", 
                height: "100%", 
                border: "none", 
                background: "white", 
                padding: "12px 12px", 
                margin: "0px 2px", 
                boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)",
                fontWeight: "500",
                cursor: "pointer"
              }}
            >
              Win Rate
              {sortOrder === "desc" ? (
      <span style={{ marginLeft: "8px" , fontSize:"18px", fontWeight:"bold"}}>↓</span>  // Down arrow for descending
    ) : (
      <span style={{ marginLeft: "8px", fontSize:"18px", fontWeight:"bold", color: "grey" }}>↑</span>

  // Up arrow for ascending
    )}
            </button>
          </StyledTableCell>
          <StyledTableCell 
            className="cryptotableviewHeader" 
            style={{ 
              borderRadius: "8px 0 0 8px", 
              border: 0, 
              padding: 0, 
              width: "20vw" 
            }}
          >
            <button 
              disabled 
              style={{ 
                color: "#fff", 
                fontSize: "16px", 
                width: "100%", 
                height: "100%", 
                border: "none", 
                background: "white", 
                padding: "12px 12px", 
                margin: "0px 2px", 
                boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)",
                fontWeight: "500",
                textAlign: "center", 
              }}
            >
              Portfolios
            </button>
          </StyledTableCell>
          {/* <StyledTableCell 
  className="cryptotableviewHeader" 
  style={{ 
    borderRadius: "8px 0 0 8px", 
    border: 0, 
    padding: 0, 
    width: "25%", 
    textAlign: "center" 
  }}
>
  <button 
    onClick={handleSort}  // Add sorting on button click
    style={{ 
      color: "#727376", 
      fontSize: "16px", 
      width: "100%", 
      height: "100%", 
      border: "none", 
      background: "white", 
      padding: "12px 12px", 
      margin: "0px 2px", 
      boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)",
      fontWeight: "500",
      textAlign: "center", 
      cursor: "pointer"
    }}
  >
    Popularity
    {sortOrder === "desc" ? (
      <span style={{ marginLeft: "8px" , fontSize:"18px", fontWeight:"bold"}}>↓</span>  // Down arrow for descending
    ) : (
      <span style={{ marginLeft: "8px", fontSize:"18px", fontWeight:"bold", color: "grey" }}>↑</span>

  // Up arrow for ascending
    )}
  </button>
</StyledTableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        { !isDataLoaded ? (
          Array.from(new Array(10)).map((_, index) => (
            <StyledTableRow key={index} style={{ backgroundColor:"white" ,}}>
              <StyledTableCell>
                <Skeleton variant="rounded" height={45} style={{marginTop:'10px'}}/>
              </StyledTableCell>
              <StyledTableCell>
                <Skeleton variant="rounded" height={45} style={{marginTop:'10px'}}/>
              </StyledTableCell>
              <StyledTableCell>
                <Skeleton variant="rounded" height={45} style={{marginTop:'10px'}}/>
              </StyledTableCell>
              <StyledTableCell>
                <Skeleton variant="rounded" height={45} style={{marginTop:'10px'}}/>
              </StyledTableCell>
            </StyledTableRow>
          ))
        ) : (
          filteredData.slice(0, rowsToShow).map((obj, index) => (
            
            <StyledTableRow 
  onClick={() => navigate(`../../influencer/${obj.forecaster_name.replace(/\s+/g, '-')}`)} 
  key={index} 
  style={{ 
    cursor: "pointer", 
    backgroundColor: index % 2 === 0 ? "white" : "#F0F0F0", 
    borderTop: "7px solid white",
    paddingTop: "2px",  // Decrease padding top
    paddingBottom: "2px"  // Decrease padding bottom
  }}
>
<StyledTableCell
    style={{
          borderBottom: "none",
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
          paddingLeft: "8px",
          paddingTop: "8px",  // Decrease padding top
          paddingBottom: "8px",  // Decrease padding bottom
          borderRight: "1px solid #DADEDF",
          width: "10%",
          textAlign: "center", 
        }}
  >
    {obj.rank}
  </StyledTableCell>
  <StyledTableCell
    className="one"
   style={{
         borderBottom: "none",
         color: "#000",
         fontSize: "16px",
         fontWeight: "500",
         paddingLeft: "16px",
         paddingTop: "8px",  // Decrease padding top
         paddingBottom: "8px",  // Decrease padding bottom
         borderRight: "1px solid white",
         width: "45%",
         background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
         textAlign: "left",
       }}
  >
       <Box
    sx={{
      display: "flex",
      alignItems: "center",
      padding: "5px 7px",
      margin: "0 -5px -10px -20px",
    }}
  >
    <Box>
      {obj.photo ? (
        <img
          src={obj.photo}
          style={{
            border: "2px solid black",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            marginLeft: "5px",
            marginBottom: "3px",
          }}
          alt="Profile"
        />
      ) : (
        <img
          src={window.constants.asset_path + "/images/avtar.jpeg"}
          style={{
            width: "38px",
            height: "38px",
            borderRadius: "33px",
            border: "2px solid black",
            marginLeft: "5px",
            marginBottom: "3px",
          }}
          alt="Default Avatar"
        />
      )}
    </Box>
    
    <Box sx={{ marginLeft: "5px" }}>
      <Typography
        sx={{
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {obj.forecaster_name}
      </Typography>
      <Box
      onClick={(e) => e.stopPropagation()} 
  sx={{
    display: "inline-flex",
    gap: "5px",
    flexWrap: "wrap",
    marginTop: "5px",
    marginBottom:"7px"
  }}
>
{obj.top_tags.map((tag, index) => (
    <Link 
      to={`/category/${tag.replace(/\s+/g, '-')}/influencers`} 
      key={tag.slug} 
      style={{ textDecoration: 'none' }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "15px",
          padding: "2px 11px",
          fontSize: "12px",
          fontWeight: "500",
          color: "#000",
          marginBottom: "4px",
          transition: "all 0.3s ease",  // Smooth transition for hover effect
          "&:hover": {
            backgroundColor: "#f0f0f0",  // Light grey background on hover
            color: "#0073e6",            // Change text color on hover
            transform: "scale(1.05)",    // Slightly increase size on hover
          },
        }}
      >
        {tag}
      </Box>
    </Link>
  ))}
</Box>
    </Box>
  </Box>
  </StyledTableCell>

  <StyledTableCell
      style={{
        borderBottom: "none",
        color: "#000",
        fontSize: "16px",
        fontWeight: "500",
        paddingLeft: "16px",
        paddingTop: "8px",  // Decrease padding top
        paddingBottom: "8px",  // Decrease padding bottom
        borderRight: "1px solid #DADEDF",
        width: "20%",
        textAlign: "center", 
      }}
    >
      {obj.win_rate}
    </StyledTableCell>

  <StyledTableCell
    style={{
      borderBottom: "none",
      color: "#000",
      fontSize: "16px",
      fontWeight: "500",
      paddingLeft: "16px",
      paddingTop: "8px",  // Decrease padding top
      paddingBottom: "8px",  // Decrease padding bottom
      borderRight: "1px solid #DADEDF",
      width: "20%",
      textAlign: "center", 
    }}
  >
    <a href={`../../influencer/${obj.forecaster_name.replace(/\s+/g, '-')}/portfolios`} style={{color:"black"}}>
    <Button
        onClick={(e) => e.stopPropagation()}
        className="btn"
        variant="outlined"
        style={{
          backgroundColor: "white",
          marginLeft: "0px",
          marginRight: "10px",
          borderRadius: "48px",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "4px",
          paddingBottom: "4px",
          border: "2px solid #1877F2",
          fontSize: "14px",
          textTransform: "none",
          borderStyle: "solid",
          borderColor: "#1877F2",
          borderWidth: "1px",
          color: "#1877F2",
          height: "27px",
          width:"auto",
        }}
      >
        View Portfolios
      </Button>
    </a>
  </StyledTableCell>
  

</StyledTableRow>

            
          ))
          
        )}
        {rowsToShow < filteredData.length && !loading && (
          <StyledTableRow>
            <StyledTableCell colSpan={6} align="center">
              <div style={{ display: 'flex', justifyContent: 'center', padding: '2% 0' }}>
                <button 
                  onClick={handleLoadMore} 
                  className="load-more"
                  style={{ 
                    backgroundColor: "rgba(67, 97, 238, 0.15)",
                    borderRadius: "6px",
                    border: "1px solid rgba(67, 97, 238, 0.15)",
                    color: "#4361ee",
                    height: "35px",
                    cursor: "pointer",
                    maxWidth: "360px",
                    width: "15%",
                    marginBottom: "-20px"
                  }}
                >
                  Explore More
                </button>
              </div>
            </StyledTableCell>
          </StyledTableRow>
        )}
      </TableBody>
    </Table>
  </div>
</TableContainer>




  
      </div>
      </div>
      ) : (
        null
      )}
      {mediaQueryVar === false && (


<div
  style={{
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "105%",
    zIndex: "100",
    marginBottom:"0px",
    marginLeft:"-5%",
    marginLeft:"-10px"
  }}
>
<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
    <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
</BottomNavigation>
</div>
)}
      
    </div>
  );
      
};


export default CategoryBasedInfluencers;