import React from "react";

const Investment = () => {
    const points = [
        "We have 4 years experience in Crypto Investment And Predictions",
        "We Guarantee 100% NO RUGPULL",
        "We Have a Platform Built Already With Traffic so you know you are not investing in a concept",
        "Shadow Portfolio Model and Algorithm Based recommendations is a tried and tested model with companies valued $200 Million",
        "We Guarantee 100% Transparency in our plans and stages",
        "Unlike 99% Presales we are not going to spend majority budget in marketing",
    ];

    return (
        <div className="container mt-5">
            <div>
                <h1 className="theme-bg shadow sub-heading">Why Invest In Our Presale?</h1>
            </div>
            <div className="card p-3 shadow">
                <ul>
                    {points.map((point, index) => (
                        <li key={index} className="mb-2 text-wrap">
                            {point}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Investment;

