import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
    lazy,
    Suspense,
  } from "react";
  import ClearIcon from "@mui/icons-material/Clear";
  import {
    Popover
  } from "@mui/material";
  import axios from "axios";
  import MenuItem from "@mui/material/MenuItem";
  import { useParams } from "react-router-dom";
  import Modal from "react-modal";
  import "./CryptoPage.css"; // Assuming you have a CSS file for styling
  import TableCell, { tableCellClasses } from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import Table from "@mui/material/Table";
  import { styled } from "@mui/material/styles";
  import TableBody from "@mui/material/TableBody";
  import Skeleton from "@mui/material/Skeleton";
  import Stack from "@mui/material/Stack";
  import Box from "@mui/material/Box";
  import { Checkbox, ListItemText, Chip ,ListSubheader,Fade} from "@mui/material";

  import json2mq from "json2mq";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import EmailPopUp from "./EmailPopUp.js";
  import Typography from "@mui/material/Typography";
  import { useNavigate } from "react-router-dom";
  import BottomNavigationAction from "@mui/material/BottomNavigationAction";
  import { BottomNavigation, Button } from "@mui/material";
  import SidebarMenuList from "../includes/sidebarMenuList.jsx";
  
  import IconButton from "@mui/material/IconButton";
  import { Grid } from "@mui/material";
  import Drawer from "@mui/material/Drawer";
  import { useLocation, Link } from "react-router-dom";
  import { Helmet } from "react-helmet";
  import { PERFORMANCE_TRACKER } from "../common/constant/SubscriptionFeatures.jsx";
  import {getUserAccessPermission} from "../apihelper/profile.js";
  import {PORTFOLIO_PERFORMANCE_TRACKER_ACCESS} from "../common/constant/SubscriptionMessage.jsx";
  import GoToSubscribeAlert from "../common/alert/GoToSubscribeAlert.jsx";

  
  import {
    FormControl,
    InputLabel,
    Select,
    CircularProgress,
    Paper,
  
  } from "@mui/material";
  
  import Dialog from "@mui/material/Dialog";
  import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
  import { Card, TextField } from "@mui/material";
  
  import { DialogTitle, DialogContent, DialogActions } from "@mui/material"; // Import Material-UI components
  
  
  import CloseIcon from "@mui/icons-material/Close";
  
  import CreatableSelect from "react-select/creatable";
  import {
    getTotalunit,
    getFetchdropdown,
  } from "../apihelper/cryptocurrencylist";
  import Alert from "@mui/material/Alert";
  import Snackbar from "@mui/material/Snackbar";
  
  const drawerWidth = 292;
  const API_URL = "https://crowdwisdom.live//wp-json/api/v1/fetchPortfolios";

  // MenuProps for smooth transitions
const menuProps = {
  TransitionComponent: Fade,
  transitionDuration: { enter: 300, exit: 200 },
  PaperProps: {
    style: { maxHeight: 300 },
  },
};
  
  const Portfolio_Performance_Tracker = (props) => {
    const { portfolioName } = useParams();
    const mPortfolioName = portfolioName.replace(/-/g, ' ');
    const { window1 } = props;
    const [returnLabel, setReturnLabel] = useState("7D Returns");
    const navigate = useNavigate();
    const location = useLocation();
    const [institutions, setInstitutions] = useState([]);
    const categoryRef = useRef(null);
    const riskRef = useRef(null);
    const portfolioRef = useRef(null);
    const categoryRefm = useRef(null);
    const riskRefm = useRef(null);
    const portfolioRefm = useRef(null);
    const loc = location.pathname;
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const mediaQueryVar = useMediaQuery(
          json2mq({
            minWidth: 900,
          }),
        );
        const handleDrawerToggle = () => {
          setMobileOpen(!mobileOpen);
        };
        const container =
        window1 !== undefined ? () => window1().document.body : undefined;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    
    const drawer = (
          <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
            <SidebarMenuList />
          </div>
        );
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
       
        const handleModalClose = () => {
          // Close the modal and reset the URL to the welcome page
          setLoginModalOpen(false);
          navigate(location.pathname, { replace: true });
        };
  
        // States for form fields
  const [category, setCategory] = useState(["Meme Coin"]);  
  const [riskLevel, setRiskLevel] = useState("Low");
  // 'institute' is an array for multi-select (selected institutions)
  const [institute, setInstitute] = useState([]);
  const [userPermission, setUserPermission] = useState(null);
  // States for search terms (separate for Category & Institution)
  const [categorySearchTerm, setCategorySearchTerm] = useState("");
  const [institutionSearchTerm, setInstitutionSearchTerm] = useState("");

  // Data states
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // States for dropdown options from backend
  const [categories, setCategories] = useState([]);
  // For institutions we now expect grouped data
  const [institutionGroups, setInstitutionGroups] = useState([]);

  // Loading flag for fetching categories/institutions
  const [loading1, setLoading1] = useState(true);
  const [goToSubscribeAlertOpen, setGoToSubscribeAlertOpen] = useState(false);
  const [subscriptionText, setSubscriptionText] = useState("");


  const handleUserPermission = React.useCallback(async () => {
          try {
              const feature = PERFORMANCE_TRACKER();
              const data = {
                  user_id: userData.user_id,
                  token: userData.token,
                  feature: feature,
              };
              const response = await getUserAccessPermission(data);
              setUserPermission(response.permission);
              console.log("user permission",response.permission);

          } catch (error) {
              console.error(error);
          }
      }, []);

       const handleLoginClick = () => {
              if (!userData) {
                  window.history.pushState({}, "", "/login");
                  localStorage.setItem("isPopupManuallyOpened", "true");
                  setLoginModalOpen(true);
              } else  {
                  setGoToSubscribeAlertOpen(true);
                  setSubscriptionText(PORTFOLIO_PERFORMANCE_TRACKER_ACCESS);
              }
          };


 useEffect(() => {
        if (userData) {
            handleUserPermission();
        }
    });

   // Separate useEffect hooks to fetch categories and institutions on mount
   useEffect(() => {
    fetchDistinctCategories();
  }, []);

  useEffect(() => {
    if (category && riskLevel) {
      fetchInstitutions(category,riskLevel);
      setInstitute([]); // Clear selected institutions
      setData([]);
    }
  }, [category,riskLevel]);

  // ---------------------------------------------------------------------------
  // API call to fetch portfolio data based on the current filters
  // ---------------------------------------------------------------------------
  const fetchPortfolios = async (updatedInstitutes = institute) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(API_URL, {
        category,
        risk: riskLevel,
        institute: updatedInstitutes, // Only send the selected institution options
        portfolio_name: mPortfolioName,
      });

      setData(response.data?.data || []);
      console.log("Updated Fetch Portfolios Data: ", response.data);
    } catch (error) {
      console.error("API Request Failed:", error);
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------------------------
  // API call to fetch distinct categories from backend
  // ---------------------------------------------------------------------------
  const fetchDistinctCategories = async () => {
    try {
      const response = await fetch(
        "https://crowdwisdom.live/wp-json/api/v1/getDistinctCategories",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      console.log("Categories Data:", result);
      if (result.code === "200") {
        setCategories(result.data); // result.data is expected to be an array of category names
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError("An error occurred while fetching categories.");
    } finally {
      setLoading1(false);
    }
  };

  // ---------------------------------------------------------------------------
  // API call to fetch institutions in groups
  // Expected backend response:
  // {
  //   code: "200",
  //   data: {
  //     top5BestPerformers: [ ... ],
  //     top5Influencers: [ ... ],
  //     remainingInstitutions: [ ... ]
  //   }
  // }
  // ---------------------------------------------------------------------------
  const fetchInstitutions = async (selectedCategories, riskLevel) => {
    try {
      const response = await fetch(
        "https://crowdwisdom.live/wp-json/api/v1/getInstitutions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categories: selectedCategories.length > 0 ? selectedCategories : ["Memecoin"], // Default to "Memecoin"
            risk : riskLevel
          }),
        }
      );
  
      const result = await response.json();
      console.log("Institutions Data:", result);
      if (result.code === "200") {
        setInstitutions(result.data || []);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError("An error occurred while fetching institutions.");
    } finally {
      setLoading1(false);
    }
  };
  

 
  

  // Compute sorted categories based on search match (matching ones come first)
  const sortedCategories = [...categories].sort((a, b) => {
    const aMatch = a.toLowerCase().includes(categorySearchTerm.toLowerCase());
    const bMatch = b.toLowerCase().includes(categorySearchTerm.toLowerCase());
    if (aMatch === bMatch) return 0;
    return aMatch ? -1 : 1;
  });
  
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isRiskOpen, setIsRiskOpen] = useState(false);
  const [isPortfolioOpen, setIsPortfolioOpen] = useState(false);

    return (
      <div className="crypto-page" style={{padding:mediaQueryVar? 0:"10px",margin: 0, backgroundColor: "#F2F2F2" }}>
         {goToSubscribeAlertOpen && (
                <GoToSubscribeAlert onClose={setGoToSubscribeAlertOpen} text={subscriptionText} />
            )}
        <Helmet>
          <title>
            Portfolio Performance Tracker
          </title>
          
        </Helmet>
  
        <Grid item xs={12} align="left">
        {mediaQueryVar ? null : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "0px",
        marginLeft: "5px",
        fontSize: "20px",
      }}
    >
    
  
      {/* Wrapper to push the IconButton to the right */}
      <div style={{ marginLeft: "auto" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none" } }}
        >
          <img
            src="/images/menubar.svg"
            alt="Menu Icon"
            style={{
              width: "24px",
              height: "24px",
              marginRight: "10px",
              marginTop: "10px",
            }}
          />
        </IconButton>
      </div>
    </div>
  )}
  
        </Grid>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "400",
              backgroundColor: "white",
              color: "#727376",
            },
          }}
        >
          {drawer}
        </Drawer>
        
  
        {mediaQueryVar ? (
          <div className="layout">
            <div className>
              <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
              >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                      backgroundColor: "white",
                      color: "#727376",
                    },
                  }}
                >
                  {drawer}
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                      backgroundColor: "white",
                      color: "#727376",
                    },
                  }}
                  open
                >
                  {drawer}
                </Drawer>
              </Box>
            </div>
  
            <div
              className="content"
              style={{
                margin: "0%",
                padding: "10px",
              }}
            >
              <Box sx={{ p: 4, maxWidth: "900px", margin: "auto", backgroundColor: "#f9f9f9", borderRadius: 2, boxShadow: 3 }}>
        <Typography variant="h5" fontWeight="bold" sx={{ mb: 3, textAlign: "center", color: "#1976d2" }}>
        Compare with Other Portfolios
        </Typography>
  
        {/* Dropdowns */}
        <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    gap: 2,
    mb: 3,
  }}
>
  {/* --- Category Dropdown (with search) --- */}
  <FormControl fullWidth variant="outlined">
  <InputLabel id="category-label">Category</InputLabel>
  <Select
  ref={categoryRef}
  labelId="category-label"
  multiple
  value={category || []}
  open={userPermission === 'true' ? isCategoryOpen : false} // Explicitly control open state

  onOpen={() => userPermission && setIsCategoryOpen(true)} // Open dropdown if allowed
  onClose={() => setIsCategoryOpen(false)} // Close dropdown

  onChange={(e) => userPermission && setCategory(e.target.value)}
  onMouseDown={(e) => {
    if (userPermission === 'false') {
      e.preventDefault();
      e.stopPropagation();
      categoryRef.current?.blur();
      setGoToSubscribeAlertOpen(true);
      setSubscriptionText(PORTFOLIO_PERFORMANCE_TRACKER_ACCESS);

    }
  }}
  onClick={(e) => {
    if (userPermission === 'false'){
      e.preventDefault();
      e.stopPropagation();
    }
  }}
 
    renderValue={(selected) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {selected.map((option) => (
        <Chip
          key={option}
          label={option}
          onMouseDown={(e) => e.stopPropagation()}
         
          onDelete={(e) => {
            e.stopPropagation();
            const newValues = category.filter((item) => item !== option);
            setCategory(newValues);
           
          }}

          sx={{ backgroundColor: "#E3F2FD", color: "#1565C0" }}
        />
      ))}
    </Box>
  )}
  label="Category"
  MenuProps={menuProps}
>
<MenuItem disableRipple>
      <TextField
        autoFocus
        placeholder="Search Category..."
        fullWidth
        value={categorySearchTerm}
        onChange={(e) => setCategorySearchTerm(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      />
    </MenuItem>
    {/* Filtered options */}
    {categories.filter((name) =>
      name.toLowerCase().includes(categorySearchTerm.toLowerCase())
    ).length > 0 ? (
      categories
        .filter((name) =>
          name.toLowerCase().includes(categorySearchTerm.toLowerCase())
        )
        .map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={category.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))
    ) : (
      <MenuItem disabled>
        <ListItemText primary="None Found" />
      </MenuItem>
    )}
</Select>


</FormControl>




  {/* --- Risk Level Dropdown (unchanged) --- */}
  <FormControl fullWidth variant="outlined">
    <InputLabel id="risk-label">Risk Level</InputLabel>
    <Select
    ref={riskRef}
    labelId="risk-label"
    value={riskLevel}
    open={userPermission === 'true'  ? isRiskOpen : false} // Explicitly control open state

  onOpen={() => userPermission && setIsRiskOpen(true)} // Open dropdown if allowed
  onClose={() => setIsRiskOpen(false)} // Close dropdown

    onChange={(e) => userPermission && setRiskLevel(e.target.value)}
    label="Risk Level"
    onMouseDown={(e) => {
      if(userPermission === 'false') {
        e.preventDefault();
        e.stopPropagation();
        riskRef.current?.blur();
        setGoToSubscribeAlertOpen(true);
      setSubscriptionText(PORTFOLIO_PERFORMANCE_TRACKER_ACCESS);
      }
    }}
    onClick={(e) => {
      if (userPermission === 'false') {
        e.preventDefault();
        e.stopPropagation();
      }
    }}
  >
    {["Low", "Medium", "High"].map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </Select>
  </FormControl>

  {/* --- Institution Name Dropdown (grouped & multi-select) --- */}
  <FormControl fullWidth variant="outlined">
  <InputLabel id="institution-label">Portfolio Names</InputLabel>
  <Select
    ref={portfolioRef}
    labelId="institution-label"
    multiple
    value={institute || []}
    open={userPermission === 'true'  ? isPortfolioOpen : false} // Explicitly control open state

    onOpen={() => userPermission && setIsPortfolioOpen(true)} // Open dropdown if allowed
    onClose={() => setIsPortfolioOpen(false)} // Close dropdown
    onChange={(e) => userPermission && setInstitute(e.target.value)}
    onMouseDown={(e) => {
      if (userPermission === 'false') {
        e.preventDefault();
        e.stopPropagation();
        portfolioRef.current?.blur();
        setGoToSubscribeAlertOpen(true);
      setSubscriptionText(PORTFOLIO_PERFORMANCE_TRACKER_ACCESS);
      }
    }}
    onClick={(e) => {
      if(userPermission === 'false') {
        e.preventDefault();
        e.stopPropagation();
      }
    }}
    renderValue={(selected) => (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {selected.map((option) => (
          <Chip
            key={option}
            label={option}
            onMouseDown={(e) => e.stopPropagation()} // Prevents dropdown re-opening
            onDelete={(e) => {
              e.stopPropagation();
              const newValues = institute.filter((item) => item !== option);
              setInstitute(newValues);
              fetchPortfolios(newValues);
            }}
            sx={{ backgroundColor: "#E3F2FD", color: "#1565C0" }}
          />
        ))}
      </Box>
    )}
    label="Portfolio Names"
    MenuProps={menuProps}
  >
    {/* Search input for portfolio names */}
    <MenuItem disableRipple>
      <TextField
        autoFocus
        placeholder="Search Portfolio..."
        fullWidth
        value={institutionSearchTerm}
        onChange={(e) => setInstitutionSearchTerm(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      />
    </MenuItem>

    {/* Filtered options */}
    {institutions.filter((name) =>
      name.toLowerCase().includes(institutionSearchTerm.toLowerCase())
    ).length > 0 ? (
      institutions
        .filter((name) =>
          name.toLowerCase().includes(institutionSearchTerm.toLowerCase())
        )
        .map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={institute.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))
    ) : (
      <MenuItem disabled>
        <ListItemText primary="None Found" />
      </MenuItem>
    )}
  </Select>
</FormControl>



</Box>

{/* --------------------- Fetch Button --------------------- */}
<Button
  variant="contained"
  fullWidth
  color="primary"
  onClick={() => fetchPortfolios()}
  disabled={loading}
>
  {loading ? (
    <CircularProgress size={24} sx={{ color: "#fff" }} />
  ) : (
    "Fetch Portfolios"
  )}
</Button>

{/* --------------------- Error Message --------------------- */}
{error && (
  <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
    {error}
  </Typography>
)}

{/* --------------------- Display Results --------------------- */}
{data && data.length > 0 && (
  <TableContainer
    component={Paper}
    sx={{ mt: 4, borderRadius: 2, boxShadow: 3 }}
  >
    <Table>
      <TableHead>
        <TableRow sx={{ backgroundColor: "#1976d2" }}>
          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
            Sl No
          </TableCell>
          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
            Portfolio Name
          </TableCell>
          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
            7D Returns
          </TableCell>
          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
            30D Returns
          </TableCell>
          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
            90D Returns
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((rec, index) => (
          <TableRow
            key={index}
            sx={{
              "&:nth-of-type(even)": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            <TableCell>{index + 1}</TableCell>
            <TableCell>{rec.portfolio_type}</TableCell>
            <TableCell>{rec.seven_day_roi}%</TableCell>
            <TableCell>{rec.thirty_day_roi}%</TableCell>
            <TableCell>{rec.ninety_day_roi}%</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)}

            </Box>
          </div>
        </div>
        
          
        ) : (
          <div style={{marginTop:"10px",}}>
           
           <Box sx={{ p: 3, maxWidth: "700px", margin: "auto", backgroundColor: "#f9f9f9", borderRadius: 2, boxShadow: 3,marginBottom:"4vh" }}>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 3, textAlign: "center", color: "#1976d2" }}>
      Compare with Other Portfolios
      </Typography>
           
  
        {/* Dropdowns */}
        <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    gap: 2,
    mb: 3,
  }}
>
  {/* --- Category Dropdown (with search) --- */}
  <FormControl fullWidth variant="outlined">
  <InputLabel id="category-label">Category</InputLabel>
  <Select
  ref={categoryRef}
  labelId="category-label"
  multiple
  value={category || []}
  open={userPermission === 'true'  ? isCategoryOpen : false} // Explicitly control open state

  onOpen={() => userPermission && setIsCategoryOpen(true)} // Open dropdown if allowed
  onClose={() => setIsCategoryOpen(false)} // Close dropdown
  onChange={(e) => userPermission && setCategory(e.target.value)}
  onMouseDown={(e) => {
    if (userPermission === 'false') {
      e.preventDefault();
      e.stopPropagation();
      categoryRef.current?.blur();
      setGoToSubscribeAlertOpen(true);
      setSubscriptionText(PORTFOLIO_PERFORMANCE_TRACKER_ACCESS);

    }
  }}
  onClick={(e) => {
    if (userPermission === 'false'){
      e.preventDefault();
      e.stopPropagation();
    }
  }}
 
    renderValue={(selected) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {selected.map((option) => (
        <Chip
          key={option}
          label={option}
          onMouseDown={(e) => e.stopPropagation()}
         
          onDelete={(e) => {
            e.stopPropagation();
            const newValues = category.filter((item) => item !== option);
            setCategory(newValues);
           
          }}

          sx={{ backgroundColor: "#E3F2FD", color: "#1565C0" }}
        />
      ))}
    </Box>
  )}
  label="Category"
  MenuProps={menuProps}
>
<MenuItem disableRipple>
      <TextField
        autoFocus
        placeholder="Search Category..."
        fullWidth
        value={categorySearchTerm}
        onChange={(e) => setCategorySearchTerm(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      />
    </MenuItem>
    {/* Filtered options */}
    {categories.filter((name) =>
      name.toLowerCase().includes(categorySearchTerm.toLowerCase())
    ).length > 0 ? (
      categories
        .filter((name) =>
          name.toLowerCase().includes(categorySearchTerm.toLowerCase())
        )
        .map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={category.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))
    ) : (
      <MenuItem disabled>
        <ListItemText primary="None Found" />
      </MenuItem>
    )}
</Select>


</FormControl>




  {/* --- Risk Level Dropdown (unchanged) --- */}
  <FormControl fullWidth variant="outlined">
    <InputLabel id="risk-label">Risk Level</InputLabel>
    <Select
    ref={riskRefm}
    labelId="risk-label"
    value={riskLevel}
    open={userPermission === 'true'  ? isRiskOpen : false} // Explicitly control open state

    onOpen={() => userPermission && setIsRiskOpen(true)} // Open dropdown if allowed
    onClose={() => setIsRiskOpen(false)} // Close dropdown
    onChange={(e) => userPermission && setRiskLevel(e.target.value)}
    label="Risk Level"
    onMouseDown={(e) => {
      if (userPermission === 'false') {
        e.preventDefault();
        e.stopPropagation();
        riskRefm.current?.blur();
        setGoToSubscribeAlertOpen(true);
      setSubscriptionText(PORTFOLIO_PERFORMANCE_TRACKER_ACCESS);
      }
    }}
    onClick={(e) => {
      if(userPermission === 'false'){
        e.preventDefault();
        e.stopPropagation();
      }
    }}
  >
    {["Low", "Medium", "High"].map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </Select>
  </FormControl>

  {/* --- Institution Name Dropdown (grouped & multi-select) --- */}
  <FormControl fullWidth variant="outlined">
  <InputLabel id="institution-label">Portfolio Names</InputLabel>
  <Select
    ref={portfolioRefm}
    labelId="institution-label"
    multiple
    value={institute || []}
    open={userPermission === 'true'  ? isPortfolioOpen : false} // Explicitly control open state

    onOpen={() => userPermission && setIsPortfolioOpen(true)} // Open dropdown if allowed
    onClose={() => setIsPortfolioOpen(false)} // Close dropdown
    onChange={(e) => userPermission && setInstitute(e.target.value)}
    onMouseDown={(e) => {
      if(userPermission === 'false'){
        e.preventDefault();
        e.stopPropagation();
        portfolioRefm.current?.blur();
        setGoToSubscribeAlertOpen(true);
      setSubscriptionText(PORTFOLIO_PERFORMANCE_TRACKER_ACCESS);
      }
    }}
    onClick={(e) => {
      if(userPermission === 'false') {
        e.preventDefault();
        e.stopPropagation();
      }
    }}
    renderValue={(selected) => (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {selected.map((option) => (
          <Chip
            key={option}
            label={option}
            onMouseDown={(e) => e.stopPropagation()} // Prevents dropdown re-opening
            onDelete={(e) => {
              e.stopPropagation();
              const newValues = institute.filter((item) => item !== option);
              setInstitute(newValues);
              fetchPortfolios(newValues);
            }}
            sx={{ backgroundColor: "#E3F2FD", color: "#1565C0" }}
          />
        ))}
      </Box>
    )}
    label="Portfolio Names"
    MenuProps={menuProps}
  >
    {/* Search input for portfolio names */}
    <MenuItem disableRipple>
      <TextField
        autoFocus
        placeholder="Search Portfolio..."
        fullWidth
        value={institutionSearchTerm}
        onChange={(e) => setInstitutionSearchTerm(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      />
    </MenuItem>

    {/* Filtered options */}
    {institutions.filter((name) =>
      name.toLowerCase().includes(institutionSearchTerm.toLowerCase())
    ).length > 0 ? (
      institutions
        .filter((name) =>
          name.toLowerCase().includes(institutionSearchTerm.toLowerCase())
        )
        .map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={institute.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))
    ) : (
      <MenuItem disabled>
        <ListItemText primary="None Found" />
      </MenuItem>
    )}
  </Select>
</FormControl>



</Box>

{/* --------------------- Fetch Button --------------------- */}
<Button
  variant="contained"
  fullWidth
  color="primary"
  onClick={() => fetchPortfolios()}
  disabled={loading}
>
  {loading ? (
    <CircularProgress size={24} sx={{ color: "#fff" }} />
  ) : (
    "Fetch Portfolios"
  )}
</Button>

{/* --------------------- Error Message --------------------- */}
{error && (
  <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
    {error}
  </Typography>
)}

{/* --------------------- Display Results --------------------- */}
{data && data.length > 0 && (
  <TableContainer
    component={Paper}
    sx={{ mt: 4, borderRadius: 2, boxShadow: 3 }}
  >
    <Table>
      <TableHead>
        <TableRow sx={{ backgroundColor: "#1976d2" }}>
          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
            Sl No
          </TableCell>
          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
            Portfolio Name
          </TableCell>
          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
            7D Returns
          </TableCell>
          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
            30D Returns
          </TableCell>
          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
            90D Returns
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((rec, index) => (
          <TableRow
            key={index}
            sx={{
              "&:nth-of-type(even)": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            <TableCell>{index + 1}</TableCell>
            <TableCell>{rec.portfolio_type}</TableCell>
            <TableCell>{rec.seven_day_roi}%</TableCell>
            <TableCell>{rec.thirty_day_roi}%</TableCell>
            <TableCell>{rec.ninety_day_roi}%</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)}

            </Box>
            
          </div>
        )} 
        {mediaQueryVar === false && (
          <div
            style={{
              position: "fixed",
              bottom: "0",
              backgroundColor: "white",
              width: "105%",
              zIndex: "100",
              marginBottom: "0px",
              marginLeft: "-25px",
            }}
          >
            <BottomNavigation showLabels>
              <BottomNavigationAction
                label="Top Portfolios"
                onClick={() => navigate("/portfolio-gallery")}
                icon={
                  <img
                    src={
                      loc === "/portfolio-gallery"
                        ? "/images/dashboardS.svg"
                        : "/images/dashboardU.svg"
                    }
                    width={"20px"}
                    height={"20px"}
                  />
                }
              />
              <BottomNavigationAction
                label="Crypto Screener"
                onClick={() => navigate("/crypto-screener")}
                icon={
                  <img
                    src={
                      loc === "/crypto-screener"
                        ? "/images/portfolioS.svg"
                        : "/images/portfolioU.svg"
                    }
                    width={"20px"}
                    height={"20px"}
                  />
                }
              />
             <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
            </BottomNavigation>
          </div>
        )}
        
      </div>
    );
  };
  
  export default Portfolio_Performance_Tracker;
  