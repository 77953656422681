const getWisdPresalePrice = (round) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // Construct the API URL
    const apiUrl = `https://crowdwisdom.live/wp-json/api/v1/get_presale_round_api?round=${round}`;

    return fetch(apiUrl, { headers: myHeaders })
        .then((response) => {
            // Check for successful response
            if (!response.ok) {
                console.log("error occur")
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }
            return response.json(); // Parse response as JSON
        })
        .then((result) => {
            // Ensure the result has the expected data
            if (!result || !result.success) {
                return {
                    code: "404",
                    message: "No presale data found for the specified round.",
                };
            }
            return result; // Return the actual result
        })
        .catch((error) => {
            console.error("Error fetching presale price:", error);
            return { code: "500", message: "Internal Server Error" };
        });
};

const saveCryptoPresalePaymentAPI = async (data) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // Construct the API URL
    const apiUrl = `https://crowdwisdom.live/wp-json/portfolio/v1/save_crypto_presale_payment`;

    return fetch(apiUrl, {
        method: "POST", // Specify POST method
        headers: myHeaders,
        body: JSON.stringify(data), // Convert data to JSON format
    })
        .then((response) => {
            if (!response.ok) {
                console.log("Error occurred:", response.status, response.statusText);
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }
            return response.json(); // Parse response as JSON
        })
        .then((result) => {
            if (!result || !result.success) {
                return {
                    code: "404",
                    message: "No presale data found for the specified round.",
                };
            }
            return result;
        })
        .catch((error) => {
            console.error("Error fetching presale price:", error);
            return { code: "500", message: "Internal Server Error" };
        });
};




module.exports = { getWisdPresalePrice, saveCryptoPresalePaymentAPI };