import { Backdrop, Modal, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const SuccessPaymentAlert = ({ open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        padding: "20px",
                        width: "90%",
                        maxWidth: "450px",
                        height: "230px",
                        textAlign: "center",
                        position: "relative", // Needed for close icon positioning
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography variant="h6" fontSize={{ xs: "16px", sm: "18px" }}>
                        Payment Successful 🎉
                    </Typography>
                    <Typography
                        variant="body1"
                        fontSize={{ xs: "14px", sm: "16px" }}
                        style={{ marginTop: "10px" }}
                    >
                        Your payment has been successfully processed!
                    </Typography>
                </div>
            </Backdrop>
        </Modal>
    );
};

export default SuccessPaymentAlert;
