import * as React from "react";
import Divider from "@mui/material/Divider";
import {Link, Navigate} from "react-router-dom";
import List from "@mui/material/List";
import {usePath} from "./PathContext";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import QuizIcon from "@mui/icons-material/Quiz";
import {Button} from "@mui/material";
import "./menulist.css";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import {styled} from "@mui/material/styles";
// import { usePathname } from 'next/navigation';
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {useState, useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import LoginPopUp from "../pages/loginpopup";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import SecurityIcon from "@mui/icons-material/Security";
import "./common_sidebar.css";
// const ASSET_PATH = process.env.PUBLIC_URL;
function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

function MenuList(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const {currentPath, setCurrentPath} = usePath();

    useEffect(() => {
        // Set the current path when the component mounts
        setCurrentPath(window.location.pathname);
    }, [setCurrentPath]);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        localStorage.removeItem("cw_portfolio_user");

        if (window.location.pathname === "/portfolio-gallery") {
            // If the user is already on portfolio-gallery, refresh the page
            window.location.reload(); // This will refresh the page to reflect the logout
        } else {
            // Navigate to portfolio-gallery if the user is on a different page
            navigate("/portfolio-gallery", {replace: true});
        }

        setOpen(false);
    };

    const handleCloseCancle = () => {
        setOpen(false);
    };
    const [snackbaropen, setSnackbaropen] = React.useState(false);
    const [isHover, setIsHover] = useState(false);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const StyledList = styled(List)({
        "&& .Mui-selected, && .Mui-selected:hover": {
            background: "linear-gradient(90deg, #1877F2 0%, #519DFF 100%)",
            color: "white",
            fontWeight: "bold !important",
            "&, & .MuiListItemIcon-root": {
                color: "white",
                fontWeight: "bold",
                borderRadius: "10px",
            },
        },
        "& .MuiListItemButton-root:hover": {
            background: "linear-gradient(90deg, #1877F2 0%, #519DFF 100%)",
            color: "white",
            fontWeight: "bold !important",
            "&, & .MuiListItemIcon-root": {
                color: "white",
                fontWeight: "bold",
                borderRadius: "10px",
            },
        },
    });

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const closesnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbaropen(false);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const pathName = useLocation().pathname;
    const isActive = (route) => {
        return pathName.startsWith(`/${route}`);
    };

    const handleListItemClick = (path) => {
        // router.push(`/${path}`);
        // localStorage.removeItem("cw_portfolio_user")
        navigate(`/${path}`, {replace: true});
    };
    const hangleLogOutClick = (path) => {
        localStorage.removeItem("cw_portfolio_user");
        navigate(`/${path}`, {replace: true});
    };

    const isDesktop = window.innerWidth > 900; 

    const menuItems = [
        {
            name: userData ? "My Portfolios" : "Portfolio Audit",
            icon: `${window.constants.asset_path}/images/portfolioicon.svg`,
            hoverIcon: `${window.constants.asset_path}/images/activeportfolioicon.svg`,
            activeIcon: `${window.constants.asset_path}/images/activeportfolioicon.svg`,
            route: userData ? "my-portfolio" : "audit-my-portfolio",
        },
        {
            name: "Top Portfolios",
            icon: `${window.constants.asset_path}/images/createicon.svg`,
            hoverIcon: `${window.constants.asset_path}/images/activecreateicon.svg`,
            activeIcon: `${window.constants.asset_path}/images/activecreateicon.svg`,
            route: "portfolio-gallery",
        },
        {
            name: "Indices",

            icon: `${window.constants.asset_path}/images/createicon.svg`,
            hoverIcon: `${window.constants.asset_path}/images/activecreateicon.svg`,
            activeIcon: `${window.constants.asset_path}/images/activecreateicon.svg`,
            route: "indices",
        },
        {
            name: "Crypto Screener",

            icon: `${window.constants.asset_path}/images/privacyicon.svg`,
            hoverIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,

            activeIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,
            route: "crypto-screener",
        },
        {
            name: "Crypto Predictions",

            icon: `${window.constants.asset_path}/images/privacyicon.svg`,
            hoverIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,

            activeIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,
            route: "crypto-predictions",
        },

        ...(isDesktop
            ? [
                  {
                      name: "Watchlist",
                      icon: `${window.constants.asset_path}/images/privacyicon.svg`,
                      hoverIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,
                      activeIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,
                      route: "crypto-watchlist",
                  },
              ]
            : []),
        {
            name: "Subscriptions",

            icon: `${window.constants.asset_path}/images/privacyicon.svg`,
            hoverIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,

            activeIcon: `${window.constants.asset_path}/images/activeprivacyicon.svg`,
            route: "subscriptions",
        },

        {
            name: "Whitepaper",

            icon: `${window.constants.asset_path}/images/whitepaper.svg`,
            hoverIcon: `${window.constants.asset_path}/images/whitepaper.svg`,

            activeIcon: `${window.constants.asset_path}/images/whitepaper.svg`,
            route: "whitepaper",
        },
        {
            name: "Influencer Rankings",

            icon: `${window.constants.asset_path}/images/accounticon.svg`,
            hoverIcon: `${window.constants.asset_path}/images/accounticon.svg`,

            activeIcon: `${window.constants.asset_path}/images/accounticon.svg`,
            route: "crypto-influencer-rankings",
        },
        {
            name: "Predict Now",

            icon: `${window.constants.asset_path}/images/faqicon.svg`,
            hoverIcon: `${window.constants.asset_path}/images/activefaqicon.svg`,

            activeIcon: `${window.constants.asset_path}/images/activefaqicon.svg`,
            route: "predict-now",
        },
        {
            name: "FAQs",

            icon: `${window.constants.asset_path}/images/faqicon.svg`,
            hoverIcon: `${window.constants.asset_path}/images/activefaqicon.svg`,

            activeIcon: `${window.constants.asset_path}/images/activefaqicon.svg`,
            route: "portfoliofaqs",
        },
        {
            name: "Profile",
            icon: `${window.constants.asset_path}/images/accounticon.svg`,
            hoverIcon: `${window.constants.asset_path}/images/activeaccounticon.svg`,
            activeIcon: `${window.constants.asset_path}/images/activeaccounticon.svg`,
            route: "account",
        },
    ];

    const menuItems2 = [
        {
            name: "Contact Us",
            icon: `${window.constants.asset_path}/images/icons/contact-us.svg`,
            hoverIcon: `${window.constants.asset_path}/images/icons/contact-us.svg`,
            activeIcon: `${window.constants.asset_path}/images/icons/contact-us.svg`,
            route: "contactus",
        },
        {
            name: "About Us",
            icon: `${window.constants.asset_path}/images/icons/about-us.svg`,
            hoverIcon: `${window.constants.asset_path}/images/icons/about-us.svg`,
            activeIcon: `${window.constants.asset_path}/images/icons/about-us.svg`,
            route: "about-us",
        },
        {
            name: "Privacy Policy",
            icon: `${window.constants.asset_path}/images/icons/privacy.svg`,
            hoverIcon: `${window.constants.asset_path}/images/icons/privacy.svg`,
            activeIcon: `${window.constants.asset_path}/images/icons/privacy.svg`,
            route: "privacyPolicy",
        },
        {
            name: "Terms of Use",
            icon: `${window.constants.asset_path}/images/icons/term-condition.svg`,
            hoverIcon: `${window.constants.asset_path}/images/icons/term-condition.svg`,
            activeIcon: `${window.constants.asset_path}/images/icons/term-condition.svg`,
            route: "termofuse",
        },
    ];

    const [isLoginModalOpen, setLoginModalOpen] = useState(false);

    const handleLoginClick = () => {
        // Add /login to the URL without navigating
        window.history.pushState({}, "", "/login");
        localStorage.setItem("isPopupManuallyOpened", "true");
        // Open the login modal
        setLoginModalOpen(true);
    };

    const handleModalClose = () => {
        // Close the modal and reset the URL to the current page
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    };

    return (
        <div className="mai_div_menu" style={{backgroundColor: "white"}}>
            {isLoginModalOpen && (
                <LoginPopUp
                    isLoginModalOpen={isLoginModalOpen}
                    setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                    onClose={handleModalClose}
                />
            )}
            <div
                className="frameWrapper_menulist"
                style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "white",
                    paddingTop: "10px",
                    paddingBottom: "20px",
                }}
            >
                <a href="/">
                    <img
                        className="frameChild_menulist"
                        alt=""
                        src={window.constants.asset_path + "/images/logo_with_bg.png"}
                        style={{width: "180px", height: "40px", marginBottom: "25px", marginTop: "7px"}}
                    />
                </a>
            </div>
            <div style={{display: "flex", flexDirection: "column"}} className="sidebarMenu">
                <StyledList>
                    <List
                        component="nav"
                        aria-label="secondary mailbox folder"
                        className="sideMenuList scrollable-list sideBarTopMenu"
                    >
                        {menuItems.map(({name, icon, hoverIcon, activeIcon, route}) => {
                            const active = isActive(route);

                            const handleMenuClick = (route) => {
                                if (route === "account") {
                                    const userData = localStorage.getItem("cw_portfolio_user");
                                    if (!userData) {
                                        handleLoginClick(); // Trigger login modal if user data is missing
                                        return;
                                    }
                                }
                                navigate(`/${route}`);
                            };

                            return (
                                <ListItem
                                    disablePadding
                                    key={route}
                                    style={{
                                        paddingLeft: "26px",
                                        fontSize: "16px",
                                        fontFamily: "Satoshi, sans-serif",
                                        lineHeight: "24px",
                                        fontWeight: "500",
                                        color: "black",
                                        backgroundColor: "white",
                                        marginTop: "3px",
                                    }}
                                    component="div"
                                    onClick={() => handleMenuClick(route)}
                                >
                                    <ListItemButton
                                        className={active ? "activeMenu" : ""}
                                        style={{
                                            borderRadius: "10px",
                                            marginRight: "30px",
                                            paddingTop: "9px",
                                            paddingBottom: "9px",
                                            paddingLeft: "18px",
                                            paddingRight: "0px",
                                        }}
                                    >
                                        <ListItemIcon>
                                            <img
                                                className="frameIcon"
                                                alt=""
                                                src={active ? activeIcon : icon}
                                                style={{marginRight: "-10px", right: 0}}
                                            />
                                        </ListItemIcon>

                                        <span
                                            style={{
                                                fontSize: "15px",
                                                fontWeight: "540",
                                                fontFamily: "Satoshi, sans-serif",
                                                marginLeft: "-23px",
                                                letterSpacing: "0.7px",
                                            }}
                                        >
                                            {name}
                                        </span>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </StyledList>

                {/* Pushes the bottom list to the bottom */}
                <div className="sideMenuBottomList">
                    <hr style={{margin: "0px", marginBottom: "10px"}} />
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Button
                            aria-label="add"
                            onClick={() => handleClickOpen()}
                            className="logout"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            style={{
                                background: "linear-gradient(90deg, #1877F2 0%, #519DFF 100%)",
                                padding: "5px 0px",
                                fontWeight: "bold",
                                width: "82%",
                                textTransform: "none",
                                fontSize: "16px",
                                color: "white",
                                marginTop: "2px",
                                borderRadius: "10px",
                            }}
                        >
                            <img
                                className="framelogout"
                                alt=""
                                style={{marginRight: "8px"}}
                                src={window.constants.asset_path + "/images/logout.svg"}
                            />{" "}
                            <span style={{fontSize: "14px"}}>Log Out</span>
                        </Button>
                    </div>

                    {/* Bottom List */}
                    <StyledList>
                        <List
                            sx={{margin: 0, padding: 0}}
                            component="nav"
                            aria-label="secondary mailbox folder"
                            className="sideMenuList"
                        >
                            {menuItems2.map(({name, icon, hoverIcon, activeIcon, route}) => {
                                const active = isActive(route);

                                const handleMenuClick = (route) => {
                                    navigate(`/${route}`);
                                };

                                return (
                                    <ListItem
                                        disablePadding
                                        key={route}
                                        style={{
                                            padding: "0px 0px",
                                            paddingLeft: "26px",
                                            fontSize: "16px",
                                            fontFamily: "Satoshi, sans-serif",
                                            lineHeight: "24px",
                                            fontWeight: "500",
                                            color: "black",
                                            backgroundColor: "white",
                                            marginTop: "3px",
                                        }}
                                        component="div"
                                        onClick={() => handleMenuClick(route)}
                                    >
                                        <ListItemButton
                                            className={active ? "activeMenu" : ""}
                                            style={{
                                                borderRadius: "10px",
                                                marginRight: "30px",
                                                paddingLeft: "18px",
                                                paddingRight: "0px",
                                            }}
                                        >
                                            <ListItemIcon>
                                                <img
                                                    className="frameIcon"
                                                    alt=""
                                                    src={active ? activeIcon : icon}
                                                    style={{marginRight: "-10px", right: 0}}
                                                />
                                            </ListItemIcon>

                                            <span
                                                style={{
                                                    fontSize: "13px",
                                                    fontWeight: "540",
                                                    fontFamily: "Satoshi, sans-serif",
                                                    marginLeft: "-23px",
                                                    letterSpacing: "0.7px",
                                                }}
                                            >
                                                {name}
                                            </span>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </StyledList>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{cursor: "move"}} id="draggable-dialog-title">
                    Logout
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure to logout?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseCancle}>
                        Cancel
                    </Button>
                    <Button onClick={handleClose}>Logout</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default MenuList;
