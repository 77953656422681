import {Drawer, IconButton, useMediaQuery} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {Link, useNavigate} from "react-router-dom";
import LoadingScreen from "../../pages/loadingscreen";
import {completeGoogleLogin} from "../../apihelper/login";
import {storeReferrerData} from "../../apihelper/profile";
import json2mq from "json2mq";
import {Box} from "@mui/system";
import LoginPopUp from "../../pages/loginpopup";
import HomeSidebarMenuList from "../../includes/homesidebar";

const drawerWidth = 292;
const Header = (props) => {
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);

    const handleLoginClick = () => {
        // Mask the URL by adding /login at the end without navigating
        window.history.pushState({}, "", "/login");

        // Mark that the popup was opened manually
        localStorage.setItem("isPopupManuallyOpened", "true");
        const isPopupManuallyOpened = localStorage.getItem("isPopupManuallyOpened");

        // Open the login modal
        setLoginModalOpen(true);
    };

    const handleModalClose = () => {
        setLoginModalOpen(false);
        // navigate("/", {replace: true});
    };
    const [loadingForLogin, setLoadingForLogin] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkLoginStatus = async () => {
            // Retrieve portfolio user from localStorage once
            const portfolioUser = localStorage.getItem("cw_portfolio_user");

            if (portfolioUser) {
                setIsLoggedIn(true); // Clear after use
            } else {
                // Handle URL search only once
                const search = window.location.search;
                const params = new URLSearchParams(search);
                const googleLoginCode = params.get("code");

                if (googleLoginCode) {
                    setLoadingForLogin(true); // Show loading screen during login

                    // Attempt to complete Google login only once
                    try {
                        const data = await completeGoogleLogin(googleLoginCode);
                        if (data.code === "200") {
                            localStorage.setItem("cw_portfolio_user", JSON.stringify(data.data));

                            const referrerId = localStorage.getItem("referrerId");

                            if (referrerId) {
                                try {
                                    // Make an API call to store referrer-referee relationship
                                    await storeReferrerData(referrerId, data.data.user_id, data.state); // Use logged-in user's ID

                                    // Clear referrerId from localStorage after storing
                                    localStorage.removeItem("referrerId");
                                    window.location.replace("/account");
                                } catch (error) {
                                    console.error("Error storing referrer data:", error);
                                    // Handle error if storing referrer data fails
                                }
                            }
                            setIsLoggedIn(true);

                            let originalUrl = sessionStorage.getItem("originalUrl") || "/portfolio-gallery";
                            if (originalUrl === "/login" || originalUrl === "/") {
                                originalUrl = "/portfolio-gallery";
                            }
                            if (originalUrl.includes("/signUp?ref")) {
                                originalUrl = "/account";
                            }
                            window.location.replace(originalUrl); // Direct replacement
                            sessionStorage.removeItem("originalUrl"); // Clear after use
                        } else {
                            console.error("Google login failed");
                            // Handle login failure logic here
                        }
                    } catch (error) {
                        console.error("Error completing Google login:", error);
                        // Handle error during login process
                    }
                }
            }
        };

        checkLoginStatus();
    }, [navigate, completeGoogleLogin, setLoadingForLogin]);

    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );
    const {window1} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const drawer = (
        <div className="d-flex flex-column h-100" >
            <HomeSidebarMenuList />
        </div>
    );

    const container = window1 !== undefined ? () => window1().document.body : undefined;

    const handleLogout = () => {
        localStorage.removeItem("cw_portfolio_user");
        setIsLoggedIn(false); // Update login status
        // navigate("/", {replace: true}); // Redirect to home page
    };
    return (
        <>
            {loadingForLogin && <LoadingScreen />}
            {mediaQueryVar === true ? (
                <div style={{fontFamily: "Satoshi, Arial, sans-serif", width: "100%", overflow: "hidden"}}>
                    {isLoginModalOpen && (
                        <LoginPopUp
                            isLoginModalOpen={isLoginModalOpen}
                            setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                            onClose={()=>handleModalClose()}
                        />
                    )}

                    <div className="header">
                        <img
                            src="images/logo_with_bg.png"
                            width={180}
                            height={40}
                            alt="Crowd Wisdom 360"
                            className="logo"
                        />
                        <div className="nav-links">
                            <Link to="/portfolio-gallery">Portfolio Gallery</Link>
                            <Link to="/portfoliofaqs">FAQs</Link>
                            <Link to="/contactus">Contact us</Link>
                            <Link to="/whitepaper">Whitepaper</Link>
                        </div>
                        {isLoggedIn ? (
                            <button className="connect-wallet" onClick={() => handleLogout()}>
                                <span style={{color: "white", textDecoration: "none"}}>Logout</span>
                            </button>
                        ) : (
                            <button
                                className="connect-wallet"
                                onClick={() => {
                                    const originalUrl = window.location.pathname + window.location.search;
                                    sessionStorage.setItem("originalUrl", originalUrl);
                                    handleLoginClick();
                                }}
                            >
                                <span style={{color: "white", textDecoration: "none"}}>Login/Signup</span>
                            </button>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <Helmet>
                        <title>Best Crypto Portfolios</title>
                    </Helmet>

                    <Box
                        component="nav"
                        sx={{
                            width: "100%", // Take full width on mobile
                        }}
                        aria-label="mailbox folders"
                    >
                        <Drawer
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            sx={{
                                "& .MuiDrawer-paper": {
                                    width: drawerWidth, // Adjust width for mobile
                                    backgroundColor: "white",
                                    color: "#727376",
                                },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            padding: 0,
                            margin: 0,
                            backgroundColor: "white",
                            // Allow scrolling
                        }}
                        className="maindiv"
                    >
                        {/* Include the LoginModal and pass the necessary props */}

                        <div >
                            <div
                                className="header"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "10px",
                                    background: "#fff",
                                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                    paddingBottom: "30px",
                                    paddingTop: "20px",
                                }}
                            >
                                <img src="images/logo_with_bg.png" width={180} height={40} alt="Crowd Wisdom 360" />
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerToggle}
                                    sx={{display: {sm: "none"}}}
                                >
                                    <img
                                        src={window.constants.asset_path + "/images/menubar.svg"}
                                        alt="Menu Icon"
                                        style={{width: "24px", height: "24px", marginRight: "17px"}}
                                    />
                                </IconButton>
                            </div>

                        </div>
                    </Box>
                </>
            )}
        </>
    );
};

export default Header;
