import Slider from "@mui/material/Slider";
import PropTypes from "prop-types";
import React from "react";

const RHSlider = ({
  className = "",
  min = { value: 0, label: "$0" },
  max = { value: 100, label: "$100" },
  value =  { value: 59, label: "$59" },
  showValueLabel = true,
  fixed = false
}) => {
  // Create the marks array with min and max
  const marks = [
    {
      value: min.value,
      label: min.label,
    },
    {
      value: max.value,
      label: max.label,
    },
    {
      value: value.value,
      label: value.label,
    },
  ];

  return (
    <div className={className}>
      <Slider
        aria-label="Slider"
        valueLabelDisplay={showValueLabel ? "auto" : "off"} // Conditionally show the value label
        valueLabelFormat={(value) => `${value}`} // Format value label to show the correct value with $
        marks={marks}
        min={min.value} // Set minimum value
        max={max.value} // Set maximum value
        value={value.value} // Use the current value for the slider
      />
    </div>
  );
};

// Prop validation
RHSlider.propTypes = {
  className: PropTypes.string,
  min: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  max: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  value: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }), // Updated to expect a single number
  showValueLabel: PropTypes.bool,
};

export default RHSlider;
