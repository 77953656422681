import {
    Chart as ChartJS,
    CategoryScale,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import React, { useRef } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PortfolioChart = ({ chartData, selectedPortfolio, roiData, isLoggedIn }) => {
    const chartRef = useRef(null);
    const selectedChartData = chartData[selectedPortfolio];
    const isMobile = window.innerWidth <= 768;

    console.log("roiData ", roiData, chartData, selectedChartData, selectedPortfolio)

    const getChartOptions = (isMobile) => ({
        scales: {
            x: {
                title: {
                    display: true,
                    text: "",
                    font: { size: isMobile ? 10 : 14, family: "Arial", weight: "bold" },
                    color: isLoggedIn ? "#4A4A4A" : "rgba(0, 0, 0, 0.1)", // Blur effect
                },
                ticks: {
                    font: { size: isMobile ? 8 : 12, family: "Arial" },
                    color: isLoggedIn ? "#4A4A4A" : "rgba(0, 0, 0, 0.1)", // Blur effect
                    autoSkip: true,
                    maxTicksLimit: Math.floor(isMobile ? 7 : 14),
                },
                grid: { display: false },
            },
            y: {
                title: {
                    display: true,
                    text: "Index Value",
                    font: { size: isMobile ? 10 : 14, family: "Arial", weight: "bold" },
                    color: isLoggedIn ? "#4A4A4A" : "rgba(0, 0, 0, 0.1)", // Blur effect
                },
                ticks: {
                    font: { size: isMobile ? 8 : 12, family: "Arial" },
                    color: isLoggedIn ? "#4A4A4A" : "rgba(0, 0, 0, 0.1)", // Blur effect
                    stepSize: isMobile ? 25 : 50,
                },
                grid: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.05)",
                },
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: "top",
                labels: {
                    font: {
                        family: "Arial",
                        size: isMobile ? 12 : 14,
                    },
                    color: isLoggedIn ? "#4A4A4A" : "rgba(0, 0, 0, 0.1)", // Blur effect
                },
            },
            title: {
                display: true,
                font: { size: isMobile ? 16 : 20, family: "Arial", weight: "bold" },
                color: isLoggedIn ? "#333333" : "rgba(0, 0, 0, 0.1)", // Blur effect
            },
            tooltip: isLoggedIn
                ? {
                      enabled: true,
                      callbacks: {
                          label: function (tooltipItem) {
                              return `Index Value: ${tooltipItem.raw}`;
                          },
                      },
                      backgroundColor: "#ffffff",
                      titleFont: { family: "Arial", size: 14 },
                      bodyFont: { family: "Arial", size: 12 },
                      borderColor: "#333",
                      borderWidth: 1,
                      titleColor: "#4A4A4A",
                      bodyColor: "#4A4A4A",
                  }
                : false, // Disable tooltips when not logged in
        },
        elements: {
            point: {
                radius: 0, // Remove dots
            },
            line: {
                tension: 0.5, // Smooth the line further
                backgroundColor: "#7e7ef7",
            },
        },
        interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
        },
    });

    return (
        <div className={`${!isLoggedIn ? "blurred-graph" : ""} h-100`}>
            <Line
                ref={chartRef}
                data={{
                    labels: selectedChartData ? selectedChartData.dates : [],
                    datasets: [
                        {
                            label:
                                selectedChartData && roiData[selectedPortfolio]
                                    ? roiData[selectedPortfolio]?.portfolio_name + " Index"
                                    : "Memecoin Index",
                            data: selectedChartData ? selectedChartData.values : [],
                            fill: true,
                            borderColor: "#7e7ef7",
                            backgroundColor: "#7e7ef7",
                            borderWidth: 2,
                        },
                    ],
                }}
                options={getChartOptions(isMobile)}
            />
        </div>
    );
};

export default PortfolioChart;
