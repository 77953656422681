import React, {useEffect, useMemo, useState} from "react";
import RHSlider from "../../../common/hooks/slider/RHSlider";
import {getPresalePriceData, handleStripeCheckout} from "./api";
import SOLConnectButton from "../connect_wallet/SOLConnectButon";
import BNBConnectButton from "../connect_wallet/BNBConnectButton";
import ETHConnectButton from "../connect_wallet/ETHConnectButton";
import { dollerToWisd } from "../helper";
import ETHConnect2 from "../connect_wallet/ETHConnect2";


export default function PresaleForm() {
    const [currentPrice, setCurrentPrice] = useState(null);
    const [nextStagePrice, setNextStagePrice] = useState(null);
    const [selectedAmount, setSelectedAmount] = useState("");
    const [selectedCryptoPrice, setSelectedCryptoPrice] = useState();
    const [paymentMethod, setPaymentMethod] = useState("card");
    const [wisdCoins, setWisdCoins] = useState();

    useEffect(() => {
        Promise.all([getPresalePriceData(1), getPresalePriceData(2)])
        .then(([data1, data2]) => {
            setCurrentPrice(data1);
            setNextStagePrice(data2);
        })
        .catch((error) => console.error("Error fetching current and next stage prices:", error));
    }, []);

    const handleAmountChange = (value) => {
        setSelectedAmount(value);
        for (let i = 0; i < priceList.length; i++) {
            if (priceList[i].stripePriceId === value) {
                setWisdCoins(dollerToWisd(Number(priceList[i].price)))
                setSelectedCryptoPrice(Number(priceList[i].price));
                break;
            }
        }
    
    };

    const handlePaymentMethodChange = (tabNum) => {
        setPaymentMethod(tabNum);
    };

    const handleCheckout = () => {
        if (!selectedAmount) {
            alert("Please select an amount");
            return;
        }

        if (paymentMethod === 1) {
            alert("Work in progress");
        } else {
            handleStripeCheckout(selectedAmount);
        }
    };

    const priceList = [
        {price: "20", stripePriceId: "price_1QiAbkKAXtGoEI6mt5oRDjcc"},
        {price: "50", stripePriceId: "price_1QiAfDKAXtGoEI6moqhBiev3"},
        {price: "99", stripePriceId: "price_1QiAfsKAXtGoEI6m0nX6khu2"},
    ]

    const priceOptions = useMemo(
        () =>
            priceList.map((amount, index) => (
                <option key={index} value={amount.stripePriceId}>
                    ${amount.price}
                </option>
            )),
        []
    );

    const sliderValues = useMemo(
        () => ({
            minValue: {value: 0, label: "$0"},
            maxValue: {value: 100000, label: "$100000"},
            value: {value: 35000, label: "$35000"},
        }),
        []
    );

    return (
        <div className="d-flex justify-content-center align-items-center my-5 presale-form">
            <div className="main-content col-md-8">
                <div className="secondary-main">
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <div className="card rounded shadow d-flex justify-content-center align-items-center">
                            <div className="heading text-center">
                                <h1 className="d-inline-block btn-theme-bg">CrowdWisdom Presale Stage 1</h1>
                            </div>
                            <div className="price-div mt-4">
                                <p>
                                    1 $WISD ={" "}
                                    <span>
                                        {currentPrice
                                            ? `$${currentPrice.data?.[0]?.presale_price || "Not known"}`
                                            : "Fetching..."}
                                    </span>
                                </p>
                                <p>
                                    Next Stage ={" "}
                                    <span>
                                        {nextStagePrice
                                            ? `$${nextStagePrice.data?.[0]?.presale_price || "Not known"}`
                                            : "Fetching..."}
                                    </span>
                                </p>
                            </div>
                            <div className="cypto-btn">
                                <button
                                    onClick={() => handlePaymentMethodChange("eth")}
                                    className={`btn-theme-bg ${paymentMethod === "eth" ? "active" : ""}`}
                                >
                                    <img src="images/icons/ethereum_icon.png" alt="crypto-img" />
                                    <span className="button-text">ETH</span>
                                </button>
                                <button
                                    onClick={() => handlePaymentMethodChange("sol")}
                                    className={`btn-theme-bg ${paymentMethod === "sol" ? "active" : ""}`}
                                >
                                    <img src="images/icons/solana-sol-logo.svg" alt="crypto-img" />
                                    <span className="button-text">SOL</span>
                                </button>
                                <button
                                    onClick={() => handlePaymentMethodChange("bnb")}
                                    className={`btn-theme-bg ${paymentMethod === "bnb" ? "active" : ""}`}
                                >
                                    <img src="images/icons/bnb-bnb-logo.svg" alt="crypto-img" />
                                    <span className="button-text">BNB</span>
                                </button>
                                <button
                                    onClick={() => handlePaymentMethodChange("card")}
                                    className={`btn-theme-bg ${paymentMethod === "card" ? "active" : ""}`}
                                >
                                    <img src="images/temp_images/image-1.png" alt="crypto-img" />
                                    <span className="button-text">CARD</span>
                                </button>
                            </div>
                            <div className="input-div-main">
                                <div className="input-div row">
                                    <label htmlFor="you-pay-amount" className="col-8">
                                        {paymentMethod !== "card" ? "Amount You Pay" : "Amount You Pay"}
                                    </label>
                                    <div className="select-container col-4">
                                        <select
                                            onChange={(e) => handleAmountChange(e.target.value)}
                                            value={selectedAmount}
                                            className="btn-theme-bg"
                                        >
                                            <option>Select</option>
                                            {priceOptions}
                                        </select>
                                    </div>
                                </div>
                                <div className="input-div row mt-3">
                                    <label htmlFor="you-pay-amount" className="col-8">
                                        Amount In WISD You Receive
                                    </label>
                                    <div className="col-4">
                                        <input className="col-4 btn-theme-bg" type="text" value={wisdCoins} readOnly/>
                                    </div>
                                </div>
                            </div>

                            <div className="connect-wallet-btn mt-3 d-flex justify-content-center">
                                {paymentMethod === "card" ? (
                                    
                                    <button onClick={handleCheckout}>
                                        <img src="images/icons/wallet.svg" alt="crypto-img" />
                                        <span className="button-text">Pay with card</span>
                                    </button>
                                ) :
                                paymentMethod === "sol" ? (
                                    <>
                                        <SOLConnectButton paymentMethod={paymentMethod} select_price={selectedCryptoPrice}/>   
                                    </>
                                ):
                                paymentMethod === "eth" ? (
                                    <>
                                        <ETHConnect2 paymentMethod={paymentMethod} select_price={selectedCryptoPrice}/>   
                                    </>
                                ):
                                paymentMethod === "bnb" ? (
                                    <>
                                        <BNBConnectButton paymentMethod={paymentMethod} select_price={selectedCryptoPrice}/>   
                                    </>
                                ): null}
                            </div>
                            <div className="text-info-div mt-4">
                                <div className="info-div">
                                    <p>Amount Raised For Presale</p>
                                    <p>USD Raised: </p>
                                    <p>Token Sold: </p>
                                </div>
                            </div>

                            <RHSlider
                                className="slider"
                                min={sliderValues.minValue}
                                max={sliderValues.maxValue}
                                value={sliderValues.value}
                                showValueLabel={true}
                                fixed= {true}
                            />
                        </div>
                    </div>
                    <div className="bottom-btn mt-3">
                        <button>
                            <span className="button-text">Whitepaper</span>
                        </button>
                        <button>
                            <span className="button-text">How To Buy</span>
                        </button>
                    </div>
                    <div className="bottom-signup-btn container mt-4">
                        <button>
                            <img src="images/icons/signup-arrow.png" alt="crypto-img" />
                            <span className="button-text">Sign Up For Listing Updates</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
