import React, { Suspense, lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const LazyImage = ({ src, alt }) => {
  const ImageComponent = lazy(() =>
    new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve({ default: () => <img src={src} alt={alt} /> });
    })
  );

  return (
    <Suspense fallback={<div style={{ width: "24px", height: "24px", background: "#ccc" }}></div>}>
      <ImageComponent />
    </Suspense>
  );
};

const Card = ({ user }) => {
  const { image = "default-image.jpg", name, domain, about, facebook, twitter, dribble, github } = user;

  return (
    <div className="position-relative col-sm-12 col-md-6 col-lg-6 col-xl-4 p-lg-3 p-md-2 p-4 ">
      <div className="p-3 border-0 rounded-3 shadow-sm " style={{ cursor: "pointer", backgroundColor: "rgba(255, 225, 68, 0.5)" }}>
        <div
          className="card-img-top position-relative"
          style={{
            background: `url(${image}) no-repeat center center / cover`,
            height: "0",
            paddingBottom: "100%",
            borderRadius: "0.25rem",
          }}
        ></div>
        <div className="card-body p-3">
          <h5 className="card-title fw-bold" style={{ fontSize: "1rem" }}>
            {name}
          </h5>
          <p className="card-text text-secondary" style={{ fontSize: "14px" }}>
            {domain}
          </p>
          <p className="card-text text-secondary text-muted text-wrap" style={{ fontSize: "13px" }}>
            {about}
          </p>
          <div className="d-flex justify-content-start gap-3">
            <a href={facebook} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <LazyImage src="images/icons/social_icons/facebook-f-brands-solid.svg" alt="Facebook Icon" />
            </a>
            <a href={twitter} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <LazyImage src="images/icons/social_icons/twitter-brands-solid.svg" alt="Twitter Icon" />
            </a>
            <a href={dribble} target="_blank" rel="noopener noreferrer" aria-label="Dribbble">
              <LazyImage src="images/icons/social_icons/dribbble-brands-solid.svg" alt="Dribbble Icon" />
            </a>
            <a href={github} target="_blank" rel="noopener noreferrer" aria-label="GitHub">
              <LazyImage src="images/icons/social_icons/github-brands-solid.svg" alt="GitHub Icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
