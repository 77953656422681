import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Config, cookieToInitialState, WagmiProvider } from 'wagmi';
import { createAppKit } from '@reown/appkit/react';
import { ethersAdapter, metadata, networks, projectId, solanaWeb3JsAdapter, wagmiAdapter } from './common/appkitConfig.tsx';
const queryClient = new QueryClient()

const generalConfig = {
  projectId,
  metadata,
  networks,
  themeMode: 'light' as const,
  features: {
    email: true, // default to true
    socials: [],
    emailShowWallets: false, // default to true
  },
  themeVariables: {
    '--w3m-accent': '#494949',
  }
};
console.log("Available networks:", networks);



// Create modal
// createAppKit({
//   adapters: [wagmiAdapter, solanaWeb3JsAdapter],
//   ...generalConfig,
// })

const appKit = createAppKit({
  adapters: [ethersAdapter, solanaWeb3JsAdapter],
  networks: networks,
  metadata,
  projectId,
  features: {
    analytics: true,
    socials: [],
    email: false,
  }
})

console.log("modal", appKit)


// AppKit Provider Component
export function ReownAppKitProvider({ children, cookies }: { children: ReactNode; cookies: string | null }) {
  const initalState = cookieToInitialState(wagmiAdapter.wagmiConfig as Config, cookies)

  return (
    
    <WagmiProvider config={wagmiAdapter.wagmiConfig as Config} initialState={initalState}>
        <QueryClientProvider client={queryClient}>
          {children}
          {/* {modal} */}
          </QueryClientProvider>
    </WagmiProvider>
  );
}