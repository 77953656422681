import React from "react";
import "./footer.css";

const Footer = ({className = ''}) => {
    return (
        <div className={`p-3 footer-container ${className}`}>
            <div className="row text-secondary">
                {/* Left-aligned copyright text */}
                <div
                    className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start align-items-center mt-4"
                    style={{height: "100%"}}
                >
                    <div>© Crowdwisdom360 2024 All rights reserved</div>
                </div>

                {/* Right-aligned links */}
                <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end align-items-center footer-links mt-4">
                    <a href="/contactus" className="footer-link-text mx-1 pe-2 text-secondary">
                        Contact Us
                    </a>
                    <a href="/about-us" className="footer-link-text mx-1 pe-2 text-secondary">
                        About Us
                    </a>
                    <a href="/privacyPolicy" className="footer-link-text mx-1 pe-2 text-secondary">
                        Privacy Policy
                    </a>
                    <a href="/termofuse" className="mx-1 text-secondary">
                        Terms of Use
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
