import {WidthFull} from "@mui/icons-material";
import React from "react";

const RoadMap = () => {
    return (
        <div className="container mt-5 px-3">
            <div>
                <h1 className="theme-bg shadow sub-heading">Roadmap</h1>
            </div>
            <div className="row  mt-5">
                <div className="col-5">
                    <div>
                        <div className="number">
                            <p>1</p>
                        </div>
                        <div className="odd-card" style={{Width: "40%", height: "240px"}}></div>
                    </div>
                    <div>
                        <div className="number" style={{marginTop: "240px"}}>
                            <p>3</p>
                        </div>

                        <div className="odd-card" style={{Width: "40%", height: "240px"}}></div>
                    </div>
                </div>
                <div className="col-2 bg-grey d-flex justify-content-center">
                    <div className=" mid-div theme-bg"></div>
                </div>
                <div className="col-5 ">
                    <div style={{marginTop: "240px"}}>
                        <div className="number" style={{marginTop: "240px"}}>
                            <p>2</p>
                        </div>

                        <div className="even-card" style={{Width: "40%", height: "240px"}}></div>
                    </div>

                    <div style={{marginTop: "240px"}}>
                        <div className="number" style={{marginTop: "240px"}}>
                            <p>4</p>
                        </div>

                        <div className="even-card" style={{Width: "40%", height: "240px"}}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoadMap;
