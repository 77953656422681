import React, {useEffect, useCallback, useState} from "react";
import TableHook from "./TableHook";
import TableCardHook from "./TableCardHook";
import {getTop7DayPerformerGainerLooserAPI} from "../../../../apihelper/profile";
import {Skeleton, useMediaQuery} from "@mui/material";
import json2mq from "json2mq";
import {useNavigate} from "react-router-dom";

export const Day7SummaryTable = () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const [loading, setLoading] = useState(false);
    const [my7DaysPortfolio, setMy7DaysPortfolio] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();

    const fetchData = useCallback(() => {
        if (!userData) {
            console.error("User data not available");
            return;
        }
        setLoading(true);
        getTop7DayPerformerGainerLooserAPI(userData)
        .then((res) => {
            if (res.code === 200) {
                setMy7DaysPortfolio(res.data);
                setTableData(res.data["gainer_data"] || []);
            } else {
                console.error("Fetch data error: response code not 200");
            }
            setLoading(false);
        })
        .catch((error) => {
            console.error("Fetch data error:", error);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (userData) {
            fetchData();
        }
    }, [fetchData]);

    const tabData = tableData.map((portfolio) => [
        <div className="table-col-1 d-flex gap-4" key={portfolio.coin_name}>
            <img
                src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${portfolio["crypto_id"]}.png`}
                className="crypto_icon m-auto"
                alt={portfolio["coin_name"]}
            />
            <span className="tab-text m-auto">{portfolio["slug"]}</span>
        </div>,
        portfolio["price"],
        `${portfolio["seven_day"]}%`,
        `${portfolio["thirty_day"]}%`,
        <div className="table-col-1" key={portfolio.portfolio_name}>
            <span className="tab-text">{portfolio.portfolio_name}</span>
        </div>,
    ]);

    const tableHeader = [
        {label: "Crypto", key: "crypto", sorting: true},
        {label: "Price", key: "price", sorting: true},
        {label: "Last 7 Days", key: "seven_day", sorting: true},
        {label: "Last 30 Days", key: "thirty_day", sorting: true},
        {label: "Portfolio Name", key: "portfolio_name", sorting: false},
    ];

    const [sorting, setSorting] = useState({key: "", order: ""});

    const tabList = [{title: "Top Gainers"}, {title: "Top Losers"}];

    const mediaQueryVar = useMediaQuery(json2mq({minWidth: 900}));

    useEffect(() => {
        if (activeTab === 0) {
            setTableData(my7DaysPortfolio["gainer_data"] || []);
        } else {
            setTableData(my7DaysPortfolio["looser_data"] || []);
        }
    }, [activeTab]);

    return (
        <div className="section-2 mt-5 pt-5">
            <div className="profile-headings mt-5">
                <h1>PORTFOLIO SUMMARY FOR LAST 7 DAYS</h1>
            </div>
            <div className="tab-2-7-day-summary mt-3 d-flex justify-content-center justify-content-md-start">
                <div
                    style={{
                        display: "flex",
                        borderRadius: "50px",
                        overflow: "hidden",
                        backgroundColor: "rgb(3 130 255)",
                        padding: "5px",
                    }}
                    className="tab-hook"
                >
                    {tabList.map((tab, index) => (
                        <button
                            className="tab_hook_link"
                            key={index}
                            onClick={() => setActiveTab(index)}
                            style={{
                                textDecoration: "none",
                                padding: mediaQueryVar ? "10px 20px" : "5px 10px",
                                marginLeft: "3px",
                                minHeight: "20px",
                                lineHeight: "25px",
                                borderRadius: "50px",
                                textTransform: "none",
                                fontWeight: activeTab === index ? "700" : "500",
                                fontSize: "16px",
                                backgroundColor: activeTab === index ? "#FFFFFF" : "transparent",
                                color: activeTab === index ? "#000000" : "#FFFFFF",
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            {tab.title}
                        </button>
                    ))}
                </div>
            </div>
            <TableHook header={tableHeader} tableData={tabData} loading={loading} />

            {/* for mobile */}
            {tableData.map((portfolio) => (
                <TableCardHook
                    key={portfolio.slug}
                    header={{
                        name: portfolio.slug,
                        icon: `https://s2.coinmarketcap.com/static/img/coins/64x64/${portfolio["crypto_id"]}.png`,
                    }}
                    body={[
                        {key: "Price", value: portfolio["price"]},
                        {key: "Last 7 Days", value: portfolio["seven_day"]},
                        {key: "Last 30 Days", value: portfolio["thirty_day"]},
                    ]}
                    footer={[
                        {
                            label: portfolio["portfolio_name"],
                        },
                    ]}
                />
            ))}
            {loading &&
                Array.from({length: 3}).map((_, index) => (
                    <TableCardHook
                        key={index}
                        header={{
                            name: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />,
                            icon: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} width={40} />,
                        }}
                        body={[
                            {key: "Price", value: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />},
                            {
                                key: "Last 7 Days",
                                value: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />,
                            },
                            {
                                key: "Last 30 Days",
                                value: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />,
                            },
                        ]}
                        footer={[{label: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />}]}
                        loading={loading}
                    />
                ))}

            {tableData.length === 0 && !loading && (
                <div className="add-crypto-watchlist-button d-flex justify-content-center mt-3">
                    <button
                        className="d-flex align-items-center "
                        onClick={() => {
                            navigate(`/crypto-screener`);
                        }}
                    >
                        <span>ADD MORE CRYPTOS</span>
                        <img src="images/profile_image/account_image/left-arrow-icon.svg" className="left-arrow-icon" />
                    </button>
                </div>
            )}
        </div>
    );
};
