import React from "react";
import { useNavigate } from "react-router-dom";

const TableCardHook = ({header, body, footer, loading}) => {
    const navigate = useNavigate();
    return (
        <div className="is-mobile">
            {loading ? (
                <div className="sm-table-card">
                    <div className="sm-table-heading">
                        <h6>{header.name}</h6>
                    </div>
                    <div className="sm-table-body d-flex flex-wrap">
                        {body.map((item, index) => (
                            <span key={index} className="d-flex flex-grow-1 mb-2">
                                {item.key}: <span className="ms-1">{item.value}</span>
                            </span>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="sm-table-card">
                    <div className="sm-table-heading">
                        <h6>
                            {header.icon && (
                                <img
                                    alt="image"
                                    src={`${header.icon}`}
                                    className="me-2 analysis-icon"
                                    style={{width: 30, height: 30, borderRadius: 19}}
                                />
                            )}
                            {header.name}
                        </h6>
                    </div>
                    <div className="sm-table-body d-flex flex-wrap">
                        {body.map((item, index) => (
                            <span key={index} className="d-flex flex-grow-1 mb-2">
                                {item.key}: <span className="ms-1">{item.value}</span>
                            </span>
                        ))}
                    </div>

                    <div className="sm-table-footer d-flex justify-content-around">
                        {footer.map((item, index) => (
                            <div className="position-relative d-inline-block">
                                <button
                                    key={index}
                                    className="sm-btn-1 position-relative d-flex align-items-center"
                                    onClick={() => {
                                        if (item?.link) {
                                            navigate(item.link);
                                        }
                                    }}
                                >
                                    {item.icon && (
                                        <img
                                            alt={item.alt}
                                            src={`${window.constants.asset_path}/images/profile_image/account_image/${item.icon}`}
                                            className="me-2 analysis-icon"
                                        />
                                    )}
                                    <span>{item.label}</span>
                                    {item.badge && (
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute end-0 top-0 translate-middle-y">
                                            {item.badge}
                                        </span>
                                    )}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TableCardHook;
