import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { CheckCircle, TrendingUp, TrendingDown, AlertCircle, Briefcase, Pin, Eye, EyeOff } from "lucide-react";
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useNavigate} from "react-router-dom";
import LoginPopUp from "./loginpopup";
import {useLocation} from "react-router-dom";
import {ReactComponent as HomeIcon} from "../pages/account/profile/profile_icon/home-icon.svg";
import {ReactComponent as SearchIcon} from "../pages/account/profile/profile_icon/search-icon.svg";
import {ReactComponent as PredictionPageIcon} from "../pages/account/profile/profile_icon/prediction-page-icon.svg";
import {ReactComponent as AuditPortfolioIcon} from "../pages/account/profile/profile_icon/audit-portfolio-icon.svg";
import {ReactComponent as GalleryIcon} from "../pages/account/profile/profile_icon/gallery-icon.svg";
import {ReactComponent as Investicon} from "../pages/account/profile/profile_icon/invest-icon.svg";
import {ReactComponent as SearchWhiteIcon} from "../pages/account/profile/profile_icon/search-white-icon.svg";
import {ReactComponent as NotificationIcon} from "../pages/account/profile/profile_icon/notification-icon.svg";
import {ReactComponent as ProfileIcon} from "../pages/account/profile/profile_icon/profile-icon.svg";
import {Helmet} from "react-helmet";
const drawerWidth = 292;
const API_URL = "https://crowdwisdom.live/wp-json/api/v1/crypto_alerts"; // Replace with your actual API URL

const TabHook = ({ activeTab, tabList }) => {
  return (
      <div
          style={{
              display: "flex",
              borderRadius: "50px",
              overflow: "hidden",
              backgroundColor: "rgb(3 130 255)",
              padding: "5px",
          }}
      >
          {tabList.map((tab, index) => (
              <a
                  key={index}
                  href={tab.url} 
                  target="_self"  // Ensures proper link behavior
                  rel="noopener noreferrer"  // Security best practice
                  style={{
                      textDecoration: "none",
                      whiteSpace: "nowrap",
                      padding: "0px 20px",
                      marginLeft: "3px",
                      minHeight: "20px",
                      lineHeight: "36px",
                      borderRadius: "50px",
                      textTransform: "none",
                      fontWeight: activeTab === index ? "700" : "500",
                      fontSize: "16px",
                      backgroundColor: activeTab === index ? "#FFFFFF" : "transparent",
                      color: activeTab === index ? "#000000" : "#FFFFFF",
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                  }}
              >
                  {tab.title}
              </a>
          ))}
      </div>
  );
};


const CryptoAlerts = (props) => {
  const { window1 } = props;
  // Sample alerts
  const sampleAlerts = [
    { id: 1, portfolio: "Crypto Shadow January Portfolio", coin: "BTC", message: "Whales have Turned Bullish", alertType: "bullish", read: false, pinned: false },
    { id: 2, portfolio: "Coincodex portfolio", coin: "ETH", message: "Whales have Turned Bearish", alertType: "bearish", read: false, pinned: false },
    { id: 3, portfolio: "Crypto GEM Portfolio", coin: "DOGE", message: "Social Media have Turned Bullish", alertType: "bullish", read: false, pinned: false },
    { id: 4, portfolio: "GBV Captial Portfolio", coin: "SOL", message: "Social Media have Turned Bearish", alertType: "bearish", read: false, pinned: false },
    { id: 5, portfolio: "Crypto Benny Shadow Portfolio", coin: "ADA", message: "is now an Outperformer", alertType: "outperformer", read: false, pinned: false },
    { id: 6, portfolio: "Solid AI shadow Portfolio", coin: "XRP", message: "is now an Underperformer", alertType: "underperformer", read: false, pinned: false },
  ];

  const [alerts, setAlerts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [unreadAlerts, setUnreadAlerts] = useState(0);
  

  // Function to toggle read/unread state
  const toggleRead = (id) => {
    setAlerts((prev) =>
      prev.map((alert) => (alert.id === id ? { ...alert, read: !alert.read } : alert))
    );
  };

  // Function to toggle pin state
  const togglePin = (id) => {
    setAlerts((prev) =>
      prev
        .map((alert) => (alert.id === id ? { ...alert, pinned: !alert.pinned } : alert))
        .sort((a, b) => b.pinned - a.pinned) // Pinned alerts come first
    );
  };

  // Alert styles
  const alertStyles = {
    bullish: { background: "#e8f5e9", borderLeft: "6px solid #2e7d32", color: "#2e7d32" },
    bearish: { background: "#ffebee", borderLeft: "6px solid #c62828", color: "#c62828" },
    outperformer: { background: "#e3f2fd", borderLeft: "6px solid #1565c0", color: "#1565c0" },
    underperformer: { background: "#fff3e0", borderLeft: "6px solid #ef6c00", color: "#ef6c00" },
    normal: { background: "#e8f5e9", borderLeft: "6px solid #2e7d32", color: "#2e7d32"},
    neutral : { background: "#fff3e0", borderLeft: "6px solid #ef6c00", color: "#ef6c00" },
  };

  
  const drawer = (
          <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
      <SidebarMenuList/>
          </div>
      );

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
      const handleModalClose = () => {
          // Close the modal and reset the URL to the welcome page
          setLoginModalOpen(false);
          navigate(location.pathname, {replace: true});
      };

      


      const container =
      window1 !== undefined ? () => window1().document.body : undefined;
       const mediaQueryVar = useMediaQuery(
            json2mq({
              minWidth: 900,
            }),
          );

          const handleLoginClick = () => {
                  if (!userData) {
                    setIsLoading(false);
                      window.history.pushState({}, "", "/login");
                      localStorage.setItem("isPopupManuallyOpened", "true");
                      setLoginModalOpen(true);
                  } 
              };
        
          useEffect(() => {

            
            if(!userData){
              const originalUrl = `/alerts`;
              sessionStorage.setItem("originalUrl", originalUrl);
              handleLoginClick();
            }

          else{
            const userId = userData?.user_id || null;

            const fetchAlerts = async () => {
              try {
                const response = await fetch(API_URL, {
                  method: "POST", // Use POST instead of GET
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                });
          
                if (!response.ok) {
                  throw new Error(`Error: ${response.status}`);
                }
          
                const data = await response.json();
                console.log(data);
                setAlerts(data.response); // Assuming alerts are in `response`
                setUnreadAlerts(data.unread_notifications);

               // Mark notifications as read
        const markReadResponse = await fetch("https://crowdwisdom.live/wp-json/api/v1/mark-read", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: userId }),
        });

        if (!markReadResponse.ok) {
          throw new Error(`Error marking notifications as read: ${markReadResponse.status}`);
        }

        setUnreadAlerts(0); // Reset unread count in UI
    

              } catch (error) {
                console.error("Failed to fetch alerts:", error);
              } finally {
                setIsLoading(false);
              }
            };
          
            fetchAlerts();
          }
          }, []);

         

       const headerTabOptions = [
                {
                    id: 1,
                    text: "Go To Home Page",
                    route: "/",
                    icon: <HomeIcon />,
                    color: "#FFB45C",
                },
                {
                    id: 2,
                    text: "Crypto Screener",
                    route: "/crypto-screener",
                    icon: <SearchIcon />,
                    color: "#D2FD8B",
                },
                {
                    id: 3,
                    text: "Price Predictions",
                    route: "/crypto-predictions",
                    icon: <PredictionPageIcon />,
                    color: "#FFE144",
                },
                {
                    id: 4,
                    text: "Audit My Portfolio",
                    route: "/audit-my-portfolio",
                    icon: <AuditPortfolioIcon />,
                    color: "#48CEFF",
                },
            ];

       const profileTabList = [
              {
                  title: (
                    <div style={{width: "140px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                    <NotificationIcon style={{ width: "25px", height: "25px" , paddingRight:"5px"}} /> My Alerts
                </div>
                
                  ),
                  url: "/alerts",
              },
              {
                  title: (
                      <div style={{width: "140px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                          <AuditPortfolioIcon style={{ width: "35px", height: "35px" , paddingRight:"5px"}}  /> My Watchlist
                      </div>
                  ),
                  url: "/my-portfolio",
              },
              {
                  title: (
                      <div style={{width: "140px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                          <SearchWhiteIcon style={{ width: "25px", height: "25px" , paddingRight:"5px"}} /> Crypto Screener
                      </div>
                  ),
                  url: "/crypto-screener",
              },
          ];
       const profileTabMobileList = [
            {
              title: (
                <div style={{width: "60px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                <NotificationIcon style={{ width: "20px", height: "20px" , paddingRight:"2px"}} /> Alerts
            </div>
            
              ),
              url: "/alerts",
          },
          {
              title: (
                  <div style={{width: "60px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                      <AuditPortfolioIcon style={{ width: "30px", height: "30px" , paddingRight:"2px"}}  /> Watchlist
                  </div>
              ),
              url: "/my-portfolio",
          },
          {
              title: (
                  <div style={{width: "60px",display:"flex",alignItems:"center", justifyContent:"center"}}>
                      <SearchWhiteIcon style={{ width: "20px", height: "20px" , paddingRight:"2px"}} /> Screener
                  </div>
              ),
              url: "/crypto-screener",
          },
          ];
          
          const [visibleCount, setVisibleCount] = useState(10);

          const loadMore = () => {
            setVisibleCount((prevCount) => prevCount + 10);
          }; 

          const [searchQuery, setSearchQuery] = useState("");
  
         

  const [sortOrder, setSortOrder] = useState("desc"); // Default to descending order

          // Sorting function (toggle between ascending and descending)
  const handleSort = () => {
    setSortOrder((prevOrder) => (prevOrder === "desc" ? "asc" : "desc"));
  };

  const [filteredAlerts, setFilteredAlerts] = useState([]);

  useEffect(() => {
    if (!alerts) return;
  
    const updatedAlerts = alerts
      .filter((alert) =>
        [alert.coin, alert.message, alert.portfolio, alert.created_at.formatted].some(
          (field) =>
            field && field.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
      .sort((a, b) => {
        return sortOrder === "asc"
          ? a.created_at.timestamp - b.created_at.timestamp // Ascending
          : b.created_at.timestamp - a.created_at.timestamp; // Descending
      });
  
    setFilteredAlerts(updatedAlerts);
  }, [alerts, searchQuery, sortOrder]);
  

        

          
          
        
  return (

    
    <div style={{ minHeight: "100vh", background: mediaQueryVar? "#f4f4f4": "#fff", padding: mediaQueryVar? "20px" : "13px" ,marginLeft:mediaQueryVar ? "292px":"0px"}}>
                    <Helmet>
                        <title>Crowdwisdom360 Crypto Alerts</title>
                    </Helmet>

                  {mediaQueryVar ? (
                            <div
                            className="d-flex justify-content-between"
                            style={{
                                border: "1px solid #1877F2",
                                borderRadius: "3rem",
                                padding: "3px 4px",
                                margin: "auto",
                                background: "#FFF",
                                marginBottom:"20px",
                                marginRight:"5%"
                            }}
                        >
                            {headerTabOptions.map((option) => (
                                <a
                                    key={option.id}
                                    href={option.route}
                                    className="d-flex align-items-center"
                                    style={{
                                        backgroundColor: option.color,
                                        textDecoration: "none",
                                        boxShadow: "0px 5px 0px 0px #dddbdb",
                                        borderRadius: "50px",
                                        padding: "8px 12px",
                                    }}
                                >
                                    {option.icon}
                                    <span
                                        style={{
                                            paddingLeft: "2%",
                                            whiteSpace: "nowrap",
                                            color: "#000000",
                                            fontWeight: "700",
                                        }}
                                    >
                                        {option.text}
                                    </span>
                                </a>
                            ))}
                        </div>
                        
                        ) : null}    
{/* {mediaQueryVar ? null : (
    <>
   <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
    <Typography style={{fontSize:"24px", fontWeight:"700"}}> Crypto Alerts </Typography>
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="end"
      onClick={handleDrawerToggle}
      sx={{ display: { sm: "none" } }}
    >
      <img
        src="/images/menubar.svg"
        alt="Menu Icon"
        style={{
          width: "24px",
          height: "24px",
          marginRight: "10px",
          marginTop: "10px",
        }}
      />
    </IconButton>
      
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "400",
              backgroundColor: "white",
              color: "#727376",
            },
          }}
        >
          {drawer}
        </Drawer></div>
        </>
   )} */}

                        <div className="d-flex justify-content-between my-3">
                        {mediaQueryVar ? (
                            <>
                                <TabHook activeTab={0} tabList={profileTabList} />
                                <div className="d-flex profile-left-section">
                                    <div className=" my-profile-container">
                                        <a
                                            href="/account"
                                            className="rounded-pill px-3 py-2 d-flex align-items-center justify-content-between my-profile-btn w-auto"
                                        >
                                            <ProfileIcon />
                                            <span className="tab-text">My Profile</span>
                                        </a>
                                    </div>
                                    <div className="notification-section position-relative">
                                        <NotificationIcon className="notification-icon fa-2x" />
                                        <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                            {unreadAlerts}
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : (
                          <>
                          <div className=" my-profile-container">
                                  <a
                                      href="/account"
                                      className="rounded-pill px-3 py-2 d-flex align-items-center justify-content-between my-profile-btn w-auto"
                                  >
                                      <ProfileIcon />
                                      <span className="tab-text">My Profile</span>
                                  </a>
                              </div>
                              <div className="notification-section position-relative">
                                  <NotificationIcon className="notification-icon fa-2x" />
                                  <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                      {unreadAlerts}
                                  </span>
                              </div>
                          
                      </>
                        )}
                    </div>
                      
                      
        {/* Search bar */}                 
   {mediaQueryVar ?(
    <div
    style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        marginTop: "16px",
        marginBottom: "1%",
    }}
>
<img src="/images/searchblueicon.svg"
    
    style={{
        position: "absolute",
        left: "25px",
        width: "20px",
        height: "20px",
       
    }}
/>

    <input
        type="text"
        placeholder="Search Portfolios, Predictions Cryptos"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
            paddingLeft: "60px",
            width: "565px",
            height: "47px",
            borderRadius: "3rem",
            border: "1px solid #1877f2",
            backgroundColor: "transparent",
            fontSize: "large",
            maxWidth: "800px",
            color: "#1877f2",
            caretColor: "#1877f2",
            outline: "none",
        }}
    />
    </div>
   ):(
    <div
    style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        marginTop: "16px",
        marginBottom: "1%",
    }}
>
<img src="/images/searchblueicon.svg"
    
    style={{
        position: "absolute",
        left: "25px",
        width: "20px",
        height: "20px",
       
    }}
/>

    <input
        type="text"
        placeholder="Search Portfolios, Predictions Cryptos"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
            paddingLeft: "60px",
            width: "565px",
            height: "42px",
            borderRadius: "3rem",
            border: "1px solid #1877f2",
            backgroundColor: "transparent",
            fontSize: "14px",
            maxWidth: "800px",
            color: "#1877f2",
            caretColor: "#1877f2",
            outline: "none",
        }}
    />
    </div>
   )}                                            

   {mediaQueryVar ? null : (
    <div style={{margin: "20px auto"}}>
    <TabHook activeTab={0} tabList={profileTabMobileList} />
    </div>
   )}
                   
   

   

{mediaQueryVar ? (
  <Box
    component="nav"
    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    aria-label="mailbox folders"
  >
    {/* The implementation can be swapped with JS to avoid SEO duplication of links. */}
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          backgroundColor: "white",
          color: "#727376",
        },
      }}
    >
      {drawer}
    </Drawer>

    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", sm: "block" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          backgroundColor: "white",
          color: "#727376",
        },
      }}
      open
    >
      {drawer}
    </Drawer>
  </Box>
) : null}

{mediaQueryVar ? (

    //Desktop Design

      <div style={{  width: "100%", background: "#fff", padding: "20px", borderRadius: "12px", boxShadow: "0 4px 15px rgba(0,0,0,0.1)", margin: "auto" }}>
<h2 style={{
  color: "#000000",
  textAlign: "center",
  fontSize: "22px",
  fontWeight: "bold",
  marginBottom: "20px",
  borderRadius: "10px",
  borderBottom: "5px solid #1877F2",
  background: "linear-gradient(180deg, rgba(24, 119, 242, 0.11) -1.89%, rgba(24, 119, 242, 0.00) 60.38%)",
 
  padding: "10px",
  borderRadius: "8px",
  boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)"
}}>
  <div style={{display:"flex",alignItems:"center"}}>
  Alerts 
  <img
  src="/images/sort_icon.svg"
  alt="Sort Icon"
  style={{
    width: "24px",
    height: "24px",
    marginRight: "10px",
    marginTop: "10px",
    cursor: "pointer",
    transform: sortOrder === "asc" ? "rotate(180deg)" : "none",
    transition: "transform 0.3s ease-in-out",
  }}
  onClick={handleSort}
/>
</div>
</h2>

      
      {/* Loading State */}
      {isLoading && (
        <div>
          {[...Array(3)].map((_, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "15px" }}>
              <div style={{ width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#e0e0e0" }}></div>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <div style={{ width: "200px", height: "20px", backgroundColor: "#e0e0e0" }}></div>
                <div style={{ width: "150px", height: "15px", backgroundColor: "#e0e0e0" }}></div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* No Alerts Message */}
      {!isLoading && filteredAlerts.length === 0 && (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <span style={{ fontSize: "50px", display: "block", marginBottom: "10px" }}>🔔</span>
          <p style={{ color: "#777", fontSize: "16px" }}>No alerts available</p>
        </div>
      )}

      {/* Alerts List */}
      {!isLoading &&
        filteredAlerts.slice(0, visibleCount).map((alert) => (
        <motion.div
          key={alert.id}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
            borderRadius: "20px",
            boxShadow: "0px 3px 8px rgba(0,0,0,0.1)",
            background: "linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%)",
            color:"black",
            border:"1px solid #1877F2",
            marginBottom: "10px",
            paddingBottom: "28px",
            paddingTop:"10px"
          }}
        >
           
          {/* Left Side */}
          <div style={{ display: "flex", flexDirection:"column", gap: "8px",maxWidth:"600px" }}>
          <div style={{ fontSize: "14px", color: "#555" }}>
     
           {alert.created_at.formatted} 
    
          </div>
            {/* <div style={{width:"30px"}}>
            {alert.alertType === "bullish" ? (
              <CheckCircle size={22} color="#28a745" />
            ) : alert.alertType === "bearish" ? (
              <TrendingDown size={22} color="#dc3545" />
            ) : alert.alertType === "outperformer" ? (
              <TrendingUp size={22} color="#007bff" />
            ) : (
              <AlertCircle size={22} color="#ffc107" />
            )}</div> */}
            <div>

            <div style={{display:"flex", alignItems:"center",gap:"3px"}}>
              <img src={alert.logo} width={30} height={30} style={{borderRadius:"20px"}}></img>
              <strong style={{ fontSize: "16px" }}>{alert.coin}</strong>
              <p style={{ fontSize: "14px", color: "#000", margin: "5px 0" }}>
  {(() => {
    let message = alert.message;

    // Replace exact phrases with styled versions
    message = message.replace(/Moderate Performer/gi, (match) => {
      return `<span style="color: orange; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Neutral/gi, (match) => {
      return `<span style="color: orange; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Emerging Outperformer/gi, (match) => {
      return `<span style="color: green; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Outperformer/gi, (match) => {
      return `<span style="color: green; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Underperformer/gi, (match) => {
      return `<span style="color: red; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Bullish/gi, (match) => {
      return `<span style="color: green; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Bearish/gi, (match) => {
      return `<span style="color: red; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Added/gi, (match) => {
      return `<span style="color: black; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    message = message.replace(/Removed/gi, (match) => {
      return `<span style="color: black; font-weight: bold; text-transform: uppercase;">${match}</span>`;
    });

    return <span dangerouslySetInnerHTML={{ __html: message }} />;
  })()}
</p>
</div>





    {alert.portfolio && (
     <> <Briefcase size={14} style={{ verticalAlign: "middle" , marginLeft:"35px"}} /> <span style={{color:"black",fontSize:"14px"}}>{alert.portfolio}</span></>
     )}

             

            </div>
          </div>
          
          {/* Right Side Buttons */}
          <div style={{ width:"150px" }}>
            {/* <motion.div
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
              onClick={() => togglePin(alert.id)}
              style={{ cursor: "pointer", color: alert.pinned ? "#ff9800" : "#555" }}
            >
              <Pin size={20} />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
              onClick={() => toggleRead(alert.id)}
              style={{ cursor: "pointer", color: "#555" }}
            >
              {alert.read ? <EyeOff size={20} /> : <Eye size={20} />}
            </motion.div> */}
            <button
  style={{ 
    borderRadius: "50px",
    border: "1px solid #1877F2",
    background: "linear-gradient(91deg, rgba(154, 255, 249, 0.60) 18.86%, rgba(255, 240, 109, 0.60) 100.02%)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)", 
    padding: "8px 12px", 
    color: "#1877F2",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer"
  }}
  onClick={() => {
    const messageLower = alert.message.toLowerCase();
    const performanceKeywords = ["underperformer", "moderate performer", "outperformer", "bullish", "bearish", "neutral"];
    
    if (performanceKeywords.some(keyword => messageLower.includes(keyword))) {
      navigate(`/crypto/${alert.tag_url}`);
    } else if (messageLower.includes("prediction")) {
      navigate(`/crypto/${alert.tag_url}/predictions`);
    } else {
      navigate(`/influencer/${alert.tag_url}/portfolios`);
    }
  }}
>
  Check Out Now
</button>



          </div>
        </motion.div>
      ))}

      {/* Load More Button */}
      {!isLoading && visibleCount < filteredAlerts.length && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button
            onClick={loadMore}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#fff",
              background: "#1877F2",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)"
            }}
          >
            Load More
          </button>
        </div>
      )}
      </div>

):(
 
      //Mobile Design

      <div style={{  width: "100%", margin: "auto" }}>
      <h2 style={{
        color: "#000000",
        textAlign: "center",
        fontSize: "22px",
        fontWeight: "bold",
        marginBottom: "20px",
        borderRadius: "10px",
        borderBottom: "2px solid #1877F2",
        background: "linear-gradient(91deg, rgba(154, 255, 249, 0.30) 18.86%, rgba(255, 240, 109, 0.30) 100.02%)",
       
        padding: "10px",
        borderRadius: "8px",
        boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)"
      }}>
        <div style={{display:"flex",alignItems:"center"}}>
        Alerts 
        <img
              src="/images/sort_icon.svg"
              alt="Menu Icon"
              style={{
                width: "24px",
                height: "24px",
                marginRight: "10px",
                marginTop: "10px",
                cursor:"pointer",
                transform: sortOrder === "asc" ? "rotate(180deg)" : "none",
    transition: "transform 0.3s ease-in-out",
              }}
              onClick={handleSort}
            /></div>
      </h2>
      
            
            {/* Loading State */}
            {isLoading && (
              <div>
                {[...Array(3)].map((_, index) => (
                  <div key={index} style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "15px" }}>
                    <div style={{ width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#e0e0e0" }}></div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                      <div style={{ width: "200px", height: "20px", backgroundColor: "#e0e0e0" }}></div>
                      <div style={{ width: "150px", height: "15px", backgroundColor: "#e0e0e0" }}></div>
                    </div>
                  </div>
                ))}
              </div>
            )}
      
            {/* No Alerts Message */}
            {!isLoading && filteredAlerts.length === 0 && (
              <div style={{ padding: "20px", textAlign: "center" }}>
                <span style={{ fontSize: "50px", display: "block", marginBottom: "10px" }}>🔔</span>
                <p style={{ color: "#777", fontSize: "16px" }}>No alerts available</p>
              </div>
            )}
      
            {/* Alerts List */}
            {!isLoading &&
              filteredAlerts.slice(0, visibleCount).map((alert) => (
              <motion.div
                key={alert.id}
                style={{
                  display: "flex",
                  flexDirection: mediaQueryVar? "row" : "column",
                  justifyContent: "space-between",
                  padding: "15px",
                  borderRadius: "20px",
                  boxShadow: "0px 3px 8px rgba(0,0,0,0.1)",
                  background: "linear-gradient(180deg, rgba(24, 119, 242, 0.40) -1.89%, rgba(24, 119, 242, 0.00) 60.38%)",
                  color:"black",
                  border:"1px solid #1877F2",
                  marginBottom: "10px",
                  paddingBottom: "28px",
                  paddingTop:"10px"
                }}
              >
                 
                {/* Left Side */}
                <div
  style={{
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    maxWidth: "600px",
  }}
>
  <div style={{ display: "inline-block" }}>
    <span
      style={{
        fontSize: "14px",
        color: "#000",
        borderBottom: "1px solid #1877F2",
      }}
    >
      {alert.created_at.formatted}
    </span>
  </div>

  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    {/* Logo & Coin Name */}
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <img
       src={alert.logo}
        width={30}
        height={30}
        style={{ borderRadius: "20px" }}
      />
      <strong style={{ fontSize: "16px" }}>{alert.coin}</strong>
    </div>

    {/* Alert Message */}
    <div style={{ flex: 1 }}>
      <p
        style={{
          fontSize: "14px",
          color: "#000",
          margin: "5px 0",
          textAlign: "left",
        }}
      >
        {(() => {
          let message = alert.message;

          message = message.replace(/Moderate Performer|Neutral/gi, (match) => {
            return `<span style="color: orange; font-weight: bold; text-transform: uppercase;">${match}</span>`;
          });

          message = message.replace(
            /Emerging Outperformer|Outperformer|Bullish/gi,
            (match) => {
              return `<span style="color: green; font-weight: bold; text-transform: uppercase;">${match}</span>`;
            }
          );

          message = message.replace(/Underperformer|Bearish/gi, (match) => {
            return `<span style="color: red; font-weight: bold; text-transform: uppercase;">${match}</span>`;
          });

          message = message.replace(/Added|Removed/gi, (match) => {
            return `<span style="color: black; font-weight: bold; text-transform: uppercase;">${match}</span>`;
          });

          return <span dangerouslySetInnerHTML={{ __html: message }} />;
        })()}
      </p>
    </div>
  </div>

  {alert.portfolio && (
    <div style={{ display: "flex", alignItems: "center", gap: "5px",margin:"auto" }}>
      <Briefcase size={14} style={{ verticalAlign: "middle" }} />
      <div>{alert.portfolio}</div>
    </div>
  )}
</div>

                
                {/* Right Side Buttons */}
                <div style={{ display:"flex",alignItems:"center", justifyContent:"center", margin:"15px auto 0px auto" }}>
                  {/* <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.2 }}
                    onClick={() => togglePin(alert.id)}
                    style={{ cursor: "pointer", color: alert.pinned ? "#ff9800" : "#555" }}
                  >
                    <Pin size={20} />
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.2 }}
                    onClick={() => toggleRead(alert.id)}
                    style={{ cursor: "pointer", color: "#555" }}
                  >
                    {alert.read ? <EyeOff size={20} /> : <Eye size={20} />}
                  </motion.div> */}
                  <button
        style={{ 
          borderRadius: "50px",
          border: "1px solid #1877F2",
          background: "linear-gradient(91deg, rgba(154, 255, 249, 0.60) 18.86%, rgba(255, 240, 109, 0.60) 100.02%)",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)", 
          padding: "8px 12px", 
          color: "#1877F2",
          fontSize: "16px",
          fontWeight: "bold",
          cursor: "pointer"
        }}
        onClick={() => {
          const messageLower = alert.message.toLowerCase();
          const performanceKeywords = ["underperformer", "moderate performer", "outperformer", "bullish", "bearish", "neutral"];
          
          if (performanceKeywords.some(keyword => messageLower.includes(keyword))) {
            navigate(`/crypto/${alert.tag_url}`);
          } else if (messageLower.includes("prediction")) {
            navigate(`/crypto/${alert.tag_url}/predictions`);
          } else {
            navigate(`/influencer/${alert.tag_url}/portfolios`);
          }
        }}
      >
        Check Out Now
      </button>
      
      
      
                </div>
              </motion.div>
            ))}
      
            {/* Load More Button */}
            {!isLoading && visibleCount < filteredAlerts.length && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <button
                  onClick={loadMore}
                  style={{
                    padding: "10px 20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#fff",
                    background: "#1877F2",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)"
                  }}
                >
                  Load More
                </button>
              </div>
            )}
            </div>

)}

      {isLoginModalOpen && (
                        <LoginPopUp
                            isLoginModalOpen={isLoginModalOpen}
                            setLoginModalOpen={setLoginModalOpen}
                            onClose={handleModalClose}
                        />
                    )}
    </div>
  );
};

export default CryptoAlerts;
