import {useMediaQuery} from "@mui/material";
import json2mq from "json2mq";
import React from "react";

const TabHook = ({activeTab, tabList}) => {
    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );

    return (
        <div
            style={{
                display: "flex",
                borderRadius: "50px",
                overflow: "hidden",
                backgroundColor: "rgb(3 130 255)",
                padding: "5px",
            }}
            className="tab-hook"
        >
            {tabList.map((tab, index) => (
                <a
                    className="tab_hook_link"
                    key={index}
                    href={tab.url}
                    target="_self" // Ensures proper link behavior
                    rel="noopener noreferrer" // Security best practice
                    style={{
                        textDecoration: "none",

                        padding: mediaQueryVar ? "10px 20px" : "5px 10px",
                        marginLeft: "3px",
                        minHeight: "20px",
                        lineHeight: "25px",
                        borderRadius: "50px",
                        textTransform: "none",
                        fontWeight: activeTab === index ? "700" : "500",
                        fontSize: "16px",
                        backgroundColor: activeTab === index ? "#FFFFFF" : "transparent",
                        color: activeTab === index ? "#000000" : "#FFFFFF",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                    }}
                >
                    {tab.title}
                </a>
            ))}
        </div>
    );
};

export default TabHook;
