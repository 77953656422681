import React, {useCallback, useEffect, useState} from "react";
import TableHook from "./TableHook";
import {useNavigate} from "react-router-dom";
import TableCardHook from "./TableCardHook";
import {getmultiPortfolio} from "../../../../apihelper/portfolio";
import {Skeleton} from "@mui/material";

const MyPortfolioTable = () => {
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const [loading, setLoading] = useState(false);
    const [sorting, setSorting] = useState({});
    const [header, setHeader] = useState([
        {label: "Portfolio Name", key: "portfolio_name", sorting: "asc"},
        {label: "Last 7 Days", key: "seven_day", sorting: "asc"},
        {label: "Last 30 Days", key: "thirty_day", sorting: "asc"},
        {label: "Last 6 Months", key: "six_month", sorting: "asc"},
        {label: "", key: "action", sorting: ""},
    ]);
    const [sortOrder, setSortOrder] = useState({
        portfolio_name: "asc",
        seven_day: "asc",
        thirty_day: "asc",
        six_month: "asc",
    });

    const [sortKey, setSortKey] = useState("portfolio_name");
    const [sortType, setSortType] = useState("pn_asc");
    const [myPortfolio, setMyPortfolio] = useState([]);

    const fetchData = useCallback(
        (sortKey, sortType) => {
            setLoading(true);
            getmultiPortfolio({
                user_id: userData?.user_id,
                Token: userData?.token,
                start_index: 0,
                sort_key: sortKey,
                sort_type: sortType,
            }).then((res) => {
                if (res.code === "200") {
                    setMyPortfolio(res.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    console.error("fetchdata1 is called 200 not");
                }
            });
        },
        [userData]
    );

    useEffect(() => {
        fetchData();
    }, []);

    const row = myPortfolio.map(({portfolio_name, seven_day_roi, thirty_day_roi, six_month_roi, portfolio_type_id}) => [
        <div key={portfolio_name} className="table-col-1">
        
            <span className="tab-text">{portfolio_name}</span>
        </div>,
        `${seven_day_roi || 0}%`,
        `${thirty_day_roi || 0}%`,
        `${six_month_roi || 0}%`,
        <div key={six_month_roi} className="d-flex m-auto align-items-center">
            <button
                className="btn btn-outline-primary rounded-pill mx-3 d-flex align-items-center analysis-btn"
                onClick={() =>
                    navigate(
                        `../my-portfolio/${portfolio_type_id}/${portfolio_name.replace(
                            / /g,
                            "-"
                        )}/detailed-portfolio-analysis`
                    )
                }
            >
                <img
                    alt="Analysis"
                    src={`${window.constants.asset_path}/images/analysis.png`}
                    className="me-2 analysis-icon"
                    style={{width: 30, height: 30, borderRadius: 19}}
                />
                Analysis
            </button>
        </div>,
    ]);

    const handleSort = (column) => {
        const newSortOrder = sortOrder[column] === "asc" ? "desc" : "asc";
        setSortOrder((prevSortOrder) => ({...prevSortOrder, [column]: newSortOrder}));

        const sortMapping = {
            portfolio_name: newSortOrder === "asc" ? "pn_desc" : "pn_asc",
            seven_day: newSortOrder === "asc" ? "sd_desc" : "sd_asc",
            thirty_day: newSortOrder === "asc" ? "td_desc" : "td_asc",
            six_month: newSortOrder === "asc" ? "sm_desc" : "sm_asc",
        };

        setSortKey(sortMapping[column]);

        setHeader((prevHeader) =>
            prevHeader.map((h) =>
                h.key === column ? {...h, sorting: newSortOrder} : h.sorting ? {...h, sorting: "asc"} : h
            )
        );
    };

    const parseForecastPrice = (price) => parseFloat(price.replace(/[^0-9.-]+/g, ""));

    useEffect(() => {
        setSortType(sortKey);
    }, [sortKey]);

    useEffect(() => {
        setMyPortfolio(getSortedData());
    }, [sortType]);

    const getSortedData = useCallback(() => {
        const sortingFunctions = {
            pn_asc: (a, b) => a.portfolio_name.localeCompare(b.portfolio_name),
            pn_desc: (a, b) => b.portfolio_name.localeCompare(a.portfolio_name),
            sd_asc: (a, b) => parseForecastPrice(a.seven_day_roi) - parseForecastPrice(b.seven_day_roi),
            sd_desc: (a, b) => parseForecastPrice(b.seven_day_roi) - parseForecastPrice(a.seven_day_roi),
            td_asc: (a, b) => parseForecastPrice(a.thirty_day_roi) - parseForecastPrice(b.thirty_day_roi),
            td_desc: (a, b) => parseForecastPrice(b.thirty_day_roi) - parseForecastPrice(a.thirty_day_roi),
            sm_asc: (a, b) => parseForecastPrice(a.six_month_roi) - parseForecastPrice(b.six_month_roi),
            sm_desc: (a, b) => parseForecastPrice(b.six_month_roi) - parseForecastPrice(a.six_month_roi),
        };

        return [...myPortfolio].sort(sortingFunctions[sortType]);
    }, [myPortfolio, sortType]);

    return (
        <>
            <div className="is-desktop">
                <TableHook
                    header={header.map((h) => ({
                        ...h,
                        onClick: () => handleSort(h.key),
                        icon: h.sorting === "asc" ? "↑" : h.sorting === "desc" ? "↓" : "",
                    }))}
                    tableData={row}
                    loading={loading}
                />
            </div>
            {myPortfolio.map((portfolio) => (
                <TableCardHook
                    key={portfolio.portfolio_name}
                    header={{name: portfolio.portfolio_name}}
                    body={[
                        {key: "Last 7 Days", value: `${portfolio["seven_day_roi"]}%`},
                        {key: "Last 6 Months", value: `${portfolio["six_month_roi"]}%`},
                        {key: "Last 30 Days", value: `${portfolio["thirty_day_roi"]}%`},
                    ]}
                    footer={[
                        {
                            label: "Analysis",
                            icon: `analysis-icon-2.svg`,
                            link: `../portfolio/${portfolio.portfolio_name.replace(
                                / /g,
                                "-"
                            )}/detailed-portfolio-analysis`,
                        },
                        {
                            label: "Recommendations",
                            icon: `start-fill-blue-icon.svg`,
                            link: `../portfolio/${portfolio.portfolio_name.replace(/ /g, "-")}/recommendations`,
                        },
                    ]}
                />
            ))}
            {loading &&
                Array.from({length: 3}).map((_, index) => (
                    <TableCardHook
                        key={index}
                        header={{
                            name: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />,
                            icon: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} width={40} />,
                        }}
                        body={[
                            {
                                key: "Last 7 Days",
                                value: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />,
                            },
                            {
                                key: "Last 6 Months",
                                value: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />,
                            },
                            {
                                key: "Last 30 Days",
                                value: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />,
                            },
                        ]}
                        footer={[{label: <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />}]}
                        loading={loading}
                    />
                ))}
        </>
    );
};

export default MyPortfolioTable;
