import {Skeleton, Stack} from "@mui/material";
import React from "react";

const TableHook = ({header, tableData, loading}) => {
    // console.log("header", header);
    return (
        <div className="profile-table-main is-desktop">
            <table className="profile-table mt-3">
                <thead className="t-head">
                    <tr>
                        {header.map((head, index) => (
                            <th key={index} className={head.sort ? "sort-header" : ""} onClick={() => head.onClick()}>
                                {head.label}
                                <span>{head.icon}</span>
                            </th>
                        ))}
                    </tr>
                </thead>
                {loading ? (
                    <tbody>
                        {Array.from({length: 4}).map((_, index) => (
                            <tr key={index}>
                                {header.map((head, index) => (
                                    <td>
                                        <Stack>
                                            <Skeleton sx={{marginTop: "10px"}} variant="rounded" height={40} />
                                        </Stack>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <tbody>
                        {tableData.map((row, index) => (
                            <tr key={index}>
                                {row.map((data, index) => (
                                    <td key={index}>{data}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                )}
            </table>
        </div>
    );
};

export default TableHook;

