import React from "react";

const Products = () => {
    const productsList = [
        { name: "Crypto Screener", description: "XXXXXXXXXXXX" },
        { name: "Crypto Market Index", description: "XXXXXXXXXXXX" },
        { name: "Shadow Portfolios", description: "XXXXXXXXXXXX" },
        { name: "Algorithm Based Recommendations for Improving Portfolio", description: "XXXXXXXXXXXX" },
    ];

    return (
        <div className="container mt-5">
            <div>
                <h1 className="theme-bg shadow sub-heading">Our Products</h1>
            </div>
            <div className="row">
                {productsList.map((product, index) => (
                    <div className="p-3 col-md-4" key={index}>
                        <div className="p-3 shadow product-card">
                            <h6 className="text-wrap text-center mb-5 text-decoration-underline fw-bold">
                                {product.name}
                            </h6>
                            <p className="text-wrap">{product.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Products;
