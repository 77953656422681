import "./portfolio.css";
import CreatableSelect from "react-select/creatable";
import React, { useState ,useRef} from 'react';
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import { Link as RouterLink } from 'react-router-dom';
import Divider from "@mui/material/Divider";
import LoginPopUp from '../loginpopup';

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
  import { Tabs, Tab } from '@mui/material';
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuList from "../../includes/menulist";
import MenuListHome from "../../includes/menuListHome";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { EditText } from "react-edit-text"; // Import EditText component
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useEffect } from "react";
import { Card, Button, Grid, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import { updatePrivacy } from "../../apihelper/profile.js";

import Alert from "@mui/material/Alert";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import { getCryporCurrencyList } from "../../apihelper/cryptocurrencylist";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { getsymbollink } from "../../apihelper/getsymbollink.js";
import CircularProgress from "@mui/material/CircularProgress";
import BarChartIcon from '@mui/icons-material/BarChart';
import { Dialog, DialogTitle,BottomNavigationAction,BottomNavigation, DialogContent, DialogActions } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getPortfolioData,getmultiPortfolio } from "../../apihelper/portfolio";
import { addToPortfolio,RenamePortfolio,updateProfilePhoto } from "../../apihelper/portfolio";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AddIcon from '@mui/icons-material/Add';
import Portfolioadd from "../addPage/portfolioadd";
import SidebarMenuList from "../../includes/sidebarMenuList";
import { getCryporCurrencyList,getTotalunit,getFetchdropdown } from "../../apihelper/cryptocurrencylist";
import 'react-edit-text/dist/index.css';
import WatchListGallery from "../home/watchlistgallery.js";
import { useLocation } from "react-router-dom";
import Footer from '../footer';
const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));




const drawerWidth = 292;
const existingOptions = [
  { value: 'Category A', label: 'Category A' },
  { value: 'Category B', label: 'Category B' },
  { value: 'Category C', label: 'Category C' },
];
const MultiPortfolio = (props) => {

  const [openModal, setOpenModal] = useState(false);
  const [portfolioTypeId, setPortfolioTypeId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null); // State to store selected file
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };

  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] =useState(false);

  const handleClose = () => setOpen(false);

  const [snackbaropen, setSnackbaropen] = useState(false);

  const [alignment, setAlignment] = useState("left");
  const [loader, setLoader] = useState(false);
  const [slugdata, setSlugdata] = useState([]);
  const [index, setIndex] = useState(0);
  const [addMoreLoader, setAddMoreLoader] = useState(false);
  const [portfolioname, setPortfolioname] = useState('');
  const [filter, setFilter] = useState("");
  const [loaderbutton, setLoaderButton] = useState(true);
  const [roivalue, setRoivalue] = useState("--");
  const [portfoliovalue, setPortfoliovalue] = useState("--");
  const [lastUpdatedTime, setLastUpdatedTiem] = useState("");
  const [portfolioid, setportfolioid] = useState("");
  const [options, setOptions] = useState([]);
  const [dropDown, setdropDown] = useState([]);
  const [newOptionValue, setNewOptionValue] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [slugpopupdata, setSlugpopupdata] = useState({
    slugname: "",
    slug: "",
    units: 0,
  });

  
  const [msgAfterLoading, setMsgAfterLoading] = useState("");
  const [unit, setUnit] = useState("");
  const [buySell, setbuySell] = React.useState("");
  const [price, setPrice] = useState("");

  const [selectedDate, handleDateChange] = useState("");
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
    useState("loading...");
  const [severity, setSeverity] = useState("info");
  const [initialPage, setInitialPage] = useState(false);
  const [localUserData,setLocalUserData]=useState(undefined)
  const [loaderForSymbolLink,setLoaderForSymbolLink]=useState(false);
  const [dataslug, setdataslug] = useState('');
  const [totalUnits, setTotalUnits] = useState(0); 
  const [Usablewallet, setUsablewallet] = useState("");
  const [user_role, setuser_role] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const location = useLocation(); 
  const loc = location.pathname;


  const [lockStatus, setLockStatus] = useState({});

  // // Retrieve saved lock state from localStorage when the component mounts
  // useEffect(() => {
  //   const savedLockStatus = JSON.parse(localStorage.getItem('lockStatus')) || {};
  //   setLockStatus(savedLockStatus);
  // }, []);
  

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    if (userData) {
      const user_id = userData.user_id;
      const token = userData.token;
  
      // Fetch current privacy data
      updatePrivacy({
        user_id,
        token,
        action: '' // Assuming this fetches the current lock status
      })
        .then((res) => {
          if (res.code === "200") {
            // Initialize lock status
            const newLockStatus = {};
  
            // Process the private portfolios
            const privatePortfolios = res.data.private_prtfs;
            if (privatePortfolios) {
              // Convert the comma-separated string into an array of IDs
              const lockedPortfolioIds = privatePortfolios.split(',').map(id => parseInt(id, 10));
  
              // Set the lock status for each portfolio type ID
              lockedPortfolioIds.forEach(id => {
                newLockStatus[id] = true;
              });
            }
  
            // Update state and localStorage
            setLockStatus(newLockStatus);
            localStorage.setItem("lockStatus", JSON.stringify(newLockStatus));
          } else {
            console.log("Error fetching data");
          }
        })
        .catch((err) => {
          console.error("API Error:", err);
        });
    }
    
  }, []);
  
  

  // Function to toggle lock status based on portfolio_type_id and update localStorage
  const handleLockClick = (portfolio_type_id) => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    if (!userData) {
      console.error("User data not found");
      return;
    }

    const user_id = userData.user_id;
    const token = userData.token;

    // Toggle the lock state for this portfolio type
    setLockStatus((prevState) => {
      const isLocked = prevState[portfolio_type_id];

      // Toggle lock state for the given portfolio_type_id
      const updatedLockStatus = {
        ...prevState,
        [portfolio_type_id]: !isLocked, // Toggle lock/unlock state
      };

      // Save the updated lock status to local storage
      localStorage.setItem("lockStatus", JSON.stringify(updatedLockStatus));

      // Update the privacy status in the backend
      updatePrivacy({
        user_id,
        portfolio_type_id,
        token,
        action: isLocked ? 'remove' : 'add', // Determine if we are adding or removing the lock
      })
        .then((res) => {
          if (res.code === "200") {
            console.log("Privacy updated successfully:", res.data);
          } else {
            console.error("Error in API response:", res.message);
          }
        })
        .catch((err) => {
          console.error("API Error:", err);
        });

      return updatedLockStatus; // Return the updated state immediately
    });
  };
  const handleButtonClick_ = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const fetchDropdownOptions = async () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    console.log("User ID : ", userData.user_id);


    getFetchdropdown({
      user_id: userData.user_id,
      
      Token:userData.token
    }).then((res) => {
      if (res.code === "200") {
        console.log("Data of fetchdropdown: ",res.data);
        const newOptions = res.data.map(item => ({ value: item.id, label: item.name }));
          setOptions(newOptions);
          setuser_role(res.user_role);
       } else {
        console.log("Data fething error");
      }
    
    });
   
    
  }
  const handleChange = async (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__) {
  
      console.log('Creating new option:', selectedOption.value);
      setSelectedOption(null);

    } 
    else if(!selectedOption) {
      // Handle the case when the selection is cleared
      setSelectedOption(null);
      
      // Perform any other necessary actions
      return;
    }else{
    setSelectedOption(selectedOption);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  }
  };


  const handleCreate = async (inputValue) => {

    if (options.find(option => option.value === inputValue)) {
      return; // Exit early if the option already exists
    }
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log(inputValue);
    const newOption = { value: inputValue, label: inputValue };
    const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
    setOptions(newOptions); // Update the options state
    const createdOption = newOptions.find(option => option.value == inputValue);
   // console.log(createdOption);
    if (createdOption) {
      handleChange(createdOption);
    }
   
  };
  
  

  const handleSymbolLink=(symbolName)=>{
    //console.log(symbolName)
    setLoaderForSymbolLink(true);
    getsymbollink(symbolName).then(res=>{
      if(res.code==='200')
      window.open(res.article_link, '_blank', 'noreferrer');
      // window.location.replace(res.article_link);
      // setLoaderForSymbolLink(false);
      setLoaderForSymbolLink(false);
    })
  }
  

  useEffect(() => {
    if (dropDown) {
      fetchDropdownOptions();
    }
  }, [dropDown]);

  const handleOpenSelect = () => {
    setdropDown(true);
  };

  
  const handleOpen = (portfolioId,portfolio_name) => {
    const formattedName = portfolio_name.replace(/ /g, '-');
    // e.stopPropagation();
    navigate(`/my-portfolio/${portfolioId}/${encodeURIComponent(formattedName)}`);

  };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );
  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {

    if(userData){
    
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      getmultiPortfolio({
     
        user_id: userData.user_id,
        search_text:filter,
        Token: userData.token,
        start_index: 0,
       
      }).then((res) => {
        if (res.code === "200") {
        
          setSlugdata(res.data);
          setRoivalue(res.total_profit);
          setPortfoliovalue(res.total_portfolio_value);
        
          setLastUpdatedTiem(res.updated_on);
          setportfolioid(res.portfolio_id)
          setData(res.data);
          console.log("On first use effect:",res.data);
          if (res.data.length < 10) {
            setLoaderButton(false);
          } else {
            setLoaderButton(true);
          }
          setLoader(true);
          setAddMoreLoader(false);
        } else {
          setMsgAfterLoading(res.message);
        }
      });
    }
  }, [filter]);

  const resetSelectedFile = () => {
    setSelectedFile(null);
  };

  const fetchData = () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    setLocalUserData(userData);
    getmultiPortfolio({
      user_id: userData.user_id,
      search_text: filter,
      Token: userData.token, 
       start_index: 0,
    
    }).then((res) => {
      if (res.code === "200") {
        setRoivalue(res.total_profit);
        setPortfoliovalue(res.total_portfolio_value);
        setSlugdata(res.data);
        setData(res.data);
        setLastUpdatedTiem(res.updated_on);
        setportfolioid(res.portfolio_id);
        console.log("AFter clicking fetchData:",res.data);
     
        if (res.data.length === 0) {
          setInitialPage(true);
        }
        if (res.data.length < 10) {
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        // Handle error
      }
    });
  }
  const [rowsToShow, setRowsToShow] = useState(7);

  const handleLoadMore = () => {
    setRowsToShow(rowsToShow + 4);
  };

  // const loadMore = () => {
  //   // setLoader(false);
  //   setAddMoreLoader(true);
  //   setIndex(index + 10);
  //   //console.log(index);
  //   const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  //   getmultiPortfolio({
  //     slug: filter,
  //     user_id: userData.user_id,
  //     start_index: index + 10,
  //     limit: `${10}`,
  //     Token: userData.token,
  //   }).then((res) => {
  //     if (res.code === "200") {
  //       //console.log("data got successfully");
  //       setSlugdata([...slugdata, ...res.data]);
  //       //console.log(res.data);
  //       if (res.data.length < 10) {
  //         //console.log("change Loaderbutton");
  //         setLoaderButton(false);
  //       } else {
  //         setLoaderButton(true);
  //       }
  //       setLoader(true);
  //       setAddMoreLoader(false);
  //     } else {
  //       //console.log("Data fething error");
  //     }
  //     // console.log(slugdata)
  //   });
  // };
  const [isRowClicked, setIsRowClicked] = useState(false);

  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbaropen(false);
  };
  const handleClick = () => {
    setSnackbaropen(true);
    setOpen(true);
    setSeverity("info");
    // setSnackbarcolor("success");
    // setSnackbarmessage("Data saved successfully");
    setMsgforUpdatePortfolio("loading...");
    //console.log(price, selectedDate, buySell, unit);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    addToPortfolio({
      user_id: userData.user_id,
      slug: slugpopupdata.slugname,
      unit: unit,
      acquisition_price: price,
      date_time: selectedDate,
      type: buySell,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        setOpen(false);
        setSeverity("info");
        //console.log(res.message);
        setMsgforUpdatePortfolio(res.message);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        setLoader(false);
        getPortfolioData({
          slug: filter,
          user_id: userData.user_id,
          start_index: `${index}`,
         
          Token: userData.token,
        }).then((res) => {
          if (res.code === "200") {
            //console.log("data got successfully");
            setSlugdata(res.data);
            // console.log(res.data)
            if (res.data.length === 0) {
              setInitialPage(true);
            }
            if (res.data.length < 20) {
              setLoaderButton(false);
            } else {
              setLoaderButton(true);
            }
            setLoader(true);
            setAddMoreLoader(false);
          } else {
            //console.log("Data fething error");
          }
          // console.log(slugdata)
        });
        setUnit("");
        setbuySell("");
        setPrice("");
        handleDateChange("");
        //navigate("/portfolio");
      } else {
        setSeverity("error");
        // setOpen(true)
        setMsgforUpdatePortfolio(res.message);
      }
    });
  };
  


  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100" style={{overflowY:'hidden'}}>
      <SidebarMenuList/>
    </div>
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));

  
  const handleSave = async (portfolioname,portfolio_id) => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    RenamePortfolio({
      user_id: userData.user_id,
      portfolio_id: portfolio_id,
      portfolio_name: portfolioname,
      Token: userData.token,
    }).then((res) => {
      if (res.code == "200") {
        return;
      } else {
       // console.log(res.message);
       setSeverity("error")
      setMsgforUpdatePortfolio(res.message);
        setSnackbaropen(true);
      }
    });
  };

  
  const handleSubmit = () => {
    // console.log(selectedFile);
    // return false;
    
    
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  
    const user_id=userData.user_id
    const token=userData.token

    updateProfilePhoto({user_id,portfolioTypeId,selectedFile,token}).then(res=>{
      if(res.code=="200" && severity!="error"){
      
       
        setSnackbaropen(true);
        setSeverity("info")
        setMsgforUpdatePortfolio("Loading...")
        setMsgforUpdatePortfolio(res.message);
        fetchData();
        setOpenModal(false);
        setSelectedFile(null);
        setPortfolioTypeId(null);
        setPortfolioname('');
        setSelectedOption(null);
        fetchDropdownOptions();

    
      }
      else{
        setSeverity("error")
        setMsgforUpdatePortfolio(res.message);
        setSnackbaropen(true);
      }
    })
   
  }; 

  const [selectedButton, setSelectedButton] = useState("CoinPrices");
  const [isTableView, setIsTableView] = useState(true);
  const handleButtonClick = (buttonName) => {
    // If the same button is clicked again, don't toggle the view
    if (selectedButton === buttonName) {
      return;
    }

    // Toggle the view based on the clicked button
    setSelectedButton(buttonName);
    setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
  };
 
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  const [sortOrder, setSortOrder] = useState({ // Track ascending/descending for each column
    roi : 'asc',
    profit : 'asc'
  });
  const [sortType, setSortType] = useState('roi_asc');

  const handleSort = (column) => {
    console.log("Arrow Clicked");
    const newSortOrder = sortOrder[column] === 'asc' ? 'desc' : 'asc'; // Toggle sort order
    setSortOrder({ ...sortOrder, [column]: newSortOrder });

    console.log("Sorted");
  
    // Set the sort type based on the column
    switch (column) {
      case 'roi':
        setSortType(newSortOrder === 'asc' ? 'roi_asc' : 'roi_desc');
        break;

      case 'profit':
          setSortType(newSortOrder === 'asc' ? 'profit_asc' : 'profit_desc');
          break;
      
      default:
        break;
    }
  };

  //console.log("Data : ", data);
  // Helper function to convert net profit to a number
  const convertNetProfit = (profit) => {
    const number = parseFloat(profit.replace(/[^0-9.-]+/g, '')); // Remove $ sign and commas
    return isNaN(number) ? 0 : number; // Handle invalid or empty values
  };
  

  const filteredData = data.filter(portfolio => 
    portfolio.total_net_profit !== null && 
    portfolio.total_net_profit !== undefined && 
    !isNaN(convertNetProfit(portfolio.total_net_profit))
  );
  
  //console.log("Filtered Data: ", filteredData);
  
  // Helper function to convert ROI value to a number
  const convertRoiValue = (roi) => {
    const number = parseFloat(roi.replace('%', ''));
    return isNaN(number) ? 0 : number; // Handle invalid or empty values
  };
  
  
  const sortedData = [...filteredData].sort((a, b) => {
    switch (sortType) {
      case 'roi_asc':
        return convertRoiValue(a.roi_value) - convertRoiValue(b.roi_value);
      case 'roi_desc':
        return convertRoiValue(b.roi_value) - convertRoiValue(a.roi_value);
      
      case 'profit_asc':
        return convertNetProfit(a.total_net_profit) - convertNetProfit(b.total_net_profit);
      case 'profit_desc':
        return convertNetProfit(b.total_net_profit) - convertNetProfit(a.total_net_profit);
       
      default:
        return 0;
    }
  });
  
  //console.log("Sorted Data: ", sortedData);
  


  const EditButton = () => {
    
    const inputRef = useRef(null);

    const handleSaveChangesButtonClick = () => {
      const inputValue = inputRef.current.value;
      handleSave(inputValue, selectedOption.value);
      handleSubmit();
      handleClosePopup();
    };
    
  
    return (
      <>
      <Helmet>
        <title>My Portfolios</title>
</Helmet>
      {userData && (
        <Button
          variant="outlined"
          style={{
            backgroundColor: 'white',
            color: '#1877F2',
            borderColor: '#1877F2',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '27px',
            textTransform: 'none',
            fontFamily: 'Satoshi, sans-serif',
            fontSize: '14px',
            fontWeight: '700',
            padding: '7px 13px',
            textDecoration: '',
            marginLeft:mediaQueryVar===false?"8px":"15px",
            width: mediaQueryVar===false ?"60px":"",
            marginRight:mediaQueryVar===false?"20px":"",
            height: mediaQueryVar===false ?"35px":"",

          }}
          onClick={handleButtonClick_}
          onMouseDown={(e) => {
            // Change button styles on click
            e.currentTarget.style.backgroundColor = '#1877F2';
            e.currentTarget.style.color = 'white';
          }}
          onMouseUp={(e) => {
            // Revert button styles when click is released
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = '#1877F2';
          }}
        >
          Edit
        </Button>
      )}
        <Dialog PaperProps={{
    sx: {
      position: mediaQueryVar===false?'fixed':'static',
      bottom: mediaQueryVar === false? '0' : '',
      left: mediaQueryVar === false? '0' : '',
      right: mediaQueryVar === false? '0' : '',
      overflow: mediaQueryVar===false?"":"hidden",
      margin: '0',
      maxWidth: '100%',
      minHeight: '50%', // Adjust max height if needed
      borderRadius:mediaQueryVar===true?"24px":"24px 24px 0 0",
      padding:"10px",
      alignItems:"center"
    }
  }}
  open={openPopup} onClose={handleClosePopup}>
  {mediaQueryVar === false &&(
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <button style={{
        width: '48px',
        height: '4px',
        borderRadius: '100px',
        background: '#E2E2E2',
        border: 'none',
        margin: '7px',
        marginBottm:'-10x'
      }}></button>
    </div>
  )}
  <div style={{ padding: '20px', width: mediaQueryVar==false?'100%':'400px', margin: '0' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
      <Typography style={{ fontWeight: '700', fontSize: '22.103px', paddingLeft:"30%" }} gutterBottom>
        Edit Portfolio
      </Typography>

      <div className="primaryButton">
        <img
          className="frameIcon"
          alt=""
          onClick={handleClosePopup}
          src={window.constants.asset_path+'/images/frame17.svg'}
          style={{ background: "#F3F3F3", width: "25px", height: "25px" }}
        />
      </div>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
      <Typography style={{ fontSize:"14.736px",fontWeight: '500', marginBottom: '5px', color:'black' }}>
        Select Portfolio
      </Typography>
      <div style={{ marginTop: '5px', marginBottom:"8px" }}>
        <CreatableSelect
          isClearable
          isCreatable={false}
          styles={{
            control: (provided) => ({
              ...provided,
              borderRadius: '12px',
              border: '1px solid #1877F2',
              boxShadow: 'none',
              minHeight: '40px',
              '&:hover': {
                borderRadius: '12px',
                border: '2px solid #1877F2',
              },
            }),
            menu: (provided) => ({
              ...provided,
              borderRadius: '8px',
              maxHeight: '200px', // Set max height to allow scrolling
              overflow: 'hidden',  // Hide the scrollbar
            }),
            menuList: (provided) => ({
              ...provided,
              maxHeight: '200px',
              overflowY: 'scroll',  // Enable vertical scrolling
              '::-webkit-scrollbar': {
                width: '0px',  // Hide scrollbar for Webkit browsers
                height: '0px'
              },
              '-ms-overflow-style': 'none',  // Hide scrollbar for IE and Edge
              'scrollbar-width': 'none'  // Hide scrollbar for Firefox
            }),
            placeholder: (provided) => ({
              ...provided,
              color: '',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              color: '#1877F2', // Add color to the dropdown indicator
              border:"1px solid #1877F2",
              padding:"5px",
              margin:"8px",
              borderRadius:"3.786px",
              '&:hover': {
                color: '#1877F2', // Add hover color to the dropdown indicator
              },
            }),
            indicatorSeparator: (provided) => ({
              display: 'none', // Hide the vertical mark
            }),
          }}
          placeholder=""
          onClick={(e) => handleOpenSelect()}
          onChange={(options) => handleChange(options)}
          options={[
            ...options // Existing options
          ]}
          value={selectedOption}
        />
      </div>
      <div className="upload-container" style={{marginBottom:"0px"}}>
        <p className="upload-label" style={{fontSize:"14.736px", fontWeight:"500"}}>Upload Image</p>
        <div className="upload-wrapper">
          <input
            className="upload-input"
            min="1"
            max="10"
            type="number"
            placeholder=""
          />
          <img src={window.constants.asset_path  + '/images/photo-icon.svg'} alt="Upload Icon" className="upload-icon" onClick={(e) => {
            e.stopPropagation();
            setOpenModal(true);
            setPortfolioTypeId(selectedOption.value);
            setPortfolioname(inputRef.current.value);
          }}/>
        </div>
      </div>
      <div style={{marginBottom:"10px"}}>
        <p style={{fontSize:"14.736px", fontWeight:"500"}}>Change Portfolio name</p>
        <input
          style={{color:"black",backgroundColor:"white",paddingLeft:"15px",marginTop:"0px",marginBottom:"20px",border: "1px solid #1877F2", borderRadius:"10.384px", width:"100%", height:"45px" }}
          type="text"
          placeholder=""
          ref={inputRef}
        />
      </div>
      <Button
        value=""
        style={{
          lineHeight: "20px",
          width: "100%",
          borderRadius:"27px",
          border:"1px solid #1877F2",
          padding:"15px 85px",
          marginLeft:"0px",
          backgroundColor:  "#1877F2",
          color: "white",
          textTransform:"none",
          fontSize:"16.578px",
          fontWeight:"700",
          marginTop:"-10px"
        }}
        onClick={handleSaveChangesButtonClick}
      >
        Save changes
      </Button>
    </div>
  </div>
</Dialog>

      </>
    );
  };

  return (
    <>
    {mediaQueryVar===true?(
       
    <Box
      sx={{
        display: "flex",
       
        flexDirection: mediaQueryVar === false ? "column" : "",
    height: '100vh',
    // overflow: 'hidden', 
      }}
      className="maindiv"
    >
      <CssBaseline />
      <div className="windowheader">
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={window.constants.asset_path+'/images/logo_with_bg.png'}
              width={220}
              height={50}
              alt="CWLOGO"
              style={{ margin: "10px", borderRadius: "5px" }}
            />
          </Toolbar>
        </AppBar>
      </div>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          padding: "2vw",
          backgroundColor: "#EDF1F6",
        }}
      >
        {initialPage && <Portfolioadd />}
        {!initialPage && (
          <>
            <div className="windowheader">
              <Toolbar />
            </div>
            <Box sx={{ flexGrow: 1 }} style={{ marginBottom: "15px" }}>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "space-between",  }}
              >
                <Grid item className="CryptocurrencyListHeader" >
                  <strong>
                    {" "}
                    <Typography
                     variant="subtitle1" 
                      noWrap
                      component="div"
                      style={{
                        
                        fontSize: "25px",
                        fontStyle: "normal",
                       marginLeft:"15px",
                       marginBottom:"-10px",
                         marginTop: mediaQueryVar === false ? "10px" : "",
                      }}
                    > 
                    
                    <span style={{
                      fontSize: "24px",
                      fontFamily: "Satoshi, sans-serif",
                      color: "black",
                      fontWeight: "700"
                    }}>
                     {selectedButton === "CoinPrices" ? (
                      <>
                        {userData ? "My Portfolios" : ""}
                      </>
                    ) : (
                      <>
                      My Watchlist
                      </>
                    )}
                    </span>
                    </Typography>
              
                  </strong>
              
                </Grid>
                {selectedButton == "watchlist" &&(
  <Grid item xs={12} md={3} sx={{marginRight:"30px",marginLeft:"-60px"}}>
  
      <TextField
        style={{
          width: "18.8vw",
          borderRadius: "46px",
          marginTop: mediaQueryVar === true ? "" : "60px",
          fontFamily: "Satoshi, sans-serif",
          borderRadius:"46px",
          border:" 1px #ECECEC solid",
          backgroundColor:"#F7F7F7",
          paddingTop:"2px",
          paddingBottom:"2px",
          paddingLeft:"12px",
          paddingRight:"30px",
          fontSize:"16px",
          fontWeight:"500",
          
        }}
        className="cryptocurrencyListHeaderSearch"
        size="small"
        placeholder="Search Portfolios"
        onInput={(e) => setFilter(e.target.value)}
        value={filter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src="images/search.svg"/>
            </InputAdornment>
          ),
          style: {
            border: "none", // Remove the border from the input
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent', // Remove the border of the fieldset
            },
            '&:hover fieldset': {
              borderColor: 'transparent', // Remove the border on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent', // Remove the border when focused
            },
          },
        }}
      />
    
  </Grid>
)}
              </Grid>


  

              <Box sx={{ 
              border: "1px solid white",  
              borderRadius: "8px", 
              padding: "25px 25px", 
              margin: "10px",
              marginLeft:"15px", 
              marginRight: "25px", 
              boxShadow: "0 2px 4px rgba(0,0,0,0)", 
              backgroundColor: "white" ,
              overflow: "auto",
              // Hide the scrollbar
              '&::-webkit-scrollbar': {
              display: 'none',
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'
              }}>

<Grid
  container
  justifyContent="space-between"
  alignItems="center"
  style={{
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "20px",
    marginTop: mediaQueryVar === true ? "15px" : "15px",
  }}
>
  <Grid item xs={12} md={9}>
  {userData ? (
  <Box
  sx={{
    backgroundColor: "#1877F2", // Background color for the container
    paddingTop: { xs: '10px', sm: '4px' }, // Padding inside the container
    paddingLeft: { xs: '0px', sm: '0px' },
    paddingRight: { xs: '0px', sm: '0px' },
    paddingBottom: { xs: '0px', sm: '4px' },
    borderRadius: "49px", // Border radius for the container
    display: 'inline-flex', // Make sure the box is flexible
    justifyContent: 'flex-start', // Center the content
    alignItems: 'center', // Center the content
    width: userData ? { xs: '260px', sm: '292.5px' } : { xs: '260px', sm: '470px' },
    height: { xs: '44px', sm: '50px' },
    border: 'none',
    marginLeft: "20px",
  }}
>
  <Tabs
    value={selectedButton}
    onChange={(event, newValue) => handleButtonClick(newValue)}
    aria-label="basic tabs example"
    sx={{
      alignSelf: mediaQueryVar === true ? "flex-start" : "",
      marginLeft: mediaQueryVar === true ? "2px" : "2px",
      borderRadius: "50px", // Adjust border radius for more rounded corners
      border: "none",
      "& .MuiTabs-indicator": {
        display: "none",
      },
      display: 'flex', // Make sure the tabs are displayed in a row
      overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
    }}
  >
    {/* Conditional rendering based on userData */}
    <Tab
      label="My Portfolios"
      value="CoinPrices"
      sx={{
        whiteSpace: "nowrap",
        bottom: "0px",
        marginTop: "1px",
        marginBottom: "0px",
        marginLeft: "4px",
        marginRight: "3px",
        paddingBottom: "9px",
        paddingTop: "9px",
        paddingLeft: "22px",
        paddingRight: "22px",
        width: "135px",
        minHeight: "22px",
        lineHeight: { xs: '28px', sm: '22px' },
        borderRadius: selectedButton === "CoinPrices" ? "20px" : "20px", // Rounded corners for selected tab
        textTransform: "none",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: selectedButton === "CoinPrices" ? "700" : "500", 
        backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
        border: 'none', // Remove border for tabs
        background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
      }}
    />
    
 
      
        <Tab
          label="My Watchlist"
          value="watchlist"
          sx={{
            whiteSpace: "nowrap",
            bottom: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "3px",
            marginRight: "1px",
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "20px",
            paddingRight: "15px",
            minHeight: "20px",
            lineHeight: { xs: '36px', sm: '36px' },
            borderRadius: selectedButton === "watchlist" ? "50px" : "50px", // Rounded corners for selected tab
            textTransform: "none",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: selectedButton === "watchlist" ? "700" : "500", 
            backgroundColor: selectedButton === "watchlist" ? "#FFFFFF" : "transparent", // White background for selected tab
            color: selectedButton === "watchlist" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
            border: 'none', // Remove border for tabs
            background: selectedButton === "watchlist" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
          }}
        />
      
   
  </Tabs>
</Box>
  ):(
  <Box
  sx={{
    backgroundColor: "#1877F2", // Background color for the container
    paddingTop: { xs: '10px', sm: '4px' }, // Padding inside the container
    paddingLeft: { xs: '0px', sm: '0px' },
    paddingRight: { xs: '0px', sm: '0px' },
    paddingBottom: { xs: '0px', sm: '4px' },
    borderRadius: "49px", // Border radius for the container
    display: 'inline-flex', // Make sure the box is flexible
    justifyContent: 'flex-start', // Center the content
    alignItems: 'center', // Center the content
    width: userData ? { xs: '260px', sm: '292.5px' } : { xs: '260px', sm: '470px' },
    height: { xs: '44px', sm: '50px' },
    border: 'none',
    marginLeft: "20px",
  }}
>
  <Tabs
    value={selectedButton}
    onChange={(event, newValue) => handleButtonClick(newValue)}
    aria-label="basic tabs example"
    sx={{
      alignSelf: mediaQueryVar === true ? "flex-start" : "",
      marginLeft: mediaQueryVar === true ? "2px" : "2px",
      borderRadius: "50px", // Adjust border radius for more rounded corners
      border: "none",
      "& .MuiTabs-indicator": {
        display: "none",
      },
      display: 'flex', // Make sure the tabs are displayed in a row
      overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
    }}
  >
    {/* Conditional rendering based on userData */}
    <Tab
      label="My Portfolios"
      value="CoinPrices"
      sx={{
        whiteSpace: "nowrap",
        bottom: "0px",
        marginTop: "1px",
        marginBottom: "0px",
        marginLeft: "4px",
        marginRight: "3px",
        paddingBottom: "9px",
        paddingTop: "9px",
        paddingLeft: "22px",
        paddingRight: "22px",
        width: "135px",
        minHeight: "22px",
        lineHeight: { xs: '28px', sm: '22px' },
        borderRadius: selectedButton === "CoinPrices" ? "20px" : "20px", // Rounded corners for selected tab
        textTransform: "none",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: selectedButton === "CoinPrices" ? "700" : "500", 
        backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
        border: 'none', // Remove border for tabs
        background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
      }}
    />
    
        <Tab
          label="Top Portfolios"
          value="topPortfolios"
          onClick={() => window.location.href = "/portfolio-gallery"}
          sx={{
            whiteSpace: "nowrap",
            bottom: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "3px",
            marginRight: "1px",
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "20px",
            paddingRight: "15px",
            minHeight: "20px",
            lineHeight: { xs: '36px', sm: '36px' },
            borderRadius: selectedButton === "topPortfolios" ? "50px" : "50px", // Rounded corners for selected tab
            textTransform: "none",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: selectedButton === "topPortfolios" ? "700" : "500", 
            backgroundColor: selectedButton === "topPortfolios" ? "#FFFFFF" : "transparent", // White background for selected tab
            color: selectedButton === "topPortfolios" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
            border: 'none', // Remove border for tabs
            background: selectedButton === "topPortfolios" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
          }}
        />
        <Tab
          label="Crypto Screener"
          value="cryptoScreener"
          onClick={() => window.location.href = "/crypto-screener"}
          sx={{
            whiteSpace: "nowrap",
            bottom: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "3px",
            marginRight: "1px",
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "20px",
            paddingRight: "15px",
            minHeight: "20px",
            lineHeight: { xs: '36px', sm: '36px' },
            borderRadius: selectedButton === "cryptoScreener" ? "50px" : "50px", // Rounded corners for selected tab
            textTransform: "none",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: selectedButton === "cryptoScreener" ? "700" : "500", 
            backgroundColor: selectedButton === "cryptoScreener" ? "#FFFFFF" : "transparent", // White background for selected tab
            color: selectedButton === "cryptoScreener" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
            border: 'none', // Remove border for tabs
            background: selectedButton === "cryptoScreener" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
          }}
        />
    
   
  </Tabs>
</Box> 
  
  )}

    {selectedButton == "CoinPrices" &&(
    <EditButton />
  )}
  </Grid>

  {selectedButton == "CoinPrices" && userData && (
  <Grid item xs={12} md={3} sx={{marginRight:"30px",marginLeft:"-60px"}}>
  
      <TextField
        style={{
          width: "18.5vw",
          borderRadius: "46px",
          marginTop: mediaQueryVar === true ? "" : "60px",
          fontFamily: "Satoshi, sans-serif",
          borderRadius:"46px",
          border:" 1px #1877F2 solid",
          backgroundColor:"#fff",
          paddingTop:"2px",
          paddingBottom:"2px",
          paddingLeft:"12px",
          paddingRight:"30px",
          fontSize:"16px",
          fontWeight:"500",
          
        }}
        className="cryptocurrencyListHeaderSearch"
        size="small"
        placeholder="Search Portfolios"
        onInput={(e) => setFilter(e.target.value)}
        value={filter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src="images/search.svg"/>
            </InputAdornment>
          ),
          style: {
            border: "none", // Remove the border from the input
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent', // Remove the border of the fieldset
            },
            '&:hover fieldset': {
              borderColor: 'transparent', // Remove the border on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent', // Remove the border when focused
            },
          },
        }}
      />
    
  </Grid>
)}
</Grid>
            {selectedButton === "CoinPrices" ? (
              <>

<TableContainer style={{ overflowX: "auto", borderRadius: "5px", margin: "0 auto", width: "100%", paddingRight:"20px" }}>
<div style={{ border: "1px solid #EEE", borderRadius: "5px", overflow: "hidden", width: "100%" }}>
                <Table
                  className="non_responsiveTable" aria-label="customized table" 
                  style={{ borderCollapse: "collapse", width: "100%" }}

                >
                  {userData && (
                  <TableHead>
                    <TableRow>
                    <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "30vw" }}>
            <button disabled style={{
              fontWeight: "500", textAlign: "left", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
              border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
            }}>
             Names
            </button>

                      </StyledTableCell>
                      <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "auto" ,minWidth:"8vw"}}>
            <button disabled style={{
              fontWeight: "500", textAlign: "left", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
              border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
            }}>
    Wallet
    </button>
  
                      </StyledTableCell>
    <StyledTableCell 
   
    className="cryptotableviewHeader" style={{ padding: 0, width: "auto",minWidth:"8vw" }}>
            <button 
             onClick={() => handleSort('profit')}
            
           style={{
              fontWeight: "500", textAlign: "left", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
              border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
            }}>
  Profit {sortOrder.profit === 'asc' ? '↑' : '↓'}
  </button>
  </StyledTableCell>
                    
  <StyledTableCell 
  
  className="cryptotableviewHeader" style={{ padding: 0, width: "auto" ,minWidth:"10vw"}}>
            <button
            onClick={() => handleSort('roi')}
              style={{
              fontWeight: "500", textAlign: "left", color: "#727376", fontSize: "16px", width: "100%", height: "auto",
              border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
            }}>
   1 Month ROI {sortOrder.roi === 'asc' ? '↑' : '↓'}
  </button>
                      </StyledTableCell>
                      <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "auto" }}>
                    
                  </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  )}
                  {loader === false && userData && (
                    <TableBody >
                      {rows.map((idx) => (
                        <StyledTableRow key={idx} >
                          <StyledTableCell >
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell >
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell >
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                         

                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  )}
                  {!userData && (
                    <TableBody>
                      <StyledTableRow>
                        {/* Other table cells */}
                        
                        {/* If you want to add a centered button in the last row */}
                        <TableCell colSpan={4} style={{ textAlign: "center" }}>
                          <button
                            onClick={() => {
                              
                                const originalUrl = '/audit-my-portfolio';
                                sessionStorage.setItem("originalUrl", originalUrl);
                                console.log("original url: " + originalUrl);
                                handleLoginClick();
                                
                            }}
                            style={{
                              border: "1px solid #1877F2",
                              borderRadius: "48px",
                              padding: "15px 36px",
                              backgroundColor: "white",
                              color: "#1877F2",
                              fontSize: "24px",
                              height: "66px",
                              marginRight: "8px",
                              fontWeight: "500",
                              margin: "20px",
                            }}
                          >
                            Audit My Portfolio
                          </button>
                        </TableCell>
                      </StyledTableRow>
                  </TableBody>
                  
                  )}
                  {loader && userData && (
                    <TableBody>
                      {sortedData.slice(0, rowsToShow).map((obj, idx) => (
                      <StyledTableRow     
                      onClick={(e) => {
                       
                       
                          handleOpen(obj.portfolio_type_id, obj.portfolio_name);
                        
                        
                      }}
                     
                      sx={{
                        backgroundColor: idx % 2 === 0 ? "white" : "#F0F0F0",
                        cursor: "pointer"
                      }}
        
                      className={mediaQueryVar ? 'clickable-row' : ''} key={idx} style={{ backgroundColor: idx % 2 === 0 ? "#FFF" : "#F6F6F6" }}>
                           <StyledTableCell
  component="th"
  scope="row"
  style={{
    borderBottom: "none",
    color: "#000",
    fontSize: "16px",
    fontWeight: "500",
    paddingLeft: "16px",
    paddingTop: "6px",
    paddingBottom: "6px",
    borderRight: "1px solid #DADEDF",
    backgroundColor: "white",
  }}
>
  <div
    style={{
      background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))',
      padding: "1px 7px 10px 15px",
      marginLeft: "-20px",
      marginRight: "-5px",
      marginBottom: "-5px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center", // vertical alignment
    }}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className="btcParent">
        {obj["article_link"] ? (
          <a href={obj.article_link}>
            <div>{obj.slug}</div>
          </a>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: "5px 7px", margin: "0 -5px -10px -20px" }}>
              <Box>
                {obj.photo ? (
                  <img
                    width={39.77}
                    height={38}
                    src={window.constants.asset_path + obj.photo}
                    style={{
                      borderRadius: "19px",
                      border: "2px solid black",
                    }}
                  />
                ) : (
                  <img
                    width={39.77}
                    height={38}
                    src={window.constants.asset_path + '/images/avtar.jpeg'}
                    style={{
                      borderRadius: "19px",
                      border: "2px solid black",
                    }}
                  />
                )}
              </Box>
              <Box sx={{ marginLeft: "5px", marginTop: "8px" }}>
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "16px",
                    fontWeight: "500",
                    marginLeft: "5px",
                  }}
                >
                  {obj.portfolio_name.replace(/-/g, " ")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    flexWrap: "wrap",
                    marginTop: "5px",
                  }}
                >
                  {obj.slugs.slice(0, 3).map((tag) => (
                    <Box
                      key={tag.slug}
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        padding: "2px 11px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#000",
                        marginBottom: "13px",
                      }}
                    >
                      {tag.category}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </div>
        )}
      </div>
    </div>

    {/* Right-aligned unlock icon */}
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
            src={
              lockStatus[obj.portfolio_type_id]
                ? window.constants.asset_path + '/images/lock.svg'
                : window.constants.asset_path + '/images/unlock.svg'
            }
            width="20px"
            height="20px"
            alt={lockStatus[obj.portfolio_type_id] ? "Lock" : "Unlock"}
            style={{ cursor: "pointer" }}  // Make it clickable
            onClick={(e) => {
              e.stopPropagation();  // Stops the event from propagating up the DOM
              handleLockClick(obj.portfolio_type_id);  // Toggle lock/unlock for this specific row
            }}
          />
    </div>
  </div>
</StyledTableCell>
                          <StyledTableCell
                            className="one"
                            style={{
                              borderBottom: "none",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                paddingLeft: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRight: "1px solid #DADEDF",
                            }}
                          >
                            {/* <div className="table_mobile">PRICE</div> */}

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>{obj.total_wallet}</span>
                              {/* <span>
                                Last Updated {obj.price_today_updated_on}
                              </span> */}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            className="one"
                            style={{
                              borderBottom: "none",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                paddingLeft: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRight: "1px solid #DADEDF",
                            }}
                          >
                            {obj.total_net_profit !== ''  ? obj.total_net_profit : 'N/A'}
                          </StyledTableCell>
                        
                          <StyledTableCell
                             style={{
                              borderBottom: "none",
                color: "#000",
                fontSize: "16px",
                fontWeight: "500",
                paddingLeft: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRight: "1px solid #DADEDF",
                            }}
                          >
                            {/* <div className="table_mobile">ROI VALUE</div> */}
                            <div
                              // style={{
                              //   color: `${obj.outlook_for_today[0].textcolor}`,

                              //   fontWeight:
                              //     `${obj.outlook_for_today[0].bold}` === "yes"
                              //       ? "bold"
                              //       : "",
                              //   fontStyle:
                              //     `${obj.outlook_for_today[0].itllic}` === "yes"
                              //       ? "italic"
                              //       : "",
                              // }}
                            >
                          
                              {obj.roi_value !== ''  ? obj.roi_value : 'N/A'}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell
  style={{
    borderBottom: "none",
    color: "#000",
    fontSize: "16px",
    fontWeight: "500",
    paddingLeft: "16px",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderRight: "1px solid #DADEDF",
  }}
>
  <Button
    className="btn"
    variant="outlined"
    onClick={() => handleOpen(obj.portfolio_type_id, obj.portfolio_name)}
    style={{
      backgroundColor: "white",
      marginLeft: "0px",
      marginRight: "10px",
      borderRadius: "48px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "4px",
      paddingBottom: "4px",
      border: "2px solid #1877F2",
      fontFamily: "Satoshi, sans-serif",
      fontSize: "14px",
      textTransform: "none",
      borderStyle: "solid",
      borderColor: "#1877F2",
      borderWidth: "1px",
      color: "#1877F2",
      height: "27px",
     
      width: mediaQueryVar === false ? "100%" : "105px",
    }}
  >
    View Details
  </Button>
</StyledTableCell>


                        </StyledTableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
                {slugdata.length === 0 && loader === true && (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <h4>No Data Found</h4>
                  </div>
                )}
                 </div>
              </TableContainer>
              {rowsToShow < sortedData.length && (
                <div className="loadingButton">
                  <LoadingButton
                    loading={addMoreLoader}
                    loadingPosition="center"
                    variant="outlined"
                    style={{
                      maxWidth: "360px",
                      marginBottom:"60px",
                      width: "80vw",
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: mediaQueryVar === false ? "80vw" : "180px",
                    }}
                    onClick={handleLoadMore}
                  >
                    <span>Load More</span>
                  </LoadingButton>
                </div>
              )} 
              
           
              </>
            ) : (
              <>
                   <WatchListGallery filter={filter} onButtonClick={handleButtonClick}/>
                 </>
              
            )}
          
        
          </Box>
            
            <Modal
              sx={{ overflow: "scroll" }}
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="CardOpener">
                <Card className="card__container">
                  <div className="card__container__row1">
                    <Typography className="editPortfolio1" gutterBottom>
                      Add To Portfolio
                    </Typography>
                    <div className="primaryButton">
                      <img
                        className="frameIcon"
                        alt=""
                        onClick={handleClose}
                        src="images/frame17.svg"
                      />
                    </div>
                  </div>
                  <div className="card__container__row2">
                    <div className="row2__left">
                      <img
                        className="row2__left__bitcoinLogo"
                        alt=""
                        src="images/b57ac673f06a4b0338a596817eb0a50ce16e2059f327dc117744449a47915cb2-1@2x.png"
                      />
                      <div className="row2__left__text">
                        <p className="row2__left__text__btc">
                          {slugpopupdata.slugname}
                        </p>
                        <p className="row2__left__text__bitcoin">
                          {slugpopupdata.slug}
                        </p>
                      </div>
                    </div>
                    <div className="row2__right" >
                          <p className="row2__right__text__unit"> PRICE</p>
                       
                  <p className="row2__right__text__unitcount">
                    {slugpopupdata.pricetoday}
                  </p>
                
                </div>
                    <div className="row2__right">
                      <p className="row2__right__text__unit">UNITS</p>
                      <p className="row2__right__text__unitcount">
                        {slugpopupdata.units}
                      </p>
                    </div>
                  </div>
                  <div className="card__container__row3">
                    <div className="card__container__row3__r1">
                      <p className="card__container__row3__r1__text">Units</p>
                      <input
                        style={{ color: "#000000 !important" }}
                        className="card__container__row3__r1__input"
                        min="1"
                        max="10"
                        type="number"
                        placeholder="No. of Units"
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                      ></input>
                    </div>
                    <div className="card__container__row3__r2">
                      <p className="card__container__row3__r2__text">
                      Buy/Sell Price($)
                      </p>
                      <input
                        style={{ color: "#000000 !important" }}
                        className="card__container__row3__r2__input"
                        min="1"
                        max="10"
                        type="number"
                        placeholder="Enter Price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      ></input>
                    </div>
                    <div className="card__container__row3__r3">
                      <p className="card__container__row3__r3__text">Date</p>
                      <div className="card__container__row3__r3__date">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={selectedDate}
                            onChange={(newValue) => handleDateChange(newValue)}
                            renderInput={(prams) => <TextField />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="card__container__row3__r4">
                      <p className="card__container__row3__r4__text">
                        Select &nbsp; Buy/Sell
                      </p>
                      <ButtonGroup
                        className="buttonToggle"
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                        aria-label="text alignment"
                      >
                        <Button
                          className={
                            buySell === "buy"
                              ? "buttonToggleButActive"
                              : "buttonToggleBuy"
                          }
                          value="Buy"
                          style={{
                            lineHeight: "20px",
                            width: "100px",
                            backgroundColor: buySell === "buy" ? "blue" : "",
                            color: buySell === "buy" ? "white" : "",
                          }}
                          onClick={() => {
                            setbuySell("buy");
                            // console.log("buy clicked");
                          }}
                        >
                          Buy
                        </Button>
                        <Button
                          className={
                            buySell === "sell"
                              ? "buttonToggleSellActive"
                              : "buttonToggleSell"
                          }
                          value="sell"
                          style={{
                            lineHeight: "20px",
                            width: "100px",
                            backgroundColor: buySell === "sell" ? "blue" : "",
                            color: buySell === "sell" ? "white" : "",
                          }}
                          onClick={() => {
                            setbuySell("sell");
                            // console.log("sell clicked");
                          }}
                        >
                          sell
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleClick}
                  >
                    Save
                  </Button>
                </Card>
              </div>
            </Modal> </Box>
            {/* <Modal
         open={open}
         onClose={handleClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={style} className={styles.editPortfolio}>
           <div className={styles.editPortfolioParent}>
             <div className={styles.editPortfolio1}>Add To Portfolio </div>
             <div className={styles.primaryButton}>
               <img className={styles.frameIcon} alt="" onClick={handleClose} src="/frame17.svg" />
 
             </div>
 
           </div>
           <TableContainer component={Paper} >
             <Table className={styles.responsiveTable} >
               <TableHead>
                 <TableRow>
                   <StyledTableCell>NAME OF CRYPTO</StyledTableCell>
                   <StyledTableCell >UNITS</StyledTableCell>
                   <StyledTableCell align="right">BUY/SELL ALL UNITS</StyledTableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
 
                 <StyledTableRow>
                   <StyledTableCell component="th" scope="row" className={styles.b57ac673f06a4b0338a596817eb0a5Parent}>
                     <img width={30}
                       className={styles.b57ac673f06a4b0338a596817eb0a5Icon}
                       alt=""
                       src="/b57ac673f06a4b0338a596817eb0a50ce16e2059f327dc117744449a47915cb2-1@2x.png"
                     />
                     <div className={styles.btcParent}>
                       <div className={styles.btc}>BTC</div>
                       <div className={styles.bitcoin}>Bitcoin</div>
                     </div>
                   </StyledTableCell>
                   <StyledTableCell >4</StyledTableCell>
 
                   <StyledTableCell align="right"><Button variant="outlined" onClick={dec}><HorizontalRuleOutlinedIcon /></Button>
 
 
                     <TextField
                       sx={{
                         width: 60,
                         "& fieldset": { border: 'none' },
                       }}
                       inputProps={{ min: 0, height: 36, style: { textAlign: 'center' } }}
 
                       hiddenLabel
                       id="filled-hidden-label-normal"
                       value={count}
                       size="small"
                       type="text"
                     />
                     <Button variant="outlined" onClick={inc}><AddOutlinedIcon /></Button></StyledTableCell>
 
                 </StyledTableRow>
 
 
 
               </TableBody>
             </Table>
           </TableContainer>
 
 
           <Button className={styles.saveWrapper} variant="contained" color="primary" size="large" onClick={handleClick}>Save</Button>
 
 
         </Box>
       </Modal> */}
       <Dialog open={openModal} onClose={() => setOpenModal(false)} sx={{ zIndex: 1500 }}>
        <DialogTitle>Upload photo for {portfolioname}</DialogTitle>
        <DialogContent>
          {/* <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} id="imageInput" />
          <label htmlFor="imageInput">
            Click here to choose an image
          </label> */}
         <div style={{ border: '1px solid #003776', padding: '10px', borderRadius: '5px', maxWidth: '400px' }}>
      <label style={{ color: '#003776', cursor: 'pointer' }}>
        <u>
          Click to upload{' '}
          <label htmlFor="contained-button-file">
            <input
              accept="image/*, application/pdf"
              id="contained-button-file"
              multiple
              type="file"
              // onChange={handleImageUpload}
              onChange={(e)=>setSelectedFile(e.target.files[0])}
              style={{ display: 'none' }} // Hide the input field
            />
         
          </label>
        </u>{' '}
        <div style={{ color: '#44474F', fontSize: '13px' }}>Supported file formats: png ,jpeg &jpg.</div>
       
      </label>
    </div>
    <Typography style={{ color: '#003776', cursor: 'pointer' }}>
              {selectedFile && (
                <span>
                
             
                  <img
  src={URL.createObjectURL(selectedFile)}
  alt="Selected File"
  style={{
    maxWidth: '100%',
    marginTop: '10px',
    borderRadius: '50%', // Makes the image round
    width: '80px', // Fixed width
    height: '80px', // Fixed height
    objectFit: 'cover', // Ensures the image covers the entire space
  }}
/>
<CheckCircleIcon style={{ marginLeft: '25px' }} />File Selected.
                </span>
              )}
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button sx={{fontWeight:'bold'}} onClick={() => { setOpenModal(false); resetSelectedFile(); }}>Cancel</Button>
          <Button onClick={() => { setOpenModal(false);  }} variant="contained"sx={{fontWeight:'bold'}} disabled={!selectedFile}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
            <Snackbar
              open={snackbaropen}
              autoHideDuration={5000}
              onClose={closesnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
            </Snackbar>
          </>
        )}
        {loaderForSymbolLink===true &&
         <div >
              <CircularProgress style={{position:"fixed",top:"40vh",left:"45vw"}}/>
          </div> 
          }
          
      </Box>
      {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "105%",
            zIndex: "100",
            marginLeft:"-10px"
          }}
        >
          <BottomNavigation showLabels>
  <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
    <BottomNavigationAction
              label="Crypto Screener"
              onClick={() => navigate("/crypto-screener")}
              icon={
                <img
                  src={
                    loc === "/crypto-screener"
                      ? "/images/portfolioS.svg"
                      : "/images/portfolioU.svg"
                  }
                  width={"20px"}
                  height={"20px"}
                />
              }
            />
</BottomNavigation>
        </div>
      )}
      {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
    </Box>
    ):(

      <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  }}
>
<Helmet>
        <title>My Portflios</title>
</Helmet>
  
  <Box
    component="nav"
    sx={{
      width: "100%", // Take full width on mobile
    }}
    aria-label="mailbox folders"
  >
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth, // Adjust width for mobile
          backgroundColor: "white",
          color: "#727376",
        },
      }}
    >
      {drawer}
    </Drawer>
  </Box>


  <Box
    component="main"
    sx={{
      flexGrow: 1,
      paddingTop:3,
      paddingBottom: 3,
      paddingLeft:3,
      paddingRight:0,
      backgroundColor: "white",
      paddingBottom: "2vw",
      minHeight: "100vh",
      marginRight:"6px",
      overflowY:"auto"

    }}
    className="maindiv"
  >

        {initialPage && <Portfolioadd />}
        {!initialPage && (
          <>
            
            <div style={{ 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  marginTop: "0px", 
  marginBottom: "10px" 
}}>

                    <Typography
                      variant="h6"
                      component="div"
                      style={{
                        fontFamily: "Satoshi, sans-serif",
                        fontWeight: 700,
                        fontSize: "20px",
                      }}
                    > 
                     {selectedButton === "CoinPrices" ? (
                      <>
                        {userData ? "My Portfolios" : ""}
                      </>
                    ) : (
                      <>
                      My Watchlist
                      </>
                    )}
                    
                    </Typography>
              <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{ display: { sm: "none" } }}
  >
   <img src={window.constants.asset_path  + '/images/menubar.svg'}
 alt="Menu Icon" style={{ width: "24px", height: "24px",marginRight:"15px" }} />
  </IconButton>
  </div>

  {selectedButton == "CoinPrices" && userData &&(
    <TextField
    style={{
      width: "98%",
      borderRadius: "46px",
      marginTop: mediaQueryVar ? "" : "0px",
      fontFamily: "Satoshi, sans-serif",
      marginRight: "25px",
      marginLeft:"-10px",
      border: "1px #b6b6b8 solid",
      backgroundColor: "white",
      paddingTop: "0px",
      paddingBottom: "2px",
      paddingLeft: "12px",
      paddingRight: "30px",
      marginBottom: "5px",
      fontSize:"14px",
      fontWeight:"400"
    }}
    className="cryptocurrencyListHeaderSearch"
    size="small"
    placeholder="Search Portfolios"
    onInput={(e) => setFilter(e.target.value)}
    value={filter}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <img src="images/search.svg"/>
        </InputAdornment>
      ),
      style: {
        border: "none", // Remove the border from the input
      },
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent', // Remove the border of the fieldset
        },
        '&:hover fieldset': {
          borderColor: 'transparent', // Remove the border on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent', // Remove the border when focused
        },
      },
    }}
  />
)}

  {selectedButton == "watchlist" &&(
<TextField
                 style={{
                   width: "98%",
                   borderRadius: "46px",
                   marginTop: mediaQueryVar ? "" : "0px",
                   fontFamily: "Satoshi, sans-serif",
                   marginRight: "25px",
                   marginLeft:"-10px",
                   border: "1px #b6b6b8 solid",
                   backgroundColor: "white",
                   paddingTop: "0px",
                   paddingBottom: "2px",
                   paddingLeft: "12px",
                   paddingRight: "30px",
                   marginBottom: "5px",
                   fontSize:"14px",
                   fontWeight:"400"
                 }}
                 size="small"
                 placeholder="Search Portfolios"
                 onChange={(e) => setFilter(e.target.value)}
                 value={filter}
                 InputProps={{
                   startAdornment: (
                     <InputAdornment position="start">
                       <img  width={13} height={13} src={window.constants.asset_path + '/images/search.svg'} alt="search Icon" />
                     </InputAdornment>
                   ),
                   style: {
                     border: "none", // Remove the border from the input
                   },
                 }}
                 sx={{
                   '& .MuiOutlinedInput-root': {
                     '& fieldset': {
                       borderColor: 'transparent', // Remove the border of the fieldset
                     },
                     '&:hover fieldset': {
                       borderColor: 'transparent', // Remove the border on hover
                     },
                     '&.Mui-focused fieldset': {
                       borderColor: 'transparent', // Remove the border when focused
                     },
                   },
                 }}
               />
              )}
                
  <Grid
                  container
                  justifyContent="flex-end"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent:"flex-start",
                    width: "100%",
                    marginBottom: "35px",
                    marginTop: "17px",
                    
                  }}
                >
{userData ? (
<Box
  sx={{
    backgroundColor: "#1877F2", // Background color for the container
    paddingTop: "3.105px", // Padding inside the container
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "3.105px",
    borderRadius: "38.042px", // Border radius for the container
    display: 'inline-flex', // Make sure the box is flexible
    justifyContent: 'center', // Center the content
    alignItems: 'center', // Center the content
    width: userData? "240px":"330px", // Full width on extra small screens
    height: "39px",
    border: 'none',
    marginLeft: userData? "-5px":"-10px",
  }}
>
  <Tabs
    value={selectedButton}
    onChange={(event, newValue) => handleButtonClick(newValue)}
    aria-label="basic tabs example"
    sx={{
      marginLeft: "0px",
      "& .MuiTabs-indicator": {
        display: "none",
      },
      display: 'flex', // Make sure the tabs are displayed in a row
      overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
    }}
  >
    <Tab
      label="My Portfolios"
      value="CoinPrices"
      sx={{
        whiteSpace: "nowrap",
        borderRadius: "20.962px",
        minHeight: "31.5px",
        height: "31.5px",
        fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
        marginLeft: userData? "4px":"0px",
        paddingLeft: "16px",
        paddingRight: "16px",
        marginTop: "8px",
        border: "1px solid black",
        marginBottom: "-5px",
        fontSize: "13px",
        marginRight: "0px",
        backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
        textTransform: "none",
        border: "none",
      }}
    />
      
        <Tab
      label="My Watchlist"
      value="watchlist"
      sx={{
        whiteSpace: "nowrap",
        borderRadius:"20.962px",
        minHeight:"31px",
        height:"31px",
        marginLeft:"0px",
        marginTop:"8px",
        border:"1px solid black",
        fontWeight: selectedButton === "watchlist" ? "700" : "500",
        marginRight:"0px",
        fontSize:"13px",
        paddingRight:"-5px",
        textTransform:"none",
        backgroundColor: selectedButton === "watchlist" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "watchlist" ? "#000000 !important" : "#FFFFFF !important", // Black text for selected tab
        border: 'none', // Remove border for tabs
      }}
    />
      
  </Tabs>
</Box>
) : (
<Box
  sx={{
    backgroundColor: "#1877F2", // Background color for the container
    paddingTop: "3.105px", // Padding inside the container
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "3.105px",
    borderRadius: "38.042px", // Border radius for the container
    display: 'inline-flex', // Make sure the box is flexible
    justifyContent: 'center', // Center the content
    alignItems: 'center', // Center the content
    width: userData? "245px":"330px", // Full width on extra small screens
    height: "39px",
    border: 'none',
    marginLeft: userData? "-5px":"-10px",
  }}
>
  <Tabs
    value={selectedButton}
    onChange={(event, newValue) => handleButtonClick(newValue)}
    aria-label="basic tabs example"
    sx={{
      marginLeft: "0px",
      "& .MuiTabs-indicator": {
        display: "none",
      },
      display: 'flex', // Make sure the tabs are displayed in a row
      overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
    }}
  >
    <Tab
      label="My Portfolios"
      value="CoinPrices"
      sx={{
        whiteSpace: "nowrap",
        borderRadius: "20.962px",
        minHeight: "31.5px",
        height: "31.5px",
        fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
        marginLeft: userData? "4px":"0px",
        paddingLeft: "16px",
        paddingRight: "16px",
        marginTop: "8px",
        border: "1px solid black",
        marginBottom: "-5px",
        fontSize: "13px",
        marginRight: "0px",
        backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
        textTransform: "none",
        border: "none",
      }}
    />
      
      <Tab
          label="Top Portfolios"
          onClick={() => window.location.href = "/portfolio-gallery"}
          value="topPortfolios"
          sx={{
            whiteSpace: "nowrap",
            borderRadius: "20.962px",
            minHeight: "31px",
            height: "31px",
            marginLeft: "0px",
            marginTop: "8px",
            border: "1px solid black",
            fontWeight: selectedButton === "topPortfolios" ? "700" : "500",
            marginRight: "0px",
            fontSize: "13px",
            paddingRight: "-5px",
            textTransform: "none",
            backgroundColor: selectedButton === "topPortfolios" ? "#FFFFFF" : "transparent", // White background for selected tab
            color: selectedButton === "topPortfolios" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
            border: "none", // Remove border for tabs
          }}
        />
        <Tab
          label="Screener"
          value="screener"
          onClick={() => window.location.href = "/crypto-screener"}
          sx={{
            whiteSpace: "nowrap",
            borderRadius: "20.962px",
            minHeight: "31px",
            height: "31px",
            marginLeft: "0px",
            marginTop: "8px",
            border: "1px solid black",
            fontWeight: selectedButton === "screener" ? "700" : "500",
            marginRight: "0px",
            fontSize: "13px",
            paddingRight: "-5px",
            textTransform: "none",
            backgroundColor: selectedButton === "screener" ? "#FFFFFF" : "transparent", // White background for selected tab
            color: selectedButton === "screener" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
            border: "none", // Remove border for tabs
          }}
        />
     
    
      
  </Tabs>
</Box>
)}
{selectedButton == "CoinPrices" && userData && (
  <EditButton/>
)}



</Grid>

          
            {selectedButton === "CoinPrices" ? (
              <>
<TableContainer sx={{maxWidth:"380px",borderRadius: "10px",marginTop:"-20px",marginLeft:"-15px",
  overflowY:"auto",
  
    "&::-webkit-scrollbar": {
      display: "none",
    },
    // Hides scrollbar for Firefox
    scrollbarWidth: "none",
    marginBottom:"30px",
    paddingBottom:"50px"
}}>
  {loader && userData && (
    <Stack>
      {slugdata.map((obj,idx) => (

          <Card
            key={idx}
            sx={{
              marginBottom:"10px",
              marginLeft:"0px",
              boxShadow: "none",
              
            }}
          >
            <Box sx={{

borderRadius: "6px",
marginTop: "9px",
marginBottom:"9px",
marginLeft:"20px",
marginRight:"0px",
paddingTop: "3px",
paddingBottom:"8px",
paddingLeft:"5px",
paddingRight:"5px",
border: "1px solid #DADEDF",

background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))',
minheight:"150px",

            }}>
          <RouterLink
          key={idx}
          to={`${obj.portfolio_type_id}/${obj.portfolio_name.replace(/ /g, "-")}`}
          
          style={{ textDecoration: "none", color: "inherit" }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Box style={{ marginBottom:"-5px"}}>
                {obj.photo ? (
                  <img
                    src={window.constants.asset_path + obj.photo}
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "33px",
                      border: "2px solid black",
                     
                    }}
                    alt="Profile Pic"
                  />
                ) : (
                  <img
                    src={window.constants.asset_path + "/images/avtar.jpeg"}
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "33px",
                      border: "2px solid black"

                    }}
                    alt="Default Profile Pic"
                  />
                )}
              </Box>
              
              

              <Box sx={{ flexGrow: 1 }}>
                <Stack direction="row" justifyContent="space-between">

                  <Typography
                    variant="h6"
                    sx={{ color: "black", fontWeight: "500", fontSize: "16px", padding: "10px",fontFamily:"Satoshi,sans-serif" }}
                  >
                    {obj.portfolio_name.replace(/-/g, " ")}                  

                  </Typography>

                 

                  <RouterLink
          to={`${obj.portfolio_type_id}/${obj.portfolio_name.replace(/ /g, "-")}`}

                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Typography variant="body2">
                      <img
                        src={`${window.constants.asset_path}/images/link.svg`} // Example path
                        alt="Link Symbol"
                        style={{
                          width: "10px",
                          height: "15px",
                          marginLeft: "8px"
                        }}
                      />
                    </Typography>
                  </RouterLink>
                </Stack>
              </Box>
            </Stack>
            </RouterLink>
            <Box
        sx={{
          display: "flex",
          gap: "5px",
          flexWrap: "wrap",
          marginTop: "0px",
          marginLeft:"47px"
        }}
      >
        {obj.slugs.slice(0, 2).map((tag) => (
          <Box
            key={tag.slug}
            sx={{
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "2px 11px",
              fontSize: "11px",
              fontWeight: "500",
              color: "#000",
              
              marginBottom:"5px"
            }}
          >
            {tag.category}
          </Box>
        ))}
      </Box>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box
             sx={{
              background: "white",
              borderRadius: "6px",
              marginLeft:"20px",
              marginTop:"-20px",
              display: "flex",
              
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: "36px",
              height: "36px",
              //border: "1px solid #1877F2",
              //borderTop: "1px solid #1877F2", // Added border-top
              borderBottom: "1px solid #1877F2", // Added border-bottom
              borderLeft: "1px solid #1877F2", // Added border-left
              borderRight: "1px solid #1877F2", // Added border-right
            }}
          >
        
              <Typography sx={{color: "#76787A", fontWeight: "500", fontSize: "13px", padding: "10px",fontFamily:"Satoshi,Arial,sans-serif" ,marginTop:"-0px" }}>
                1 Month ROI:
                <span
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingLeft: "10px",
                  }}
                >
                  {obj.roi_value}
                </span>
              </Typography>
              <Typography sx={{ color: "#76787A", marginTop:"-8px",fontWeight: "500", fontSize: "13px", padding: "10px" ,fontFamily:"Satoshi,Arial,sans-serif" ,}}>
                Profit:
                <span
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingLeft: "10px",
                     
                  }}
                >
{obj.total_net_profit !== ''  ? obj.total_net_profit : 'N/A'}                </span>
              </Typography>
              
            </Box>
            <Box
              sx={{
                background: "white",
                //borderRadius: "6px",
                borderBottomLeftRadius:"6px",
                borderBottomRightRadius:"6px",
                marginLeft:"20px",
                marginTop:"-8px",
                display: "flex",
                
                justifyContent: "space-between",
                alignItems: "center",
                minHeight: "26px",
                height: "26px",
                //border: "1px solid #1877F2",
                //borderTop: "1px solid #1877F2", // Added border-top
                borderBottom: "1px solid #1877F2", // Added border-bottom
                borderLeft: "1px solid #1877F2", // Added border-left
                borderRight: "1px solid #1877F2",
              }}
            >
              <Typography sx={{  marginTop:"-8px",color: "#76787A", fontWeight: "500", fontSize: "13px", padding: "10px",fontFamily:"Satoshi,Arial,sans-serif" , }}>
                Wallet:
                <span
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingLeft: "10px",
                  }}
                >
                  {obj.total_wallet}
                </span>
              </Typography>

    <div style={{ display: "flex", alignItems: "center",margin:"5px",paddingBottom:"2px" }}>
      <img
            src={
              lockStatus[obj.portfolio_type_id]
                ? window.constants.asset_path + '/images/lock.svg'
                : window.constants.asset_path + '/images/unlock.svg'
            }
            width="20px"
            height="20px"
            alt={lockStatus[obj.portfolio_type_id] ? "Lock" : "Unlock"}
            style={{ cursor: "pointer" }}  // Make it clickable
            onClick={(e) => {
              e.stopPropagation();  // Stops the event from propagating up the DOM
              handleLockClick(obj.portfolio_type_id);  // Toggle lock/unlock for this specific row
            }}
          />
    </div>
              
              
            </Box>
          </Card>
      ))}
    </Stack>
  ) }
  {!userData && (
    <div
    style={{
      display: 'flex',
      justifyContent: 'center', // Centers the button horizontally
      alignItems: 'center', // Centers the button vertically (if height is set)
    }}
  >
    <button
      onClick={() => {
        const originalUrl = '/audit-my-portfolio';
        sessionStorage.setItem("originalUrl", originalUrl);
        console.log("original url: " + originalUrl);
        handleLoginClick();
      }}
      style={{
        border: "1px solid #1877F2",
        borderRadius: "48px",
        padding: "10px 26px",
        backgroundColor: "white",
        color: "#1877F2",
        fontSize: "18px",
        height: "66px",
        marginRight: "8px",
        fontWeight: "500",
        margin: "20px",
        marginRight:"-20px"
      }}
    >
      Audit My Portfolio
    </button>
  </div>
  
  )}
  {slugdata.length === 0 && loader===true && (
    <Typography align="center" variant="h6">
      No Data Found
    </Typography>
  )}
 {rowsToShow < sortedData.length && (
    <Box sx={{ textAlign: "center", mt: 2 }}>
      <LoadingButton
        loading={addMoreLoader}
        loadingPosition="center"
        variant="outlined"
        style={{
          maxWidth: "360px",
          width: "80vw",
          backgroundColor: "rgba(67, 97, 238, 0.15)",
          borderRadius: "6px",
          border: "none",
          textTransform: "none",
          borderStyle: "solid",
          borderColor: "rgba(67, 97, 238, 0.15)",
          borderWidth: "1px",
          color: "#4361ee",
          marginBottom:"80px"
        }}
        onClick={handleLoadMore}
      >
        <span style={{ color: "#4361ee" }}>Load More</span>
      </LoadingButton>
    </Box>
  )}
</TableContainer>
             
              
           
              </>
            ) : (
              <>
         <WatchListGallery filter={filter} onButtonClick={handleButtonClick}/>
                 </>
              
            )}
          
        
        
            
            <Modal
              sx={{ overflow: "scroll" }}
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="CardOpener">
                <Card className="card__container">
                  <div className="card__container__row1">
                    <Typography className="editPortfolio1" gutterBottom>
                      Add To Portfolio
                    </Typography>
                    <div className="primaryButton">
                      <img
                        className="frameIcon"
                        alt=""
                        onClick={handleClose}
                        src="images/frame17.svg"
                      />
                    </div>
                  </div>
                  <div className="card__container__row2">
                    <div className="row2__left">
                      <img
                        className="row2__left__bitcoinLogo"
                        alt=""
                        src="images/b57ac673f06a4b0338a596817eb0a50ce16e2059f327dc117744449a47915cb2-1@2x.png"
                      />
                      <div className="row2__left__text">
                        <p className="row2__left__text__btc">
                          {slugpopupdata.slugname}
                        </p>
                        <p className="row2__left__text__bitcoin">
                          {slugpopupdata.slug}
                        </p>
                      </div>
                    </div>
                    <div className="row2__right" >
                          <p className="row2__right__text__unit"> PRICE</p>
                       
                  <p className="row2__right__text__unitcount">
                    {slugpopupdata.pricetoday}
                  </p>
                
                </div>
                    <div className="row2__right">
                      <p className="row2__right__text__unit">UNITS</p>
                      <p className="row2__right__text__unitcount">
                        {slugpopupdata.units}
                      </p>
                    </div>
                  </div>
                  <div className="card__container__row3">
                    <div className="card__container__row3__r1">
                      <p className="card__container__row3__r1__text">Units</p>
                      <input
                        style={{ color: "#000000 !important" }}
                        className="card__container__row3__r1__input"
                        min="1"
                        max="10"
                        type="number"
                        placeholder="No. of Units"
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                      ></input>
                    </div>
                    <div className="card__container__row3__r2">
                      <p className="card__container__row3__r2__text">
                      Buy/Sell Price($)
                      </p>
                      <input
                        style={{ color: "#000000 !important" }}
                        className="card__container__row3__r2__input"
                        min="1"
                        max="10"
                        type="number"
                        placeholder="Enter Price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      ></input>
                    </div>
                    <div className="card__container__row3__r3">
                      <p className="card__container__row3__r3__text">Date</p>
                      <div className="card__container__row3__r3__date">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={selectedDate}
                            onChange={(newValue) => handleDateChange(newValue)}
                            renderInput={(prams) => <TextField />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="card__container__row3__r4">
                      <p className="card__container__row3__r4__text">
                        Select &nbsp; Buy/Sell
                      </p>
                      <ButtonGroup
                        className="buttonToggle"
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                        aria-label="text alignment"
                      >
                        <Button
                          className={
                            buySell === "buy"
                              ? "buttonToggleButActive"
                              : "buttonToggleBuy"
                          }
                          value="Buy"
                          style={{
                            lineHeight: "20px",
                            width: "100px",
                            backgroundColor: buySell === "buy" ? "blue" : "",
                            color: buySell === "buy" ? "white" : "",
                          }}
                          onClick={() => {
                            setbuySell("buy");
                            // console.log("buy clicked");
                          }}
                        >
                          Buy
                        </Button>
                        <Button
                          className={
                            buySell === "sell"
                              ? "buttonToggleSellActive"
                              : "buttonToggleSell"
                          }
                          value="sell"
                          style={{
                            lineHeight: "20px",
                            width: "100px",
                            backgroundColor: buySell === "sell" ? "blue" : "",
                            color: buySell === "sell" ? "white" : "",
                          }}
                          onClick={() => {
                            setbuySell("sell");
                            // console.log("sell clicked");
                          }}
                        >
                          sell
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleClick}
                  >
                    Save
                  </Button>
                </Card>
              </div>
            </Modal> 
            {/* <Modal
         open={open}
         onClose={handleClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={style} className={styles.editPortfolio}>
           <div className={styles.editPortfolioParent}>
             <div className={styles.editPortfolio1}>Add To Portfolio </div>
             <div className={styles.primaryButton}>
               <img className={styles.frameIcon} alt="" onClick={handleClose} src="/frame17.svg" />
 
             </div>
 
           </div>
           <TableContainer component={Paper} >
             <Table className={styles.responsiveTable} >
               <TableHead>
                 <TableRow>
                   <StyledTableCell>NAME OF CRYPTO</StyledTableCell>
                   <StyledTableCell >UNITS</StyledTableCell>
                   <StyledTableCell align="right">BUY/SELL ALL UNITS</StyledTableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
 
                 <StyledTableRow>
                   <StyledTableCell component="th" scope="row" className={styles.b57ac673f06a4b0338a596817eb0a5Parent}>
                     <img width={30}
                       className={styles.b57ac673f06a4b0338a596817eb0a5Icon}
                       alt=""
                       src="/b57ac673f06a4b0338a596817eb0a50ce16e2059f327dc117744449a47915cb2-1@2x.png"
                     />
                     <div className={styles.btcParent}>
                       <div className={styles.btc}>BTC</div>
                       <div className={styles.bitcoin}>Bitcoin</div>
                     </div>
                   </StyledTableCell>
                   <StyledTableCell >4</StyledTableCell>
 
                   <StyledTableCell align="right"><Button variant="outlined" onClick={dec}><HorizontalRuleOutlinedIcon /></Button>
 
 
                     <TextField
                       sx={{
                         width: 60,
                         "& fieldset": { border: 'none' },
                       }}
                       inputProps={{ min: 0, height: 36, style: { textAlign: 'center' } }}
 
                       hiddenLabel
                       id="filled-hidden-label-normal"
                       value={count}
                       size="small"
                       type="text"
                     />
                     <Button variant="outlined" onClick={inc}><AddOutlinedIcon /></Button></StyledTableCell>
 
                 </StyledTableRow>
 
 
 
               </TableBody>
             </Table>
           </TableContainer>
 
 
           <Button className={styles.saveWrapper} variant="contained" color="primary" size="large" onClick={handleClick}>Save</Button>
 
 
         </Box>
       </Modal> */}
        <Dialog open={openModal} onClose={() => setOpenModal(false)} sx={{ zIndex: 1500 }}>
        <DialogTitle>Upload photo for {portfolioname}</DialogTitle>
        <DialogContent>
          {/* <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} id="imageInput" />
          <label htmlFor="imageInput">
            Click here to choose an image
          </label> */}
         <div style={{ border: '1px solid #003776', padding: '10px', borderRadius: '5px', maxWidth: '400px' }}>
      <label style={{ color: '#003776', cursor: 'pointer' }}>
        <u>
          Click to upload{' '}
          <label htmlFor="contained-button-file">
            <input
              accept="image/*, application/pdf"
              id="contained-button-file"
              multiple
              type="file"
              // onChange={handleImageUpload}
              onChange={(e)=>setSelectedFile(e.target.files[0])}
              style={{ display: 'none' }} // Hide the input field
            />
         
          </label>
        </u>{' '}
        <div style={{ color: '#44474F', fontSize: '13px' }}>Supported file formats: png ,jpeg &jpg.</div>
       
      </label>
    </div>
    <Typography style={{ color: '#003776', cursor: 'pointer' }}>
              {selectedFile && (
                <span>
                
             
                  <img
  src={URL.createObjectURL(selectedFile)}
  alt="Selected File"
  style={{
    maxWidth: '100%',
    marginTop: '10px',
    borderRadius: '50%', // Makes the image round
    width: '80px', // Fixed width
    height: '80px', // Fixed height
    objectFit: 'cover', // Ensures the image covers the entire space
  }}
/>
<CheckCircleIcon style={{ marginLeft: '25px' }} />File Selected.
                </span>
              )}
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button sx={{fontWeight:'bold'}} onClick={() => { setOpenModal(false); resetSelectedFile(); }}>Cancel</Button>
          <Button onClick={() => { setOpenModal(false);  }} variant="contained"sx={{fontWeight:'bold'}} disabled={!selectedFile}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
            <Snackbar
              open={snackbaropen}
              autoHideDuration={5000}
              onClose={closesnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
            </Snackbar>
          </>
        )}
        {loaderForSymbolLink===true &&
         <div >
              <CircularProgress style={{position:"fixed",top:"40vh",left:"45vw"}}/>
          </div> 
          }
          
      </Box>
      {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "100%",
            zIndex: "100",
            marginTop:"5px"
          }}
        >
          <BottomNavigation showLabels>
          <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
              label="Crypto Screener"
              onClick={() => navigate("/crypto-screener")}
              icon={
                <img
                  src={
                    loc === "/crypto-screener"
                      ? "/images/portfolioS.svg"
                      : "/images/portfolioU.svg"
                  }
                  width={"20px"}
                  height={"20px"}
                />
              }
            />
</BottomNavigation>
        </div>
      )}
      {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
    </Box>
    )}
</>

  );
}

export default MultiPortfolio;