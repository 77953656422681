import React, {useState, useEffect, useMemo, useCallback, useRef} from "react";

import "../../CryptoPage.css";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {BottomNavigation, CircularProgress} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import {Grid} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import {useLocation, Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {getUserRewardList} from "../../../apihelper/portfolio";

import EmailPopUp from "../../EmailPopUp.js";
import SidebarMenuList from "../../../includes/sidebarMenuList.jsx";
import LoginPopUp from "../../loginpopup.js";
import TransactionCards from "./TransactionCards.jsx";
import TabHook from "../../../common/hooks/TabHook.jsx";

const RewardTrasactionHistory = (props) => {
    const {window1} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [rewards, setRewards] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = useMemo(() => JSON.parse(localStorage.getItem("cw_portfolio_user")), []);
    const drawerWidth = 292;
    const [page, setPage] = useState(0);
    const limit = 10;

    // const [loadingRewards, setLoadingRewards] = useState(false);
    const [dataCompleted, setDataCompleted] = useState(false);
    const tableBodyRef = useRef(null);
    const lastRowRef = useRef(null);

    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );

    const mediaQueryVar2 = useMediaQuery(
        json2mq({
            maxWidth: 420,
        })
    );

    const container = window1 ? window1().document.body : undefined;

    const getIcon = useMemo(
        () => (path, activeIcon, inactiveIcon) => location.pathname === path ? activeIcon : inactiveIcon,
        [location.pathname]
    );

    const fetchUserRewards = useCallback(async () => {
        if (loading || dataCompleted) return;
        const startIndex = page * limit;

        setLoading(true);
        try {
            const data = await getUserRewardList(userData, startIndex, limit);
            if (data.length < limit) {
                setDataCompleted(true);
            }
            setPage(page + 1);
            setRewards((prevRewards) => [...prevRewards, ...data]);
        } catch (error) {
            console.error("Failed to fetch rewards", error);
        } finally {
            setLoading(false);
        }
    }, [userData, loading, dataCompleted]);

    useEffect(() => {
        fetchUserRewards();
    }, [fetchUserRewards]);

    const handleModalClose = useCallback(() => {
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    }, [navigate, location.pathname]);

    const handleDrawerToggle = useCallback(() => {
        setMobileOpen((prev) => !prev);
    }, []);

    const handleSubscriptionClick = () => {
        if (userData) {
            navigate("/subscriptions/screener360");
        } else {
            const originalUrl = "/subscriptions/screener360";
            sessionStorage.setItem("originalUrl", originalUrl);
            handleLoginClick();
        }
    };

    const handleLoginClick = () => {
        window.history.pushState({}, "", "/login");
        localStorage.setItem("isPopupManuallyOpened", "true");
        setLoginModalOpen(true);
    };

    const handleIntersection = (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !dataCompleted) {
            fetchUserRewards(); // Trigger the API call when the last row is in the viewport
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: tableBodyRef.current,
            rootMargin: "0px",
            threshold: 1.0,
        });

        if (lastRowRef.current) {
            observer.observe(lastRowRef.current);
        }

        // Cleanup the observer when the component unmounts
        return () => {
            if (lastRowRef.current) {
                observer.unobserve(lastRowRef.current);
            }
        };
    }, [handleIntersection]);

    return (
        <div
            className="crypto-page"
            style={{
                padding: mediaQueryVar ? 0 : "10px",
                margin: 0,
                backgroundColor: mediaQueryVar ? "#F2F2F2" : "#fff",
                height: "100vh",
            }}
        >
            <Helmet>
                <title>Transaction History</title>
            </Helmet>
            {mediaQueryVar ? (
                <div className="layout">
                    <Box
                        component="nav"
                        sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                        aria-label="mailbox folders"
                    >
                        <Drawer
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{keepMounted: true}}
                            sx={{
                                display: {xs: "block", sm: "none"},
                                "& .MuiDrawer-paper": {
                                    boxSizing: "border-box",
                                    width: drawerWidth,
                                    backgroundColor: "white",
                                    color: "#727376",
                                },
                            }}
                        >
                            <div className="d-flex flex-column h-100" style={{overflowY: "hidden"}}>
                                <SidebarMenuList />
                            </div>
                        </Drawer>
                        <Drawer
                            variant="permanent"
                            sx={{
                                display: {xs: "none", sm: "block"},
                                "& .MuiDrawer-paper": {
                                    boxSizing: "border-box",
                                    width: drawerWidth,
                                    backgroundColor: "white",
                                    color: "#727376",
                                },
                            }}
                            open
                        >
                            <div className="d-flex flex-column h-100" style={{overflowY: "hidden"}}>
                                <SidebarMenuList />
                            </div>
                        </Drawer>
                    </Box>

                    <div className="content" style={{margin: "0%", padding: "10px", marginTop: "60px"}}>
                        <div style={{margin: "8px", width: "200px"}}>
                            <TabHook
                                activeTab={1}
                                tabList={[
                                    {title: "Earn", url: "/account"},
                                    {title: "Withdraw", url: "/reward-transaction-history"},
                                ]}
                            />
                        </div>
                        <div style={{alignItems: "center"}}>
                            <TransactionCards clickable={false} />
                        </div>

                        <div
                            style={{
                                borderRadius: "10px",
                                backgroundColor: "#fff",
                                padding: "15px",
                                fontFamily: "Satoshi, sans-serif",
                                margin: "15px 5px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                }}
                            >
                                <span style={{fontSize: "20px", fontWeight: "700"}}>Reward Credit</span>
                            </div>

                            <div>
                                <table
                                    className="predictions-table"
                                    style={{border: "2px solid #DADEDF", width: "100%", marginLeft: 0}}
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    cursor: "pointer",
                                                    textAlign: "left",
                                                    width: "40%",
                                                    fontWeight: "500",
                                                    color: "#727376",
                                                    fontSize: "16px",
                                                }}
                                            >
                                                Action
                                            </th>
                                            <th
                                                style={{
                                                    cursor: "pointer",
                                                    textAlign: "center",
                                                    fontWeight: "500",
                                                    color: "#727376",
                                                    fontSize: "16px",
                                                    width: "30%",
                                                }}
                                            >
                                                Reward Token
                                            </th>
                                            <th
                                                style={{
                                                    cursor: "pointer",
                                                    textAlign: "center",
                                                    fontWeight: "500",
                                                    color: "#727376",
                                                    fontSize: "16px",
                                                    width: "30%",
                                                }}
                                            >
                                                Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rewards?.length > 0 ? (
                                            rewards?.map((data, index) => (
                                                <>
                                                    <tr key={index}>
                                                        <td
                                                            style={{
                                                                width: "23%",
                                                                background:
                                                                    "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
                                                                borderBottom: "6px solid #fff",
                                                                padding: "5px 7px",
                                                                color: "#000",
                                                                fontSize: "16px",
                                                                fontWeight: "500",
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                                                            {data.action || "N/A"}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                paddingLeft: "10px",
                                                            }}
                                                        >
                                                            {data.reward_tokens ?? "0"}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                paddingLeft: "10px",
                                                            }}
                                                        >
                                                            {data.created_at
                                                                ? new Date(data.created_at).toLocaleDateString()
                                                                : "N/A"}
                                                        </td>
                                                    </tr>
                                                    {/* Last row that is observed */}
                                                    <tr ref={lastRowRef} style={{visibility: "hidden"}}></tr>
                                                </>
                                            ))
                                        ) : !loading && dataCompleted && rewards?.length === 0 ? (
                                            <tr>
                                                <td
                                                    colSpan={3}
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "20px",
                                                        fontSize: "18px",
                                                        fontWeight: "500",
                                                        color: "#727376",
                                                    }}
                                                >
                                                    No Reward Transaction Available
                                                </td>
                                            </tr>
                                        ) : null}
                                        {loading && !dataCompleted && (
                                            <tr>
                                                <td colSpan="100%" style={{textAlign: "center", padding: "20px"}}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <CircularProgress />
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <Grid item xs={12} align="left" sx={{marginBottom: "10%"}}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "10px",
                                marginLeft: "5px",
                                fontSize: "20px",
                            }}
                        >
                            <EmailPopUp />

                            <Typography style={{fontSize: "20px", fontWeight: "700"}}>
                                Reward Transaction History
                            </Typography>

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerToggle}
                                sx={{display: {sm: "none"}}}
                            >
                                <img
                                    src="/images/menubar.svg"
                                    alt="Menu Icon"
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                        marginRight: "10px",
                                        marginTop: "10px",
                                    }}
                                />
                            </IconButton>
                        </div>
                    </Grid>

                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{keepMounted: true}}
                        sx={{
                            display: {xs: "block", sm: "none"},
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: {drawerWidth},
                                backgroundColor: "white",
                                color: "#727376",
                            },
                        }}
                    >
                        <div className="d-flex flex-column h-100" style={{overflowY: "hidden"}}>
                            <SidebarMenuList />
                        </div>
                    </Drawer>
                    <div style={{margin: "8px", width: "160px"}}>
                        <TabHook
                            activeTab={1}
                            tabList={[
                                {title: "Earn", url: "/account"},
                                {title: "Withdraw", url: "/reward-transaction-history"},
                            ]}
                        />
                    </div>
                    <TransactionCards clickable={false} />

                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "10%",
                            }}
                        >
                            <span style={{fontSize: "18px", fontWeight: "700"}}>Reward Credit</span>
                        </div>

                        <table
                            className="predictions-table-mobile"
                            style={{
                                width: "100%",
                                borderCollapse: "separate",
                                borderSpacing: "0 10px",
                                marginBottom: "80px",
                            }}
                        >
                            <tbody>
                                {dataCompleted && !rewards?.length ? (
                                    <tr>
                                        <td
                                            colSpan={5}
                                            style={{
                                                textAlign: "center",
                                                padding: "20px",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#76787A",
                                            }}
                                        >
                                            No Reward Transaction Available
                                        </td>
                                    </tr>
                                ) : (
                                    rewards?.map((data, index) => (
                                        <tr
                                            key={index}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                borderRadius: "10px",
                                                marginBottom: "20px",
                                                padding: "0px",
                                                height: "100px",
                                            }}
                                        >
                                            <td
                                                style={{
                                                    background:
                                                        "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
                                                    borderRadius: "6px 6px 0px 0px",
                                                    padding: "10px",
                                                    fontWeight: "500",
                                                    fontSize: "20px",
                                                    color: "#000",
                                                }}
                                            >
                                                {data.action}
                                            </td>

                                            <td style={{padding: "4px 10px", fontSize: "14px"}}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    <div>
                                                        <span
                                                            style={{
                                                                color: "#76787A",
                                                                fontSize: "16px",
                                                                marginRight: "7px",
                                                            }}
                                                        >
                                                            Reward Token :
                                                        </span>{" "}
                                                        {data.reward_tokens}
                                                    </div>
                                                    <div>
                                                        <span
                                                            style={{
                                                                color: "#76787A",
                                                                fontSize: "16px",
                                                                marginRight: "7px",
                                                            }}
                                                        >
                                                            Date :
                                                        </span>{" "}
                                                        {data.created_at
                                                            ? new Date(data.created_at).toLocaleDateString()
                                                            : "N/A"}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                                <tr ref={lastRowRef} style={{visibility: "hidden"}}></tr>
                            </tbody>
                            {loading && !dataCompleted && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "20px 0",
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            )}
                        </table>
                    </div>

                    <div
                        style={{
                            position: "fixed",
                            bottom: "0",
                            background: "#F1F1F1",
                            height: "10px",
                            zIndex: 200,
                            width: "100%",
                        }}
                    />
                    <div
                        style={{
                            position: "fixed",
                            bottom: "0",
                            backgroundColor: "white",
                            width: "105%",
                            zIndex: "100",
                            marginBottom: "0px",
                            marginLeft: "-25px",
                        }}
                    >
                        <BottomNavigation showLabels>
                            <BottomNavigationAction
                                label="Top Portfolios"
                                onClick={() => navigate("/portfolio-gallery")}
                                icon={
                                    <img
                                        src={getIcon(
                                            "/portfolio-gallery",
                                            "/images/dashboardS.svg",
                                            "/images/dashboardU.svg"
                                        )}
                                        width={"20px"}
                                        height={"20px"}
                                        alt="Top Portfolios"
                                    />
                                }
                            />
                            <BottomNavigationAction
                                label="Crypto Screener"
                                onClick={() => navigate("/crypto-screener")}
                                icon={
                                    <img
                                        src={getIcon(
                                            "/crypto-screener",
                                            "/images/portfolioS.svg",
                                            "/images/portfolioU.svg"
                                        )}
                                        width={"20px"}
                                        height={"20px"}
                                        alt="Crypto Screener"
                                    />
                                }
                            />
                            <BottomNavigationAction
                                label="$1/Month Subscription"
                                onClick={handleSubscriptionClick}
                                icon={
                                    <img
                                        src={getIcon(
                                            "/subscriptions/screener360",
                                            "/images/dollarS.svg",
                                            "/images/dollarU.svg"
                                        )}
                                        width={24}
                                        height={24}
                                        alt="Subscription"
                                        style={{marginBottom: "-3px"}}
                                    />
                                }
                            />
                        </BottomNavigation>
                    </div>
                </>
            )}

            {isLoginModalOpen && (
                <LoginPopUp
                    isLoginModalOpen={isLoginModalOpen}
                    setLoginModalOpen={setLoginModalOpen}
                    onClose={handleModalClose}
                />
            )}
        </div>
    );
};

export default RewardTrasactionHistory;
