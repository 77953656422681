import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Snackbar, Alert, Autocomplete } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { completeGoogleLogin } from "../apihelper/login";
import Box from "@mui/material/Box";
import Drawer from '@mui/material/Drawer';
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import './CryptoPage.css'; // Assuming you have a CSS file for styling
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";
import { addDays, format } from 'date-fns'
import IconButton from "@mui/material/IconButton";
import { Helmet } from 'react-helmet';
import LoginPopUp from "./loginpopup.js";


const drawerWidth = 292;

const AirdropForm = (props) => {
  const { window1 } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const container = window1 !== undefined ? () => window1().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [cryptoOptions, setCryptoOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [mediaQueryVar1, setMediaQueryVar] = useState(window.innerWidth < 900);
  const loc = location.pathname;
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");

    // Open the login modal
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal and reset the URL to the current page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
};


  
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  
const drawer = (
  <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
<SidebarMenuList/>
  </div>
);

const handleDrawerToggle = () => {
  setMobileOpen(!mobileOpen);
};


  return (
    <div className="layout" 
    style=
    {{ 
      backgroundColor: '#EDF1F6', 
      minHeight: '100vh', 
      padding: '20px',
      paddingBottom:mediaQueryVar ? "" : "200px"
       }}>
         <Helmet>
        <title>Airdrop Form</title>
</Helmet>
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>

    {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
    <div className="content">
    <div style={{display:"flex", justifyContent:"space-between"}}>
    <Button size="small" color="primary"onClick={handleGoBack} edge="start"  aria-label="menu"
            style={{
              borderRadius: "42px",
              padding:"10px 14px",
              backgroundColor:"#FFF",
              border:"1px solid #F0F0F0",
              width:"85px",
              height:"38px",
              marginLeft:"4%",
              marginBottom:"10px",
              marginTop:"20px"
            }}>
            <img src={window.constants.asset_path + '/images/backarrow.svg'} alt="" /> 
            <span style={{fontSize:"14px",fontWeight:"500",color:"#727272",marginLeft:"8px",textTransform:"none"}}>Back</span>
      </Button>
      <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
        >
            <img 
                src='/images/menubar.svg'
                alt="Menu Icon" 
                style={{ 
                    width: "24px", 
                    height: "24px",
                    marginRight: "10px",
                    marginTop: "10px" 
                }} 
            />
      </IconButton>
      </div>
      <div style={{ height: mediaQueryVar===true ? "" : "100%", marginTop:"5%",backgroundColor: 'white', borderRadius: '8px', padding: mediaQueryVar ? '32px': '18px', margin: mediaQueryVar===true ? "23px 40px 10px 40px" : "10px" , boxShadow: '0 2px 4px rgba(0,0,0,0.1)',  }}>
  
  <div style={{
  marginTop:mediaQueryVar ? "" : "20px",
  display: "flex",
  flexDirection: "column", // Adjust layout based on screen size
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  maxWidth: "1200px", // Increase the max width for larger screens
  margin: "0 auto",
  padding: mediaQueryVar ? "20px" : "0px",
  fontFamily: "'Satoshi', sans-serif",
  color: "#333",
  
  gap: "30px", // Space between content and form
}}>

  {/* Left Side - Content Section */}
  <div style={{
    flex: 1,
    padding: mediaQueryVar ? "20px" : "8px"
  }}>
    <h2 style={{
      color: "#1877F2",
      marginBottom: "20px",
      lineHeight: "1.5",
      fontSize: "20px", // Slightly larger for emphasis
      textAlign: "left", // Align left for a professional look
    }}>
      Crowdwisdom360 Airdrop: Get Started Today!
    </h2>
    <p style={{
      marginBottom: "20px",
      fontSize: "18px",
      lineHeight: "1.8", // Increase line height for better readability
    }}>
      Add your portfolio (no wallet connect needed), check our analytics and recommendation engine, 
      add your Solana wallet address, and receive $10 worth of Wisecoins.
    </p>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {!userData && (
      <Button
       onClick={() => {
        const originalUrl = '/account';
        sessionStorage.setItem('originalUrl', originalUrl);
        console.log("original url: " + originalUrl);
        handleLoginClick();
      }}
        type="submit"
        variant="contained"
        color="primary"
        style={{
          color: "white",
          background: '#1877F2',
          padding: "5px",
          textTransform: "none",
          width:"150px",
          minHeight: "45px",
          fontWeight: '700',
          fontSize: '18px',
          borderRadius: "23.363px",
          marginBottom:"10px"

        }}
      >
       Sign Up Now!
      </Button>
    )}
    </div>
    <h3 style={{
      color: "#1877F2",
      marginBottom: "10px",
      lineHeight: "1.5",
      fontSize: "20px", 
      textAlign: "left",
      borderBottom: "2px solid #E1E1E1",
      paddingBottom: "10px",
    }}>
      Crowdwisdom360 Platform Summary
    </h3>
    <p style={{
      marginBottom: "20px",
      fontSize: "18px",
      lineHeight: "1.8",
    }}>
      Crowdwisdom360 offers a streamlined approach to maximizing your crypto returns through top influencer 
      portfolios and personalized recommendations. Using machine learning, the platform recommends portfolios 
      and cryptocurrencies tailored to your preferences and investment goals.
    </p>
    <p style={{
      marginBottom: "20px",
      fontSize: "18px",
      lineHeight: "1.8",
    }}>
      Users can explore portfolios across various categories and track their performance with detailed ROI metrics. 
      The platform features influencer profiles and their track records, providing insights into their investment strategies 
      and past performance. It also supports tracking transaction history, managing watchlists, and earning rewards for engagement.
    </p>
    <h3 style={{
      color: "#1877F2",
      marginBottom: "10px",
      lineHeight: "1.5",
      fontSize: "20px", 
      textAlign: "left",
      borderBottom: "2px solid #E1E1E1",
      paddingBottom: "10px",
    }}>
      For Creators and Influencers
    </h3>
    <p style={{
      marginBottom: "20px",
      fontSize: "18px",
      lineHeight: "1.8",
    }}>
      Creators and influencers on Crowdwisdom360 can elevate their presence by curating and sharing their own portfolios. 
      They can build a dedicated following, earn rewards for their recommendations and engagement, and leverage social 
      sharing tools to promote their portfolios. Creators also have the opportunity to monetize their expertise by offering 
      exclusive content and charging for access to their recommendations, while benefiting from advanced analytics to 
      refine their investment strategies.
    </p>
  </div>
 

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {!userData && (
      <Button
       onClick={() => {
        const originalUrl = '/account';
        sessionStorage.setItem('originalUrl', originalUrl);
        console.log("original url: " + originalUrl);
        handleLoginClick();
      }}
        type="submit"
        variant="contained"
        color="primary"
        style={{
          color: "white",
          background: '#1877F2',
          padding: "5px",
          textTransform: "none",
          width:"150px",
          minHeight: "45px",
          fontWeight: '700',
          fontSize: '18px',
          borderRadius: "23.363px"
        }}
      >
       Sign Up Now!
      </Button>
    )}
    </div>
    
</div>
</div>
      
    </div>

    {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            marginLeft:"-6%",
            width: "105%",
            zIndex: "1000",
            marginLeft:"-10px"
          }}
        >
          <BottomNavigation showLabels>
      <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>{
          navigate("/portfolio-gallery");
        }
         
        }
        icon={<img src={loc === '/portfolio-gallery' ? `${window.constants.asset_path}/images/dashboardS.svg` : `${window.constants.asset_path}/images/dashboardU.svg`} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
        navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
      }}
        icon={<img src={loc === '/my-portfolio' ? `${window.constants.asset_path}/images/portfolioS.svg` : `${window.constants.asset_path}/images/portfolioU.svg`}  />}
      />
 <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? `${window.constants.asset_path}/images/dollarS.svg`:`${window.constants.asset_path}/images/dollarU.svg`
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />

    

      </BottomNavigation>
        </div>
      )}
  </div>
  );
};

export default AirdropForm;
