import React, { useEffect, useState } from "react";
import {
    useAppKitAccount,
    useAppKitNetworkCore,
    useAppKitProvider,
    useDisconnect,
} from "@reown/appkit/react";
import { ethers, JsonRpcSigner } from "ethers";
import { saveCryptoPresalePayment, verifyTransactionEthBackend } from "../presale_form/api";
import { bnbRecipientAddress } from "../../../common/constant/api_key";
import ProcessingAlert from "../../../common/alert/ProcessingAlert";
import SuccessPaymentAlert from "../../../common/alert/SuccessPaymentAlert";
import { X } from "lucide-react";
import { a } from "react-spring";

const BNBConnectButton = ({ paymentMethod, select_price }) => {
    const { address, isConnected } = useAppKitAccount();
    const { disconnect } = useDisconnect();

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { walletProvider } = useAppKitProvider("bsc:mainnet");
    const {chainId} = useAppKitNetworkCore();
    console.log("Connected useAppKitProvider:", useAppKitProvider(), useAppKitAccount());

    useEffect(() => {
        if (walletProvider) {
          console.log("Wallet provider available:", walletProvider);
        }
      }, [walletProvider]);

    // Handle Send BNB Logic
    const handleSendBNB = async () => {
        if (!select_price) {
            alert("Please select an amount")
            return;
        }
        try {
            if (!walletProvider) {
                alert("Wallet provider is not available. Please connect your wallet.");
                return;
            }

            console.log("Recipient Address:", address);

            const response = await fetch(
                "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd"
            );
            const data = await response.json();
            const bnbPriceInUsd = data.binancecoin.usd;
            const priceInUSD = select_price;
            const bnbToPay = ethers.parseUnits((priceInUSD / bnbPriceInUsd).toFixed(8), "ether");

            console.log("BNB price in USD:", bnbPriceInUsd);
            console.log("BNB to pay:", bnbToPay);

            const provider = new ethers.BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            const network = await provider.getNetwork();

            if (network.chainId !== 97) {
                alert("Please switch to Binance Smart Chain (BSC Testnet) in your wallet.");
                return;
            }

            const tx = {
                to: bnbRecipientAddress,
                value: bnbToPay,
            };

            console.log("Transaction Details:", tx);

            setLoading(true);
            const transactionResponse = await signer.sendTransaction(tx);
            await transactionResponse.wait();

            console.log("BNB transaction successful:", transactionResponse.hash);
            await saveCryptoPresalePayment(
                bnbRecipientAddress,
                await signer.getAddress(),
                transactionResponse.hash,
                select_price,
                "bnb"
            );

            setIsSuccess(true);
        } catch (error) {
            console.error("Error sending BNB:", error);
            alert(`An error occurred: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };


    // Handle Disconnect Logic
    const handleDisconnect = async () => {
        setLoading(true);
        try {
            await disconnect();
            setIsSuccess(false);
        } catch (error) {
            console.error("Disconnection failed:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isConnected) {
            handleDisconnect();
        }
    }, [paymentMethod]);

    return loading ? (
        <ProcessingAlert />
    ) : (
        <>
            <div style={{ textAlign: "center" }}>
                <appkit-button />
                {isConnected && <button onClick={() => handleSendBNB()}>Pay with {paymentMethod.toUpperCase()}</button>}
            </div>
            <SuccessPaymentAlert open={isSuccess} onClose={() => setIsSuccess(false)} />
        </>
    );
};

export default BNBConnectButton;
