import { Drawer, useMediaQuery } from "@mui/material";
import React from "react";
import SidebarMenuList from "../../includes/sidebarMenuList";
import { isDesktop, drawerWidth } from "../constants";
import json2mq from "json2mq";

const Header2 = ({ index, children }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const mediaQueryVar = useMediaQuery(json2mq(isDesktop));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return mediaQueryVar ? (
        <div className="col" style={{maxWidth:"315px"}}>
            <Drawer
                variant="permanent"
                open
                sx={{
                    display: { xs: "block" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                        backgroundColor: "white",
                        color: "#727376",
                    },
                }}
            >
                <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
                    <SidebarMenuList />
                </div>
            </Drawer>
        </div>
    ) : (
        <div>
            <div className="d-flex justify-content-between py-4 px-3">
                {children}
                {!mediaQueryVar && (
                    <div
                        onClick={handleDrawerToggle}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            marginTop: "10px",
                        }}
                    >
                        <img
                            src="/images/menubar.svg"
                            alt="Menu Icon"
                            style={{ width: "24px", height: "24px", marginRight: "10px" }}
                        />
                    </div>
                )}
            </div>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                        backgroundColor: "white",
                        color: "#727376",
                    },
                }}
            >
                <SidebarMenuList />
            </Drawer>
        </div>
    );
};

export default Header2;
