import "./portfolio.css";
import CreatableSelect from "react-select/creatable";
import React, { useState ,useRef} from 'react';
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import { Link as RouterLink } from 'react-router-dom';
import Divider from "@mui/material/Divider";
import LoginPopUp from '../loginpopup';
import Footer from "../footer.js";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
  import { Tabs, Tab } from '@mui/material';
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuList from "../../includes/menulist";
import MenuListHome from "../../includes/menuListHome";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { EditText } from "react-edit-text"; // Import EditText component
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useEffect } from "react";
import { Card, Button, Grid, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import { updatePrivacy } from "../../apihelper/profile.js";

import Alert from "@mui/material/Alert";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import { getCryporCurrencyList } from "../../apihelper/cryptocurrencylist";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { getsymbollink } from "../../apihelper/getsymbollink.js";
import CircularProgress from "@mui/material/CircularProgress";
import BarChartIcon from '@mui/icons-material/BarChart';
import { Dialog, DialogTitle,BottomNavigationAction,BottomNavigation, DialogContent, DialogActions } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getPortfolioData,getmultiPortfolio } from "../../apihelper/portfolio";
import { addToPortfolio,RenamePortfolio,updateProfilePhoto } from "../../apihelper/portfolio";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AddIcon from '@mui/icons-material/Add';
import Portfolioadd from "../addPage/portfolioadd";
import SidebarMenuList from "../../includes/sidebarMenuList";
import { getCryporCurrencyList,getTotalunit,getFetchdropdown } from "../../apihelper/cryptocurrencylist";
import 'react-edit-text/dist/index.css';
import WatchListGallery from "../home/watchlistgallery.js";
import { useLocation } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

import {
    getCryporCurrencyListHome,

  } from "../../apihelper/homepage";
const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));




const drawerWidth = 292;
const existingOptions = [
  { value: 'Category A', label: 'Category A' },
  { value: 'Category B', label: 'Category B' },
  { value: 'Category C', label: 'Category C' },
];
const NewUserAudit = (props) => {

  const [openModal, setOpenModal] = useState(false);
  const [portfolioTypeId, setPortfolioTypeId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null); // State to store selected file
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };
    const [isAtBottom, setIsAtBottom] = useState(false);
  

  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] =useState(false);

  const handleClose = () => setOpen(false);

  const [snackbaropen, setSnackbaropen] = useState(false);

  const classes = useStyles();
  const [alignment, setAlignment] = useState("left");
  const [loader, setLoader] = useState(false);
  const [slugdata, setSlugdata] = useState([]);
  const [index, setIndex] = useState(0);
  const [addMoreLoader, setAddMoreLoader] = useState(false);
  const [portfolioname, setPortfolioname] = useState('');
  const [filter, setFilter] = useState("");
  const [loaderbutton, setLoaderButton] = useState(true);
  const [roivalue, setRoivalue] = useState("--");
  const [portfoliovalue, setPortfoliovalue] = useState("--");
  const [lastUpdatedTime, setLastUpdatedTiem] = useState("");
  const [portfolioid, setportfolioid] = useState("");
  const [options, setOptions] = useState([]);
  const [dropDown, setdropDown] = useState([]);
  const [newOptionValue, setNewOptionValue] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [slugpopupdata, setSlugpopupdata] = useState({
    slugname: "",
    slug: "",
    units: 0,
  });

  
  const [msgAfterLoading, setMsgAfterLoading] = useState("");
  const [unit, setUnit] = useState("");
  const [buySell, setbuySell] = React.useState("");
  const [price, setPrice] = useState("");

  const [selectedDate, handleDateChange] = useState("");
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
    useState("loading...");
  const [severity, setSeverity] = useState("info");
  const [initialPage, setInitialPage] = useState(false);
  const [localUserData,setLocalUserData]=useState(undefined)
  const [loaderForSymbolLink,setLoaderForSymbolLink]=useState(false);
  const [dataslug, setdataslug] = useState('');
  const [totalUnits, setTotalUnits] = useState(0); 
  const [Usablewallet, setUsablewallet] = useState("");
  const [user_role, setuser_role] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const location = useLocation(); 
  const loc = location.pathname;


  const [lockStatus, setLockStatus] = useState({});

  // // Retrieve saved lock state from localStorage when the component mounts
  // useEffect(() => {
  //   const savedLockStatus = JSON.parse(localStorage.getItem('lockStatus')) || {};
  //   setLockStatus(savedLockStatus);
  // }, []);
  
const handleChange = async (selectedOption, slug) => {
    if (selectedOption && selectedOption._isNew_) {
      setIsDialogOpen(true);
      console.log("Creating new option:", selectedOption.value);
      setSelectedOption(null);
      setdataslug(slug);
    } else if (!selectedOption) {
      // Handle the case when the selection is cleared
      setSelectedOption(null);
      setShowAlert(false);
      // Perform any other necessary actions
      return;
    } else {

      const selectedValue = selectedOption.value;
      if (selectedValue.includes('-')) {
        setErrorMessage('Hyphens are not allowed in the option.'); // Set error message
        setShowAlert(true); // Show the alert
        return; // Abort further processing
      }
      setShowAlert(false);
      setSelectedOption(selectedOption);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      getTotalunit({
        user_id: userData.user_id,
        slug: slug, // Assuming value contains the slug
        portfolio_type_id: selectedOption.value, // Adjust as needed
        Token: userData?.token,
      }).then((res) => {
        if (res.code === "200") {
          setTotalUnits(res.total_uint);
          setUsablewallet(res.wallet_amount);
        } else {
          console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    }
  };
  
  

  const handleCreate = async (inputValue) => {

    if (options.find(option => option.value === inputValue)) {
      return; // Exit early if the option already exists
    }
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log(inputValue);
    const newOption = { value: inputValue, label: inputValue };
    const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
    setOptions(newOptions); // Update the options state
    const createdOption = newOptions.find(option => option.value == inputValue);
   // console.log(createdOption);
    if (createdOption) {
      handleChange(createdOption);
    }
   
  };
  
  

  const handleSymbolLink=(symbolName)=>{
    //console.log(symbolName)
    setLoaderForSymbolLink(true);
    getsymbollink(symbolName).then(res=>{
      if(res.code==='200')
      window.open(res.article_link, '_blank', 'noreferrer');
      // window.location.replace(res.article_link);
      // setLoaderForSymbolLink(false);
      setLoaderForSymbolLink(false);
    })
  }
  
  useEffect(() => {
    fetchDropdownOptions();
  }, []);


  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );
  const mediaQueryVar2 = useMediaQuery(
    json2mq({
      maxWidth: 410,
    })
  );
  const mediaQueryVar3 = useMediaQuery(
    json2mq({
      maxWidth: 430,
    })
  );
  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };


  const resetSelectedFile = () => {
    setSelectedFile(null);
  };

  
  const [rowsToShow, setRowsToShow] = useState(7);

  

  // const loadMore = () => {
  //   // setLoader(false);
  //   setAddMoreLoader(true);
  //   setIndex(index + 10);
  //   //console.log(index);
  //   const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  //   getmultiPortfolio({
  //     slug: filter,
  //     user_id: userData.user_id,
  //     start_index: index + 10,
  //     limit: `${10}`,
  //     Token: userData.token,
  //   }).then((res) => {
  //     if (res.code === "200") {
  //       //console.log("data got successfully");
  //       setSlugdata([...slugdata, ...res.data]);
  //       //console.log(res.data);
  //       if (res.data.length < 10) {
  //         //console.log("change Loaderbutton");
  //         setLoaderButton(false);
  //       } else {
  //         setLoaderButton(true);
  //       }
  //       setLoader(true);
  //       setAddMoreLoader(false);
  //     } else {
  //       //console.log("Data fething error");
  //     }
  //     // console.log(slugdata)
  //   });
  // };
  const [isRowClicked, setIsRowClicked] = useState(false);

  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbaropen(false);
  };

  
const [isFormSubmitted, setIsFormSubmitted] = useState(false);
const [showAlert, setShowAlert] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
const [isDialogOpen, setIsDialogOpen] = useState(false);
const handleChange2 = (e) => {
  const value = e.target.value;
  // Check if the input contains a hyphen
  if (value.includes('-')) {
    setShowAlert(true); // Show the alert if there's a hyphen
  } else {
    setShowAlert(false); // Hide the alert if there's no hyphen
    setNewOptionValue(value); // Update the state with the new value
  }
};
const handleDialogSubmit = () => {
    // Handle submitting the dialog (e.g., creating the new option)
    console.log("Creating new option:", newOptionValue);

    // Update options state with the new option
    const newOption = { value: newOptionValue, label: newOptionValue };
    setOptions([...options, newOption]);
    setSelectedOption(newOption);
    // Close the dialog
    setIsDialogOpen(false);

    // setSelectedOption(selectedOption);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getTotalunit({
      user_id: userData.user_id,
      slug: dataslug, // Assuming value contains the slug
      portfolio_type_id: newOption.value, // Adjust as needed
      Token: userData?.token,
    }).then((res) => {
      if (res.code === "200") {
        setTotalUnits(res.total_uint);
        setNewOptionValue("");
        setUsablewallet(res.wallet_amount);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };

const formatToNonZero = (number) => {
  number = parseFloat(number);
  
  if (number >= 1) {
    return number.toFixed(2);
  } else if (number >= 0.1) {
    return number.toFixed(1);
  } else if (number >= 0.01) {
    return number.toFixed(4);
  } else if (number >= 0.001) {
    return number.toFixed(5);
  } else if (number >= 0.0001) {
    return number.toFixed(6);
  } else if (number >= 0.00001) {
    return number.toFixed(7);
  } else if (number >= 0.000001) {
    return number.toFixed(8);
  } else if (number >= 0.0000001) {
    return number.toFixed(9);
  } else if (number >= 0.00000001) {
    return number.toFixed(10);
  } else if (number >= 0.000000001) {
    return number.toFixed(11);
  }
  return number.toFixed(1);
};

  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100" style={{overflowY:'hidden'}}>
      <SidebarMenuList/>
    </div>
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));





  const [selectedButton, setSelectedButton] = useState("CoinPrices");
  const [isTableView, setIsTableView] = useState(true);
  const handleButtonClick = (buttonName) => {
    // If the same button is clicked again, don't toggle the view
    if (selectedButton === buttonName) {
      return;
    }

    // Toggle the view based on the clicked button
    setSelectedButton(buttonName);
    setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
  };
 
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  const [sortOrder, setSortOrder] = useState({ // Track ascending/descending for each column
    roi : 'asc',
    profit : 'asc'
  });
  const [sortType, setSortType] = useState('roi_asc');

  const handleSort = (column) => {
    console.log("Arrow Clicked");
    const newSortOrder = sortOrder[column] === 'asc' ? 'desc' : 'asc'; // Toggle sort order
    setSortOrder({ ...sortOrder, [column]: newSortOrder });

    console.log("Sorted");
  
    // Set the sort type based on the column
    switch (column) {
      case 'roi':
        setSortType(newSortOrder === 'asc' ? 'roi_asc' : 'roi_desc');
        break;

      case 'profit':
          setSortType(newSortOrder === 'asc' ? 'profit_asc' : 'profit_desc');
          break;
      
      default:
        break;
    }
  };

  //console.log("Data : ", data);
  // Helper function to convert net profit to a number
  const convertNetProfit = (profit) => {
    const number = parseFloat(profit.replace(/[^0-9.-]+/g, '')); // Remove $ sign and commas
    return isNaN(number) ? 0 : number; // Handle invalid or empty values
  };
  

  const filteredData = data.filter(portfolio => 
    portfolio.total_net_profit !== null && 
    portfolio.total_net_profit !== undefined && 
    !isNaN(convertNetProfit(portfolio.total_net_profit))
  );
  
  //console.log("Filtered Data: ", filteredData);
  
  // Helper function to convert ROI value to a number
  const convertRoiValue = (roi) => {
    const number = parseFloat(roi.replace('%', ''));
    return isNaN(number) ? 0 : number; // Handle invalid or empty values
  };
  
  
  const sortedData = [...filteredData].sort((a, b) => {
    switch (sortType) {
      case 'roi_asc':
        return convertRoiValue(a.roi_value) - convertRoiValue(b.roi_value);
      case 'roi_desc':
        return convertRoiValue(b.roi_value) - convertRoiValue(a.roi_value);
      
      case 'profit_asc':
        return convertNetProfit(a.total_net_profit) - convertNetProfit(b.total_net_profit);
      case 'profit_desc':
        return convertNetProfit(b.total_net_profit) - convertNetProfit(a.total_net_profit);
       
      default:
        return 0;
    }
  });
  
  const [showModal, setShowModal] = useState(false); // Change the state name to showModal
  
  
  
  useEffect(() => {
   
 
    getCryporCurrencyListHome({
      slug: filter,
      start_index: `${index}`,
      limit: `${20}`,
      category: '',
          hashtagdata:'',
          selected_filters: [], 
          volume: [],
          marketCap: [],
          sentiment_filters: [],
          user_id: userData?.user_id,
          pathname: "audit-my-portfolio"
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully");
        setSlugdata(res.data);
       
        console.log(res.data);
        setuser_role(res.user_role);
        
        if (res.data.length < 20) {
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  },[filter]);

  useEffect(() => {
    setIndex(0);
    setLoader(false);
    if ((filter.length < 1) & (filter.length >= 1)) {
      setLoader(true);
      setAddMoreLoader(false);
    } else {
      getCryporCurrencyListHome({
        slug: filter,
        start_index: 0,
        limit: `${20}`,
        category: '',
          hashtagdata:'',
          selected_filters: [], 
          volume: [],
          marketCap: [],
          sentiment_filters: [],
          user_id: userData?.user_id,
          pathname: "audit-my-portfolio",
      }).then((res) => {
        if (res.code === "200") {
          console.log("data got successfully");
         // console.log(res);
          setSlugdata(res.data);
          setuser_role(res.user_role);
          setLastUpdatedTiem(res.current_price_updated_on);
          

          if (res.data.length < 20) {
            //console.log("change Loaderbutton");
            setLoaderButton(false);
          } else {
            setLoaderButton(true);
          }
          setLoader(true);
          setAddMoreLoader(false);
        } else {
          console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    }
  }, [filter]);

  const loadMore = () => {
    // setLoader(false);
    setAddMoreLoader(true);
    setIndex(index + 20);
   console.log(index);
    getCryporCurrencyListHome({
          slug: filter,
          start_index: index + 20,
          limit: `${20}`,
          category: '',
          hashtagdata:'',
          selected_filters: [], 
          volume: [],
          marketCap: [],
          sentiment_filters: [],
          user_id: userData?.user_id,
          pathname: "audit-my-portfolio",
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully");
        setSlugdata([...slugdata, ...res.data]);
        
        if (res.data.length < 20) {
          //console.log("change Loaderbutton");
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };
  const slugdata1 = slugdata.slice();

  const sortedSlugdata = slugdata1;
  const handleAddToPortfolio = (idx) => {
    console.log("IDX value is : ", idx);
  
    // Check if userData exists before making the API call
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    if (!userData) {
      const originalUrl = '/audit-my-portfolio';
      sessionStorage.setItem("originalUrl", originalUrl);
      console.log("original url: " + originalUrl);
      window.history.pushState({}, "", "/login");
      setLoginModalOpen(true);
      return; // Exit the function if userData is not present
    }
  
    console.log("User Data is there");
  
    // If userData exists, proceed with fetching cryptocurrency data
    getCryporCurrencyListHome({
      slug: idx,
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully", res.data);
  
        // Update slugdata
        setuser_role(res.user_role);
  
        // Use res.data directly here
        setSlugpopupdata({
          slugname: res.data[0].slug,
          slug: res.data[0].name,
          units: res.data[0].total_unit,
          pricetoday: res.data[0].price_today,
          logo: res.data[0].logo,
        });
        console.log("Slug Popup Data : ", res.data);
        setOpen(true);
      } else {
        console.log("Data fetching error");
      }
    });
  };
  
const handleClick = (buySell) => {
    setSnackbaropen(true);
    setOpen(true);
    setSeverity("info");
    setIsFormSubmitted(false);
    // setSnackbarcolor("success");
    // setSnackbarmessage("Data saved successfully");
    setMsgforUpdatePortfolio("loading...");
    //console.log(price, selectedDate, buySell, unit);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const portfolioId =
      selectedOption && !isNaN(parseInt(selectedOption.value))
        ? parseInt(selectedOption.value)
        : 0;
    const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId

    addToPortfolio({
      portfolio_type_id: portfolioId,
      portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined

      existing_portfolio: existingPortfolio,
      user_id: userData.user_id,
      slug: slugpopupdata.slugname,
      unit: unit,
      acquisition_price: price,
      date_time: selectedDate,
      type: buySell,
      Token: userData?.token,
    }).then((res) => {
      if (res.code === "200") {
        // setOpen(false);
        setSeverity("info");
        //console.log(res.message);
        setMsgforUpdatePortfolio(res.message);
        setPortfolioTypeId(res.portfolio_type_id);
        setIsFormSubmitted(true);
        // navigate(
        //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
        //     selectedOption.label
        //   )}`
        // );
      } else {
        setSeverity("error");
        // setOpen(true)
        setMsgforUpdatePortfolio(res.message);
      }
    });
  };
  const fetchDropdownOptions = async () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  
    // Run the function only if userData exists and has user_id and token
    if (!userData || !userData.user_id || !userData.token) {
      console.log("User data is missing. Skipping fetchDropdownOptions.");
      return;
    }
  
    getFetchdropdown({
      user_id: userData.user_id,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        const newOptions = res.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
  
        setOptions(newOptions);
        setuser_role(res.user_role);
  
        // Set the last option as the default selected option
        if (newOptions.length > 0) {
          setSelectedOption(newOptions[newOptions.length - 1]);
          handleChange(newOptions[newOptions.length - 1], slugpopupdata.slugname);
        }
      } else {
        console.log("Data fetching error");
      }
    });
  };
  

 

  return (
    <>
    {mediaQueryVar===true?(
      
       
    <Box
      sx={{
        display: "flex",
       
        flexDirection: mediaQueryVar === false ? "column" : "",
        minHeight: '100vh',
    // overflow: 'hidden', 
      }}
      className="maindiv"
    >
      <Helmet>
        <title>Audit My Portfolio</title>
</Helmet>
      <CssBaseline />
      <div className="windowheader">
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={window.constants.asset_path+'/images/logo_with_bg.png'}
              width={220}
              height={50}
              alt="CWLOGO"
              style={{ margin: "10px", borderRadius: "5px" }}
            />
          </Toolbar>
        </AppBar>
      </div>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          padding: "2vw",
          backgroundColor: "#EDF1F6",
        }}
      >
        {initialPage && <Portfolioadd />}
        {!initialPage && (
          <>
            <div className="windowheader">
              <Toolbar />
            </div>
            <Box sx={{ flexGrow: 1 }} style={{ marginBottom: "15px" }}>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "space-between",  }}
              >
                <Grid item className="CryptocurrencyListHeader" >
                  <strong>
                    {" "}
                    <Typography
                     variant="subtitle1" 
                      noWrap
                      component="div"
                      style={{
                        
                        fontSize: "25px",
                        fontStyle: "normal",
                       marginLeft:"15px",
                       marginBottom:"-10px",
                         marginTop: mediaQueryVar === false ? "10px" : "",
                      }}
                    > 
                    
                    <span style={{
                      fontSize: "24px",
                      fontFamily: "Satoshi, sans-serif",
                      color: "black",
                      fontWeight: "700"
                    }}>
                     {selectedButton === "CoinPrices" && userData ? "Audit My Portfolio" : null}
  {selectedButton === "watchlist" && <div>My Watchlist</div>}

                    </span>
                    </Typography>
              
                  </strong>
              
                </Grid>
                {selectedButton == "watchlist" &&(
  <Grid item xs={12} md={3} sx={{marginRight:"30px",marginLeft:"-60px"}}>
  
      <TextField
        style={{
          width: "18.8vw",
          borderRadius: "46px",
          marginTop: mediaQueryVar === true ? "" : "60px",
          fontFamily: "Satoshi, sans-serif",
          borderRadius:"46px",
          border:" 1px #ECECEC solid",
          backgroundColor:"#F7F7F7",
          paddingTop:"2px",
          paddingBottom:"2px",
          paddingLeft:"12px",
          paddingRight:"30px",
          fontSize:"16px",
          fontWeight:"500",
          
        }}
        className="cryptocurrencyListHeaderSearch"
        size="small"
        placeholder="Search Portfolios"
        onInput={(e) => setFilter(e.target.value)}
        value={filter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src="images/search.svg"/>
            </InputAdornment>
          ),
          style: {
            border: "none", // Remove the border from the input
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent', // Remove the border of the fieldset
            },
            '&:hover fieldset': {
              borderColor: 'transparent', // Remove the border on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent', // Remove the border when focused
            },
          },
        }}
      />
    
  </Grid>
)}
              </Grid>


  

              <Box sx={{ 
              border: "1px solid white",  
              borderRadius: "8px", 
              padding: "25px 25px", 
              margin: "10px",
              marginLeft:"15px", 
              marginRight: "25px", 
              boxShadow: "0 2px 4px rgba(0,0,0,0)", 
              backgroundColor: "white" ,
              overflow: "auto",
              // Hide the scrollbar
              '&::-webkit-scrollbar': {
              display: 'none',
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'
              }}>

<Grid
  container
  justifyContent="space-between"
  alignItems="center"
  style={{
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "20px",
    marginTop: mediaQueryVar === true ? "15px" : "15px",
  }}
>
  <Grid item xs={12} md={9}>
  {userData ? (
  <Box
  sx={{
    backgroundColor: "#1877F2", // Background color for the container
    paddingTop: { xs: '10px', sm: '4px' }, // Padding inside the container
    paddingLeft: { xs: '0px', sm: '0px' },
    paddingRight: { xs: '0px', sm: '0px' },
    paddingBottom: { xs: '0px', sm: '4px' },
    borderRadius: "49px", // Border radius for the container
    display: 'inline-flex', // Make sure the box is flexible
    justifyContent: 'flex-start', // Center the content
    alignItems: 'center', // Center the content
    width: userData ? { xs: '427px', sm: '429px' } : { xs: '260px', sm: '470px' },
    height: { xs: '44px', sm: '50px' },
    border: 'none',
    marginLeft: "0px",
  }}
>
  <Tabs
    value={selectedButton}
    onChange={(event, newValue) => handleButtonClick(newValue)}
    aria-label="basic tabs example"
    sx={{
      alignSelf: mediaQueryVar === true ? "flex-start" : "",
      marginLeft: mediaQueryVar === true ? "2px" : "2px",
      borderRadius: "50px", // Adjust border radius for more rounded corners
      border: "none",
      "& .MuiTabs-indicator": {
        display: "none",
      },
      display: 'flex', // Make sure the tabs are displayed in a row
      overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
    }}
  >
    {/* Conditional rendering based on userData */}
    <Tab
      label="Audit Portfolio"
      value="CoinPrices"
      sx={{
        whiteSpace: "nowrap",
        bottom: "0px",
        marginTop: "1px",
        marginBottom: "0px",
        marginLeft: "4px",
        marginRight: "3px",
        paddingBottom: "9px",
        paddingTop: "9px",
        paddingLeft: "22px",
        paddingRight: "22px",
        width: "135px",
        minHeight: "22px",
        lineHeight: { xs: '28px', sm: '22px' },
        borderRadius: selectedButton === "CoinPrices" ? "20px" : "20px", // Rounded corners for selected tab
        textTransform: "none",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: selectedButton === "CoinPrices" ? "700" : "500", 
        backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
        border: 'none', // Remove border for tabs
        background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
      }}
    />
    
        <Tab
          label="My Portfolios"
          value="MyPortfolios"
          onClick={() => navigate('/my-portfolio')}
          sx={{
            whiteSpace: "nowrap",
            bottom: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "3px",
            marginRight: "1px",
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "20px",
            paddingRight: "15px",
            minHeight: "20px",
            lineHeight: { xs: '36px', sm: '36px' },
            borderRadius: selectedButton === "MyPortfolios" ? "50px" : "50px", // Rounded corners for selected tab
            textTransform: "none",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: selectedButton === "MyPortfolios" ? "700" : "500", 
            backgroundColor: selectedButton === "MyPortfolios" ? "#FFFFFF" : "transparent", // White background for selected tab
            color: selectedButton === "MyPortfolios" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
            border: 'none', // Remove border for tabs
            background: selectedButton === "MyPortfolios" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
          }}
        />
        <Tab
          label="My Watchlist"
          value="watchlist"
          sx={{
            whiteSpace: "nowrap",
            bottom: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "3px",
            marginRight: "1px",
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "20px",
            paddingRight: "15px",
            minHeight: "20px",
            lineHeight: { xs: '36px', sm: '36px' },
            borderRadius: selectedButton === "watchlist" ? "50px" : "50px", // Rounded corners for selected tab
            textTransform: "none",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: selectedButton === "watchlist" ? "700" : "500", 
            backgroundColor: selectedButton === "watchlist" ? "#FFFFFF" : "transparent", // White background for selected tab
            color: selectedButton === "watchlist" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
            border: 'none', // Remove border for tabs
            background: selectedButton === "watchlist" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
          }}
        />
    
   
  </Tabs>
</Box> 
  ):(
  <Box
  sx={{
    backgroundColor: "#1877F2", // Background color for the container
    paddingTop: { xs: '10px', sm: '4px' }, // Padding inside the container
    paddingLeft: { xs: '0px', sm: '0px' },
    paddingRight: { xs: '0px', sm: '0px' },
    paddingBottom: { xs: '0px', sm: '4px' },
    borderRadius: "49px", // Border radius for the container
    display: 'inline-flex', // Make sure the box is flexible
    justifyContent: 'flex-start', // Center the content
    alignItems: 'center', // Center the content
    width: userData ? { xs: '260px', sm: '292.5px' } : { xs: '260px', sm: '457px' },
    height: { xs: '44px', sm: '50px' },
    border: 'none',
    marginLeft: "0px",
  }}
>
  <Tabs
    value={selectedButton}
    onChange={(event, newValue) => handleButtonClick(newValue)}
    aria-label="basic tabs example"
    sx={{
      alignSelf: mediaQueryVar === true ? "flex-start" : "",
      marginLeft: mediaQueryVar === true ? "2px" : "2px",
      borderRadius: "50px", // Adjust border radius for more rounded corners
      border: "none",
      "& .MuiTabs-indicator": {
        display: "none",
      },
      display: 'flex', // Make sure the tabs are displayed in a row
      overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
    }}
  >
    {/* Conditional rendering based on userData */}
    <Tab
      label="Audit Portfolio"
      value="CoinPrices"
      sx={{
        whiteSpace: "nowrap",
        bottom: "0px",
        marginTop: "1px",
        marginBottom: "0px",
        marginLeft: "4px",
        marginRight: "3px",
        paddingBottom: "9px",
        paddingTop: "9px",
        paddingLeft: "22px",
        paddingRight: "22px",
        width: "135px",
        minHeight: "22px",
        lineHeight: { xs: '28px', sm: '22px' },
        borderRadius: selectedButton === "CoinPrices" ? "20px" : "20px", // Rounded corners for selected tab
        textTransform: "none",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: selectedButton === "CoinPrices" ? "700" : "500", 
        backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
        border: 'none', // Remove border for tabs
        background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
      }}
    />
    
        <Tab
          label="Top Portfolios"
          value="topPortfolios"
          onClick={() => navigate('/portfolio-gallery')}
          sx={{
            whiteSpace: "nowrap",
            bottom: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "3px",
            marginRight: "1px",
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "20px",
            paddingRight: "15px",
            minHeight: "20px",
            lineHeight: { xs: '36px', sm: '36px' },
            borderRadius: selectedButton === "topPortfolios" ? "50px" : "50px", // Rounded corners for selected tab
            textTransform: "none",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: selectedButton === "topPortfolios" ? "700" : "500", 
            backgroundColor: selectedButton === "topPortfolios" ? "#FFFFFF" : "transparent", // White background for selected tab
            color: selectedButton === "topPortfolios" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
            border: 'none', // Remove border for tabs
            background: selectedButton === "topPortfolios" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
          }}
        />
        <Tab
          label="Crypto Screener"
          value="cryptoScreener"
          onClick={() => navigate('/crypto-screener')}
          sx={{
            whiteSpace: "nowrap",
            bottom: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "3px",
            marginRight: "1px",
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "20px",
            paddingRight: "15px",
            minHeight: "20px",
            lineHeight: { xs: '36px', sm: '36px' },
            borderRadius: selectedButton === "cryptoScreener" ? "50px" : "50px", // Rounded corners for selected tab
            textTransform: "none",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: selectedButton === "cryptoScreener" ? "700" : "500", 
            backgroundColor: selectedButton === "cryptoScreener" ? "#FFFFFF" : "transparent", // White background for selected tab
            color: selectedButton === "cryptoScreener" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
            border: 'none', // Remove border for tabs
            background: selectedButton === "cryptoScreener" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
          }}
        />
    
   
  </Tabs>
</Box> 
  
  )}

    
  </Grid>

  
</Grid>
            {selectedButton === "CoinPrices" && (
              <>

<TableContainer style={{ overflowX: "auto", borderRadius: "5px", margin: "0 auto", width: "100%", paddingRight:"20px" }}>
<div style={{ border: "1px solid #EEE", borderRadius: "5px", overflow: "hidden", width: "100%" }}>
                <Table
                  className="non_responsiveTable" aria-label="customized table" 
                  style={{ borderCollapse: "collapse", width: "100%" }}

                >
                  
               
                    <TableBody>
                      <StyledTableRow>
                        {/* Other table cells */}
                        
                        {/* If you want to add a centered button in the last row */}
                        <TableCell colSpan={4} style={{ textAlign: "center" }}>
  {!userData ? (
    <div>
    <div
    style={{
      padding: "15px 20px",
      borderRadius: "8px",
      background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
      fontSize: "16px",
      fontWeight: "500",
      textAlign: "left",
      lineHeight: "1.5",
      color: "#000",
      marginBottom:"15px",
    }}
  >
    <h4 style={{ marginTop: "0", marginBottom: "20px", fontWeight:"700", fontSize:"20px" }}>
      How to Audit Your Crypto Portfolio
    </h4>
    <ul style={{ paddingLeft: "20px", margin: "0" }}>
    <li>
      Click on any cryptocurrency to add; a pop-up will appear.
      </li>
      <li>Use the pop-up to create new portfolio and add cryptocurrencies.
      </li>
      <li>
      Once complete, access the portfolio, click "Analysis"  and "Recommendations" for portfolio analysis and suggestions.
      </li>
    </ul>
  </div>
    <button
      onClick={() => {
        const originalUrl = "/audit-my-portfolio";
        sessionStorage.setItem("originalUrl", originalUrl);
        console.log("original url: " + originalUrl);
        handleLoginClick();
      }}
      style={{
        border: "1px solid #1877F2",
        borderRadius: "48px",
        padding: "15px 36px",
        backgroundColor: "white",
        color: "#1877F2",
        fontSize: "24px",
        height: "66px",
        marginRight: "8px",
        fontWeight: "500",
        margin: "20px",
        marginTop:0
      }}
    >
      Audit My Portfolio
    </button></div>
  ) : (
    <div
      style={{
        padding: "15px 20px",
        borderRadius: "8px",
        background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
        fontSize: "16px",
        fontWeight: "500",
        textAlign: "left",
        lineHeight: "1.5",
        color: "#000",
        marginBottom:"15px",
      }}
    >
      <h4 style={{ marginTop: "0", marginBottom: "20px", fontWeight:"700", fontSize:"20px" }}>
        How to Audit Your Crypto Portfolio
      </h4>
      <ul style={{ paddingLeft: "20px", margin: "0" }}>
      <li>
        Click on any cryptocurrency to add; a pop-up will appear.
        </li>
        <li>Use the pop-up to create new portfolio and add cryptocurrencies.
        </li>
        <li>
        Once complete, access the portfolio, click "Analysis"  and "Recommendations" for portfolio analysis and suggestions.
        </li>
      </ul>
    </div>
  )}
</TableCell>

                      </StyledTableRow>
                  </TableBody>
                  
         
                  
                </Table>
                {selectedButton == "CoinPrices" && (
  <Grid item xs={12} md={3} sx={{marginRight:"5px",float:"right", marginBottom:"15px"}}>
  
      <TextField
        style={{
          width: "18.5vw",
          borderRadius: "46px",
          marginTop: mediaQueryVar === true ? "" : "60px",
          fontFamily: "Satoshi, sans-serif",
          borderRadius:"46px",
          border:" 1px #1877F2 solid",
          backgroundColor:"#fff",
          paddingTop:"2px",
          paddingBottom:"2px",
          paddingLeft:"12px",
          paddingRight:"30px",
          fontSize:"16px",
          fontWeight:"500",
          
        }}
        className="cryptocurrencyListHeaderSearch"
        size="small"
        placeholder="Search Cryptos"
        onInput={(e) => setFilter(e.target.value)}
        value={filter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src="images/search.svg"/>
            </InputAdornment>
          ),
          style: {
            border: "none", // Remove the border from the input
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent', // Remove the border of the fieldset
            },
            '&:hover fieldset': {
              borderColor: 'transparent', // Remove the border on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent', // Remove the border when focused
            },
          },
        }}
      />
    
  </Grid>
)}
                 </div>
                 {loader === true ? (
  <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
    {sortedSlugdata.map((obj, idx) => (
      <li
        key={idx}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 15px",
          borderBottom: "1px solid #ECECEC",
          
        }}
      
      >
        {/* Logo and Name */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Logo/Icon */}
          <img
            src={obj.logo}
            alt={`${obj.slug} logo`}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              border: "2px solid black",
              marginRight: "15px",
            }}
          />

          {/* Name/Slug */}
          <span
            style={{
              fontSize: "16px",
              fontWeight: "500",
              fontFamily: "Satoshi, sans-serif",
              color: "black",
            }}
          >
            {obj.name} ({obj.slug})
          </span>
        </div>

        {/* Grade */}
        <span
          style={{
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Satoshi, sans-serif",
            color: "gray",
          }}
        >
                                                                  <Button
                                                                      className="btn"
                                                                      variant="outlined"
                                                                      onClick={() => handleAddToPortfolio(obj.slug)}
                                                                      style={{
                                                                          backgroundColor: "white",
                                                                          padding: "4px 10px",
                                                                          fontFamily: "Satoshi, sans-serif",
                                                                          borderRadius: "48px",
                                                                          border: "1px solid #1877F2",
                                                                          textTransform: "none",
                                                                          fontSize: mediaQueryVar === false ? "10px" : "11px",
                                                                          fontWeight: "500",
                                                                          color: "#1877F2",
                                                                          whiteSpace: "nowrap",
                                                                          width: "120px",
                                                                          height: "30px",
                                                                          marginLeft: "20px",
                                                                          marginBottom: "10px",
                                                                      }}
                                                                  >
                                                                      Add to Portfolio
                                                                  </Button>
        </span>
      </li>
    ))}
  </ul>
) : (
  <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
    {Array(20)
      .fill(0)
      .map((_, idx) => (
        <li
          key={idx}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px",
            borderBottom: "1px solid #ECECEC",
          }}
        >
          {/* Skeleton for Logo */}
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "#E0E0E0",
              marginRight: "15px",
            }}
          ></div>

          {/* Skeleton for Name/Slug */}
          <div style={{ flex: 1 }}>
            <div
              style={{
                width: "150px",
                height: "16px",
                backgroundColor: "#E0E0E0",
                marginBottom: "5px",
              }}
            ></div>
          </div>

          {/* Skeleton for Grade */}
          <div
            style={{
              width: "80px",
              height: "16px",
              backgroundColor: "#E0E0E0",
            }}
          ></div>
        </li>
      ))}
  </ul>
)}


 
              </TableContainer>
              {loader && loaderbutton  && (
          <div className="loadingButton">
            <LoadingButton
              loading={addMoreLoader}
              loadingPosition="center"
              variant="outlined"
              style={{
                maxWidth: "360px",

                marginBottom: mediaQueryVar === false ? "10px" : "20px",
                backgroundColor: "rgba(67, 97, 238, 0.15)",
                borderRadius: "6px",
                border: "none",
                textTransform: "none",
                borderStyle: "solid",
                borderColor: "rgba(67, 97, 238, 0.15)",
                borderWidth: "1px",
                color: "#4361ee",
                width: mediaQueryVar === false ? "80vw" : "140px",
              }}
              onClick={() => {
                loadMore();
              }}
            >
              <span style={{ color: "#4361ee" }}>Load More</span>
            </LoadingButton>
          </div>
        )}
           
              </>

              
            )}
        {selectedButton === "watchlist" && (
          <>
          <WatchListGallery filter={filter} onButtonClick={handleButtonClick}/>
          </>
        
        )}
          
        
          </Box>
         
             </Box>
            {/* <Modal
         open={open}
         onClose={handleClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={style} className={styles.editPortfolio}>
           <div className={styles.editPortfolioParent}>
             <div className={styles.editPortfolio1}>Add To Portfolio </div>
             <div className={styles.primaryButton}>
               <img className={styles.frameIcon} alt="" onClick={handleClose} src="/frame17.svg" />
 
             </div>
 
           </div>
           <TableContainer component={Paper} >
             <Table className={styles.responsiveTable} >
               <TableHead>
                 <TableRow>
                   <StyledTableCell>NAME OF CRYPTO</StyledTableCell>
                   <StyledTableCell >UNITS</StyledTableCell>
                   <StyledTableCell align="right">BUY/SELL ALL UNITS</StyledTableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
 
                 <StyledTableRow>
                   <StyledTableCell component="th" scope="row" className={styles.b57ac673f06a4b0338a596817eb0a5Parent}>
                     <img width={30}
                       className={styles.b57ac673f06a4b0338a596817eb0a5Icon}
                       alt=""
                       src="/b57ac673f06a4b0338a596817eb0a50ce16e2059f327dc117744449a47915cb2-1@2x.png"
                     />
                     <div className={styles.btcParent}>
                       <div className={styles.btc}>BTC</div>
                       <div className={styles.bitcoin}>Bitcoin</div>
                     </div>
                   </StyledTableCell>
                   <StyledTableCell >4</StyledTableCell>
 
                   <StyledTableCell align="right"><Button variant="outlined" onClick={dec}><HorizontalRuleOutlinedIcon /></Button>
 
 
                     <TextField
                       sx={{
                         width: 60,
                         "& fieldset": { border: 'none' },
                       }}
                       inputProps={{ min: 0, height: 36, style: { textAlign: 'center' } }}
 
                       hiddenLabel
                       id="filled-hidden-label-normal"
                       value={count}
                       size="small"
                       type="text"
                     />
                     <Button variant="outlined" onClick={inc}><AddOutlinedIcon /></Button></StyledTableCell>
 
                 </StyledTableRow>
 
 
 
               </TableBody>
             </Table>
           </TableContainer>
 
 
           <Button className={styles.saveWrapper} variant="contained" color="primary" size="large" onClick={handleClick}>Save</Button>
 
 
         </Box>
       </Modal> */}
       
           
          </>
        )}
        {loaderForSymbolLink===true &&
         <div >
              <CircularProgress style={{position:"fixed",top:"40vh",left:"45vw"}}/>
          </div> 
          }

<Modal
  sx={{ overflow: "hidden" }}
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <div className="CardOpener" style={{ overflow: "hidden" }}>
    {isFormSubmitted ? (
      <Card className="card__container">
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: 50, mr: 1 }} />
          </div>
          <Typography
            severity="success"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Portfolio added successfully!
          </Typography>
        </>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              setIsFormSubmitted(false);
              setOpen(false);
              setUnit("");
              setSelectedOption("");
              setPrice("");
              setTotalUnits(0);
              handleDateChange("");
              setUsablewallet("");
            }}
            color="primary"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "150px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
          >
            Add More Cryptos
          </Button>
          <Button
           onClick={() =>
            navigate(
              `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                selectedOption.label.replace(/\s+/g, '-')
              )}`
            )
          }
          
            variant="outlined"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "100px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
            color="primary"
          >
            View Portfolio
          </Button>
        </div>
      </Card>
    ) : (
      <Dialog
        PaperProps={{
          sx: {
            position: mediaQueryVar ? 'static' : 'fixed',
            bottom: mediaQueryVar ? '' : 0,
            left: mediaQueryVar ? '' : 0,
            right: mediaQueryVar ? '' : 0,
            marginTop: '1%',
            maxWidth: '100%',
            minHeight: '60%', // Adjust max height if needed
            borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
            padding: "10px",
            alignItems: "center",
            overflow: "scroll", 
            '&::-webkit-scrollbar': {
              display: 'none',
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'// Ensure no scrollbar
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <div style={{ padding: '20px', width: '400px', margin: '0' ,marginTop:"-5px",}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottom:"1px #E3E3E3 solid" }}>
            <Typography style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft: "100px" }} gutterBottom>
              Add To Portfolio
            </Typography>
            

            <div className="primaryButton">
              <img
                className="frameIcon"
                alt=""
                onClick={handleClose}
                src={window.constants.asset_path + '/images/frame17.svg'}
                style={{ background: "#F3F3F3", width: "25px", height: "25px", 
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' ,marginLeft: mediaQueryVar === false ? '10px' : ''}}>
            <Typography style={{ fontSize: "13.845px", fontWeight: '500', marginBottom: '1px', color: 'black',marginTop:"-8px" }}>
              Select Portfolio
            </Typography>
            <div style={{ marginTop: '5px' }}>
              <CreatableSelect
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '12px',
                    border: '1px solid #1877F2',
                    boxShadow: 'none',
                    minHeight: '40px',
                    '&:hover': {
                      borderRadius: '12px',
                      border: '2px solid #1877F2',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#1877F2',
                    border: "1px solid #1877F2",
                    padding: "5px",
                    margin: "8px",
                    borderRadius: "3.786px",
                    '&:hover': {
                      color: '#1877F2',
                    },
                  }),
                  indicatorSeparator: (provided) => ({
                    display: 'none',
                  }),
                }}
                placeholder=""
                onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                onCreateOption={handleCreate}
                options={[
                  { value: '', label: 'Create new portfolio', _isNew_: true }, // Dynamic "create new option" item
                  ...options // Existing options
                ]}
                value={selectedOption}
              />
            </div>
            {showAlert && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          {errorMessage}
        </Alert>
        
      )}
          </div>
          <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '10px', marginBottom: '5px' }}>
          Usable Wallet Value: {Usablewallet}
          </Typography>
          <div style={{ padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
              <img
                style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "27.69px", width: "27.69px" }}
                alt=""
                src={slugpopupdata.logo}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "14px", color: "#7F7F80" }}>
                  {slugpopupdata.slugname}
                </Typography>
              
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '23%', flex: 1 }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Price</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0',marginLeft:"15px" }}>
                {slugpopupdata.pricetoday}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Units</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {totalUnits}
              </Typography>
            </div>
          </div>
          <div className="card__container__row3">
            <div style={{marginLeft:"-5px"}}>
              <p style={{ fontSize: "14px", fontWeight: "500", }}>Enter number of Units</p>
              <input
                style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "350px", height: "45px",margin:0 }}
                min="1"
                max="10"
                type="number"
                placeholder=""
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              ></input>
            </div>
            <div>
            ${formatToNonZero(
  unit * parseFloat((slugpopupdata.pricetoday || '').replace(/^\$/, ''))
)}

    </div>
            {user_role === "superuser" && (
              <>
                <div className="card__container__row3__r2">
                  <div style={{marginLeft:"-5px"}}>
                  <p  style={{ fontSize: "14px", fontWeight: "500" }}>
                    Buy/Sell Price($)
                  </p>
                  <input
                  style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "345px", height: "45px" }}
                    className="card__container__row3__r2__input"
                    min="1"
                    max="10"
                    type="number"
                    placeholder="Enter Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></input></div>
                </div>
                <div style={{marginBottom:"10px",marginLeft:"-5px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Date</p>
                      <TextField
                        type="date"
                        value={selectedDate}
                        onChange={(e) => handleDateChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10.384px',
                          border:"1px solid #1877F2",
                          boxShadow:"none",
                          width:"190%",
                          height:"45px",
                          
                        },
                        }}
                        />
                    </div>
              </>
            )}
            <div className="card__container__row3__r4" style={{ marginTop: "10px" }}>
              <Button
                className={
                  buySell === "buy"
                    ? "buttonToggleButActive"
                    : "buttonToggleBuy"
                }
                value="Buy"
                style={{
                  lineHeight: "20px",
                  width: "159px",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  padding: "15px 85px",
                  marginLeft: "-10px",
                  backgroundColor: buySell === "buy" ? "" : "#1877F2",
                  color: buySell === "buy" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("buy")}
              >
                Add
              </Button>
              <Button
                className={
                  buySell === "sell"
                    ? "buttonToggleSellActive"
                    : "buttonToggleSell"
                }
                value="sell"
                style={{
                  lineHeight: "20px",
                  width: "159px",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  marginLeft: "10px",
                  padding: "15px 85px",
                  backgroundColor: buySell === "sell" ? "" : "#1877F2",
                  color: buySell === "sell" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("sell")}
              >
                Remove
              </Button>
             
            </div>
          </div>
        </div>
      </Dialog>
    )}
  </div>
</Modal>

        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
  <DialogTitle>
    Create New Portfolio
    <IconButton aria-label="close"onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }}  sx={{ position: 'absolute', right: 13, top: 8 }}>
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
  <div>
      <TextField
        autoFocus
        label="Enter Name"
        value={newOptionValue}
        onChange={handleChange2}
        fullWidth
        margin="dense"
      />
      {showAlert && (
        <Alert severity="error" sx={{ marginTop: '8px' }}>
          Hyphens are not allowed in the name.
        </Alert>
      )}
    </div>

    
  </DialogContent>
  <DialogActions sx={{marginRight:'14px'}}>
  <Button onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }} sx={{fontWeight:'bold'}}  variant="outlined" color="primary">
      Cancel
    </Button>
    <Button onClick={handleDialogSubmit} variant="contained" color="primary" sx={{fontWeight:'bold'}}disabled={!newOptionValue.trim()}>
      Submit
    </Button>
  
  </DialogActions>
</Dialog>

        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
        </Snackbar>
            
          
      </Box>
      {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "105%",
            zIndex: "100",
            marginLeft:"-10px"
          }}
        >
          <BottomNavigation showLabels>
  <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="Audit Portfolio"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
    <BottomNavigationAction
                  label="Crypto Screener"
                  onClick={() => navigate("/crypto-screener")}
                  icon={
                    <img
                      src={
                        loc === "/crypto-screener"
                          ? "/images/portfolioS.svg"
                          : "/images/portfolioU.svg"
                      }
                      width={"20px"}
                      height={"20px"}
                    />
                  }
                />
</BottomNavigation>
        </div>
      )}
      {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
    </Box>
    ):(

      <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  }}
>
<Helmet>
        <title>Audit My Portfolio</title>
</Helmet>
  
  <Box
    component="nav"
    sx={{
      width: "100%", // Take full width on mobile
    }}
    aria-label="mailbox folders"
  >
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth, // Adjust width for mobile
          backgroundColor: "white",
          color: "#727376",
        },
      }}
    >
      {drawer}
    </Drawer>
  </Box>


  <Box
    component="main"
    sx={{
      flexGrow: 1,
      paddingTop:3,
      paddingBottom: 3,
      paddingLeft:3,
      paddingRight:0,
      backgroundColor: "white",
      paddingBottom: "2vw",
      minHeight: "100vh",
      marginRight:"6px",
      overflowY:"auto"

    }}
    className="maindiv"
  >

        {initialPage && <Portfolioadd />}
        {!initialPage && (
          <>
            
            <div style={{ 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  marginTop: "0px", 
  marginBottom: "10px" 
}}>

<Typography
  variant="h6"
  component="div"
  style={{
    fontFamily: "Satoshi, sans-serif",
    fontWeight: 700,
    fontSize: "20px",
  }}
>
  {selectedButton === "CoinPrices" && userData ? "Audit My Portfolio" : null}
  {selectedButton === "watchlist" && <div>My Watchlist</div>}
</Typography>

              <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{ display: { sm: "none" } }}
  >
   <img src={window.constants.asset_path  + '/images/menubar.svg'}
 alt="Menu Icon" style={{ width: "24px", height: "24px",marginRight:"15px" }} />
  </IconButton>
  </div>

  

  {selectedButton == "watchlist" &&(
<TextField
                 style={{
                   width: "98%",
                   borderRadius: "46px",
                   marginTop: mediaQueryVar ? "" : "0px",
                   fontFamily: "Satoshi, sans-serif",
                   marginRight: "25px",
                   marginLeft:"-10px",
                   border: "1px #b6b6b8 solid",
                   backgroundColor: "white",
                   paddingTop: "0px",
                   paddingBottom: "2px",
                   paddingLeft: "12px",
                   paddingRight: "30px",
                   marginBottom: "5px",
                   fontSize:"14px",
                   fontWeight:"400"
                 }}
                 size="small"
                 placeholder="Search Portfolios"
                 onChange={(e) => setFilter(e.target.value)}
                 value={filter}
                 InputProps={{
                   startAdornment: (
                     <InputAdornment position="start">
                       <img  width={13} height={13} src={window.constants.asset_path + '/images/search.svg'} alt="search Icon" />
                     </InputAdornment>
                   ),
                   style: {
                     border: "none", // Remove the border from the input
                   },
                 }}
                 sx={{
                   '& .MuiOutlinedInput-root': {
                     '& fieldset': {
                       borderColor: 'transparent', // Remove the border of the fieldset
                     },
                     '&:hover fieldset': {
                       borderColor: 'transparent', // Remove the border on hover
                     },
                     '&.Mui-focused fieldset': {
                       borderColor: 'transparent', // Remove the border when focused
                     },
                   },
                 }}
               />
              )}
                
  <Grid
                  container
                  justifyContent="flex-end"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent:"flex-start",
                    width: "100%",
                    marginBottom: "35px",
                    marginTop: "17px",
                    
                  }}
                >
{userData ? (
<Box
  sx={{
    backgroundColor: "#1877F2", // Background color for the container
    paddingTop: "3.105px", // Padding inside the container
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "3.105px",
    borderRadius: "38.042px", // Border radius for the container
    display: 'inline-flex', // Make sure the box is flexible
    justifyContent: 'center', // Center the content
    alignItems: 'center', // Center the content
    width: userData? ( mediaQueryVar2 ? "335px":"340px"):"330px", // Full width on extra small screens
    height: "39px",
    border: 'none',
    marginLeft: userData? "-10px":"-10px",
    
  }}
>
  <Tabs
    value={selectedButton}
    onChange={(event, newValue) => handleButtonClick(newValue)}
    aria-label="basic tabs example"
    sx={{
      marginLeft: "0px",
      "& .MuiTabs-indicator": {
        display: "none",
      },
      display: 'flex', // Make sure the tabs are displayed in a row
      overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
    }}
  >
    <Tab
      label="Audit Portfolio"
      value="CoinPrices"
      sx={{
        whiteSpace: "nowrap",
        borderRadius: "20.962px",
        minHeight: "31.5px",
        height: "31.5px",
        fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
        marginLeft: userData? (selectedButton === "watchlist"? "-8px" : "4px"):"0px",
        paddingLeft: "16px",
        paddingRight: "16px",
        marginTop: "8px",
        border: "1px solid black",
        marginBottom: "-5px",
        fontSize: "13px",
        marginRight: "0px",
        backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
        textTransform: "none",
        border: "none",
      }}
    />
    <Tab
      label="My Portfolios"
      value="MyPortfolios"
      onClick={() => navigate('/my-portfolio')}
      sx={{
        whiteSpace: "nowrap",
        borderRadius:"20.962px",
        minHeight:"31px",
        height:"31px",
        marginLeft:selectedButton === "watchlist"? "-20px":"0px",
        marginTop:"8px",
        border:"1px solid black",
        fontWeight: selectedButton === "MyPortfolios" ? "700" : "500",
        marginRight:selectedButton === "watchlist"?"10px":"0px",
        fontSize:"13px",
        paddingRight:"-5px",
        textTransform:"none",
        backgroundColor: selectedButton === "MyPortfolios" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "MyPortfolios" ? "#000000 !important" : "#FFFFFF !important", // Black text for selected tab
        border: 'none', // Remove border for tabs
      }}
    />
      
        <Tab
      label="My Watchlist"
      value="watchlist"
      sx={{
        whiteSpace: "nowrap",
        borderRadius:"20.962px",
        minHeight:"31px",
        height:"31px",
        marginLeft:"-15px",
        marginTop:"8px",
        border:"1px solid black",
        fontWeight: selectedButton === "watchlist" ? "700" : "500",
        marginRight:"0px",
        fontSize:"13px",
        paddingRight:"-5px",
        textTransform:"none",
        backgroundColor: selectedButton === "watchlist" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "watchlist" ? "#000000 !important" : "#FFFFFF !important", // Black text for selected tab
        border: 'none', // Remove border for tabs
      }}
    />
      
  </Tabs>
</Box>
) : (
<Box
  sx={{
    backgroundColor: "#1877F2", // Background color for the container
    paddingTop: "3.105px", // Padding inside the container
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "3.105px",
    borderRadius: "38.042px", // Border radius for the container
    display: 'inline-flex', // Make sure the box is flexible
    justifyContent: 'center', // Center the content
    alignItems: 'center', // Center the content
    width: "334px", // Full width on extra small screens
    height: "39px",
    border: 'none',
    marginLeft: userData? "-5px":"-10px",
    marginTop:"-20px"
  }}
>
  <Tabs
    value={selectedButton}
    onChange={(event, newValue) => handleButtonClick(newValue)}
    aria-label="basic tabs example"
    sx={{
      marginLeft: "0px",
      "& .MuiTabs-indicator": {
        display: "none",
      },
      display: 'flex', // Make sure the tabs are displayed in a row
      overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
    }}
  >
    <Tab
      label="Audit Portfolio"
      value="CoinPrices"
      sx={{
        whiteSpace: "nowrap",
        borderRadius: "20.962px",
        minHeight: "31.5px",
        height: "31.5px",
        fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
        marginLeft: userData? "4px":"0px",
        paddingLeft: "16px",
        paddingRight: "16px",
        marginTop: "8px",
        border: "1px solid black",
        marginBottom: "-5px",
        fontSize: "13px",
        marginRight: "0px",
        backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
        textTransform: "none",
        border: "none",
        marginLeft:"4px"
      }}
    />
      
      <Tab
          label="Top Portfolios"
          onClick={() => navigate('/portfolio-gallery')}
          value="topPortfolios"
          sx={{
            whiteSpace: "nowrap",
            borderRadius: "20.962px",
            minHeight: "31px",
            height: "31px",
            marginLeft: "0px",
            marginTop: "8px",
            border: "1px solid black",
            fontWeight: selectedButton === "topPortfolios" ? "700" : "500",
            marginRight: "0px",
            fontSize: "13px",
            paddingRight: "-5px",
            textTransform: "none",
            backgroundColor: selectedButton === "topPortfolios" ? "#FFFFFF" : "transparent", // White background for selected tab
            color: selectedButton === "topPortfolios" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
            border: "none", // Remove border for tabs
          }}
        />
        <Tab
          label="Screener"
          value="screener"
          onClick={() => navigate('/crypto-screener')}
          sx={{
            whiteSpace: "nowrap",
            borderRadius: "20.962px",
            minHeight: "31px",
            height: "31px",
            marginLeft: "0px",
            marginTop: "8px",
            border: "1px solid black",
            fontWeight: selectedButton === "screener" ? "700" : "500",
            marginRight: "0px",
            fontSize: "13px",
            paddingRight: "-5px",
            textTransform: "none",
            backgroundColor: selectedButton === "screener" ? "#FFFFFF" : "transparent", // White background for selected tab
            color: selectedButton === "screener" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
            border: "none", // Remove border for tabs
          }}
        />
     
    
      
  </Tabs>
</Box>
)}




</Grid>

          
            {selectedButton === "CoinPrices" && (
              <>
<TableContainer sx={{width:"100%",borderRadius: "10px",marginTop:"-20px",marginLeft:"-15px",
  overflowY:"auto",
  
    "&::-webkit-scrollbar": {
      display: "none",
    },
    // Hides scrollbar for Firefox
    scrollbarWidth: "none",
    marginBottom:"10px",
    paddingBottom:"0px",
    marginLeft:userData? (mediaQueryVar3?"-10px":"-8px") :"",
}}>
  

  <div
  style={{
    display: "flex",
    justifyContent: "center", // Centers content horizontally
    alignItems: "center", // Centers content vertically
    height: "100%", // Ensures vertical centering in parent container,
    
  }}
>
  {!userData ? (
    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
    <div
    style={{
      padding: "10px 5px",
      borderRadius: "8px",
      background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
      fontSize: "16px",
      fontWeight: "500",
      textAlign: "left",
      lineHeight: "1.5",
      color: "#000",
      width: "100%",
      
    }}
  >
    <h3 style={{ marginTop: "0", marginBottom: "10px", textAlign: "center", fontSize:"18px", fontWeight:'700' }}>
      How to Audit Your Crypto Portfolio
    </h3>
    <ul style={{ paddingLeft: "20px", margin: "0" }}>
      <li>
      Click on any cryptocurrency to add; a pop-up will appear.
      </li>
      <li>Use the pop-up to create new portfolio and add cryptocurrencies.
      </li>
      <li>
      Once complete, access the portfolio, click "Analysis"  and "Recommendations" for portfolio analysis and suggestions.
      </li>
    </ul>
  </div>
    <button
      onClick={() => {
        const originalUrl = "/audit-my-portfolio";
        sessionStorage.setItem("originalUrl", originalUrl);
        console.log("original url: " + originalUrl);
        handleLoginClick();
      }}
      style={{
        border: "1px solid #1877F2",
        borderRadius: "48px",
        padding: "10px 26px",
        backgroundColor: "white",
        color: "#1877F2",
        fontSize: "18px",
        height: "66px",
        margin: "20px",
        marginLeft: "-5px",
        fontWeight: "500",
        
      }}
    >
      Audit My Portfolio
    </button></div>
  ) : (
    <div
      style={{
        padding: "10px 5px",
        borderRadius: "8px",
        background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
        fontSize: "16px",
        fontWeight: "500",
        textAlign: "left",
        lineHeight: "1.5",
        color: "#000",
        width: "100%",
        
      }}
    >
      <h3 style={{ marginTop: "0", marginBottom: "10px", textAlign: "center", fontSize:"18px", fontWeight:'700' }}>
        How to Audit Your Crypto Portfolio
      </h3>
      <ul style={{ paddingLeft: "20px", margin: "0" }}>
        <li>
        Click on any cryptocurrency to add; a pop-up will appear.
        </li>
        <li>Use the pop-up to create new portfolio and add cryptocurrencies.
        </li>
        <li>
        Once complete, access the portfolio, click "Analysis"  and "Recommendations" for portfolio analysis and suggestions.
        </li>
      </ul>
    </div>
  )}
</div>

  
 
  
</TableContainer>
{selectedButton == "CoinPrices" &&  (
  <Grid item xs={12} md={3} sx={{marginTop:"-55px",marginRight:"5px", marginLeft:"-12px", marginBottom:"15px"}}>
  
      <TextField
        style={{
          width: "100%",
          borderRadius: "46px",
          marginTop: mediaQueryVar === true ? "" : "60px",
          fontFamily: "Satoshi, sans-serif",
          borderRadius:"46px",
          border:" 1px #1877F2 solid",
          backgroundColor:"#fff",
          paddingTop:"2px",
          paddingBottom:"2px",
          paddingLeft:"12px",
          paddingRight:"30px",
          fontSize:"16px",
          fontWeight:"500",
          
        }}
        className="cryptocurrencyListHeaderSearch"
        size="small"
        placeholder="Search Cryptos"
        onInput={(e) => setFilter(e.target.value)}
        value={filter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src="images/search.svg"/>
            </InputAdornment>
          ),
          style: {
            border: "none", // Remove the border from the input
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent', // Remove the border of the fieldset
            },
            '&:hover fieldset': {
              borderColor: 'transparent', // Remove the border on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent', // Remove the border when focused
            },
          },
        }}
      />
    
  </Grid>
)}
{loader === true ? (
  <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
    {sortedSlugdata.map((obj, idx) => (
      <li
      key={idx}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 2px 10px 0px",
        borderBottom: "1px solid #ECECEC",
        cursor: "pointer",
      }}
     
    >
      {/* Logo and Name */}
      <div style={{ display: "flex", flexDirection: "column", marginLeft: "-10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={obj.logo}
            alt={`${obj.slug} logo`}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              border: "2px solid black",
              marginRight: "15px",
            }}
          />
    
          {/* Name and Slug */}
          <div
            style={{
              fontSize: "15px",
              fontWeight: "500",
              fontFamily: "Satoshi, sans-serif",
              color: "black",
              maxWidth: "200px", // Adjust to fit your design
            }}
          >
            {(obj.grade === "Moderate Performer") && obj.name.length + obj.slug.length > 22 ? (
              <>
                {obj.name.slice(0, 23 - obj.slug.length)}- <br />
                {obj.name.slice(23 - obj.slug.length)} ({obj.slug})
              </>
            ) : (
              `${obj.name} (${obj.slug})`
            )}
          </div>
        </div>
      </div>
    
      {/* Grade */}
      <span
        style={{
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Satoshi, sans-serif",
          color: "gray",
          whiteSpace: "nowrap",
        }}
      >
        <Button
                                                                      className="btn"
                                                                      variant="outlined"
                                                                      onClick={() => handleAddToPortfolio(obj.slug)}
                                                                      style={{
                                                                          backgroundColor: "white",
                                                                          padding: "4px 10px",
                                                                          fontFamily: "Satoshi, sans-serif",
                                                                          borderRadius: "48px",
                                                                          border: "1px solid #1877F2",
                                                                          textTransform: "none",
                                                                          fontSize: mediaQueryVar === false ? "10px" : "11px",
                                                                          fontWeight: "500",
                                                                          color: "#1877F2",
                                                                          whiteSpace: "nowrap",
                                                                          width: "120px",
                                                                          height: "30px",
                                                                          marginLeft: "20px",
                                                                          marginBottom: "10px",
                                                                      }}
                                                                  >
                                                                      Add to Portfolio
                                                                  </Button>
      </span>
    </li>
    
    ))}
  </ul>
) : (
  <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
    {Array(20)
      .fill(0)
      .map((_, idx) => (
        <li
          key={idx}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px",
            borderBottom: "1px solid #ECECEC",
          }}
        >
          {/* Skeleton for Logo */}
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "#E0E0E0",
              marginRight: "15px",
            }}
          ></div>

          {/* Skeleton for Name/Slug */}
          <div style={{ flex: 1 }}>
            <div
              style={{
                width: "150px",
                height: "16px",
                backgroundColor: "#E0E0E0",
                marginBottom: "5px",
              }}
            ></div>
          </div>

          {/* Skeleton for Grade */}
          <div
            style={{
              width: "80px",
              height: "16px",
              backgroundColor: "#E0E0E0",
            }}
          ></div>
        </li>
      ))}
  </ul>
)}

 {loader && loaderbutton && (
          <div className="loadingButton">
            <LoadingButton
              loading={addMoreLoader}
              loadingPosition="center"
              variant="outlined"
              style={{
                maxWidth: "60%",
                marginTop:"0px",
                marginBottom:"70px",
                backgroundColor: "rgba(67, 97, 238, 0.15)",
                borderRadius: "6px",
                border: "none",
                textTransform: "none",
                borderStyle: "solid",
                borderColor: "rgba(67, 97, 238, 0.15)",
                borderWidth: "1px",
                color: "#4361ee",
                width: mediaQueryVar === false ? "80vw" : "140px",
                marginLeft:"10px"
              }}
              onClick={() => {
                loadMore();
              }}
            >
              <span style={{ color: "#4361ee" }}>Load More</span>
            </LoadingButton>
          </div>
        )}
             
              
           
              </>
            
            )}
            {selectedButton === "watchlist" && (
               <>
               <WatchListGallery filter={filter} onButtonClick={handleButtonClick}/>
                       </>

            )}
            
          </>
        )}
        {loaderForSymbolLink===true &&
         <div >
              <CircularProgress style={{position:"fixed",top:"40vh",left:"45vw"}}/>
          </div> 
          }
          
      </Box>
      <Modal
  sx={{ overflow: "hidden" }}
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <div className="CardOpener" style={{ overflow: "hidden" }}>
    {isFormSubmitted ? (
      <Card className="card__container">
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: 50, mr: 1 }} />
          </div>
          <Typography
            severity="success"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Portfolio added successfully!
          </Typography>
        </>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              setIsFormSubmitted(false);
              setOpen(false);
              setUnit("");
              setSelectedOption("");
              setPrice("");
              setTotalUnits(0);
              handleDateChange("");
              setUsablewallet("");
            }}
            color="primary"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "150px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
          >
            Add More Cryptos
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                  selectedOption.label
                )}`
              )
            }
            variant="outlined"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "100px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
            color="primary"
          >
            View Portfolio
          </Button>
        </div>
      </Card>
    ) : (
      <Dialog
        PaperProps={{
          sx: {
            position: mediaQueryVar ? 'static' : 'fixed',
            bottom: mediaQueryVar ? '' : 0,
            left: mediaQueryVar ? '' : 0,
            right: mediaQueryVar ? '' : 0,
            margin: '0',
            maxWidth: '100%',
            minHeight: '50%', // Adjust max height if needed
            borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
            padding: "10px",
            alignItems: "center",
            overflow: "scroll", 
            '&::-webkit-scrollbar': {
              display: 'none',
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'// Ensure no scrollbar
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <div style={{ padding: '20px', width: mediaQueryVar===false?'100%': '400px', margin: '0' ,marginTop:"-5px",}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottom:"1px #E3E3E3 solid" }}>
            <Typography style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft: "25%" }} gutterBottom>
              Add To Portfolio
            </Typography>
            

            <div className="primaryButton">
              <img
                className="frameIcon"
                alt=""
                onClick={handleClose}
                src={window.constants.asset_path + '/images/frame17.svg'}
                style={{ background: "#F3F3F3", width: "25px", height: "25px", 
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' ,marginLeft: mediaQueryVar === false ? '5px' : ''}}>
            <Typography style={{ fontSize: "13.845px", fontWeight: '500', marginBottom: '1px', color: 'black',marginTop:"-8px" }}>
              Select Portfolio
            </Typography>
            <div style={{ marginTop: '5px' }}>
              <CreatableSelect
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '12px',
                    border: '1px solid #1877F2',
                    boxShadow: 'none',
                    minHeight: '40px',
                    '&:hover': {
                      borderRadius: '12px',
                      border: '2px solid #1877F2',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#1877F2',
                    border: "1px solid #1877F2",
                    padding: "5px",
                    margin: "8px",
                    borderRadius: "3.786px",
                    '&:hover': {
                      color: '#1877F2',
                    },
                  }),
                  indicatorSeparator: (provided) => ({
                    display: 'none',
                  }),
                }}
                placeholder=""
                onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                onCreateOption={handleCreate}
                options={[
                  { value: '', label: 'Create new portfolio', _isNew_: true }, // Dynamic "create new option" item
                  ...options // Existing options
                ]}
                value={selectedOption}
              />
            </div>
            {showAlert && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          {errorMessage}
        </Alert>
        
      )}
          </div>
          <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '10px', marginBottom: '5px' }}>
          Usable Wallet Value: {Usablewallet}
          </Typography>
          <div style={{ padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
              <img
                style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "27.69px", width: "27.69px" }}
                alt=""
                src={slugpopupdata.logo}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "14px", color: "#7F7F80" }}>
                  {slugpopupdata.slugname}
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'black', margin: '0' }}>
                  {slugpopupdata.slug}
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px', flex: 1 }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Price</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {slugpopupdata.pricetoday}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Units</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {totalUnits}
              </Typography>
            </div>
          </div>
          <div className="card__container__row3">
            <div style={{marginLeft:"-5px"}}>
              <p style={{ fontSize: "14px", fontWeight: "500", }}>Enter number of Units</p>
              <input
                style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "100%", height: "45px",margin:0 }}
                min="1"
                max="10"
                type="number"
                placeholder=""
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              ></input>
            </div>
            <div>
            ${formatToNonZero(
  unit * parseFloat((slugpopupdata.pricetoday || '').replace(/^\$/, ''))
)}

    </div>
            {user_role === "superuser" && (
              <>
                <div className="card__container__row3__r2">
                  <div style={{marginLeft:"-5px"}}>
                  <p  style={{ fontSize: "14px", fontWeight: "500" }}>
                    Buy/Sell Price($)
                  </p>
                  <input
                  style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "100%", height: "45px" }}
                    className="card__container__row3__r2__input"
                    min="1"
                    max="10"
                    type="number"
                    placeholder="Enter Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></input></div>
                </div>
                <div style={{marginBottom:"10px",marginLeft:"-5px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Date</p>
                      <TextField
                        type="date"
                        value={selectedDate}
                        onChange={(e) => handleDateChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10.384px',
                          border:"1px solid #1877F2",
                          boxShadow:"none",
                          width:"190%",
                          height:"45px",
                          
                        },
                        }}
                        />
                    </div>
              </>
            )}
            <div className="card__container__row3__r4" style={{ marginTop: "10px" }}>
              <Button
                className={
                  buySell === "buy"
                    ? "buttonToggleButActive"
                    : "buttonToggleBuy"
                }
                value="Buy"
                style={{
                  lineHeight: "20px",
                  width: "45%",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  padding: "15px 8px",
                  marginLeft: "5px",
                  backgroundColor: buySell === "buy" ? "" : "#1877F2",
                  color: buySell === "buy" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("buy")}
              >
                Add
              </Button>
              <Button
                className={
                  buySell === "sell"
                    ? "buttonToggleSellActive"
                    : "buttonToggleSell"
                }
                value="sell"
                style={{
                  lineHeight: "20px",
                  width: "45%",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  marginLeft: "5px",
                  marginRight:"10px",
                  padding: "15px 8px",
                  backgroundColor: buySell === "sell" ? "" : "#1877F2",
                  color: buySell === "sell" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("sell")}
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    )}
  </div>
</Modal>
<Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
  <DialogTitle>
    Create New Portfolio
    <IconButton aria-label="close"onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }}  sx={{ position: 'absolute', right: 13, top: 8 }}>
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
  <div>
      <TextField
        autoFocus
        label="Enter Name"
        value={newOptionValue}
        onChange={handleChange2}
        fullWidth
        margin="dense"
      />
      {showAlert && (
        <Alert severity="error" sx={{ marginTop: '8px' }}>
          Hyphens are not allowed in the name.
        </Alert>
      )}
    </div>
  </DialogContent>
  <DialogActions sx={{marginRight:'14px'}}>
  <Button onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }} sx={{fontWeight:'bold'}}  variant="outlined" color="primary">
      Cancel
    </Button>
    <Button onClick={handleDialogSubmit} variant="contained" color="primary" sx={{fontWeight:'bold'}}disabled={!newOptionValue.trim()}>
      Submit
    </Button>
  
  </DialogActions>
</Dialog>

        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
        </Snackbar>

      {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "100%",
            zIndex: "100",
            marginTop:"5px"
          }}
        >
          <BottomNavigation showLabels>
          <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
                   label="Screener"
                   onClick={() => navigate("/crypto-screener")}
                   icon={
                     <img
                       src={
                         loc === "/crypto-screener"
                           ? "/images/portfolioS.svg"
                           : "/images/portfolioU.svg"
                       }
                       width={"20px"}
                       height={"20px"}
                     />
                   }
                 />
</BottomNavigation>
        </div>
      )}
      {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
    </Box>
    )}
  
</>

  );
}

export default NewUserAudit;