import React, { use, useEffect } from "react";
import "./portfolioNetworthGraph.css";
import {Helmet} from "react-helmet";
import Header2 from "../../common/header/Header2";
import MainContent from "./others/MainContent";
import LoginPopUp from "../loginpopup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import BottomNavigationApp from "./others/BottomNavigation";
import { isDesktop } from "../../common/constants";
import json2mq from "json2mq";
import { useMediaQuery } from "@mui/material";

const PortfolioNetworthGraph = (props) => {
    const [isLoginModalOpen, setLoginModalOpen] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const {indexName} = useParams();
    const index = indexName ? indexName.split("-")[0] : "Crypto";
    const [index_name, setIndexName] = React.useState(index);
    const mediaQueryVar = useMediaQuery(json2mq(isDesktop));   

    const handleModalClose = () => {
        // Close the modal and reset the URL to the welcome page
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    };

    React.useEffect(() => {  
        const index = indexName ? indexName.split("-")[0] : "Crypto";
        setIndexName(index)
    },[indexName])


    return (
        <div className="Indices-page">
            <Helmet>
                <title>Crowdwisdom360 {index_name} Indices</title>
            </Helmet>
            <div>
                <Header2 index={index_name}>
                    <h6
                        style={{
                            textAlign: mediaQueryVar ? "left" : "center", // Simplified the condition
                            paddingLeft: mediaQueryVar ? "340px" : "0", // Adjust padding based on screen size
                        }}
                    >
                        <span
                            style={{
                                fontSize: "24px",
                                fontFamily: "Satoshi,sans-serif",
                                fontWeight: mediaQueryVar ? "700" : "",
                            }}
                        >
                            Cryptocurrency
                            {mediaQueryVar ? (
                                "Indices"
                            ) : (
                                <span className="bg-primary px-2 py-1 rounded text-white">Indices</span>
                            )}
                        </span>
                    </h6>
                </Header2>
                <MainContent setIndexName={setIndexName} index={index_name} setLoginModalOpen={setLoginModalOpen}/>
                <BottomNavigationApp setLoginModalOpen={setLoginModalOpen} />
                {isLoginModalOpen && (
                    <LoginPopUp
                        // isLoginModalOpen={isLoginModalOpen}
                        setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                        onClose={handleModalClose}
                    />
                )}
            </div>
        </div>
    );
};

export default PortfolioNetworthGraph;
