import React, { useCallback } from "react";
import { BottomNavigation, BottomNavigationAction, useMediaQuery } from "@mui/material";
import json2mq from "json2mq";
import { useNavigate, useLocation } from "react-router-dom";

// Memoize BottomNavigationAction for performance
const BottomNavigationActionMemo = React.memo(BottomNavigationAction);

const BottomNavigationApp = ({ setLoginModalOpen }) => {
    const mediaQueryVar = useMediaQuery(json2mq({ minWidth: 900 }));
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const location = useLocation();
    const loc = location.pathname;
    const navigate = useNavigate();

    // Memoized navigation handlers
    const handleNavigation = useCallback((path) => {
        navigate(path);
    }, [navigate]);

    const handleLoginClick = () => {
        window.history.pushState({}, "", "/login");
        localStorage.setItem("isPopupManuallyOpened", "true");
        setLoginModalOpen(true);
    };

    return (
        <div>
            {mediaQueryVar === false && (
                <div
                    style={{
                        position: "fixed",
                        bottom: "0",
                        backgroundColor: "white",
                        width: "100vw",
                        zIndex: "100",
                        marginBottom: "0px",
                    }}
                >
                    <BottomNavigation showLabels>
                        <BottomNavigationActionMemo
                            label="Top Portfolios"
                            onClick={() => handleNavigation("/portfolio-gallery")}
                            icon={
                                <img
                                    src={
                                        loc === "/portfolio-gallery"
                                            ? "/images/dashboardS.svg"
                                            : "/images/dashboardU.svg"
                                    }
                                    alt="Top Portfolios"
                                    width={24}
                                    height={24}
                                    style={{ marginBottom: "-3px" }}
                                />
                            }
                        />
                        <BottomNavigationActionMemo
                            label="Crypto Screener"
                            onClick={() => handleNavigation("/crypto-screener")}
                            icon={
                                <img
                                    src={
                                        loc === "/crypto-screener"
                                            ? "/images/portfolioS.svg"
                                            : "/images/portfolioU.svg"
                                    }
                                    alt="Crypto Screener"
                                    width={24}
                                    height={24}
                                    style={{ marginBottom: "-3px" }}
                                />
                            }
                        />
                        <BottomNavigationActionMemo
                            label="Influencer Rankings"
                            onClick={() => {
                                    handleNavigation("/crypto-influencer-rankings");
                            }}
                            icon={
                                <img
                                    src={
                                        loc === "/crypto-influencer-rankings"
                                            ? "/images/dollarS.svg"
                                            : "/images/dollarU.svg"
                                    }
                                    width={24}
                                    height={24}
                                    alt="Subscription"
                                    style={{ marginBottom: "-3px" }}
                                />
                            }
                        />
                    </BottomNavigation>
                </div>
            )}
        </div>
    );
};

export default BottomNavigationApp;
